import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";

import {
  ALL_TAB_VALUE,
  IssuesTableContextProvider,
} from "../pages/violations/ViolationsBreakdown/RealtimeSection/context/IssuesTableContext";
import { useIssuesTableContext } from "../pages/violations/ViolationsBreakdown/RealtimeSection/context/useIssuesTableContext";

import { RealtimeIssuesDrawerProps } from "./types";

import {
  CheckCategory,
  CheckType,
  ImpactGroupType,
  ViolationStatus,
} from "@/generated/reliabilityApi";
import { useGetViolations } from "@/shared/hooks/reliability-api/violations/useGetViolations";
import { useRealtimeIssues } from "@/components/ClustersView/ClusterOverview/components/HealthSection/hooks";
import { DEFAULT_ISSUES_TABLE_PAGINATION_MODEL } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/context/initialState";
import { IssuesTable } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/IssuesTableWithContext/IssuesTable";
import { IssuesTabs } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/IssuesTableWithContext/IssuesTabs/IssuesTabs";

export type Props = RealtimeIssuesDrawerProps & {
  open: boolean;
  onClose: () => void;
};

export const RealtimeIssuesDrawer: React.FC<Props> = ({
  open,
  onClose,
  checkCategory,
  checkTypes,
  initialCheckType,
  clusters,
}) => {
  const workloadIssuesStats = useRealtimeIssues(checkCategory, clusters);

  const breakdownByCheckType = workloadIssuesStats.groups.reduce(
    (acc, group) => {
      acc[group.checkType] = group.check;
      return acc;
    },
    {} as Record<CheckType, number>
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={5}
      PaperProps={{
        sx: {
          boxSizing: "border-box",
          padding: "16px",
          width: "60%",
        },
      }}
    >
      <Box display="flex" gap="8px" marginBottom="24px">
        <IconButton onClick={onClose} sx={{ padding: 0 }}>
          <Close />
        </IconButton>
        <Typography variant="h3">Realtime Issues</Typography>
      </Box>
      <IssuesTableContextProvider
        initialTabValue={initialCheckType}
        issuesBreakdown={breakdownByCheckType}
        violationsGroupBy={"RealTimeIssues"}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ paddingInline: "16px" }}
        >
          <IssuesTabs />
          <OpenIssuesTable
            checkCategory={checkCategory}
            checkTypes={checkTypes}
            clusters={clusters}
          />
        </Box>
      </IssuesTableContextProvider>
    </Drawer>
  );
};

const OpenIssuesTable: React.FC<{
  checkCategory: CheckCategory;
  checkTypes: CheckType[];
  clusters?: string[];
}> = ({ checkCategory, checkTypes, clusters }) => {
  const { tabValue } = useIssuesTableContext();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    DEFAULT_ISSUES_TABLE_PAGINATION_MODEL
  );

  const getViolationsRes = useGetViolations({
    checkType: tabValue === ALL_TAB_VALUE ? checkTypes : [tabValue],
    checkCategory: [checkCategory],
    pageSize: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
    impactGroupType: [ImpactGroupType.Realtime],
    clusterName: clusters,
    status: [ViolationStatus.Open, ViolationStatus.Confirmed],
  });

  return (
    <IssuesTable
      violationsRes={getViolationsRes}
      customPaginationObj={{
        customPaginationModel: paginationModel,
        setCustomPaginationModel: setPaginationModel,
      }}
    />
  );
};
