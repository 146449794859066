type ReportingEntity = "group" | "filter" | "table";

export const getReportingNameSuffixByType = (
  type: ReportingEntity,
  name: string
) => {
  switch (type) {
    case "group":
      return `${name} group`;
    case "filter":
      return `${name} filter`;
    case "table":
      return `${name} table`;
    default:
      return name;
  }
};
