import { palette } from "@komodorio/design-system";
import { datadogRum } from "@datadog/browser-rum";
import Drawer from "@mui/material/Drawer";
import React, { memo, useEffect } from "react";
import styled from "styled-components";

import { PodExecDrawerContent } from "./PodExecDrawerContent";
import { PodExecDrawerHeader } from "./PodExecDrawerHeader";

import { dataDogViewNames } from "@/shared/constants/datadog";

interface PodExecDrawerProps {
  open: boolean;
  onClose: () => void;
  onMinimize: () => void;
  containerName: string;
  namespace: string;
  podName: string;
  agentId: string;
  cluster: string;
  identifier: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${palette.white[0]};
`;

export const PodExecDrawer: React.FC<PodExecDrawerProps> = memo(
  ({
    open,
    onClose,
    containerName,
    onMinimize,
    podName,
    namespace,
    agentId,
    cluster,
    identifier,
  }) => {
    useEffect(() => {
      datadogRum.startView(dataDogViewNames.podExecAction);
    }, []);

    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        elevation={5}
        slotProps={{
          backdrop: {
            invisible: true,
          },
        }}
        PaperProps={{ sx: { width: "60%" } }}
        data-e2e-selector="drawer"
        aria-label="Pod exec drawer"
        aria-expanded={open}
      >
        <Container>
          <PodExecDrawerHeader
            podName={podName}
            containerName={containerName}
            onClose={onClose}
            onMinimize={onMinimize}
          />
          <PodExecDrawerContent
            containerName={containerName}
            podName={podName}
            namespace={namespace}
            agentId={agentId}
            cluster={cluster}
            identifier={identifier}
          />
        </Container>
      </Drawer>
    );
  }
);
