import { useCallback } from "react";

import {
  SnackbarNotification,
  useSnackbarNotificationStore,
} from "@/shared/store/snackbarNotificationStore/snackbarNotificationStore";
import { selectSetSnackbarNotification } from "@/shared/store/snackbarNotificationStore/snackbarNotificartionStoreSelectors";

export const useSetSnackbarNotification = () => {
  const setSnackbarNotification = useSnackbarNotificationStore(
    selectSetSnackbarNotification
  );

  return useCallback(
    (notification: SnackbarNotification) => {
      setSnackbarNotification(notification);
    },
    [setSnackbarNotification]
  );
};
