/* eslint-disable max-lines */

import {
  AdvancedMultiSelect,
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ACTIONS_CONTAINER_WIDTHS } from "../../../../../../shared-ui/design-system/src/components/komodor-ui/Select/shared/components/DropDownTriggerField";

import { CustomServiceSelection } from "./CustomServiceSelection";
import {
  buildKomodorUidFromServiceInfo,
  compareServiceInfo,
  useBaselineServiceOptions,
  useComparedServicesOptions,
} from "./useComparedServicesOptions";
import { useServicesComparisonContext } from "./context/useServicesComparisonContext";
import { MAX_COMPARED_SERVICES } from "./constants";

import { useKomodorServices } from "@/shared/hooks/useKomodorServices";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ServiceInfo } from "@/shared/types/ServiceInfo";

const Container = styled.div`
  display: flex;
  gap: 24px;
  flex: 1;
`;

const StyledSingleSelect: typeof SingleSelect<string> = styled(SingleSelect)`
  & .narrowMenuList {
    padding: 0;
  }
  & .narrowMenuItem {
    padding: 5px 16px;
  }
  & .narrowSearchBar {
    padding: 1px 12px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledAdvancedMultiSelect: typeof AdvancedMultiSelect<string> = styled(
  AdvancedMultiSelect
)`
  & .narrowMenuList {
    padding: 0;
  }
  & .narrowMenuListContainer {
    padding: 0;
  }
  & .narrowMenuItem {
    padding: 0.5px 0;
  }
  & .narrowSearchBar {
    padding: 1px 12px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  & .stickyContainer {
    padding: 0;
  }

  & .narrowItemSelectionMenu {
    padding: 6px 20px;
    margin-top: 0px;
    & .MuiButton-root {
      padding: 0;
    }
  }
`;
const FullWidthCustomServiceSelection = styled(CustomServiceSelection)`
  width: 100%;
`;

export const ComparisonSelection: React.FC = () => {
  const services = useKomodorServices().servicesAsDictionary.services;
  const {
    baselineServiceId,
    comparedServicesIds,
    isBaselineSelectionOpen,
    isComparedSelectionOpen,
    setBaselineServiceId,
    setComparedServicesIds,
    setIsBaselineSelectionOpen,
    setIsComparedSelectionOpen,
  } = useServicesComparisonContext();

  const sortServices = useCallback(
    (toSort: typeof services) => {
      return Object.values(toSort).sort((a, b) => {
        const aUid = buildKomodorUidFromServiceInfo(a);
        const bUid = buildKomodorUidFromServiceInfo(b);
        if (comparedServicesIds?.some((service) => service.value === aUid)) {
          return -1;
        }
        if (comparedServicesIds?.some((service) => service.value === bUid)) {
          return 1;
        }
        const parsedBaselineKomodorUid = baselineServiceId
          ? parseKomodorUid(baselineServiceId.value)
          : null;
        const aServiceNameMatchesBaseline =
          parsedBaselineKomodorUid?.name === a.title;
        const bServiceNameMatchesBaseline =
          parsedBaselineKomodorUid?.name === b.title;
        if (aServiceNameMatchesBaseline) {
          return -1;
        }
        if (bServiceNameMatchesBaseline) {
          return 1;
        }
        return compareServiceInfo(a, b);
      });
    },
    [baselineServiceId, comparedServicesIds]
  );

  const [displayedComparedServices, setDisplayedComparedServices] = useState<
    ServiceInfo[]
  >(() => sortServices(services));

  const comparisonServicesOptions = useComparedServicesOptions(
    comparedServicesIds,
    displayedComparedServices,
    baselineServiceId
  );

  const baselineServicesOptions: MuiSelectionOption<string>[] =
    useBaselineServiceOptions(baselineServiceId, services);

  const optionToCustomElement = useCallback(
    (option: MuiSelectionOption<string>) => {
      const service = parseKomodorUid(option.value);
      if (!service) {
        return null;
      }
      return (
        <CustomServiceSelection
          clusterName={service.cluster}
          namespace={service.namespace}
          serviceName={service.name}
          maxWidth="435px"
        />
      );
    },
    []
  );

  const optionToCustomElementMultiSelect = useCallback(
    (option: MuiSelectionOption<string>, isChecked?: boolean) => {
      const service = parseKomodorUid(option.value);
      if (!service) {
        return null;
      }
      return (
        <Box display="flex" gap="16px" padding="3.5px 16px" maxWidth="90%">
          <Checkbox
            checked={isChecked}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 20 },
              padding: 0,
            }}
          />
          <FullWidthCustomServiceSelection
            clusterName={service.cluster}
            namespace={service.namespace}
            serviceName={service.name}
            maxWidth="100%"
          />
        </Box>
      );
    },
    []
  );

  const servicesLength = useMemo(
    () => Object.keys(services).length,
    [services]
  );

  const ceilComparedServices = Math.min(
    MAX_COMPARED_SERVICES,
    comparisonServicesOptions.length
  );

  const onComparedMultiSelectClose = useCallback(() => {
    setDisplayedComparedServices(sortServices(services));
    setIsComparedSelectionOpen(false);
  }, [services, setIsComparedSelectionOpen, sortServices]);

  const onBaselineSelect = useCallback(
    (value: MuiSelectionOption<string> | undefined) => {
      setComparedServicesIds([]);
      setBaselineServiceId(value ? value : null);
    },
    [setBaselineServiceId, setComparedServicesIds]
  );

  useEffect(() => {
    setDisplayedComparedServices(sortServices(services));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baselineServiceId]);

  const baselineServiceKind = useMemo(
    () =>
      baselineServiceId
        ? parseKomodorUid(baselineServiceId?.value)?.kind
        : undefined,
    [baselineServiceId]
  );
  return (
    <ResponsiveLayout>
      <Container>
        <StyledSingleSelect
          virtualizerProps={{ estimateSize: () => 28 }}
          options={baselineServicesOptions}
          placeholder="Select comparison baseline"
          label="Comparison Baseline"
          onChange={onBaselineSelect}
          value={baselineServiceId ? baselineServiceId : undefined}
          width="360px"
          minWidth="360px"
          enableSearch
          texts={{ searchPlaceholder: "Search service" }}
          customValueElement={(value) => {
            const { cluster, namespace, name } =
              parseKomodorUid(value.value) ?? {};
            return (
              <CustomServiceSelection
                clusterName={cluster ?? ""}
                namespace={namespace ?? ""}
                serviceName={name ?? ""}
                maxWidth={`${360 - ACTIONS_CONTAINER_WIDTHS.withClear}px`}
              />
            );
          }}
          customOptionElement={optionToCustomElement}
          disableBlankOption
          disabled={servicesLength == 0}
          selectPopoverProps={{
            maxHeight: "544px",
            width: "480px",
          }}
          enableVirtualization
          externalOpen={isBaselineSelectionOpen}
          onClose={() => setIsBaselineSelectionOpen(false)}
          classNames={{
            menuList: "narrowMenuList",
            menuItem: "narrowMenuItem",
            searchBar: "narrowSearchBar",
          }}
        />
        <StyledAdvancedMultiSelect
          enableLineBreaks={false}
          virtualizerProps={{ estimateSize: () => 28 }}
          options={comparisonServicesOptions}
          label="Compared Services"
          onChange={(value) => setComparedServicesIds(value)}
          placeholder="Select services to compare"
          minWidth="220px"
          enableSelectAll={true}
          selectionLimit={MAX_COMPARED_SERVICES}
          searchWithSelectAllHeight="62px"
          menuListPadding="0px"
          texts={{
            deselectAllText: "Clear all",
            selectAllText:
              ceilComparedServices < MAX_COMPARED_SERVICES
                ? "Select all"
                : `Select the first ${ceilComparedServices}`,
          }}
          width="100%"
          value={comparedServicesIds ? comparedServicesIds : undefined}
          customOptionElement={optionToCustomElementMultiSelect}
          disabled={servicesLength == 0 || !baselineServiceId}
          selectPopoverProps={{
            maxHeight: "346px",
          }}
          enableVirtualization
          classNames={{
            disabledItems: "narrowMenuList",
            enabledItems: "narrowMenuList",
            menuListContainer: "narrowMenuListContainer",
            menuItem: "narrowMenuItem",
            searchBar: "narrowSearchBar",
            stickyContainer: "stickyContainer",
            itemSelectionMenu: "narrowItemSelectionMenu",
          }}
          externalOpen={isComparedSelectionOpen}
          onClose={onComparedMultiSelectClose}
        />
      </Container>
      {baselineServiceKind && (
        <Box sx={{ display: "flex", marginTop: "5px", gap: "5px" }}>
          <Typography variant="h6" color="text.secondary">
            Kind:
          </Typography>
          <Typography fontSize="12px">{baselineServiceKind}</Typography>
        </Box>
      )}
    </ResponsiveLayout>
  );
};
