import React from "react";

import { useWorkloadsMetrics } from "../../hooks/useWorkloadsMetrics";

import { WorkloadsMetricsGraph } from "./WorkloadsMetricsGraph";

import { EnlargedMetricsGraph } from "@/components/Metrics/graphs/EnlargedMetricsGraph";
import { MetricType } from "@/components/Metrics/types";
import { WorkloadMetricsPreferences } from "@/components/ResourceView/tabs/WorkloadMetricsTab/components/WorkloadMetricsPreferences/WorkloadMetricsPreferences";

type EnlargedWorkloadsGraphProps = {
  endTime: number;
  onClose: () => void;
  type: MetricType;
  komodorUids: string[];
  title?: string;
};

export const EnlargedWorkloadsGraph: React.FC<EnlargedWorkloadsGraphProps> = ({
  endTime,
  onClose,
  type,
  komodorUids,
  title,
}) => {
  const { graphProps, error, isLoading, hasGraphData, linesContent } =
    useWorkloadsMetrics({
      type,
      komodorUids,
      endTime,
    });

  return (
    <EnlargedMetricsGraph
      title={title}
      controls={[<WorkloadMetricsPreferences key={"metricsPreferences"} />]}
      onClose={onClose}
      uid={undefined}
      hasGraphData={hasGraphData}
      loading={isLoading}
      error={error}
    >
      <WorkloadsMetricsGraph
        komodorUids={komodorUids}
        type={type}
        linesContent={linesContent}
        {...graphProps}
      />
    </EnlargedMetricsGraph>
  );
};
