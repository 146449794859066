import { useCallback, useEffect, useRef } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { Dictionary } from "../../types/Dictionary";
import { KOMODOR_LOADING_TIME } from "../../constants/datadog";

export type ReportEagerLoadingStateParams = {
  isDisplayed: boolean;
  loadingState: Dictionary<boolean>;
  viewName: string;
};

export const useReportEagerLoadingState = ({
  viewName,
  loadingState,
  isDisplayed,
}: ReportEagerLoadingStateParams) => {
  const hasReportedStartView = useRef(false);
  const hasReportedLoadingTime = useRef(false);

  const startView = useCallback(() => {
    datadogRum.startView(viewName);
    hasReportedStartView.current = true;
    hasReportedLoadingTime.current = false;
    // temporary for debugging
    datadogRum.addAction(`startView_${viewName}`, { viewName });
  }, [viewName]);

  const addTiming = useCallback((viewName: string) => {
    if (!hasReportedLoadingTime.current) {
      hasReportedLoadingTime.current = true;
      datadogRum.addTiming(KOMODOR_LOADING_TIME);
      // temporary for debugging
      datadogRum.addAction(`addTiming_KOMODOR_LOADING_TIME_${viewName}`);
    }
  }, []);

  const resetTiming = useCallback(() => {
    hasReportedStartView.current = false;
  }, []);

  useEffect(() => {
    if (!isDisplayed) {
      hasReportedStartView.current = false;
      return;
    }

    const loadingStateAsList = Object.values(loadingState);

    const resourcesAreDoneLoading =
      !!loadingStateAsList.length &&
      loadingStateAsList.every((loading) => !loading);

    !hasReportedStartView.current && startView();

    if (resourcesAreDoneLoading) {
      addTiming(viewName);
    }
  }, [addTiming, loadingState, isDisplayed, startView, viewName]);

  return { resetTiming };
};
