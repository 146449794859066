import { sortBy, uniqBy } from "lodash";
import { useMemo } from "react";

import { useAgentsContext } from "./agents/context/useAgentsContext";

import { Kubernetes_Agent_Info } from "@/generated/graphql";
import { agentResponse } from "@/shared/hooks/agents-api/utils";
import { Agent } from "@/generated/agents";

export type AgentType = Pick<
  Kubernetes_Agent_Info,
  | "id"
  | "clusterName"
  | "configuration"
  | "inactive"
  | "lastSeenAlive"
  | "createdAt"
  | "installationId"
>;

export const findActiveAgent = (
  agents: agentResponse | undefined,
  clusterName: string | undefined
): string | undefined => {
  if (!clusterName) {
    return undefined;
  }
  return findActiveAgentByClusterName(agents, clusterName)?.id;
};

export const findActiveAgentByClusterName = (
  agents: agentResponse | undefined,
  clusterName: string
): Agent | undefined => {
  const agentWithTheClusterName = agents?.activeAgents.filter(
    (a: Agent) => a.clusterName === clusterName
  );
  return agentWithTheClusterName?.sort(
    (a: Agent, b: Agent) =>
      new Date(b.lastSeenAlive).getTime() - new Date(a.lastSeenAlive).getTime()
  )?.[0];
};

const useFindActiveAgent = (
  agents: agentResponse | undefined,
  clusterName: string | undefined
): string | undefined => {
  return useMemo(() => {
    return findActiveAgent(agents, clusterName);
  }, [agents, clusterName]);
};

export const useActiveAgent = (
  clusterName: string | undefined
): string | undefined => {
  const agents = useAgentsContext();
  return useFindActiveAgent(agents, clusterName);
};

export const useActiveAgentsByClusters = (
  clustersNames: string[]
): (Agent | undefined)[] => {
  const agents = useAgentsContext();
  return useMemo(
    () =>
      clustersNames.map((clusterName) =>
        findActiveAgentByClusterName(agents, clusterName)
      ),
    [agents, clustersNames]
  );
};

export const useActiveAgentInfo = (
  clusterName: string | undefined
): Agent | undefined => {
  const agents = useAgentsContext();
  return useMemo(() => {
    if (!clusterName) return undefined;
    return findActiveAgentByClusterName(agents, clusterName);
  }, [agents, clusterName]);
};

export const useActiveAgents = (): agentResponse["activeAgents"] => {
  const allAgents = useAgentsContext();
  return useMemo(() => {
    if (!allAgents) return [];
    return sortBy(uniqBy(allAgents.activeAgents, "clusterName"), [
      "clusterName",
    ]);
  }, [allAgents]);
};
