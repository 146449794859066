import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1KomodorServicesAttributesPostUrl,
  KomodorServiceAttributesResponse,
  KomodorServicesApiApiV1KomodorServicesAttributesPostRequest,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { KomodorServiceAttributes } from "../../../../../components/servicesView/servicesAttributes/service-attributes-fetch-hook";

const KOMODOR_SERVICE_ATTRIBUTES_PATH = `/api/v1/komodor-services/attributes`;

const fetchKomodorServiceAttributes = async (
  apiClient: AxiosInstance,
  params: KomodorServicesApiApiV1KomodorServicesAttributesPostRequest
): Promise<KomodorServiceAttributesResponse> => {
  const { data } = await apiClient.post(
    apiV1KomodorServicesAttributesPostUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    ),
    params.komodorServiceAttributesParams
  );
  return data;
};

export const useGetKomodorServiceAttributes = (
  params: KomodorServicesApiApiV1KomodorServicesAttributesPostRequest,
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [KOMODOR_SERVICE_ATTRIBUTES_PATH, params],
    () => fetchKomodorServiceAttributes(apiClient, params),
    options
  );
};

export const convertToKomodorServiceAttributes = (
  data: KomodorServiceAttributesResponse | undefined
): KomodorServiceAttributes | undefined => {
  return data?.data?.map((komodorServiceAttribute) => {
    return komodorServiceAttribute as KomodorServiceAttributes[0];
  }) as KomodorServiceAttributes;
};
