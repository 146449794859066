// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useUserMetadata } from "./useUserMetadata";

export const useIsAccountSetUp = (): boolean => {
  const { accountId } = useUserMetadata();
  return !!accountId;
};

export const isDateInThePast = (dateString: undefined | string): boolean => {
  if (!dateString) return false;
  const dateTime = new Date(dateString).getTime();
  return dateTime < Date.now();
};
