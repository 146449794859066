import styled from "styled-components";

import { EmptyState } from "@/components/common/UpgradeAgentVersionAlert/EmptyState";
const EmptyStateContainer = styled.div`
  display: grid;
`;

const StyledEmptyState = styled(EmptyState)`
  && {
    width: 100%;
  }
`;
// Duplication of services/web/src/components/workspaces/WorkspaceGenericEmptyState.tsx
export const NodeAccessibilityComponent: React.FC = () => {
  return (
    <EmptyStateContainer style={{ paddingTop: 100 }}>
      <StyledEmptyState
        title={
          <>
            The Nodes screen is unavailable for this type of scope.
            <br />
            Please select a specific workspace to view the Nodes screen
          </>
        }
      ></StyledEmptyState>
    </EmptyStateContainer>
  );
};
