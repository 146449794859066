import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";

import { LinesLoader } from "../common/loaders/Line";
import { H2 } from "../common/typography";

import { useLogout } from "@/components/Logout/useLogout";
import { WORKSPACES_TOP_BAR_HEIGHT } from "@/components/TopBar/TopBar";

export const LogoutScene: React.FC = () => {
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <Stack
      direction="row"
      height={`calc(100vh - ${WORKSPACES_TOP_BAR_HEIGHT})`}
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Stack>
        <H2>Logging out...</H2>
        <LinesLoader marginTop={"10px"} />
      </Stack>
    </Stack>
  );
};
