export enum FlowType {
  UnhealthyPod = "Unhealthy Pod",
  UnhealthyNode = "Unhealthy Node",
  FailedDeploy = "Failed Deploy",
  FailedJobRun = "Failed Job Run",
  AvailabilityIssue = "Availability Issue",
  AvailabilityIssueIntroduction = "Availability Issue Introduction",
  UnsyncedExternalDns = "Not synced External DNS",
  CertificateIssue = "Certificate Issue",
}
