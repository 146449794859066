import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { ViolationsPage } from "./components/pages/violations/ViolationsPage";
import { TopSection } from "./components/TopSection/TopSection";
import { ReliabilityRoutes } from "./constants/reliabilityConstants";
import { useReliabilityPages } from "./hooks/useReliabilityPages";

import { DatadogReportLoadingTimeContextProvider } from "@/shared/context/datadogReportLoadingTime/DatadogReportLoadingTimeProvider";
import { useDatadogReportingProps } from "@/components/reliability/hooks/useDatadogReportingProps";

const MainContainer = styled.div`
  width: 100%;
  background-color: ${muiColors.gray[50]};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MaxWidthContainer = styled.div`
  min-width: 1000px;
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  & > div:nth-child(2) {
    flex-grow: 1;
  }
`;

const PageContainer = styled.div`
  margin-top: 24px;
`;

export const ReliabilityView: React.FC = () => {
  const routes = useReliabilityPages();

  return (
    <DatadogReportLoadingTimeContextProvider {...useDatadogReportingProps()}>
      <MainContainer>
        <TopSection />
        <MaxWidthContainer>
          <PageContainer>
            <Routes>
              <Route
                path={ReliabilityRoutes.violations}
                element={<ViolationsPage />}
              />
              {routes?.bestPractices && (
                <Route
                  path={ReliabilityRoutes.bestPractices}
                  element={<ViolationsPage />}
                />
              )}
              <Route
                path="*"
                element={<Navigate to={ReliabilityRoutes.violations} replace />}
              />
            </Routes>
          </PageContainer>
        </MaxWidthContainer>
      </MainContainer>
    </DatadogReportLoadingTimeContextProvider>
  );
};
