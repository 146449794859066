import { createContext, useContext } from "react";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export const modalContext = createContext<ModalProps>({} as ModalProps);

export const useModal = (): ModalProps => useContext(modalContext);
