import React from "react";

import { AutoScaler } from "@/generated/addonsApi";
import { ResourceUsageBar } from "@/components/k8sAddons/components/table/ResourceUsageBar/ResourceUsageBar";

type ResourceUsageCellProps = {
  autoscalerData: AutoScaler;
};

export const ResourceUsageCell: React.FC<ResourceUsageCellProps> = ({
  autoscalerData,
}) => {
  const { cpuResourceData, memoryResourceData } = autoscalerData;

  return (
    <ResourceUsageBar
      cpuResourceData={{
        resourceData: cpuResourceData,
        title: "CPU",
        type: "cpu",
      }}
      memoryResourceData={{
        resourceData: memoryResourceData,
        title: "Mem",
        type: "memory",
      }}
    />
  );
};
