import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { AutoScalerEnum } from "@/generated/addonsApi";
import { nodeAutoscalersTypeMapper } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersTable/nodeAutoscalersTypeMapper";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

type AutoscalerTypeCellProps = {
  type: AutoScalerEnum;
};

export const AutoscalerTypeCell: React.FC<AutoscalerTypeCellProps> = ({
  type,
}) => {
  const { title, icon: Icon } = nodeAutoscalersTypeMapper[type];
  return (
    <Container>
      <Icon />
      <Typography variant={"body2"}>{title}</Typography>
    </Container>
  );
};
