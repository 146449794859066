import { useMemo } from "react";

import { StatusCondition } from "@/generated/addonsApi";

/** TODO: this calculation should be performed in BE, temp solution */
export const useSelectedCondition = (
  conditions: StatusCondition[] | undefined
) => {
  return useMemo(() => {
    if (!conditions) return undefined;

    const sortedConditions = conditions.sort((a, b) => {
      if (!a.lastTransitionTime || !b.lastTransitionTime) return 0;
      return (
        new Date(b.lastTransitionTime).getTime() -
        new Date(a.lastTransitionTime).getTime()
      );
    });

    return (
      sortedConditions.find((condition) => condition.type === "Ready") ??
      sortedConditions[0]
    );
  }, [conditions]);
};
