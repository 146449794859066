import { useCallback, useMemo } from "react";
import { Dot16 } from "@komodorio/design-system/icons";

import { workloadsStrokesColors } from "../consts";
import { useParseKomodorUids } from "../hooks/metricsRequestsHooks";

import { TooltipContainer } from "@/components/Metrics/Tooltip/Tooltip";
import { TooltipItem } from "@/components/Metrics/Tooltip/tooltipTypes";
import {
  CpuUnit,
  CustomGraphDataPoint,
  MemoryUnit,
  MetricsAggregationType,
  metricsTypeToName,
} from "@/components/Metrics/types";
import {
  formatTooltipCPUTick,
  formatTooltipMemoryTick,
} from "@/components/Metrics/utils";
import { isTooltipDataValid } from "@/components/Metrics/Tooltip/tooltipUtils";

interface Payload {
  payload: CustomGraphDataPoint;
}
type WorkloadsGraphTooltipProps = {
  payload?: unknown[];
  label?: number;
  unit?: MemoryUnit | CpuUnit;
  isCpu: boolean;
  komodorUids: string[];
  aggregationType: MetricsAggregationType;
};

export const WorkloadsGraphTooltipWrapper: React.FC<
  WorkloadsGraphTooltipProps
> = (props) => {
  if (
    !isTooltipDataValid({
      label: props.label,
      payload: props.payload,
    })
  )
    return null;

  return <WorkloadsGraphTooltip {...props} />;
};

export const WorkloadsGraphTooltip: React.FC<WorkloadsGraphTooltipProps> = ({
  isCpu,
  komodorUids,
  aggregationType,
  ...props
}) => {
  const payloadData = (props.payload?.[0] as Payload).payload;
  const dataTransformFunction = useCallback(
    (tick: string) => {
      return isCpu
        ? formatTooltipCPUTick(tick, props.unit as CpuUnit)
        : formatTooltipMemoryTick(tick, props.unit as MemoryUnit);
    },
    [isCpu, props.unit]
  );
  const parsedKomodorUids = useParseKomodorUids(komodorUids);

  const tooltipItems = useMemo(() => {
    return parsedKomodorUids.map((parsedKomodorUid, index) => {
      const metricType = metricsTypeToName[aggregationType];
      const key = `${metricType}-${index}`;
      const { value: formattedValue, format } = dataTransformFunction(
        payloadData[key]?.toString() ?? ""
      );
      const item: TooltipItem = {
        key: key,
        title: `${parsedKomodorUid.cluster}/${parsedKomodorUid.namespace}/${parsedKomodorUid.name}`,
        value: formattedValue,
        format: format,
        icon: (
          <Dot16 color={workloadsStrokesColors[index % komodorUids.length]} />
        ),
      };
      return item;
    });
  }, [
    aggregationType,
    dataTransformFunction,
    komodorUids.length,
    parsedKomodorUids,
    payloadData,
  ]);

  return (
    <TooltipContainer tooltipItems={tooltipItems} label={props.label ?? 0} />
  );
};
