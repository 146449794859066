import { Breadcrumbs } from "@komodorio/design-system/komodor-ui";
import { breadcrumbsClasses, typographyClasses } from "@mui/material";

export const CustomServiceSelection: React.FC<{
  clusterName: string;
  namespace: string;
  serviceName: string;
  maxWidth?: string;
  className?: string;
}> = ({
  clusterName,
  namespace,
  serviceName,
  maxWidth = "100%",
  className,
}) => {
  return (
    <Breadcrumbs
      className={className}
      breadcrumbs={[clusterName, namespace, serviceName]}
      allowOverflow={false}
      sx={{
        maxWidth: maxWidth,
        [`& .${breadcrumbsClasses.separator}`]: {
          marginLeft: 0,
          marginRight: 0,
          color: "text.disabled",
        },
        [`& .${breadcrumbsClasses.li}:last-of-type .${typographyClasses.root}`]:
          {
            color: "text.primary",
          },
      }}
    />
  );
};
