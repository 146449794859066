import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { isEqual } from "lodash";

import { Dictionary } from "@/shared/types/Dictionary";
import { ResourceActions } from "@/shared/constants/resourceActions";

export type ReportedAction = {
  id: string;
  action: ResourceActions;
};

export type InspectionState = {
  selectedNamespaces: string[];
  reportedActions: Dictionary<ResourceActions>;
};

export type InspectionActions = {
  setSelectedNamespaces: (selectedNamespaces: string[]) => void;
  setReportedAction: (reportedAction: ReportedAction) => void;
  removeReportedAction: (resourceId: string) => void;
};

export type InspectionStore = InspectionState & InspectionActions;

export const initialState: InspectionState = {
  selectedNamespaces: [],
  reportedActions: {},
};

export const useInspectionStore = create(
  immer<InspectionStore>((set) => ({
    ...initialState,
    setSelectedNamespaces: (namespaces: string[]) => {
      set((state) => {
        if (!isEqual(state.selectedNamespaces, namespaces)) {
          state.selectedNamespaces = namespaces;
        }
      });
    },
    setReportedAction: (reportedAction: ReportedAction) => {
      set((state) => {
        const { id } = reportedAction;
        if (!state.reportedActions[id]) {
          state.reportedActions[id] = reportedAction.action;
        }
      });
    },
    removeReportedAction: (resourceId: string) => {
      set((state) => {
        delete state.reportedActions[resourceId];
      });
    },
  }))
);
