import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import MoreVert from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import useClipboard from "react-use-clipboard";

import {
  BasicViolation,
  ViolationStatus,
} from "../../../../../generated/reliabilityApi";
import { reliabilityArialLabels } from "../../../reliabilityArialLabels";
import { ViolationStatusToLabel } from "../../../constants/violationStatusToLabel";

import { IgnoreCheckViolationDialog } from "./IgnoreCheckViolationDialog/IgnoreCheckViolationDialog";
import { useUpdateViolationStatusAndCachedData } from "./hooks/useUpdateViolationStatusAndCachedData";
import { useViolationMenuItems } from "./hooks/useViolationMenuItems";
import { ActionType } from "./IgnoreCheckViolationDialog/IgnoreCheckViolationDialogTypes";

import { IsRealtimeIssues } from "@/components/reliability/ReliabilityTypes";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled(Typography).attrs<{ size: StatusSize }>(
  ({ size }) => ({
    variant: size === "small" ? "h6" : "body2",
  })
)<{ size: StatusSize }>`
  && {
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const {
  violationOptionsMenu: optionsMenuAriaLabel,
  violationStatusMenuButton: statusButtonAriaLabel,
} = reliabilityArialLabels;

type StatusSize = "small" | "large";

interface Props extends IsRealtimeIssues {
  violation: BasicViolation;
  violationLink?: string;
  size?: StatusSize;
}

const actionTypeToViolationStatus: Partial<
  Record<ActionType, ViolationStatus>
> = {
  Acknowledge: "confirmed",
  Open: "open",
  "Mark as resolved": "manually_resolved",
};

export const ViolationStatusSelector: React.FC<Props> = ({
  violation,
  violationLink,
  size = "small",
  isRealtimeIssues = false,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [ignoreRuleDialogOpen, setIgnoreRuleDialogOpen] = useState(false);
  const [isUpdatingViolation, setIsUpdatingViolation] = useState(false);
  const menuAnchorEl = useRef(null);
  const [violationStatus, setViolationStatus] = useState<
    ViolationStatus | undefined
  >(violation.status);
  const [, copy] = useClipboard(violationLink ?? "");

  const updateViolationStatusAndCachedData =
    useUpdateViolationStatusAndCachedData(isRealtimeIssues);

  const onOptionSelected = useCallback(
    async (option: string) => {
      const actionType = option as ActionType;
      if (actionType === "Copy URL") {
        copy();
        return setMenuIsOpen(false);
      }
      if (actionType === "Ignore") {
        setMenuIsOpen(false);
        return setIgnoreRuleDialogOpen(true);
      }

      const newStatus = actionTypeToViolationStatus[actionType];
      const previousStatus = violation.status;

      setViolationStatus(newStatus);
      setMenuIsOpen(false);

      setIsUpdatingViolation(true);

      const { error } = await updateViolationStatusAndCachedData({
        violation,
        newStatus,
      });

      error && setViolationStatus(previousStatus);
      setIsUpdatingViolation(false);
    },
    [copy, updateViolationStatusAndCachedData, violation]
  );

  const menuItems = useViolationMenuItems({
    violationLink,
    onOptionSelected,
    violation,
  });

  if (!violationStatus) return null;

  const statusLabel = ViolationStatusToLabel[violationStatus].toUpperCase();
  const statusColor = isUpdatingViolation ? "text.secondary" : "text.primary";

  return (
    <Container aria-label={optionsMenuAriaLabel}>
      <StyledTitle size={size} color={statusColor}>
        {statusLabel}
      </StyledTitle>
      <IconButton
        onClick={(e) => {
          e.stopPropagation(); // prevent onRowClick from firing
          setMenuIsOpen(true);
        }}
        ref={menuAnchorEl}
        aria-label={statusButtonAriaLabel}
      >
        <MoreVert />
      </IconButton>
      <Menu
        open={menuIsOpen}
        onClose={() => setMenuIsOpen(false)}
        anchorEl={menuAnchorEl.current}
      >
        {menuItems}
      </Menu>
      <IgnoreCheckViolationDialog
        violation={violation}
        open={ignoreRuleDialogOpen}
        onClose={() => setIgnoreRuleDialogOpen(false)}
      />
    </Container>
  );
};
