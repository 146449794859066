import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import { format, parseISO } from "date-fns";

import { AccordionContentItemContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { CertificateFullStatus } from "@/generated/addonsApi";
import { StatusWithPopover } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/StatusSection/StatusWithPopover";
import { StatusTitleWithTooltip } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/StatusSection/StatusTitleWithTooltip";
import { certificateDrawerStatusOrder } from "@/components/k8sAddons/addons/CertManager/certManagerConstants";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { useCertificateData } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateData";
import { useSelectedCondition } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/StatusSection/useSelectedCondition";

export const DATE_FORMAT = "LLL dd, yyyy, HH:mm:ss";

export const StatusSection: React.FC = () => {
  const { data: certificateData } = useCertificateData();
  const certificateStatus = certificateData?.data?.object.status;

  const parseDate = (date: string) => {
    let content;
    try {
      content = format(parseISO(date), DATE_FORMAT);
    } catch (e) {
      content = date;
    }
    return <Typography variant={"body2"}>{content}</Typography>;
  };

  const conditionToUse = useSelectedCondition(certificateStatus?.conditions);

  const content = useMemo(() => {
    if (!certificateStatus) return null;
    return (
      <>
        {Object.entries(certificateStatus)
          .sort((a, b) => {
            const key1 = a[0] as keyof CertificateFullStatus;
            const key2 = b[0] as keyof CertificateFullStatus;
            return (
              certificateDrawerStatusOrder.indexOf(key1) -
              certificateDrawerStatusOrder.indexOf(key2)
            );
          })
          .map(([key, value]) => {
            return (
              <AccordionContentItemContainer key={key}>
                <StatusTitleWithTooltip
                  status={key as keyof CertificateFullStatus}
                />
                {key !== "conditions" ? (
                  parseDate(value)
                ) : (
                  <StatusWithPopover condition={conditionToUse} />
                )}
              </AccordionContentItemContainer>
            );
          })}
      </>
    );
  }, [certificateStatus, conditionToUse]);

  return (
    <AccordionWrapper
      title={"Status"}
      accordionContent={content}
      ariaLabel={certificatesPageAriaLabels.drawer.sections.status}
    />
  );
};
