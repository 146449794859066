import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { getStoredAccountId } from "@/shared/utils/auth0/localEnv/storedAccountId";

export const useIsMultipleAccountsUserOnFirstLogin = (): boolean => {
  const { allowedAccounts } = useUserMetadata();
  const storedAccountId = getStoredAccountId();

  const hasMultipleAccounts =
    allowedAccounts && allowedAccounts !== "{}" && allowedAccounts?.length > 2;

  return Boolean(hasMultipleAccounts && !storedAccountId);
};
