import React, { useState } from "react";

import { ActionButtonProps } from "@/components/Actions/buttons/types";
import { SuspendResumeCronjobModal } from "@/components/Actions/modals/SuspendResumeCronjobModal/SuspendResumeCronjobModal";
import Action from "@/components/Actions/buttons/index";
import { AriaLabels } from "@/shared/config/ariaLabels";

const { Suspend: suspendAriaLabel, Resume: resumeAriaLabel } =
  AriaLabels.Inspection.Actions.Buttons;

const useModalProps = (
  setIsActionActive: ActionButtonProps["setIsActionActive"]
) => {
  const [openModal, setOpenModal] = useState(false);
  const onClick = () => {
    setOpenModal(true);
    setIsActionActive?.(true);
  };
  const onClose = () => {
    setOpenModal(false);
    setIsActionActive?.(false);
  };

  return {
    openModal,
    onClick,
    onClose,
  };
};

export const JobSuspendButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const { onClick, onClose, openModal } = useModalProps(setIsActionActive);

  return (
    <div>
      <Action
        agentId={resource.agentId}
        cluster={resource.cluster}
        onClick={onClick}
        Button={Button}
        ariaLabel={suspendAriaLabel}
      />
      <SuspendResumeCronjobModal
        onClose={onClose}
        open={openModal}
        actionType="suspend"
        resourceData={resource}
        reportAction={true}
      />
    </div>
  );
};

export const JobResumeButton: React.FC<ActionButtonProps> = ({
  resource,
  Button,
  setIsActionActive,
}) => {
  const { onClick, onClose, openModal } = useModalProps(setIsActionActive);
  return (
    <div>
      <Action
        agentId={resource.agentId}
        cluster={resource.cluster}
        onClick={onClick}
        Button={Button}
        ariaLabel={resumeAriaLabel}
      />
      <SuspendResumeCronjobModal
        onClose={onClose}
        open={openModal}
        actionType="resume"
        resourceData={resource}
        reportAction={true}
      />
    </div>
  );
};
