import { useEffect, useRef } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";

import { GetViolationsResponse } from "@/generated/reliabilityApi";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { getReportingNameSuffixByType } from "@/components/reliability/utils/loadingStateUtils";

type UseReportGroupLoadingStateParams = {
  uid: string;
  violationsRes: UseQueryResult<AxiosResponse<GetViolationsResponse>>;
};

export const useReportTableLoadingState = ({
  uid,
  violationsRes,
}: UseReportGroupLoadingStateParams) => {
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    const reportLoadingStateName = `${uid} table`;
    const { isLoading } = violationsRes;
    reportLoadingState(reportLoadingStateName, isLoading);
  }, [uid, reportLoadingState, violationsRes]);
};

export const useRegisterTableComponentLoadingState = (
  isOpen: boolean,
  id: string
) => {
  const hasRegisteredTableComponent = useRef(false);
  const { reportLoadingState } = useDatadogReportLoadingTimeContext();

  useEffect(() => {
    if (isOpen && !hasRegisteredTableComponent.current) {
      // if the group is open and the table component has not been rendered yet,
      // pre-register the table component to report loading state in order to prevent a false triggerStartView
      const tableReportingName = getReportingNameSuffixByType("table", id);
      hasRegisteredTableComponent.current = true;
      reportLoadingState(tableReportingName, true);
    }
  }, [id, isOpen, reportLoadingState]);
};
