import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import { useHasPermissions } from "../../../shared/hooks/useUserMetadata/rbac";
import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";
import { Header } from "../styles";
import { useGetUsers } from "../../../shared/hooks/auth-service/client/users/useGetUsers";
import { User } from "../../../generated/auth";

import BulkUsersFormModal from "./BulkUsersFormModal";
import DeleteUserModal from "./DeleteUserModal";
import UserFormModal from "./UserFormModal";

import { RestoreUserModal } from "@/components/Settings/Users/RestoreUserModal";
import { useUsersPage } from "@/components/Settings/Users/hooks";
import { isOnlyOneAccountAdmin } from "@/components/Settings/Users/utils";
import { ariaLabels } from "@/components/Settings/Users/const";
import { UserPermissionsDrawer } from "@/components/Settings/Users/UserPermissionsDrawer";
import { UserDrawerHeader } from "@/components/Settings/Users/components/UserDrawerHeader";
import { UserDrawerContent } from "@/components/Settings/Users/components/UserDrawerContent";
import { VIEWED_USER } from "@/shared/config/urlSearchParamsKeys";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";

export const Users: React.FC = () => {
  const [search, setSearch] = useState("");
  const { data: users, refetch } = useGetUsers();

  const [openAddUser, setOpenAddUser] = useState(false);
  const [openBulkAddUsers, setOpenBulkAddUsers] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User>();
  const [userToDelete, setUserToDelete] = useState<User>();
  const [userToRestore, setUserToRestore] = useState<User>();
  const [userToViewPermissions, setUserToViewPermissions] = useState<User>();

  const [viewedUser, setViewedUser] = useStateInSearchParams(VIEWED_USER);

  useEffect(() => {
    if (viewedUser) {
      setUserToViewPermissions(
        users?.find((user: User) => user.id === viewedUser) as User
      );
    }
  }, [viewedUser, users]);

  const handleViewUserClick = useCallback(
    (user: User | undefined) => {
      setViewedUser(user?.id || null);
      setUserToViewPermissions(user);
    },
    [setViewedUser, setUserToViewPermissions]
  );

  const { tableContent, ref } = useUsersPage({
    search,
    users,
    setUserToEdit,
    setUserToDelete,
    setUserToRestore,
    setUserToViewPermissions: handleViewUserClick,
  });

  const { canManageUsers } = useHasPermissions();
  const allowOneAccountAdmin = isOnlyOneAccountAdmin(users);

  const drawerContent = useMemo(() => {
    if (!userToViewPermissions) {
      return null;
    }

    return (
      <Stack direction="column" rowGap="24px">
        <UserDrawerHeader
          user={userToViewPermissions}
          onClose={() => handleViewUserClick(undefined)}
        />
        <UserDrawerContent user={userToViewPermissions} />
      </Stack>
    );
  }, [userToViewPermissions, handleViewUserClick]);

  return (
    <SettingsViewVerticalLayout title={"Users"}>
      <Stack ref={ref}>
        <Header>
          <SearchField
            size="medium"
            width="15rem"
            placeholder="Search"
            value={search}
            showCloseIcon={true}
            ariaLabel={ariaLabels.page.search}
            onSearch={(value) => setSearch(value)}
          />
          {canManageUsers && (
            <Stack direction="row" columnGap={1}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                size="medium"
                onClick={() => setOpenBulkAddUsers(true)}
              >
                Bulk add
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                size="medium"
                onClick={() => setOpenAddUser(true)}
              >
                Add user
              </Button>
            </Stack>
          )}
        </Header>
        <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
        {tableContent}
        {openBulkAddUsers && (
          <ValidationsProvider>
            <BulkUsersFormModal
              open
              handleClose={() => setOpenBulkAddUsers(false)}
              refreshUsers={refetch}
            />
          </ValidationsProvider>
        )}
        {openAddUser && (
          <ValidationsProvider>
            <UserFormModal
              open
              handleClose={() => setOpenAddUser(false)}
              refreshUsers={refetch}
            />
          </ValidationsProvider>
        )}
        {!!userToEdit && (
          <ValidationsProvider>
            <UserFormModal
              open
              handleClose={() => setUserToEdit(undefined)}
              user={userToEdit}
              refreshUsers={refetch}
              isOnlyOneAccountAdmin={allowOneAccountAdmin}
            />
          </ValidationsProvider>
        )}
        {!!userToRestore && (
          <RestoreUserModal
            open
            handleClose={() => setUserToRestore(undefined)}
            user={userToRestore}
            refreshUsers={refetch}
          />
        )}
        {userToDelete && (
          <DeleteUserModal
            open
            handleClose={() => setUserToDelete(undefined)}
            user={userToDelete}
            refreshUsers={refetch}
          />
        )}
        <UserPermissionsDrawer
          open={!!userToViewPermissions}
          onClose={() => handleViewUserClick(undefined)}
        >
          {drawerContent}
        </UserPermissionsDrawer>
      </Stack>
    </SettingsViewVerticalLayout>
  );
};
