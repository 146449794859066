import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { AccountPlan } from "komodor-types";

import { useFetchZendeskJwt } from "../../../shared/hooks/auth-service/client/integrations/fetchZendeskJwt";
import { useUserMetadata } from "../../../shared/hooks/useUserMetadata/useUserMetadata";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { getAppConfig } from "../../../shared/config/appConfig";

import { ZendeskContext } from "./ZendeskContext";

interface ZendeskProviderProps {
  children: ReactNode;
}

export const ZendeskProvider: FC<ZendeskProviderProps> = ({ children }) => {
  const appConfig = getAppConfig();
  const zendeskScriptUrl = appConfig.zendeskScriptUrl;
  const [jwt, setJwt] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const { accountPlan } = useUserMetadata();
  const { forceShowZendeskChatbot, forceShowIntercom } = useOverridableFlags();

  const shouldShowChat =
    !forceShowIntercom &&
    (forceShowZendeskChatbot ||
      (accountPlan != AccountPlan.trial && accountPlan != AccountPlan.free));

  const response = useFetchZendeskJwt();

  useEffect(() => {
    if (response.isSuccess) {
      setJwt(response.data.data);
      setLoading(false);
    } else if (response.isError) {
      setError(response.error as Error);
      setLoading(false);
    }
  }, [response]);

  const loadZendeskScript = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.src = zendeskScriptUrl ?? "";
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error("Zendesk script failed to load"));
      document.body.appendChild(script);
    });
  }, [zendeskScriptUrl]);

  const initializeZendesk = useCallback(() => {
    if (window.zE && jwt) {
      window.zESettings = {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: (callback: (jwt: string) => void) => {
                callback(jwt);
              },
            },
          },
        },
      };

      window.zE(
        "messenger",
        "loginUser",
        (callback: (newJwt: string) => void) => {
          callback(jwt);
        }
      );
    }
  }, [jwt]);

  useEffect(() => {
    if (shouldShowChat && zendeskScriptUrl && !loading && !window.zE) {
      loadZendeskScript()
        .then(() => {
          const interval = setInterval(() => {
            if (window.zE) {
              initializeZendesk();
              clearInterval(interval);
            }
          }, 100);
        })
        .catch((error: React.SetStateAction<Error | null>) => {
          setError(error);
        });
    }
  }, [
    jwt,
    loadZendeskScript,
    initializeZendesk,
    shouldShowChat,
    response.isLoading,
    loading,
    error,
    response,
    zendeskScriptUrl,
  ]);

  return (
    <ZendeskContext.Provider value={{ jwt, loading, error }}>
      {children}
    </ZendeskContext.Provider>
  );
};
