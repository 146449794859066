import styled, { css } from "styled-components";
import { ifProp, switchProp } from "styled-tools";
import Typography from "@mui/material/Typography";

import { palette } from "../../../themes/palette";

import { StatusProps, StatusTextTransform } from "./Status";

export const StyledTypography = styled(Typography)<{
  size: StatusProps["size"];
  color: StatusProps["color"];
  textTransform: StatusTextTransform;
}>`
  && {
    ${switchProp("size", {
      small: css`
        font-size: 10px;
        font-weight: 600;
      `,
      medium: css`
        font-size: 12px;
        font-weight: 500;
      `,
      large: css`
        font-size: 14px;
        font-weight: 500;
      `,
    })};
    ${switchProp("color", {
      error: css`
        color: ${palette.error["dark"]};
      `,
      warning: css`
        color: ${palette.warning["dark"]};
      `,
      success: css`
        color: ${palette.success["dark"]};
      `,
      running: css`
        color: ${palette.running["dark"]};
      `,
      neutral: css`
        color: ${palette.info["dark"]};
      `,
    })};

    ${({ textTransform }) =>
      textTransform && `text-transform: ${textTransform};`}
  }
`;

const StyledStatus = styled.div`
  border: 1px solid transparent;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  padding: 2px 8px;
`;

export const StyledContainer = styled(StyledStatus)<{
  color: StatusProps["color"];
  variant: StatusProps["variant"];
}>`
  && {
    ${switchProp("color", {
      error: css`
        border-color: ${palette.error["main"]};
        background: ${palette.error["light"]};
      `,
      warning: css`
        border-color: ${palette.warning["main"]};
        background: ${palette.warning["light"]};
      `,
      success: css`
        border-color: ${palette.success["main"]};
        background: ${palette.success["light"]};
      `,
      running: css`
        border-color: ${palette.running["main"]};
        background: ${palette.running["light"]};
      `,
      neutral: css`
        border-color: ${palette.info["main"]};
        background: ${palette.info["light"]};
      `,
    })};

    ${switchProp("variant", {
      text: css`
        border: none;
        background: transparent;
      `,
    })};
  }
`;
