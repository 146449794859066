//create a new react component

import { palette } from "@komodorio/design-system";
import MuiTypography from "@mui/material/Typography";
import { CollapsableContainer } from "@komodorio/design-system/komodor-ui";
import { Typography, Button } from "@komodorio/design-system/deprecated";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { Metrics } from "../../../Metrics/Metrics";
import LogsViewerModal from "../../../common/LogsViewer/LogsViewerModal";
import { padFollowingZerosToLogTime } from "../../../common/LogsViewer/PodLogsBar/PodLogsActions";
import { SpacedRowFieldContainer } from "../../monitorsConfiguration/common/styles";
import { PendingContainerHappyState } from "../PendingContainerHappyState";
import { StyledBlackContainer } from "../styles";
import { useFetchPodLogsQuery } from "../../../../generated/graphql";
import { useQueryWithVariables } from "../../../../shared/hooks/useQueryWithVariables";
import {
  useIssueMetricsEvents,
  usePodMetricsEndTime,
} from "../../../InvestigationMode/availability/useCorrelatedMetrics";
import { useIsMetricsDrawerSupported } from "../../../Metrics/metricsHooks";
import { usePodMetrics } from "../../../Metrics/usePodMetrics";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import ExternalIcon from "../../../common/ProcessList/assets/external_icon.svg?react";
import {
  ContainerState,
  ContainerWithLogs,
  GeneralK8SData,
} from "../../workflowRunView/CommonTypes";
import { MetricsGraphType } from "../../../Metrics/types";
import { useMetricsDefaultAggregationType } from "../../../InvestigationMode/metricsHooks";

import { LogsType } from "@/components/common/LogsViewer/logsTypes";
import { storedTextWrappingEnabled } from "@/components/common/LogsViewer/logsViewerGlobalSettings";

const FlexMessageError = styled.div`
  flex: 1;
  max-inline-size: max-content;
  word-break: break-word;
`;
const ContainerLogs = styled.div`
  padding: 1rem;
  border: ${palette.gray[200]} 1px solid;
  border-radius: 4px;
  margin-block-end: 1rem;
`;
const ContainerInfo = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr;
  gap: 1rem;
  align-content: flex-start;
`;

const ContainerContent: React.FC<{
  calculatedContainerState: {
    isLast: boolean;
    chosenState: ContainerState | null;
  };
  container: ContainerWithLogs;
  k8sData: GeneralK8SData;
  eventTime: Date | undefined;
  podName: string;
}> = ({ calculatedContainerState, container, k8sData, eventTime, podName }) => {
  const { metricsAvailabilityDrawer } = useOverridableFlags();
  const [showLogsExtendWindow, setShowLogsExtendWindow] = useState(false);
  const containerState = calculatedContainerState?.chosenState;
  const metricsAggType = useMetricsDefaultAggregationType();

  const taskVariables = useMemo(() => {
    return (container?.failureMessage &&
      container?.failureMessage !==
        "Could not fetch container logs, agent task did not complete") ||
      !container?.logTaskId
      ? null
      : { taskId: container.logTaskId };
  }, [container?.failureMessage, container.logTaskId]);
  const podLogsRes = useQueryWithVariables(useFetchPodLogsQuery, taskVariables);
  const logs = useMemo(() => {
    if (!podLogsRes) return [];
    return podLogsRes.pod_logs;
  }, [podLogsRes]);

  const isPendingContainer = useMemo(() => {
    return containerState?.state?.toLowerCase() === "waiting";
  }, [containerState?.state]);

  const isPendingContainerWithoutLogs = useMemo(
    () => isPendingContainer && logs.length === 0,
    [isPendingContainer, logs.length]
  );
  const endTimestamp = useMemo(() => {
    return eventTime?.getTime() ?? new Date().getTime();
  }, [eventTime]);
  const isMetricsSupported = useIsMetricsDrawerSupported(k8sData.clusterName);

  const metricsEndTime = usePodMetricsEndTime(endTimestamp);
  const podMetrics = usePodMetrics(
    k8sData.clusterName,
    k8sData.namespace,
    podName,
    container.name,
    metricsEndTime,
    !!isMetricsSupported
  );

  const issueMetricsEvents = useIssueMetricsEvents(eventTime);
  return (
    <>
      <br />
      <br />
      {containerState?.message && (
        <>
          <ContainerInfo>
            <MuiTypography variant="body2" color={palette.gray[600]}>
              Message:
            </MuiTypography>
            <CollapsableContainer containerHeight={50}>
              <MuiTypography variant="body2" color={palette.pink[700]}>
                <FlexMessageError>{containerState.message}</FlexMessageError>
              </MuiTypography>
            </CollapsableContainer>
          </ContainerInfo>
          <br />
        </>
      )}
      {isPendingContainerWithoutLogs && <PendingContainerHappyState />}
      {metricsAvailabilityDrawer !== false && !isPendingContainer && (
        <Metrics
          isMetricsSupported={isMetricsSupported}
          endTimestamp={metricsEndTime}
          metrics={podMetrics}
          events={issueMetricsEvents}
          graphType={MetricsGraphType.CONTAINER}
          aggregationType={metricsAggType}
        />
      )}
      {!isPendingContainerWithoutLogs && (
        <>
          {logs.length > 0 ? (
            <ContainerLogs>
              <SpacedRowFieldContainer>
                <Typography size="large">
                  Container Logs
                  <br />
                </Typography>
                <Button
                  variant="secondary"
                  size="large"
                  icon={ExternalIcon}
                  onClick={() => {
                    storedTextWrappingEnabled.set("false");
                    setShowLogsExtendWindow(true);
                  }}
                >
                  Expand Logs
                </Button>
              </SpacedRowFieldContainer>
              <br />
              <LogsViewerModal
                isOpen={showLogsExtendWindow}
                handleCloseModal={() => setShowLogsExtendWindow(false)}
                podLogsRes={logs}
                containerName={container.name}
                logsType={LogsType.Issue}
                podName={podName}
                clusterName={k8sData.clusterName}
                namespace={k8sData.namespace}
                showEnlargeButton={false}
              />
              <StyledBlackContainer disableTextWrapping>
                {logs.map(({ logTime, message }) => (
                  <>
                    {padFollowingZerosToLogTime(logTime)}
                    {"  "}
                    <span>{message}</span>
                    <br />
                  </>
                ))}
              </StyledBlackContainer>
            </ContainerLogs>
          ) : container.failureMessage ? (
            <>
              <br />
              <b>
                <div>Failed to fetch container logs:</div>
              </b>
              {container.failureMessage}
            </>
          ) : (
            <b>Logs are saved for up to 72 hours after the issue</b>
          )}
        </>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ContainerContent;
