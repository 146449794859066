import React from "react";
import styled from "styled-components";

import { Timeframe } from "../../../shared/types/TimeWindow";
import {
  DefaultTimeframeOptions,
  MetricsAggregationType,
  MetricsFullTimeWindow,
} from "../types";
import { MetricsControlsSelector } from "../../ResourceView/common/Metrics/MetricsControlsSelector";

import { MetricsAggregationSelector } from "./MetricsAggregationSelector";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const RowContainerWithMargin = styled(RowContainer)`
  margin-bottom: 8px;
`;

interface Props {
  aggregationSelectorAriaLabel: string;
  aggregationMetric: MetricsAggregationType;
  setAggregationMetric: (metric: MetricsAggregationType) => void;
  timeOptions: Timeframe[];
  timeWindow: MetricsFullTimeWindow;
  setTimeWindow: (tf: Partial<MetricsFullTimeWindow>) => void;
}

export const MetricsPreferences: React.FC<Props> = ({
  aggregationSelectorAriaLabel,
  aggregationMetric,
  setAggregationMetric,
  timeOptions,
  timeWindow,
  setTimeWindow,
}) => {
  return (
    <Container>
      <RowContainerWithMargin>
        <MetricsAggregationSelector
          ariaLabel={aggregationSelectorAriaLabel}
          aggregationMetric={aggregationMetric}
          setAggregationMetric={setAggregationMetric}
        />
        <MetricsControlsSelector
          setTimeWindow={setTimeWindow}
          timeWindow={timeWindow}
          options={timeOptions ?? DefaultTimeframeOptions}
        />
      </RowContainerWithMargin>
    </Container>
  );
};
