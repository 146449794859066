import { DatadogReportLoadingTimeContextState } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";

export const initialState: DatadogReportLoadingTimeContextState = {
  reportLoadingState: () => undefined,
  triggerStartView: () => undefined,
  removeFromLoadingState: () => undefined,
  loadingState: {},
  getCurrentViewOptions: () => ({ name: "" }),
  resetLoadingState: () => undefined,
};
