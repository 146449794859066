import React from "react";
import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid-pro";

import { ColumnHeader, HeaderText, Padding } from "../../styles";
import { ClusterCell } from "../ClusterCell";
import { AgentVersionCell, K8sVersionCell } from "../K8sVersionCell";
import { NodesCell } from "../NodesCell";
import { AllocationCell } from "../AllocationCell";
import { PodsCell } from "../PodsCell";
import { UnhealthyServicesCell } from "../UnhealthyServicesCell";
import { ViolationsCell } from "../ViolationsCell";
import { PotentialSavingsCell } from "../PotentialSavingsCell";

import { ClusterSummary } from "@/generated/workspacesApi";
import { JobsCell } from "@/components/ClustersView/components/JobsCell";
import { ServicesCell } from "@/components/ClustersView/components/ServicesCell";
import { RegionCell } from "@/components/ClustersView/components/RegionCell";
import { ColumnField } from "@/components/ClustersView/components/ClustersList/types";

export const availableColumnsForClustersTable: (GridColDef<ClusterSummary> & {
  field: ColumnField;
})[] = [
  {
    field: "clusterName",
    headerName: "Cluster",
    flex: 3,
    minWidth: 200,
    renderHeader: ColumnHeader,
    renderCell: ({ row }) => <ClusterCell cluster={row} />,
  },
  {
    field: "k8sVersion",
    headerName: "K8s Version",
    flex: 1,
    minWidth: 100,
    renderHeader: ColumnHeader,
    renderCell: ({ row: { k8sVersion } }) => (
      <K8sVersionCell k8sVersion={k8sVersion} />
    ),
  },
  {
    field: "agentVersion",
    headerName: "Agent Version",
    flex: 1,
    minWidth: 100,
    renderHeader: ColumnHeader,
    renderCell: ({
      row: {
        additionalInfo: { chartVersion },
      },
    }) => <AgentVersionCell chartVersion={chartVersion} />,
  },
  {
    field: "nodes",
    headerName: "Nodes",
    flex: 1,
    minWidth: 110,
    renderHeader: ColumnHeader,
    renderCell: ({ row: { nodes } }) => <NodesCell nodes={nodes} />,
  },
  {
    field: "allocation",
    headerName: "Allocation",
    flex: 2,
    minWidth: 170,
    sortable: false,
    renderHeader: ColumnHeader,
    renderCell: ({
      row: {
        inactive,
        resources,
        additionalInfo: { isMetricsInstalled },
      },
    }) => (
      <AllocationCell
        resources={resources}
        inactive={inactive}
        isMetricsInstalled={isMetricsInstalled}
      />
    ),
  },
  {
    field: "pods",
    headerName: "Pods",
    flex: 2,
    minWidth: 200,
    renderHeader: (params) => (
      <Padding left="8px">
        <ColumnHeader {...params} />
      </Padding>
    ),
    renderCell: ({
      row: {
        pods,
        additionalInfo: { isPodsEnabled },
      },
    }) => (
      <Padding left="8px">
        <PodsCell pods={pods} isPodsEnabled={isPodsEnabled} />
      </Padding>
    ),
  },
  {
    field: "unhealthyServices",
    headerName: "Unhealthy Services",
    flex: 1,
    minWidth: 110,
    renderHeader: ColumnHeader,
    renderCell: ({ row: { unhealthyWorkloads } }) => (
      <UnhealthyServicesCell
        unhealthyServicesViolations={unhealthyWorkloads.services}
      />
    ),
  },
  {
    field: "violations",
    headerName: "Reliability Risks",
    flex: 2,
    minWidth: 200,
    renderHeader: ColumnHeader,
    renderCell: ({ row: { violations } }) => (
      <ViolationsCell violations={violations} />
    ),
  },
  {
    field: "potentialSavings",
    headerName: "Potential Savings",
    flex: 1,
    minWidth: 100,
    renderHeader: (params) => (
      <HeaderText>
        {params.colDef.headerName}{" "}
        <Typography variant="body3" sx={{ display: "inline" }}>
          /mo
        </Typography>
      </HeaderText>
    ),
    renderCell: ({ row: { potentialSavings } }) => (
      <PotentialSavingsCell potentialSavings={potentialSavings} />
    ),
  },
  {
    field: "services",
    headerName: "Services",
    flex: 1,
    minWidth: 130,
    renderHeader: ColumnHeader,
    renderCell: ({ row: { services } }) => <ServicesCell services={services} />,
  },
  {
    field: "jobs",
    headerName: "Jobs",
    flex: 0.5,
    minWidth: 70,
    renderHeader: ColumnHeader,
    renderCell: ({ row: { jobs } }) => <JobsCell jobs={jobs} />,
  },
  {
    field: "region",
    headerName: "Region",
    flex: 1,
    minWidth: 130,
    renderHeader: ColumnHeader,
    renderCell: ({ row: { region } }) => <RegionCell region={region} />,
  },
];
