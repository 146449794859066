import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";

import { CustomServiceSelection } from "../CustomServiceSelection";

import { ModalHeader } from "./ModalHeader";

import { LazyEditor } from "@/components/common/LazyEditor";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

export type YamlViewerProps = {
  title: string;
  baselineUid: string;
  content: string;
  onClose: () => void;
};

export const YamlViewer: React.FC<YamlViewerProps> = ({
  title,
  baselineUid,
  content,
  onClose,
}) => {
  const baseline = parseKomodorUid(baselineUid);
  if (!baseline) {
    return null;
  }
  return (
    <Dialog open onClose={onClose} maxWidth={false}>
      <ModalHeader title={title} onClose={onClose} />
      <DialogContent dividers={true}>
        <Box display="flex" gap="9px" flexDirection="column">
          <CustomServiceSelection
            clusterName={baseline.cluster}
            namespace={baseline.namespace}
            serviceName={baseline.name}
          />
          <LazyEditor
            width="800px"
            height="606px"
            value={content}
            readOnly={true}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
