import {
  AWS,
  Azure,
  DigitalOcean,
  GoogleCloud,
  LogoIconProps,
} from "@komodorio/design-system/icons";
import { DNS } from "@komodorio/design-system/icons/logos/DNS";
import { Cloudflare } from "@komodorio/design-system/icons/logos/Cloudflare";

import { DNSProvider } from "@/components/k8sAddons/addons/externalDns/types";

const dnsProviderIcon: { [key in DNSProvider]: React.FC<LogoIconProps> } = {
  [DNSProvider.AWS]: AWS,
  [DNSProvider.Azure]: Azure,
  [DNSProvider.Google]: GoogleCloud,
  [DNSProvider.Cloudflare]: Cloudflare,
  [DNSProvider.DigitalOcean]: DigitalOcean,
};

export const DNSProviderIcon: React.FC<{
  dnsProvider: DNSProvider;
}> = ({ dnsProvider }) => {
  const ProviderIcon =
    Object.entries(dnsProviderIcon).find(([provider]) =>
      dnsProvider.includes(provider)
    )?.[1] ?? DNS;

  return <ProviderIcon />;
};
