import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TooltipWrapper } from "react-tooltip";

import Resource from "../../ResourceView/resources";
import { DEFAULT_TOOLTIP } from "../../../shared/constants/tooltipIds";

import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { DRIFT_ANALYSIS_ROUTE } from "@/components/routes/routes";
import {
  BASELINE_SERVICE_OPTION_KEY,
  COMPARED_SERVICES_OPTION_KEY,
} from "@/components/Drift/Analysis/constants";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { initialServicesComparisonState } from "@/components/Drift/Analysis/context/initialState";

interface CompareButtonProps {
  resource: Resource;
  Button: React.FC<{ onClick: () => void; disabled?: boolean }>;
  tooltipMessage?: string;
}

export const DetectDriftButton: React.FC<CompareButtonProps> = ({
  resource,
  tooltipMessage,
  Button,
}) => {
  const { driftDetection } = useOverridableFlags();
  const navigate = useNavigate();
  const baselineServiceOption = useMemo(
    () => ({
      label: resource.name,
      value:
        buildKomodorUid({
          kind: resource.kind,
          namespace: resource.namespace,
          resourceName: resource.name,
          clusterName: resource.cluster,
        }) ?? "",
    }),
    [resource.cluster, resource.kind, resource.name, resource.namespace]
  );

  const searchParams = new URLSearchParams({
    [BASELINE_SERVICE_OPTION_KEY]: JSON.stringify(baselineServiceOption),
    [COMPARED_SERVICES_OPTION_KEY]: JSON.stringify(
      initialServicesComparisonState.comparedServicesIds
    ),
  });

  return driftDetection ? (
    <TooltipWrapper
      tooltipId={DEFAULT_TOOLTIP}
      content={tooltipMessage}
      place="bottom"
    >
      <Button
        disabled={false}
        onClick={() => {
          navigate({
            pathname: DRIFT_ANALYSIS_ROUTE,
            search: searchParams.toString(),
          });
        }}
      />
    </TooltipWrapper>
  ) : null;
};
