import Typography from "@mui/material/Typography";
import React from "react";
import { parseISO } from "date-fns";
import { EMPTY_CELL } from "@komodorio/design-system/komodor-ui";

import { SyncStatus } from "@/components/k8sAddons/addons/externalDns/SyncStatus";
import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { AccordionContentItemContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { ExternalDnsRecordFull } from "@/generated/addonsApi";
import { useDateFormatter } from "@/shared/hooks/useDateFormatter";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";
import { AgentUpgradeRequiredWrapper } from "@/components/k8sAddons/addons/externalDns/AgentUpgradeRequiredWrapper";

export const StatusSection: React.FC<{
  externalDns: ExternalDnsRecordFull;
}> = ({ externalDns }) => {
  const { format } = useDateFormatter();
  const { syncStatus, lastAttemptSyncTime, lastSuccessfulSyncTime } =
    externalDns;

  return (
    <AccordionWrapper
      title={"Status"}
      accordionContent={
        <>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Status
            </Typography>
            <AgentUpgradeRequiredWrapper cluster={externalDns.clusterName}>
              <SyncStatus status={syncStatus} />
            </AgentUpgradeRequiredWrapper>
          </AccordionContentItemContainer>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Last sync attempt
            </Typography>
            <AgentUpgradeRequiredWrapper cluster={externalDns.clusterName}>
              <Typography variant="body2">
                {lastAttemptSyncTime
                  ? format(parseISO(lastAttemptSyncTime))
                  : EMPTY_CELL}
              </Typography>
            </AgentUpgradeRequiredWrapper>
          </AccordionContentItemContainer>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Last successful sync
            </Typography>
            <AgentUpgradeRequiredWrapper cluster={externalDns.clusterName}>
              <Typography variant="body2">
                {lastSuccessfulSyncTime
                  ? format(parseISO(lastSuccessfulSyncTime))
                  : EMPTY_CELL}
              </Typography>
            </AgentUpgradeRequiredWrapper>
          </AccordionContentItemContainer>
        </>
      }
      ariaLabel={externalDnsListPageAriaLabels.drawer.sections.status}
    />
  );
};
