import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useKeepSocketSessionAlive } from "../../../sockets/useKeepSocketSessionAlive";
import { useInitiateSocketSession } from "../../../sockets/useInitiateSocketSession";
import {
  PodLogLine,
  PodLogsInfoRequestProps,
  PodLogsInfoResponseProps,
  PodLogsMode,
} from "../types";
import { SocketMessageType } from "../../../sockets/types";
import { selectIsLiveTailEnabled } from "../../../../components/common/LogsViewer/store/logsViewerStoreSelectors";
import {
  addPodLogs,
  selectPodLogs,
  resetPodLogs,
  selectLogsParams,
  selectIsLogsLoading,
  setIsLogsLoading,
} from "../store/podLogsStoreSelectors";
import { usePodLogsStore } from "../store/podLogsStore";
import { useLogsViewerStore } from "../../../../components/common/LogsViewer/store/logsViewerStore";
import { getSinceTime } from "../podLogsUtils";

import { useGetLivePodLogsInitData } from "./useGetInitData";
import { useHandleIncomingSocketMessage } from "./useHandleIncomingSocketMessage";
import { buildIdentifier } from "./buildIdentifier";
import { buildEventLog } from "./handleUnknownTerminationReason";
import { useSyncLogsDirection } from "./useSyncLogsDirection";
import { useGetLogsToView } from "./useGetLogsToView";

import { useCloseSessionOnDependenciesChange } from "@/shared/sockets/useCloseSessionOnDependenciesChange";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const MS_TO_FINISH_LOADING = 1_500;

/**
 * This hook manages a single socket connection, ensuring only one connection is active at a time.
 * It handles connection re-initiation in the following scenarios:
 * 1. The client receives a termination with known error reasons and attempts to create a new session.
 * 2. The client detects a socket close event and attempts to reconnect.
 * 3. UI dependency changes trigger the client to close the session and establish a new one with updated configurations.
 */

export const useGetLivePodLogs = ({
  podName,
  selectedContainer,
  namespace,
  agentId,
  clusterName: cluster,
  shouldExecuteFetchLogs,
  isPreviousLogs,
}: PodLogsInfoRequestProps): PodLogsInfoResponseProps => {
  const logs = usePodLogsStore(selectPodLogs);
  const logsParams = usePodLogsStore(selectLogsParams);
  const isLoading = usePodLogsStore(selectIsLogsLoading);
  const resetLogs = usePodLogsStore(resetPodLogs);
  const addLogs = usePodLogsStore(addPodLogs);
  const setIsLoading = usePodLogsStore(setIsLogsLoading);
  const [isConnectionEstablished, setIsConnectionEstablished] = useState(false);

  const loadingTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const { livePodLogsReconnect, newLogsSettings } = useOverridableFlags();

  const { sinceNumber, sinceUnit, sendSince } = logsParams;

  const [lastDisplayedLogTime, setLastDisplayedLogTime] = useState<string>("");
  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  const [
    lastSeenIdxWhileLiveTailWasActive,
    setLastSeenIdxWhileLiveTailWasActive,
  ] = useState<number>(0);
  const [failureMessage, setFailureMessage] = useState<string>("");
  const containerName = selectedContainer?.value;

  const sinceTimeByUserSettings = useMemo(
    () =>
      newLogsSettings && sendSince
        ? getSinceTime(sinceNumber, sinceUnit).toISOString()
        : undefined, //CU-86c14fqqq: this conditions needs to be removed once we remove newLogsSettings FF
    [newLogsSettings, sendSince, sinceNumber, sinceUnit]
  );

  const initData = useGetLivePodLogsInitData({
    containerName,
    namespace,
    podName,
    sinceTime: isRetrying ? lastDisplayedLogTime : sinceTimeByUserSettings,
    isPreviousLogs,
  });

  const isLiveTailEnabled = useLogsViewerStore(selectIsLiveTailEnabled);

  const identifier = shouldExecuteFetchLogs
    ? buildIdentifier({
        agentId,
        namespace,
        podName,
        containerName,
      })
    : "";

  const [refreshKeepAliveSession, closeKeepAliveSession] =
    useKeepSocketSessionAlive(identifier);

  useEffect(() => {
    if (isLiveTailEnabled) {
      setLastSeenIdxWhileLiveTailWasActive(logs.length);
    }
  }, [isLiveTailEnabled, logs.length]);

  useEffect(() => {
    setIsRetrying(false);
  }, [isPreviousLogs]);

  useSyncLogsDirection();

  const onRetry = useCallback(() => {
    setIsRetrying(true);
    const lastLogTime = new Date();

    lastLogTime.setSeconds(lastLogTime.getSeconds() + 1);
    setLastDisplayedLogTime(lastLogTime.toISOString());
  }, [setLastDisplayedLogTime, setIsRetrying]);

  const handleIncomingMessage = useHandleIncomingSocketMessage({
    closeKeepAliveSession,
    identifier,
    setLogs: (newLogs: PodLogLine[], isSystemMessageLogs: boolean) => {
      addLogs(newLogs);
      if (!isSystemMessageLogs) {
        setIsRetrying(false);
        setFailureMessage("");
      }
    },
    onError: (msg: string) => {
      if (logs.length > 0) {
        addLogs([buildEventLog(msg)]);
      } else {
        setFailureMessage(msg);
      }
    },
    askForRetry: onRetry,
    isPreviousLogs,
  });

  const onConnectionOpenSetLoading = () => {
    if (loadingTimeoutIdRef.current !== null) {
      clearTimeout(loadingTimeoutIdRef.current);
      loadingTimeoutIdRef.current = null;
    }
  };

  const onAckSetLoading = () => {
    setIsConnectionEstablished(true);
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, MS_TO_FINISH_LOADING);

    loadingTimeoutIdRef.current = timeoutId;
  };

  useInitiateSocketSession({
    identifier,
    agentId,
    cluster,
    namespace,
    podName,
    handleIncomingMessage,
    refreshKeepAliveSession,
    containerName,
    initData,
    initMessageType: SocketMessageType.PodLogsInit,
    shouldReconnect: Boolean(livePodLogsReconnect),
    onReconnect: onRetry,
    onConnectionConfirmedByTheAgent: onConnectionOpenSetLoading,
    onAck: onAckSetLoading,
  });

  useCloseSessionOnDependenciesChange({
    identifier,
    dependencies: logsParams,
    connectionCleanupCb: () => {
      resetLogs();
    },
  });

  useEffect(() => {
    setIsConnectionEstablished(false);
  }, [initData]);

  // clear the GLOBAL logs when the component is unmounted
  useEffect(() => {
    return () => {
      resetLogs();
      setIsRetrying(false);
      if (loadingTimeoutIdRef.current !== null) {
        clearTimeout(loadingTimeoutIdRef.current);
      }
    };
  }, [resetLogs]);

  const logsToView = useGetLogsToView(lastSeenIdxWhileLiveTailWasActive);

  return {
    failureMessage,
    logs: logsToView,
    fetching: isLoading,
    mode: PodLogsMode.Live,
    tail: logsToView.length,
    isConnectionEstablished,
  };
};
