import { useEffect } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";

import { GetViolationsStatsResponse } from "@/generated/reliabilityApi";
import { ViolationsGroupBy } from "@/components/reliability/ReliabilityTypes";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { getReportingNameSuffixByType } from "@/components/reliability/utils/loadingStateUtils";

export const useReportGroupLoadingState = (
  getGroupsRes: UseQueryResult<AxiosResponse<GetViolationsStatsResponse>>,
  groupBy: ViolationsGroupBy
) => {
  const { reportLoadingState, loadingState } =
    useDatadogReportLoadingTimeContext();

  useEffect(() => {
    const { isLoading } = getGroupsRes;
    const reportingName = getReportingNameSuffixByType("group", groupBy);
    if (loadingState[reportingName] === isLoading) {
      return;
    }
    reportLoadingState(reportingName, isLoading);
  }, [getGroupsRes, groupBy, reportLoadingState, loadingState]);
};
