import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { logsViewerAriaLabels } from "../../../logsViewerAriaLabels";

import { MaxNumOfLinesValue, OnlyNumbersInput } from "./OnlyNumbersInput";
import { LabelCheckbox, LabelContainer } from "./inputStyles";

const { tailToggle: tailToggleAriaLabel } =
  logsViewerAriaLabels.dialog.checkbox;

const INPUT_WIDTH = 170;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

type Props = {
  linesNum: MaxNumOfLinesValue;
  setNumOfLines: (linesNum: MaxNumOfLinesValue) => void;
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  error?: string;
};

export const MaxLinesNum: React.FC<Props> = ({
  linesNum,
  setNumOfLines,
  enabled,
  setEnabled,
  error,
}) => {
  return (
    <Container>
      <LabelContainer
        aria-label={tailToggleAriaLabel}
        onClick={() => setEnabled((prev) => !prev)}
      >
        <LabelCheckbox size="small" checked={enabled} />
        <Typography variant="body2">Max log lines to display:</Typography>
      </LabelContainer>
      <OnlyNumbersInput
        value={linesNum}
        setValue={setNumOfLines}
        error={error}
        inputWidth={INPUT_WIDTH}
        disabled={!enabled}
      />
    </Container>
  );
};
