import React from "react";

import { GroupProps } from "../../../../../ReliabilityTypes";

import { ViolationsGroupCard } from "./ViolationsGroupCard";

import { useCommonViolationGroupProps } from "@/components/reliability/hooks/useCommonViolationGroupProps";

export const ViolationsGroup: React.FC<GroupProps> = ({ group, groupBy }) => {
  const { title, isOpen, onExpandClick } = useCommonViolationGroupProps({
    group,
    groupBy,
  });

  return (
    <ViolationsGroupCard
      {...group}
      isOpen={isOpen}
      title={title}
      onExpandClick={onExpandClick}
      groupBy={groupBy}
    />
  );
};
