import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { ifNotProp } from "styled-tools";
import Chip from "@mui/material/Chip";

import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "../../../shared/types";
import { Size } from "../../../../../../types";

import {
  useGetMaxContainerDimension,
  useSetMaxChildrenInContainer,
} from "./chipsContainerHooks";
import { Box } from "@mui/material";
import { LightTooltip } from "components/komodor-ui/LightTooltip/LightTooltip";

const ITEM_GAP = 6;

const Container = styled.div<{
  breaklines?: boolean;
  width?: string;
  height?: string;
}>`
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-wrap: ${({ width }) => (width !== undefined ? "nowrap" : "wrap")};
  gap: ${ITEM_GAP}px;};
  ${({ width }) => ({ maxWidth: width ? `${width}` : "unset" })};
  ${({ height }) => ({ maxHeight: height ? `${height}` : "unset" })};
  ${ifNotProp(
    "breaklines",
    css`
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
    `
  )} 
`;

const ChipsWrapper = styled.div<{
  breaklines?: boolean;
  flexDirection: "column" | "row";
}>`
  flex-wrap: ${({ flexDirection }) =>
    flexDirection === "row" ? "nowrap" : "wrap"};
  display: flex;
  align-items: flex-start;
  gap: ${ITEM_GAP}px;
  max-width: inherit;
  max-height: inherit;
  position: relative;
`;

const HiddenDimensionContainer = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
`;

const HiddenChipsWrapper = styled(ChipsWrapper)`
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
  max-width: 100%;
  top: 0;
`;

const StyledChip = styled(Chip)<{
  maxwidth?: number;
  $restrictedDimension: "width" | "height";
}>`
  && {
    white-space: nowrap;
    overflow: ${({ $restrictedDimension }) =>
      $restrictedDimension === "height" ? "hidden" : "visible"};
    text-overflow: ellipsis;
    font-size: 12px;
    margin-right: 2px;
    flex-shrink: 1;
    ${({ maxwidth }) => maxwidth && `max-width: ${maxwidth}px`};

    & .MuiChip-deleteIcon {
      font-size: 16px;
    }
    &.MuiChip-sizeMedium {
      height: 20px;
    }
  }
`;

type ChipsContainerProps<T extends MuiSelectionOptionValue> = {
  selectedItems: MuiSelectionOption<T>[];
  onRemoveItem?: (value: T) => void;
  enableLineBreaks?: boolean;
  size: Size;
  className?: string;
  restrictedDimension: "width" | "height";
};

export const ChipsContainer = <T extends MuiSelectionOptionValue>({
  enableLineBreaks,
  onRemoveItem,
  selectedItems,
  size,
  className,
  restrictedDimension,
}: ChipsContainerProps<T>) => {
  const chipSize = size === "large" ? "medium" : size;
  const [hiddenContentChipDimension, setHiddenContentChipDimension] =
    useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenContentRef = useRef<HTMLDivElement>(null);
  const chipRef = useRef<HTMLDivElement>(null);
  const maxDimension = useGetMaxContainerDimension(
    containerRef,
    restrictedDimension
  );

  useEffect(() => {
    if (!hiddenContentChipDimension && chipRef.current) {
      setHiddenContentChipDimension(
        restrictedDimension === "width"
          ? chipRef.current.clientWidth
          : chipRef.current.clientHeight
      );
    }
  }, [hiddenContentChipDimension, chipRef.current]);

  const maxChildren = useSetMaxChildrenInContainer({
    containerEl: containerRef.current,
    hiddenContentEl: hiddenContentRef.current,
    hiddenContentChipDimension: hiddenContentChipDimension,
    selectedItems,
    enableLineBreaks,
    restrictedDimension,
  });

  const getChipsList = useCallback(
    (list: MuiSelectionOption<T>[]) => {
      const maxSize =
        restrictedDimension === "height"
          ? undefined
          : maxDimension - hiddenContentChipDimension - ITEM_GAP;
      return list.map((item) => {
        return (
          <StyledChip
            key={item.value}
            title={item.label}
            label={item.render ? item.render() : item.label}
            onDelete={onRemoveItem ? () => onRemoveItem(item.value) : undefined}
            size={chipSize}
            $restrictedDimension={restrictedDimension}
            maxwidth={maxSize}
          />
        );
      });
    },
    [hiddenContentChipDimension, maxDimension, onRemoveItem, chipSize]
  );

  const fullContent = useMemo(() => {
    return getChipsList(selectedItems);
  }, [getChipsList, selectedItems]);

  const slicedContent = useMemo(() => {
    const itemsNumToUse = maxChildren
      ? selectedItems.slice(0, maxChildren)
      : selectedItems;
    return getChipsList(itemsNumToUse);
  }, [getChipsList, maxChildren, selectedItems]);

  const hiddenChildrenNum = !maxChildren
    ? 0
    : selectedItems.length - maxChildren;

  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      alignItems={restrictedDimension === "width" ? "center" : "flex-start"}
      display="flex"
    >
      <HiddenChipsWrapper
        ref={hiddenContentRef}
        flexDirection={restrictedDimension === "width" ? "row" : "column"}
      >
        {fullContent}
      </HiddenChipsWrapper>
      <Container
        {...(restrictedDimension === "width"
          ? { width: `${maxDimension}px` }
          : { height: `${maxDimension}px` })}
        className={className}
        breaklines={!!enableLineBreaks}
      >
        <ChipsWrapper
          breaklines={enableLineBreaks && restrictedDimension === "width"}
          flexDirection={restrictedDimension === "width" ? "row" : "column"}
        >
          {slicedContent}
          {hiddenChildrenNum > 0 && (
            <LightTooltip
              title={selectedItems.slice(maxChildren).map((s) => (
                <>
                  {s.label}
                  <br />
                </>
              ))}
            >
              <StyledChip
                label={`+${hiddenChildrenNum}`}
                size={chipSize}
                $restrictedDimension={restrictedDimension}
              />
            </LightTooltip>
          )}
          {!hiddenContentChipDimension && (
            <StyledChip
              label="+99"
              ref={chipRef}
              size={chipSize}
              style={{ visibility: "hidden" }}
              $restrictedDimension={restrictedDimension}
            />
          )}
        </ChipsWrapper>
      </Container>
      <HiddenDimensionContainer ref={containerRef} />
    </Box>
  );
};
