import { MagicAi24 } from "@komodorio/design-system/icons";
import { muiColors } from "@komodorio/design-system";
import { useMemo } from "react";

import Resource from "../../resources";
import { useResourceAiInvestigation } from "../common/useResourceAiInvestigation";
import { InvestigationContent } from "../common/InvestigationContent";
import { InvestigationTitle } from "../common/InvestigationTitle";

import EventDetailsSection from "@/components/common/ProcessList/details/components/EventDetailsSection";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import type JobEventGroup from "@/components/common/EventGroup/jobEvent/JobEventGroup";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { MonitorType } from "@/generated/monitorsApi";
import { FlowType } from "@/components/ResourceView/AiInvestigation/common/types";

export const InvestigationSection: React.FC<{
  resource: Resource;
  eventGroup: JobEventGroup;
}> = ({ resource, eventGroup }) => {
  const { klaudiaAiJobRun } = useOverridableFlags();
  const jobResource = useMemo(
    () => ({
      ...resource,
      kind: "Job",
      name: eventGroup.name,
      namespace: resource.namespace,
      cluster: resource.cluster,
    }),
    [eventGroup.name, resource]
  );
  const {
    enabled,
    richSession,
    isError,
    isLoading,
    onRetryCallback,
    onShareClicked,
  } = useResourceAiInvestigation({
    drawerData: {
      drawerType: DrawerType.EventDrawerById,
      eventIds: eventGroup.events.map((event) => event.id),
      eventType: MonitorType.Job,
      serviceId: eventGroup.serviceId,
    },
    requestId: eventGroup.jobUid,
    resource: jobResource,
    isHealthy: !eventGroup.isFailed,
    flowType: FlowType.FailedJobRun,
  });

  if (!enabled || !klaudiaAiJobRun || (!eventGroup.isFailed && !richSession)) {
    return null;
  }

  return (
    <EventDetailsSection
      icon={() => <MagicAi24 color="#2A47FF" width={20} height={20} />}
      title={
        <InvestigationTitle
          showIcon={false}
          richSession={richSession}
          onShareClicked={onShareClicked}
          titleProps={{ variant: "h4", color: muiColors.gray[500] }}
        />
      }
    >
      <InvestigationContent
        richSession={richSession}
        isError={isError}
        onRetryCallback={onRetryCallback}
        isLoading={isLoading}
      />
    </EventDetailsSection>
  );
};
