import React, { useMemo } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { TabsType } from "../../context/IssuesTableContext";
import { useIssuesTableContext } from "../../context/useIssuesTableContext";

import { useIssuesTabsProps } from "./useIssuesTabsProps";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const {
  realTimeGroups: {
    issuesTable: { tabsContainer: tabsContainerAriaLabel },
  },
} = reliabilityArialLabels;

export const IssuesTabs: React.FC = () => {
  const { tabValue, setTabValue } = useIssuesTableContext();
  const tabs = useIssuesTabsProps();

  const handleChange = (event: React.SyntheticEvent, newValue: TabsType) => {
    setTabValue(newValue);
  };

  const TabsList = useMemo(
    () =>
      tabs.map(({ label, value, ariaLabel }) => (
        <Tab aria-label={ariaLabel} key={value} label={label} value={value} />
      )),
    [tabs]
  );

  return (
    <Tabs
      value={tabValue}
      onChange={handleChange}
      aria-label={tabsContainerAriaLabel}
    >
      {TabsList}
    </Tabs>
  );
};
