import React from "react";

import { Size } from "../../../types";

import { StyledContainer, StyledTypography } from "./statusStyles";

export type StatusColor =
  | "error"
  | "warning"
  | "success"
  | "running"
  | "neutral";

export type StatusVariant = "text" | "outlined";

export type StatusTextTransform = "uppercase" | "capitalize" | "lowercase";

export type StatusProps = {
  label: string;
  color: StatusColor;
  size?: Size;
  variant?: StatusVariant;
  textTransform?: StatusTextTransform;
};

export const Status: React.FC<StatusProps> = ({
  label,
  color,
  size = "medium",
  variant = "outlined",
  textTransform,
}) => {
  return (
    <StyledContainer variant={variant} color={color}>
      <StyledTypography size={size} textTransform={textTransform} color={color}>
        {label}
      </StyledTypography>
    </StyledContainer>
  );
};
