import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";

import { useSnackbarMessageContext } from "@/components/Settings/email/context/useSnackbarMessageContext";
import { emailSettingsAriaLabels } from "@/components/Settings/email/emailSettingsAriaLabels";

const HIDE_SNACKBAR_DURATION = 2000;
const SNACK_TRANSITION_DURATION = 250;

const StyledAlert = styled(Alert)`
  && {
    left: 50%;
    transform: translate(50%);
    position: relative;
  }
`;

export const NotificationSnackbar: React.FC = () => {
  const { setSnackbarMessage, snackbarMessage } = useSnackbarMessageContext();
  const { color, message } = snackbarMessage ?? {};
  const [localOpen, setLocalOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setLocalOpen(true);
    }
  }, [message]);

  const onClose = () => {
    setLocalOpen(false);
    setTimeout(() => {
      setSnackbarMessage(undefined);
    }, SNACK_TRANSITION_DURATION);
  };

  return (
    <Snackbar
      open={localOpen}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={HIDE_SNACKBAR_DURATION}
      onClose={onClose}
      aria-label={emailSettingsAriaLabels.notificationCenterContainer}
    >
      <StyledAlert severity={color} variant="filled">
        {message}
      </StyledAlert>
    </Snackbar>
  );
};
