import { muiTheme } from "@komodorio/design-system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "styled-components";

import { CustomServiceSelection } from "../CustomServiceSelection";

import { ModalHeader } from "./ModalHeader";

import { ObjectDiff, ObjectDiffAsYaml } from "@/components/common/ObjectDiff";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

const StyledCustomServiceSelection = styled(CustomServiceSelection)`
  flex-grow: 1;
`;

const overridingStyles = {
  gutter: {
    borderLeft: `1px solid ${muiTheme.palette.divider}`,
  },
  diffContainer: {
    border: `1px solid ${muiTheme.palette.divider}`,
    borderRadius: "4px",
    "-webkit-mask-image": "-webkit-radial-gradient(white, black)",
  },
};

export type DiffModalProps = {
  title: string;
  baselineUid: string;
  comparedUid: string;
  isYaml?: boolean;
  oldObj: unknown;
  newObj: unknown;
  onClose: () => void;
};

export const DiffModal: React.FC<DiffModalProps> = ({
  title,
  baselineUid,
  comparedUid,
  oldObj,
  newObj,
  isYaml = false,
  onClose,
}) => {
  const baseline = parseKomodorUid(baselineUid);
  const compared = parseKomodorUid(comparedUid);
  if (!baseline || !compared) {
    return null;
  }
  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xl"
      PaperProps={{ sx: { height: "606px" } }}
    >
      <ModalHeader title={title} onClose={onClose} />
      <DialogContent dividers={true}>
        <Box display="grid" gridTemplateColumns="1fr 1fr" marginBottom="9px">
          <Box display="flex" gap="9px" flexGrow={1} paddingRight="16px">
            <Typography variant="h6">Baseline:</Typography>
            <CustomServiceSelection
              clusterName={baseline.cluster}
              namespace={baseline.namespace}
              serviceName={baseline.name}
            />
          </Box>
          <StyledCustomServiceSelection
            clusterName={compared.cluster}
            namespace={compared.namespace}
            serviceName={compared.name}
          />
        </Box>
        {isYaml ? (
          <ObjectDiffAsYaml
            oldObj={oldObj}
            newObj={newObj}
            overridingStyles={overridingStyles}
          />
        ) : (
          <ObjectDiff
            oldObj={oldObj}
            newObj={newObj}
            overridingStyles={overridingStyles}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
