import React from "react";
import Button from "@mui/material/Button";
import { Check16 } from "@komodorio/design-system/icons";

interface InstallCTAProps {
  onInstallHandler: () => void;
  title: string;
  shouldLockIntegrations?: boolean;
  disabled?: boolean;
  buttonText?: string;
  showCheck?: boolean;
}

export const InstallCTA: React.FC<InstallCTAProps> = (props) => {
  const {
    onInstallHandler,
    title,
    shouldLockIntegrations,
    disabled,
    buttonText = "Install Integration",
    showCheck,
  } = props;

  if (shouldLockIntegrations) {
    return (
      <Button variant="outlined" disabled={disabled}>
        {buttonText}
      </Button>
    );
  }

  if (title === "Kubernetes Cluster") {
    return (
      <Button
        variant="outlined"
        onClick={onInstallHandler}
        disabled={disabled}
        startIcon={disabled && showCheck ? <Check16 /> : undefined}
      >
        {buttonText}
      </Button>
    );
  }

  return (
    <Button
      variant="outlined"
      onClick={onInstallHandler}
      disabled={disabled}
      startIcon={disabled && showCheck ? <Check16 /> : undefined}
    >
      {buttonText}
    </Button>
  );
};
