import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { useMemo } from "react";

import { useMetricsGraphRequestParams } from "../components/graphs/useWorkloadMetrics";
import { useGetDatapoints } from "../components/MetricsPerContainer/metricsPerContainerHooks";
import { MetricType } from "../../../../Metrics/types";

import { useFetchWorkloadContainersMetricsData } from "./fetchWorkloadMetricsHooks";
import { useGraphData } from "./graphDataHooks";

import { useEpochsFromWorkloadMetricsTimeWindow } from "@/components/Metrics/hooks/workload/workloadMetricsTabHooks";
import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";

type UseWorkloadContainerGraphProps = {
  komodorUidParams: KomodorUidParams;
  type: MetricType;
  wantedContainer: MuiSelectionOption<string> | undefined;
  endTime?: number;
};

export const useWorkloadContainerGraph = ({
  komodorUidParams,
  type,
  wantedContainer,
  endTime,
}: UseWorkloadContainerGraphProps) => {
  const { toEpochMs, fromEpoch, toEpoch } =
    useEpochsFromWorkloadMetricsTimeWindow(endTime);
  const requestParams = useMetricsGraphRequestParams(
    komodorUidParams,
    fromEpoch,
    toEpoch
  );

  const { data, error, isFetching } = useFetchWorkloadContainersMetricsData({
    ...requestParams,
    kind: type,
  });
  const uid = useMemo(
    () => ({
      name: komodorUidParams.name,
      cluster: komodorUidParams.cluster,
      namespace: komodorUidParams.namespace,
    }),
    [
      komodorUidParams.cluster,
      komodorUidParams.name,
      komodorUidParams.namespace,
    ]
  );
  const dataPoints = useGetDatapoints(wantedContainer, data?.data);

  const { graphProps, hasGraphData } = useGraphData(dataPoints);
  const hasLimit = dataPoints?.limit?.some((dp) => dp.value > 0);

  return {
    loading: isFetching,
    error,
    graphProps,
    wantedContainer,
    uid,
    hasGraphData,
    toEpochMs,
    hasLimit,
  };
};
