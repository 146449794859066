/* eslint-disable max-lines */
export const AriaLabels = {
  AppBar: {
    WhatsNew: "whats new",
    ChatSupport: "chat support",
    Clusters: "clusters",
    WorkspaceOverview: "workspace overview",
    ClusterOverview: "cluster overview",
    Events: "events",
    ExpertOverview: "topology",
    WorkloadHealth: "workload health",
    InfrastructureHealth: "infrastructure health",
  },
  TopBar: {
    HelpButton: "help button",
    HelpMenu: "help menu",
    ChatButton: "chat button",
    SettingsButton: "settings button",
    SettingsMenu: "settings menu",
    ProfileButton: "profile button",
    ProfileMenu: "profile menu",
    innerButtons: {
      logOut: "log out",
    },
    emailSettings: "email settings button",
  },
  Workspaces: {
    TopBar: "workspaces - top bar",
    Loader: "workspaces - loader",
    Selector: "workspaces - workspace selector",
    SelectedValue: "workspaces - selected value",
    Search: "enter search term",
    ClusterGroupForm: {
      NameInput: "cluster group name input",
      DescriptionInput: "cluster group description input",
      PatternInput: "cluster group pattern input",
    },
  },
  ExploreKomodorServices: {
    Loader: "explore komodor services loader",
  },
  Alerts: {
    UpgradeAgent: {
      PermanentHideButton: {
        AbsoulteHide: "hide upgrade agent alert",
        HideByKey: "hide upgrade agent alert by key",
      },
    },
  },
  ResourceView: {
    ResourceDrawer: "resource drawer",
    Header: {
      Title: "resource view title",
      ServiceHealth: {
        Availability: {
          Container: "service health status - availability",
          Loader: "service health status - availability - loader",
          StatusInfoIcon:
            "service health status - availability - status info icon",
          HealthInfoIcon:
            "service health status - availability - health info icon",
        },
        LastDeploy: "service health status - deploy",
        LastDeployEmptyState: "service health status - deploy - empty state",
        IssueDrawer: "resource view - service health issue drawer",
      },
      Reliability: {
        StatusBox: "reliability violations status box",
      },
      Metadata: {
        Image: "resource view - metadata - image",
        AdditionalImage: "resource view - metadata - additional images",
      },
    },
    IssueBanner: {
      Pod: {
        StandalonePodIssue: "resource view - pod - standalone pod issue banner",
        AvailabilityIssue: "resource view - pod - availability issue banner",
      },
    },
    DrawerCloseButton: "close drawer",
    DrawerOpenInFullButton: "open in full screen",
    DescribeLoader: "describe tab loader",
    PodsTab: {
      TabPanel: "service-view pods",
      Filters: "resource view - pods tab - filters",
      Content: "resource view - pods tab - content",
    },
    NodesTab: {
      TabPanel: "service-view nodes",
      Filters: "resource view - nodes tab - filters",
    },
    EventsTab: {
      TabPanel: "service-view events",
    },
    InfoTab: {
      TabPanel: "service-view info",
    },
    LogsTab: {
      TabPanel: "service-view logs",
    },
    MetricsTab: {
      TabPanel: "service-view metrics",
    },
    SummaryTab: {
      TabPanel: "service-view summary",
    },
    ServiceLogsTab: {
      TabPanel: "service-view service logs",
      EmptyState: "service-view service logs - empty state",
    },
  },
  ProcessList: {
    EventDrawer: "process list - event drawer",
    EventType: "process list - event type",
    Table: "process list - table",
  },
  Events: {
    JobRun: {
      ViewJobPods: "events - job run - view job pods",
    },
  },
  EventDrawer: {
    CloseButton: "close event drawer",
    CopyButton: "share event drawer",
  },
  AvailabilityTriage: {
    HistoryLine: "triage history graph issue line",
    HistoryTriangle: "triage history graph issue alert triangle",
    HistoryIssueTooltip: "triage history graph issue tooltip",
    ReasonTooltip: "triage header reason tooltip",
    NodeInsightTooltip: "triage node insight tooltip",
    NodeInsightTooltipLink: "triage node insight tooltip details link",
    NodeTerminationInsightTooltip: "triage node termination insight tooltip",
    NodeTerminationInsightTooltipLink:
      "triage node termination insight tooltip details link",
    DeployInsightTooltip: "triage deploy insight tooltip",
    DeployInsightTooltipLink: "triage deploy insight tooltip details link",
    LimitDecreaseInsightTooltip: "triage limit decrease insight tooltip",
    LimitDecreaseInsightTooltipLink:
      "triage limit decrease insight tooltip details link",
    ActionsInsightTooltip: "triage manual action insight tooltip",
    ActionsInsightTooltipLink:
      "triage manual action insight tooltip details link",
    SameIssuesInsightTooltip: "triage same issues insight tooltip",
    SameIssuesInsightTooltipLink:
      "triage same issues insight tooltip details link",
    DurationTooltip: "triage duration tooltip",
    EditMonitorActionItem: "triage edit monitor menu item",
    InvestigateButton: "triage investigate button",
    ResourceLink: "triage resource link",
    DislikeInvestigation: "investigation-mode-exit-dislike-feedback",
    LikeInvestigation: "investigation-mode-exit-like-feedback",
  },
  StandalonePod: {
    ResourceLink: "pod issue resource link",
  },
  Inspection: {
    ResourceListTable: {
      ResourceListRow: "inspection resource list table - row",
    },
    CRD: {
      Resources: {
        Input: "Search resource name",
        Table: "Custom resources table",
      },
    },
    Pod: {
      MetricsCell: "pod metrics context",
      DescribeTab: "pod describe tab",
      LogsTab: "pod logs tab",
    },
    Actions: {
      Buttons: {
        Suspend: "Suspend",
        Resume: "Resume",
      },
      Modals: {
        SuspendResumeCronjob: "Suspend/Resume Cronjob Modal",
      },
    },
  },
  OpenIssue: {
    AvailabilityIssueTooltip: "open availability issue tooltip",
    AvailabilityIssueTooltipLink:
      "open availability issue tooltip - details link",
    PodIssueTooltip: "open pod issue tooltip",
    PodIssueTooltipLink: "open pod issue tooltip - details link",
    NodeIssueTooltip: "open node issue tooltip",
    NodeIssueTooltipLink: "open node issue tooltip - details link",
    PVCIssueTooltip: "open pvc issue tooltip",
    PVCIssueTooltipLink: "open pvc issue tooltip - details link",
    JobIssueTooltip: "open job issue tooltip",
    JobIssueTooltipLink: "open job issue tooltip - details link",
    CurrentAvailabilityHealthTooltip: "current availability health tooltip",
    CurrentAvailabilityHealthTooltipLink:
      "current availability health tooltip - details link",
  },
  PodExec: {
    OpenNewSession: "Get a shell to a running container",
  },
  PortForwarding: {
    OpenNewSession: "Port forward to a running container",
  },
  MetricsAvailability: {
    CpuGraph: {
      Name: "cpu graph",
      Graph: "availability issue metrics - cpu graph",
      UsageLine: "availability issue metrics - cpu usage line",
      CapacityLine: "availability issue metrics - cpu capacity line",
      RequestLine: "availability issue metrics - cpu request line",
      LimitLine: "availability issue metrics - cpu limit line",
      P90Line: "availability issue metrics - cpu p90 line",
      P95Line: "availability issue metrics - cpu p95 line",
      P99Line: "availability issue metrics - cpu p99 line",
      MaxLine: "availability issue metrics - cpu max line",
      Yaxis: "availability issue metrics - cpu y axis",
      Xaxis: "availability issue metrics - cpu x axis",
      RecommendationLine:
        "availability issue metrics - cpu moderate recommendation",
    },
    MemoryGraph: {
      Name: "memory graph",
      Graph: "availability issue metrics - memory graph",
      UsageLine: "availability issue metrics - memory usage line",
      CapacityLine: "availability issue metrics - memory capacity line",
      RequestLine: "availability issue metrics - memory request line",
      LimitLine: "availability issue metrics - memory limit line",
      P90Line: "availability issue metrics - memory p90 line",
      P95Line: "availability issue metrics - memory p95 line",
      P99Line: "availability issue metrics - memory p99 line",
      MaxLine: "availability issue metrics - memory max line",
      Yaxis: "availability issue metrics - memory y axis",
      Xaxis: "availability issue metrics - memory x axis",
      RecommendationLine:
        "availability issue metrics - memory moderate recommendation",
    },
    CountGraph: {
      Name: "count graph",
      Graph: "availability issue metrics - count graph",
      CountLine: "availability issue metrics - count line",
      Yaxis: "availability issue metrics - count y axis",
      Xaxis: "availability issue metrics - count x axis",
      Icon: "availability issue metrics - count icon",
    },
    BarChart: {
      Graph: "metrics - bar chart graph",
      Yaxis: "metrics - bar chart y axis",
      Xaxis: "metrics - bar chart x axis",
    },
    Tooltip: "availability issue metrics - metrics tooltip",
    Legend: {
      UsageIcon: "availability issue metrics - metrics legend - usage icon",
      RequestIcon: "availability issue metrics - metrics legend - request icon",
      LimitIcon: "availability issue metrics - metrics legend - limit icon",
      RecommendationIcon:
        "availability issue metrics - metrics legend - recommendation icon",
      Legend: "availability issue metrics - metrics legend",
      AllocatableIcon:
        "availability issue metrics - metrics legend - allocatable icon",
    },
    GenericLegend: {
      name: "metrics graph legend",
    },
    Summary: {
      Summary: "availability issue metrics - summary",
      Min: "availability issue metrics - min",
      Max: "availability issue metrics - max",
      P90: "availability issue metrics - p90",
      P95: "availability issue metrics - p95",
    },
    OneHourToggle: "availability issue metrics - 1 hour",
    FourHourToggle: "availability issue metrics - 4 hours",
    OneDayToggle: "availability issue metrics - 24 hours",
    OneWeekToggle: "availability issue metrics - 7 days",
    AggregationToggle: "availability issue metrics - aggregation",
  },
  updateAgent: {
    introduction: "update agent introduction",
    command: "update agent command",
  },
  LogsViewer: {
    SearchLogsInput: "logs viewer - search logs input",
    NextMatchButton: "logs viewer - next match button",
    PreviousMatchButton: "logs viewer - previous match button",
    TimestampToggle: "logs viewer - timestamp toggle",
    LineNumberToggle: "logs viewer - line number toggle",
    TextWrapToggle: "logs viewer - text wrap toggle",
    CopyLogsButton: "logs viewer - copy logs button",
    EnlargeLogsButton: "logs viewer - enlarge logs button",
    DownloadLogsButton: "logs viewer - download logs button",
    PreviousContainerToggle: "logs viewer - previous container toggle",
    LogsAi: {
      Analyze: "logs viewer - analyze logs button",
      LogsAiConsentForm:
        "logs viewer - logs ai consent form - consent to logs ai",
      FeedBackLike: "logs viewer - logs ai feedback like",
      FeedBackDislike: "logs viewer - logs ai feedback dislike",
      RootCause: "logs viewer - logs ai root cause",
      Solution: "logs viewer - logs ai solution",
      MaxLinesAlert: "logs viewer - logs ai max lines alert",
    },
    LogsViewerModal: {
      Modal: "logs viewer modal",
    },
  },
  InvestigationMode: {
    KomodorLogo: "investigation mode - komodor logo",
    EnrichedMessage: "investigation mode - enriched message",
    EnrichedResource: "investigation mode - enriched resource",
    IssueSummary: "investigation mode - issue summary",
    IssueSummaryTriageDrawer:
      "investigation mode - issue summary - triage drawer",
    IssueSummaryServiceLink:
      "investigation mode - issue summary - service link",
    IssueSummaryIssueLink: "investigation mode - issue summary - issue link",
    IssueSummaryLiveDuration:
      "investigation mode - issue summary - live duration",
    ExitButton: "investigation mode - exit button",
    LinksButton: "investigation mode - links button",
    ActionsButton: "investigation mode - actions button",
    LinksMenuLink: "investigation mode - links menu - link",
    FirstSnapshotTab: "investigation mode - first snapshot tab",
    LatestSnapshotTab: "investigation mode - latest snapshot tab",
    IntroductionStep: {
      StepLink: "investigation mode - introduction step - step link",
      StartInvestigatingButton:
        "investigation mode - introduction step - start investigating button",
      Summary: {
        ReasonSection:
          "investigation mode - introduction step - reason section",
        WhereSection: "investigation mode - introduction step - where section",
        WhenSection: "investigation mode - introduction step - when section",
      },
    },
    CorrelatedDeploysStep: {
      StepLink: "investigation mode - correlated deploys step - step link",
      RelatedServicesDeploys: "investigation mode - related services deploys",
      DeployCard: {
        Container: "investigation mode - correlated deploys step - deploy card",
        Header: "investigation mode - correlated deploys step - deploy row",
        ViewMoreButton:
          "investigation mode - correlated deploys step - deploy - view more button",
        LinksButton:
          "investigation mode - correlated deploys step - deploy - links button",
        RollbackButton:
          "investigation mode - correlated deploys step - deploy - rollback button",
        K8sDiffShowAllChanges:
          "investigation mode - correlated deploys step - deploy - k8s diff - show all changes",
        GitDiffViewDiffLink:
          "investigation mode - correlated deploys step - deploy - git diff - view diff link",
        GitDiffPrLink:
          "investigation mode - correlated deploys step - deploy - git diff - pr link",
        ConfigDiffShowAllChanges:
          "investigation mode - correlated deploys step - deploy - config diff - show all changes",
      },
      SkipStepButton:
        "investigation mode - correlated deploys step - skip step button",
    },
    LogsStep: {
      StepLink: "investigation mode - logs step - step link",
      SkipStepButton: "investigation mode - logs step - skip step button",
    },
    NodeEventsStep: {
      StepLink: "investigation mode - node events step - step link",
      SkipStepButton:
        "investigation mode - node events step - skip step button",
    },
    NoisyNeighborsStep: {
      ExpandableSectionHeader:
        "investigation mode - noisy neighbors step - expandable section header",
      ExpandableSectionIcon:
        "investigation mode - noisy neighbors step - expandable section icon",
      StepLink: "investigation mode - noisy neighbors step - step link",
      SkipStepButton:
        "investigation mode - noisy neighbors step - skip step button",
    },
    UnhealthyPodsStep: {
      StepLink: "investigation mode - unhealthy pods step - step link",
      ContainerRow: "investigation mode - unhealthy pods step - container row",
      ContainerTitle:
        "investigation mode - unhealthy pods step - container title",
      SkipStepButton:
        "investigation mode - unhealthy pods step - skip step button",
      Summary: {
        ReasonSection:
          "investigation mode - unhealthy pods step - reason section",
        WhereSection:
          "investigation mode - unhealthy pods step - where section",
        Status: "investigation mode - unhealthy pods step - status section",
      },
      PodEvents: "investigation mode - unhealthy pods step - pod events",
      PodsSummaryTitle:
        "investigation mode - unhealthy pods step - pods summary title",
      PodSelector: "investigation mode - unhealthy pods step - pod selector",
      PodSelectorOption:
        "investigation mode - unhealthy pods step - pod selector option",
    },
    LimitDecreasedStep: {
      StepLink: "investigation mode - limit decreased step - step link",
      SkipStepButton:
        "investigation mode - limit decreased step - skip step button",
    },
    MemoryLeakStep: {
      StepLink: "investigation mode - memory leak step - step link",
      SkipStepButton:
        "investigation mode - memory leak step - skip step button",
    },
    ReviewMemoryAllocation: {
      StepLink:
        "investigation mode - review memory allocation step - step link",
      SkipStepButton:
        "investigation mode - review memory allocation step - skip step button",
    },
    DependenciesStep: {
      StepLink: "investigation mode - dependencies step - step link",
      SkipStepButton:
        "investigation mode - dependencies step - skip step button",
    },
    ContinueInvestigatingButton:
      "investigation mode - action modal - continue investigating",
    ViewServiceButton:
      "investigation mode - action modal - view service button",
  },
  Metrics: {
    CollapsibleGraph: "metrics - collapsible graph",
    CollapsibleGraphExtendButton: "metrics - collapsible graph extend button",
    Label: "metrics - label",
    EnlargeGraphButton: "metrics - enlarge graph button",
  },
  ArgoWorkflows: {
    TimelineViewEventDrawer: "argo workflows - timeline view - event drawer",
    ShowOnlyPodsWithErrorsToggle:
      "argo workflows - only pods with errors toggle",
  },
  Workflows: {
    ShowOnlyPodsWithFailuresToggle:
      "workflows - only pods with failures toggle",
    DocsButton: "workflows - got to documentation button",
    WorkflowRunMenuItem: "workflows - workflow run menu item",
  },
  PodPhases: {
    Drawer: "pod phases - drawer",
    StatusConditionsSwitch: "pod phases - status conditions switch",
    EventsTable: "pod phases - events table",
    PodEvents: {
      EventsDrawer: "pod phases - pod events - event drawer",
      DirectionToggle: "pod phases - pod events - direction toggle",
      Accordion: "pod phases - pod events - accordion",
      PhasesTable: "pod phases - pod events - phases table",
      PhasesTablePagination:
        "pod phases - pod events - phases table pagination",
    },
    NodeIssueBanner: "pod phases - node issue banner",
    NodeTerminationBanner: "pod phases - node termination banner",
  },
  SettingsPage: {
    AccountProfile: {
      BetaFeatures: {
        // Logs AI
        KlaudiaAiConsentFormEnableButton: "enable-logsai-from-popup",
        KlaudiaAi:
          "settings page - account profile - beta features - klaudia ai",
        KlaudiaAiConsentForm:
          "settings page - account profile - beta features - klaudia ai consent form",
        KlaudiaAiDisableForm:
          "settings page - account profile - klaudia ai disable form",
        // RBAC Cluster Sync
        RbacClusterSync:
          "settings page - account profile - beta features - rbac cluster sync",
        RbacClusterSyncConsentForm:
          "settings page - account profile - beta features - rbac cluster sync consent form",
        RbacClusterSyncConsentFormEnableButton:
          "enable-rbac-cluster-sync-from-popup",
        RbacClusterSyncDisableForm:
          "settings page - account profile - rbac cluster sync disable form",
        RbacClusterSyncOpenConfigureButton:
          "settings page - account profile - rbac cluster sync open configure button",
        RbacClusterSyncConfigureSaveButton:
          "save-rbac-cluster-sync-configuration-in-popup",
      },
    },
  },
  GlobalSearch: {
    SearchButton: "global search - search button",
    CommandBar: "global search - command bar",
    AllWorkspacesToggle: "global search - all workspaces toggle",
    ItemTitleContainer: "global search - item title container",
  },
  CloudProviderIcon: {
    AWS: "aws",
    Azure: "azure",
    GoogleCloud: "gcp",
    Kubernetes: "kubernetes",
    OpenShift: "openshift",
    Vmware: "vmware",
    Oracle: "oracle",
    Vcluster: "vcluster",
    K3S: "k3s",
    Rancher: "rancher",
  },
  ClustersView: {
    Filters: {
      SearchInput: "clusters view - search input",
      CloudProviderSelect: "clusters view - cloud provider select",
      K8sVersionSelect: "clusters view - k8s version select",
      ShowInactiveSwitch: "clusters view - show inactive switch",
      ShowInactiveSwitchWrapper: "clusters view - show inactive switch wrapper",
    },
    AddClusterButton: "clusters view - add cluster button",
    ClustersTable: "clusters view - table",
    InactiveDot: "clusters view - inactive dot",
    NodesCell: {
      ReadyNodes: "clusters view - nodes cell - ready nodes",
      NotReadyNodes: "clusters view - nodes cell - not ready nodes",
    },
    ServicesCell: {
      HealthyServices: "clusters view - services cell - healthy services",
      UnhealthyServices: "clusters view - services cell - unhealthy services",
    },
    JobsCell: {
      GreenJobs: "clusters view - jobs cell - green jobs",
      GrayJobs: "clusters view - jobs cell - gray jobs",
      RedJobs: "clusters view - jobs cell - red jobs",
    },
    PodsCell: {
      GreenPods: "clusters view - pods cell - green pods",
      YellowPods: "clusters view - pods cell - yellow pods",
      RedPods: "clusters view - pods cell - red pods",
    },
    ViolationsCell: {
      Container: "clusters view - violations cell",
      High: "clusters view - violations cell - high",
      Medium: "clusters view - violations cell - medium",
      Low: "clusters view - violations cell - low",
    },
    NonClusterWorkspaceEmptyState:
      "clusters view - non cluster workspace empty state",
  },
  LinesLoader: "Loading...",
  ClusterOverview: {
    Header: {
      UpgradeAgentButton: "cluster overview - upgrade agent button",
    },
    UnhealthyServicesSection: {
      ViewAllButton:
        "cluster overview - unhealthy services section - view all button",
      IssuesTableLoader:
        "cluster overview - unhealthy services section - issues table loader",
      IssueDrawer:
        "cluster overview - unhealthy services section - issue drawer",
      SmartTextsJoinTooltip:
        "cluster overview - unhealthy services section - smart texts join tooltip",
      MoreInfoButton:
        "cluster overview - unhealthy services section - more info button",
      OpenIssuesCountGraph: {
        CountArea:
          "cluster overview - unhealthy services section - open issues count graph - count area",
        ItemIcon:
          "cluster overview - unhealthy services section - open issues count graph - tooltip item icon",
      },
    },
    ReliabilitySection: {
      ViewAllButton: "cluster overview - reliability section - view all button",
      Violations: {
        Loader: "cluster overview - reliability section - violations loader",
        ImpactGroup: {
          Container:
            "cluster overview - reliability section - violation impact group",
          ViewAllViolationsButton:
            "cluster overview - reliability section - violation impact group - view all violations button",
          ViolationItem:
            "cluster overview - reliability section - violation impact group - violation item",
        },
      },
    },
    CostSection: {
      ViewAllButton: "cluster overview - cost section - view all button",
      StatsGrid: "cluster overview - cost section - stats grid",
      StatsSummary: "cluster overview - cost section - stats summary",
    },
    HealthSection: {
      StatsLoader: "cluster overview - health section - stats loader",
      SummaryChip: "cluster overview - health section - summary chip",
      Workloads: {
        GraphIssuesButton:
          "cluster overview - health section - workloads - realtime issues button",
        GraphRisksButton:
          "cluster overview - health section - workloads - reliability risks button",
        ViewAllButton:
          "cluster overview - health section - workloads - view all",
        RealtimeIssuesTile:
          "cluster overview - health section - workloads - issues tile",
        ReliabilityRisksTile:
          "cluster overview - health section - workloads - reliability risks tile",
      },
      Infra: {
        GraphIssuesButton:
          "cluster overview - health section - infra - realtime issues button",
        GraphRisksButton:
          "cluster overview - health section - infra - reliability risks button",
        ViewAllButton: "cluster overview - health section - infra - view all",
        RealtimeIssuesTile:
          "cluster overview - health section - infra - issues tile",
        ReliabilityRisksTile:
          "cluster overview - health section - infra - reliability risks tile",
      },
    },
    AddonsSection: {
      CertManager: "cluster overview - addons section - cert manager",
      Workflows: "cluster overview - addons section - workflows",
      Helm: "cluster overview - addons section - helm",
      ExternalDNS: "cluster overview - addons section - external dns",
    },
    InventorySection: {
      Distributions: {
        Pods: "cluster overview - inventory section - pods distribution",
        Nodes: "cluster overview - inventory section - nodes distribution",
        Services:
          "cluster overview - inventory section - services distribution",
        Jobs: "cluster overview - inventory section - jobs distribution",
      },
      DistributionBox: {
        Loader:
          "cluster overview - inventory section - distribution box - loader",
        Item: "cluster overview - inventory section - distribution box - item",
        Total:
          "cluster overview - inventory section - distribution box - total",
      },
      Metrics: {
        Container: "cluster overview - inventory section - metrics",
        CpuGraph: {
          Name: "cpu graph",
          Graph: "cluster overview - inventory section - metrics - cpu graph",
          UsageLine:
            "cluster overview - inventory section - metrics - cpu usage line",
          CapacityLine:
            "cluster overview - inventory section - metrics - cpu capacity line",
          RequestLine:
            "cluster overview - inventory section - metrics - cpu request line",
          AllocatableLine:
            "cluster overview - inventory section - metrics - cpu allocatable line",
          P90Line:
            "cluster overview - inventory section - metrics - cpu p90 line",
          P95Line:
            "cluster overview - inventory section - metrics - cpu p95 line",
          P99Line:
            "cluster overview - inventory section - metrics - cpu p99 line",
          MaxLine:
            "cluster overview - inventory section - metrics - cpu max line",
          Yaxis: "cluster overview - inventory section - metrics - cpu y axis",
          Xaxis: "cluster overview - inventory section - metrics - cpu x axis",
        },
        MemoryGraph: {
          Name: "memory graph",
          Graph:
            "cluster overview - inventory section - metrics - memory graph",
          UsageLine:
            "cluster overview - inventory section - metrics - memory usage line",
          CapacityLine:
            "cluster overview - inventory section - metrics - memory capacity line",
          RequestLine:
            "cluster overview - inventory section - metrics - memory request line",
          AllocatableLine:
            "cluster overview - inventory section - metrics - memory allocatable line",
          P90Line:
            "cluster overview - inventory section - metrics - memory p90 line",
          P95Line:
            "cluster overview - inventory section - metrics - memory p95 line",
          P99Line:
            "cluster overview - inventory section - metrics - memory p99 line",
          MaxLine:
            "cluster overview - inventory section - metrics - memory max line",
          Yaxis:
            "cluster overview - inventory section - metrics - memory y axis",
          Xaxis:
            "cluster overview - inventory section - metrics - memory x axis",
        },
      },
    },
    Summary: {
      Loader: "cluster overview - summary - loader",
      Distributions: "cluster overview - inventory section - distributions",
      Metrics: "cluster overview - inventory section - metrics",
    },
  },
  ClustersGroupOverview: {
    Header: {
      Description: "clusters group overview - header - description",
      ClustersSelector: "clusters group overview - header - clusters selector",
    },
  },
  AiInvestigator: {
    LoaderLogo: "ai investigator - loader logo",
    Banner: "klaudia - ai banner",
    Drawer: {
      Minimized: "AI investigation minimized drawer",
      Row: "maximize window ai investigation session",
      RowRemove: "close window ai investigation session",
    },
  },
  DriftAnalysis: {
    MetricsTab: {
      WorkloadsCpuGraph: {
        Name: "cpu graph",
        Graph: "drift analysis workloads metrics - cpu graph",
        Yaxis: "drift analysis workloads metrics - cpu y axis",
        Xaxis: "drift analysis workloads metrics - cpu x axis",
      },
      WorkloadsMemoryGraph: {
        Name: "memory graph",
        Graph: "drift analysis workloads metrics - memory graph",
        Yaxis: "drift analysis workloads metrics - memory y axis",
        Xaxis: "drift analysis workloads metrics - memory x axis",
      },
    },
  },
};
