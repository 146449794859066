import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { MagicAi24 } from "@komodorio/design-system/icons";
import { ConfirmationDialog } from "@komodorio/design-system/komodor-ui";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "@mui/material/Button";

import { RichSessionData } from "../common/useEnrichedSession";

import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";

export type InvestigationTitleProps = {
  className?: string;
  richSession: RichSessionData | null;
  onClick?: React.EventHandler<React.MouseEvent>;
  onShareClicked: () => void;
  showIcon?: boolean;
  showTitleText?: boolean;
  titleProps?: TypographyProps;
  extraContent?: React.ReactNode;
};

export const Root = styled.div`
  display: flex;
  column-gap: 4px;
  width: 100%;
  align-items: center;
`;

export const InvestigationTitle: React.FC<InvestigationTitleProps> = ({
  className,
  onClick,
  richSession,
  onShareClicked,
  showIcon = true,
  showTitleText = true,
  titleProps,
  extraContent,
}) => {
  const { isKomodorAdmin } = useUserMetadata();
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

  return (
    <Root className={className} onClick={onClick}>
      {isShareDialogOpen && (
        <ConfirmationDialog
          title="Share Link"
          okText="Copy Link"
          width="446px"
          content={
            <Typography variant="body2">
              Please note that the person receiving the link will be able to see
              all resources regardless of their permissions.
            </Typography>
          }
          onClose={(confirmed) => {
            setIsShareDialogOpen(false);
            confirmed && onShareClicked();
          }}
        />
      )}
      {showIcon ? <MagicAi24 /> : null}
      {showTitleText ? (
        <Typography {...(titleProps ?? {})}>Root Cause Analysis </Typography>
      ) : null}
      <Typography variant="body3" color="text.secondary">
        (Powered by Klaudia AI)
      </Typography>
      {richSession?.isComplete && richSession.isNoProblemFound && (
        <Typography variant="h6" fontWeight={500}>
          | No Findings
        </Typography>
      )}
      {isKomodorAdmin && richSession?.id && (
        <Typography variant="body3" color="text.secondary">
          <Link
            target="_blank"
            to={`/ai-investigator/?sessionId=${richSession.id}`}
          >
            View session (internal)
          </Link>
        </Typography>
      )}
      {extraContent}
      {richSession?.isComplete && !richSession.isNoProblemFound ? (
        <Box flexGrow={1} display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              setIsShareDialogOpen(true);
            }}
            startIcon={<ShareOutlinedIcon />}
          >
            Share Link
          </Button>
        </Box>
      ) : null}
    </Root>
  );
};
