import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";

export const LabelContainer = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

export const LabelCheckbox = styled(Checkbox)`
  && {
    padding: 0px;
  }
`;
