import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { muiPalette } from "@komodorio/design-system";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useMemo, useState } from "react";
import { SearchField } from "@komodorio/design-system/komodor-ui";

import {
  KomodorUidParams,
  parseKomodorUid,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ConnectedWorkloadsTable } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/ConnectedWorkloadsCell/ConnectedWorkloadsTable";
import { useModal } from "@/shared/context/modal/useModal";

export const SEARCH_INPUT_PLACEHOLDER = "Workload name";

export const ConnectedWorkloadsModal: React.FC<{
  host: string;
  workloadsUids: string[];
}> = ({ host, workloadsUids }) => {
  const { open, onClose } = useModal();

  const [searchTerm, setSearchTerm] = useState("");

  const filteredParsedWorkloadsKomodorUids = useMemo<KomodorUidParams[]>(
    () =>
      workloadsUids
        .map((komodorUid) => parseKomodorUid(komodorUid))
        .filter((parsedKomodorUid): parsedKomodorUid is KomodorUidParams => {
          return (
            !!parsedKomodorUid &&
            parsedKomodorUid.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          );
        }),
    [workloadsUids, searchTerm]
  );

  return (
    <Dialog open={open} sx={{ height: "100%" }} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: `1px solid ${muiPalette.divider}`,
          alignItems: "center",
          padding: "16px 24px",
        }}
      >
        <Typography variant="h3">Connected Workloads</Typography>
        <IconButton onClick={onClose}>
          <Close color="action" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack flexDirection="row" gap="4px" sx={{ paddingBlock: "16px" }}>
          <Typography variant="h5" color="text.secondary">
            Host:
          </Typography>
          <Typography variant="body2">{host}</Typography>
        </Stack>
        <Stack gap="8px">
          <SearchField
            label="Search"
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            value={searchTerm}
            onSearch={setSearchTerm}
          />
          <Typography variant="body3" color="text.secondary">
            Showing {filteredParsedWorkloadsKomodorUids.length} workloads
          </Typography>
          <ConnectedWorkloadsTable
            workloadsKomodorUids={filteredParsedWorkloadsKomodorUids}
          />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "16px 24px",
          borderTop: `1px solid ${muiPalette.divider}`,
        }}
      >
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
