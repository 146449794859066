import { useCallback } from "react";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";

import { useTablePaginationInUrl } from "@/components/k8sAddons/hooks/table/useTablePaginationInUrl";

export const useCommonLiveStateTableProps = (isFetchingData: boolean) => {
  const { sortModel, paginationModel, setPaginationModel, setSortModel } =
    useTablePaginationInUrl();

  const onPaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      setPaginationModel(model);
    },
    [setPaginationModel]
  );

  return {
    isFetchingData,
    pageSize: paginationModel.pageSize,
    paginationModel,
    sortModel,
    onSortModelChange: setSortModel,
    onPaginationModelChange: onPaginationModelChange,
  };
};
