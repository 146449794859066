/* eslint-disable max-lines */
import React, { useMemo } from "react";
import styled from "styled-components";
import { palette, muiColors } from "@komodorio/design-system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Status, Metadata } from "@komodorio/design-system/komodor-ui";
import { Divider } from "@komodorio/design-system/deprecated";

import { useInvestigationModeStore } from "../../../shared/store/investigationModeStore/investigationModeStore";
import {
  issueSelector,
  komodorServiceSelector,
  setShowResourceDrawerSelector,
  setShowTriageDrawerSelector,
  showResourceDrawerSelector,
  showTriageDrawerSelector,
} from "../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useIssueDuration } from "../../common/ProcessList/details/WorkflowIssueEventDetails/triage/AvailabilityTriage/sections/ImpactSection";
import { useMinifiedMonitorEventGroupById } from "../../monitorsView/useWorkflowsRuns";
import EventDrawer from "../../common/ProcessList/EventDrawer";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { ResourceDrawer } from "../../ResourceView/ResourceDrawer";
import { extractServiceName } from "../../../shared/utils/serviceHelpers";

import ReplicasTile from "./ReplicasTile";
import ResourceNameTile from "./ResourceNameTile";

const { gray } = muiColors;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.gray[200]};
  border-radius: 4px;
  width: 100%;
`;

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  padding: 0.5rem 1rem;
  :first-child {
    padding-block-start: 1rem;
  }
  :last-child {
    padding-block: 0;
  }
`;
const CenteredCardRow = styled(CardRow)`
  justify-content: center;
`;

const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-block-end: 0.3rem;
`;

const CardColumn = styled.div<{ align?: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align ?? "center"};
  row-gap: 0.2rem;
  width: 100%;
`;

const StyledDivider = styled(Divider)<{
  variant?: string;
}>`
  width: ${({ variant }) => (variant === "horizontal" ? "100%" : "")};
  height: ${({ variant }) => (variant === "vertical" ? "100%" : "")};
`;

export const IssueSummary: React.FC = () => {
  const showTriage = useInvestigationModeStore(showTriageDrawerSelector);
  const setShowTriage = useInvestigationModeStore(setShowTriageDrawerSelector);

  const issue = useInvestigationModeStore(issueSelector);

  const showResourceDrawer = useInvestigationModeStore(
    showResourceDrawerSelector
  );
  const setShowResourceDrawer = useInvestigationModeStore(
    setShowResourceDrawerSelector
  );

  const resource = useInvestigationModeStore(komodorServiceSelector);
  const resourceName = useMemo(() => {
    return extractServiceName(issue?.services?.[0]);
  }, [issue?.services]);
  const correlatedEvent = useMinifiedMonitorEventGroupById({
    runId: issue?.id ?? "",
    serviceId: issue?.services?.[0] ?? "",
    type: "availability",
  });

  return issue ? (
    <>
      <Card aria-label={AriaLabels.InvestigationMode.IssueSummary}>
        <CardRow>
          <CardColumn align="start">
            <SummaryRow>
              <Typography variant={"h6"} color={gray[850]}>
                Availability Issue
              </Typography>
              <Status
                size="small"
                textTransform="uppercase"
                label={issue?.closedAt ? "closed" : "open"}
                color={issue?.closedAt ? "neutral" : "error"}
              />
            </SummaryRow>
            <Typography variant={"body2"} color={gray[850]}>
              {issue.reasons[0]}
            </Typography>
          </CardColumn>
        </CardRow>
        <StyledDivider variant={"horizontal"} />
        <CardRow>
          <CardColumn align="start">
            <ResourceNameTile
              deletedAt={resource?.isDeleted ? resource.deletedAt : undefined}
              resourceName={resourceName}
              resourceKind={resource?.kind ?? "deployment"}
              onResourceClick={() => setShowResourceDrawer(true)}
              ariaLabel={AriaLabels.InvestigationMode.IssueSummaryServiceLink}
            />
          </CardColumn>
        </CardRow>
        <StyledDivider variant={"horizontal"} />
        <CardRow>
          <CardColumn>
            <ReplicasTile />
          </CardColumn>
          <StyledDivider variant={"vertical"} />
          <CardColumn>
            <LiveDurationInfo
              eventTime={issue.eventTime}
              closedAt={issue.closedAt}
            />
          </CardColumn>
        </CardRow>
        <StyledDivider variant={"horizontal"} />
        <CenteredCardRow>
          <Button
            aria-label={AriaLabels.InvestigationMode.IssueSummaryIssueLink}
            onClick={() => {
              setShowTriage(true);
            }}
          >
            View Issue Details
          </Button>
        </CenteredCardRow>
      </Card>
      <ResourceDrawer
        open={showResourceDrawer}
        onClose={() => setShowResourceDrawer(false)}
        resource={resource}
      />
      <EventDrawer
        open={showTriage && !!correlatedEvent}
        onClose={() => setShowTriage(false)}
        event={correlatedEvent}
        ariaLabel={AriaLabels.InvestigationMode.IssueSummaryTriageDrawer}
      >
        {correlatedEvent?.renderEventDetails?.(() => setShowTriage(false)) ??
          null}
      </EventDrawer>
    </>
  ) : null;
};

const LiveDurationInfo: React.FC<{
  eventTime: Date;
  closedAt: Date | null;
}> = ({ eventTime, closedAt }) => {
  const issueDuration = useIssueDuration(eventTime, closedAt);

  return (
    <Metadata
      size={"small"}
      title={"DURATION"}
      value={issueDuration}
      align={"center"}
      aria-label={AriaLabels.InvestigationMode.IssueSummaryLiveDuration}
    />
  );
};
