import { immer } from "zustand/middleware/immer";
import { create } from "zustand";

import { LogsParams, PodLogsDirection, PodLogsStore } from "./types";
import { initialState, initialValues } from "./initialState";

export const usePodLogsStore = create(
  immer<PodLogsStore>((set) => ({
    ...initialState,
    resetLogs: () => set(() => ({ logs: [], isLoading: true })),
    addLogs: (logs) =>
      set((prev) => ({
        logs:
          prev.direction === PodLogsDirection.NewestFirst
            ? [...logs, ...prev.logs]
            : [...prev.logs, ...logs],
      })),
    setDirection: (direction) => set(() => ({ direction })),
    setPodLogsParams: (logsParams: LogsParams) =>
      set(() => ({
        logsParams,
      })),
    setIsLoading: (isLoading) => set(() => ({ isLoading })),
  }))
);

export const resetPodLogsStore = () => {
  usePodLogsStore.setState(initialValues);
};
