import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum freemiumModalNames {
  TRIAL_ENDED = "TRIAL_ENDED",
  UPGRADE_PLAN = "UPGRADE_PLAN",
  CHOSE_FREE = "CHOSE_FREE",
  USERS_RESTRICTION = "USERS_RESTRICTION",
  CLUSTERS_RESTRICTION = "CLUSTERS_RESTRICTION",
  POLICIES_RESTRICTION = "POLICIES_RESTRICTION",
  ROLES_RESTRICTION = "ROLES_RESTRICTION",
  LOCK_RESTRICTION = "LOCK_RESTRICTION",
}

export type freemiumModal = freemiumModalNames | null;

const FreemiumModalsContext = createContext<freemiumModal>(null);

const FreemiumModalsUpdateContext = createContext<Dispatch<
  SetStateAction<freemiumModal>
> | null>(null);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useFreemiumModals = (): freemiumModal => {
  return useContext(FreemiumModalsContext);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useFreemiumModalsUpdate = (): Dispatch<
  SetStateAction<freemiumModal>
> | null => {
  return useContext(FreemiumModalsUpdateContext);
};

export const FreemiumModalsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [currentFreemiumModal, setCurrentFreemiumModal] =
    useState<freemiumModal>(null);
  return (
    <FreemiumModalsContext.Provider value={currentFreemiumModal}>
      <FreemiumModalsUpdateContext.Provider value={setCurrentFreemiumModal}>
        {children}
      </FreemiumModalsUpdateContext.Provider>
    </FreemiumModalsContext.Provider>
  );
};
