import { useCallback } from "react";

import { useReliabilityStore } from "@/components/reliability/store/reliabilityStore";
import {
  selectSetGroupExpandedState,
  selectViolationsState,
} from "@/components/reliability/store/reliabilityStoreSelectors";
import { getCardTexts } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationsGroup/getCardTexts";
import { GroupProps } from "@/components/reliability/ReliabilityTypes";
import { ImpactGroupIdentifier } from "@/generated/reliabilityApi";

export const useCommonViolationGroupProps = ({
  group,
  groupBy,
}: GroupProps) => {
  const { id } = group;

  const { groups } = useReliabilityStore(selectViolationsState);
  const setIsGroupExpanded = useReliabilityStore(selectSetGroupExpandedState);
  const groupState = groups[groupBy];
  const groupIdentifier = id as ImpactGroupIdentifier;

  const isOpen = groupState.groupData[groupIdentifier]?.isExpanded ?? false;

  const onExpandClick = useCallback(() => {
    setIsGroupExpanded(groupBy, groupIdentifier);
  }, [groupBy, groupIdentifier, setIsGroupExpanded]);

  const { title } = getCardTexts(id, groupBy);

  return {
    id,
    isOpen,
    onExpandClick,
    title,
    groupState,
  };
};
