import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { WORKLOAD_HEALTH, INFRA_HEALTH } from "@/components/routes/routes";
import { ReliabilityRoutes } from "@/components/reliability/constants/reliabilityConstants";

export const useGetCurrentTab = (): string | undefined => {
  const location = useLocation();
  const { pathname } = location;

  return useMemo(() => {
    const subRoute = pathname
      .replace(`/${WORKLOAD_HEALTH}`, "")
      .replace(`/${INFRA_HEALTH}`, "");

    return Object.values(ReliabilityRoutes).find((route) =>
      subRoute.includes(route)
    );
  }, [pathname]);
};
