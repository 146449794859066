import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

type FailureMessageLineProps = {
  type: string;
  explanation?: string;
};

export const FailureMessageLine: React.FC<FailureMessageLineProps> = ({
  type,
  explanation,
}) => {
  if (!explanation) return null;
  return (
    <Container>
      <Typography variant={"h5"} color={"error.dark"}>
        {type}
      </Typography>
      <Typography variant={"body2"} color={"error.dark"}>
        {explanation}
      </Typography>
    </Container>
  );
};
