import React, { PropsWithChildren } from "react";

import { ModalProps, modalContext } from "@/shared/context/modal/useModal";

export const ModalProvider: React.FC<PropsWithChildren<ModalProps>> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <modalContext.Provider value={{ open, onClose }}>
      {children}
    </modalContext.Provider>
  );
};
