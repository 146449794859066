import { Dictionary } from "lodash";

import { WorkflowEngine } from "@/components/k8sAddons/addons/workflows/types";

export const LabelWorkflowEngine = "app.komodor.com/WorkflowEngine";
export const LabelWorkflowDagId = "app.komodor.com/WorkflowDagId";
export const LabelWorkflowRunId = "app.komodor.com/WorkflowRunId";
export const LabelWorkflowTaskId = "app.komodor.com/WorkflowTaskId";

export const LabelAirflowDagId = "dag_id";
export const LabelAirflowRunId = "run_id";
export const LabelAirflowTaskId = "task_id";
export const LabelAirflowTryNumber = "try_number";
export const LabelAirflowExecutionDate = "execution_date";
export const KomodorAirflowRunIdPrefix = "komodor_run_id";
export const KomodorAirflowRunIdPrefixDelimiter = "_";

export const getWorkflowEngine = (
  labels: Record<string, string>
): string | undefined => {
  if (isAirflowResource(labels)) {
    return WorkflowEngine.Airflow;
  }
  return labels[LabelWorkflowEngine];
};

export const getWorkflowDagId = (
  labels: Record<string, string>
): string | undefined => {
  return labels[LabelWorkflowDagId] || labels[LabelAirflowDagId];
};

export const getWorkflowRunId = (
  labels: Dictionary<string>
): string | undefined => {
  return (
    labels[LabelWorkflowRunId] ||
    labels[LabelAirflowRunId] ||
    komodorRunId(labels)
  );
};

export const getWorkflowTaskId = (
  labels: Dictionary<string>
): string | undefined => {
  return labels[LabelWorkflowTaskId] || labels[LabelAirflowTaskId];
};

export const getWorkflowTryNumber = (
  labels: Dictionary<string>
): string | undefined => {
  return labels[LabelAirflowTryNumber];
};

export type WorkflowLabels = {
  engine: string;
  dagId: string;
  runId: string;
  taskId: string;
  tryNumber: string;
};

export const isWorkflowResource = (labels: Dictionary<string>): boolean => {
  return (
    !!getWorkflowDagId(labels) &&
    !!getWorkflowRunId(labels) &&
    !!getWorkflowTaskId(labels)
  );
};

export const isAirflowResource = (labels: Dictionary<string>): boolean => {
  const hasDagId = !!labels[LabelAirflowDagId];
  const hasRunId = !!labels[LabelAirflowRunId];
  const hasTaskId = !!labels[LabelAirflowTaskId];
  const hasExecutionDate = !!labels[LabelAirflowExecutionDate];

  return (
    (hasDagId && hasRunId && hasTaskId) ||
    (hasDagId && !hasRunId && hasTaskId && hasExecutionDate)
  );
};

const komodorRunId = (labels: Dictionary<string>): string | undefined => {
  const label = labels[LabelAirflowExecutionDate];
  if (!label) {
    return undefined;
  }
  return `${KomodorAirflowRunIdPrefix}${KomodorAirflowRunIdPrefixDelimiter}${label}`;
};

export const isKomodorRunIdPrefix = (runId: string): boolean => {
  return runId.startsWith(KomodorAirflowRunIdPrefix);
};

export const getLabelWithoutKomodorRunIdPrefix = (runId: string): string => {
  return runId.replace(
    `${KomodorAirflowRunIdPrefix}${KomodorAirflowRunIdPrefixDelimiter}`,
    ""
  );
};

export const getWorkflowLabels = (
  labels: Dictionary<string>
): WorkflowLabels => {
  return {
    engine: getWorkflowEngine(labels) ?? "",
    dagId: getWorkflowDagId(labels) ?? "",
    runId: getWorkflowRunId(labels) ?? "",
    taskId: getWorkflowTaskId(labels) ?? "",
    tryNumber: getWorkflowTryNumber(labels) ?? "",
  };
};
