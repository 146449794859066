import React from "react";
import { compact } from "lodash";

import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import { columnsConfig } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/hooks/nodeAutoscalerTableHooks";
import { SEARCH_TABLE_PLACEHOLDER } from "@/components/k8sAddons/addons/NodeAutoscalers/nodeAutoscalersConstants";
import { PageFiltersContainer } from "@/components/k8sAddons/styles";
import { AddonFilterHandler } from "@/components/k8sAddons/components/AddonFilterHandler/AddonFilterHandler";
import { sharedFilterProps } from "@/components/k8sAddons/constants/filters";

export const Filters: React.FC = () => {
  const { searchFieldComponent, searchFilter } = useSearchFieldAsGenericFilter({
    filterName: columnsConfig.name.field,
    searchFieldProps: {
      placeholder: SEARCH_TABLE_PLACEHOLDER,
      label: "Search",
    },
  });

  const additionalFilters = compact([searchFilter]);

  return (
    <PageFiltersContainer>
      {searchFieldComponent}
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.cluster.field}
        label={"Cluster"}
        additionalFilters={additionalFilters}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.autoscalerType.field}
        label={"Type"}
        additionalFilters={additionalFilters}
      />
    </PageFiltersContainer>
  );
};
