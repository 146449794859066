import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import {
  useFilterWorkloadsMetricsByContainer,
  useWorkloadsGraphData,
} from "./graphDataHooks";
import {
  useParseMultipleMetricsResults,
  useWorkloadsContainersMetricsParams,
} from "./metricsRequestsHooks";

import { MetricType } from "@/components/Metrics/types";
import { useGetWorkloadsContainersMetrics } from "@/shared/hooks/metrics-api/client/useGetWorkloadContainersMetrics";
import { WorkloadMetricsContainers } from "@/generated/metricsApi";

type WorkloadsMetricsParams = {
  type: MetricType;
  komodorUids: string[];
  wantedContainer: MuiSelectionOption<string> | undefined;
  endTime?: number;
};

export const useWorkloadsContainersMetrics = ({
  type,
  komodorUids,
  wantedContainer,
  endTime,
}: WorkloadsMetricsParams) => {
  const { toEpochMs, fromEpoch, toEpoch, requestsParams, enabledClusters } =
    useWorkloadsContainersMetricsParams({
      komodorUids,
      endTime,
      type,
    });

  const results = useGetWorkloadsContainersMetrics({
    params: requestsParams,
    enabledClusters,
  });

  const { error, loadingStates, isLoading, data } =
    useParseMultipleMetricsResults(results);

  const dataForContainer = useFilterWorkloadsMetricsByContainer(
    wantedContainer,
    data as (WorkloadMetricsContainers | undefined)[]
  );

  const { graphProps, hasGraphData, linesContent } = useWorkloadsGraphData(
    dataForContainer,
    type
  );

  return {
    loadingStates,
    isLoading,
    error,
    graphProps,
    hasGraphData,
    fromEpoch,
    toEpoch,
    toEpochMs,
    linesContent,
  };
};
