import { useEffect, useState } from "react";

import { Maybe } from "../../generated/graphql";
import { useParsedKomodorService } from "../../shared/hooks/resources/useParsedResource";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";

import Resource from "./resources";

import { useGetKomodorServiceById } from "@/shared/hooks/resources-api/client/komodor-services/useGetKomodorServiceById";
import {
  KomodorServicesApiApiV1KomodorServicesIdGetRequest,
  KomodorServicesResponse,
  KomodorServicesResponseDataInner,
} from "@/generated/resourcesApi";

const SERVICE_POLLING_INTERVAL = 7500;
const FIELDS: KomodorServicesApiApiV1KomodorServicesIdGetRequest["fields"] = [
  "id",
  "agentId",
  "inactive",
  "isDeleted",
  "deletedAt",
  "k8sClusterName",
  "namespace",
  "displayName",
  "kind",
  "updatedAt",
  "createdAt",
  "k8s_metadata",
  "deploy_state",
  "health_state",
  "datadog_data",
  "sentry_data",
];

export const useKomodorServiceAsResourceWithInterval = (
  serviceId: string | undefined,
  expectedKind?: string,
  resultData?: unknown
): [Resource | undefined, boolean] => {
  const { data, isInitialLoading, isError } = useGetKomodorServiceById(
    {
      id: serviceId ?? "",
      fields: FIELDS,
    },
    {
      enabled: !!serviceId,
      refetchInterval: SERVICE_POLLING_INTERVAL,
    }
  );

  const [resource, komodorService] = useParsedResourceFromData(
    data,
    resultData
  );

  const cachedResource = useCachedResource(resource);

  if (isUnexpectedKind(expectedKind, komodorService?.kind)) {
    return [undefined, false];
  }
  return [isError ? cachedResource : resource, isInitialLoading];
};

export const useKomodorServiceAsResource = (
  serviceId: string | undefined,
  expectedKind?: string,
  resultData?: unknown
): [Resource | undefined, boolean] => {
  const { data, isLoading, isError } = useGetKomodorServiceById(
    {
      id: serviceId ?? "",
      fields: FIELDS,
    },
    {
      enabled: !!serviceId,
    }
  );

  const [resource, komodorService] = useParsedResourceFromData(
    data,
    resultData
  );

  const cachedResource = useCachedResource(resource);

  if (isUnexpectedKind(expectedKind, komodorService?.kind)) {
    return [undefined, false];
  }

  return [isError ? cachedResource : resource, isLoading];
};

const useCachedResource = (resource: Resource | undefined) => {
  const [cachedResource, setCachedResource] = useState<Resource | undefined>(
    undefined
  );

  useEffect(() => {
    if (resource) {
      setCachedResource(resource);
    }
  }, [resource]);

  return cachedResource;
};

const isUnexpectedKind = (
  expectedKind: string | undefined,
  kind: Maybe<string> | undefined
) => {
  return expectedKind !== undefined && expectedKind !== kind;
};

const useParsedResourceFromData = (
  data: KomodorServicesResponse | undefined,
  resultData?: unknown
): [Resource | undefined, KomodorServicesResponseDataInner | undefined] => {
  const featureFlags = useOverridableFlags();
  const komodorService = data?.data?.[0];

  return [
    useParsedKomodorService(komodorService, featureFlags, resultData),
    komodorService,
  ];
};
