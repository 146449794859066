import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Link } from "../../../../../../ResourceView/tabs/InfoTab/AdditionalLinks/links/useKomodorAnnotations";
import IntegrationLinkView from "../IntegrationLink";

import generateCustomLinkWithParameters from "./CustomLinkUtil";

import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";

interface CustomLinkComponentProps {
  linkTemplate: Link;
  displayName: string | undefined;
  k8sClusterName: string | undefined;
  namespace: string | undefined;
  deploySpec?: Record<string, unknown>;
  startTime?: Date;
  endTime: Date;
  failedPod?: string;
  dispatchLinkMetric?: () => void;
  isPodEvent?: boolean;
}
const CustomLinkComponent: React.FC<CustomLinkComponentProps> = ({
  linkTemplate,
  displayName,
  k8sClusterName,
  namespace,
  deploySpec,
  startTime,
  endTime,
  failedPod,
  dispatchLinkMetric,
  isPodEvent = false,
}) => {
  // eslint-disable-next-line no-template-curly-in-string
  const isFailedPodHref = linkTemplate.url.includes("${failedPod}");
  const { showMotionalPodLinks } = useFlags();
  const shouldGenerateLink =
    displayName &&
    k8sClusterName &&
    namespace &&
    (deploySpec || (isPodEvent && showMotionalPodLinks));

  const { userId } = useUserMetadata();
  const { data: user } = useGetUserById({ id: userId });
  const { href, errors } = shouldGenerateLink
    ? generateCustomLinkWithParameters(
        linkTemplate.url,
        displayName,
        k8sClusterName,
        namespace,
        Boolean(failedPod),
        deploySpec,
        startTime,
        endTime,
        isFailedPodHref ? failedPod : undefined,
        user?.email
      )
    : { href: "#loading", errors: [] };

  return (
    <IntegrationLinkView
      icon={linkTemplate.icon}
      href={href}
      title={linkTemplate.text}
      error={errors?.[0]}
      noUnderLine={false}
      dispatchLinkMetric={dispatchLinkMetric}
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default CustomLinkComponent;
