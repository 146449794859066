import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const NameFieldCell = styled(Typography).attrs({
  variant: "body2",
  color: "text.primary",
  fontWeight: 500,
  sx: {
    cursor: "pointer",
    textDecoration: "underline",
  },
})`
  margin-block-end: 0.25rem;
`;
