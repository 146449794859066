import { IconButton } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { muiColors, muiPalette } from "@komodorio/design-system";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Copy24, Download24, IconProps } from "@komodorio/design-system/icons";
import React, { useCallback } from "react";
import { TooltipWrapper } from "react-tooltip";
import OpenInFull from "@mui/icons-material/OpenInFull";

import { DEFAULT_TOOLTIP } from "../../../../shared/constants/tooltipIds";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import {
  PodLogLine,
  PodLogsMode,
} from "../../../../shared/hooks/podLogs/types";
import { useLogsViewerStore } from "../store/logsViewerStore";
import {
  selectIsLiveTailEnabledByUser,
  selectIsAnalyzingLogs,
  setLiveTailEnabledByUser,
} from "../store/logsViewerStoreSelectors";
import { logsViewerAriaLabels } from "../logsViewerAriaLabels";
import { useLogsViewerContext } from "../LogsViewerContext";
import { SettingsButton } from "../../SettingsButton/SettingsButton";

import { downloadContentToTextFile, getLogsFromFetch } from "./PodLogsActions";
import { LogsOptions } from "./LogsOptions/LogsOptions";
import { useLogsActionsOptions } from "./useLogsActionsOptions";

import { copyContentToClipboard } from "@/shared/utils/copyContentToClipboard";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

const SwitchContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

type LogsActionsProps = {
  podLogsRes: PodLogLine[];
  podName?: string;
  mode: PodLogsMode;
  onEnlarge?: () => void;
};

const OpenInFullComp: React.FC<IconProps> = () => {
  return <OpenInFull fontSize="medium" />;
};

const LogsActions: React.FC<LogsActionsProps> = ({
  podLogsRes,
  podName,
  mode,
  onEnlarge,
}) => {
  const { isInModal } = useLogsViewerContext();
  const logs = podLogsRes.length ? getLogsFromFetch(podLogsRes) : "";
  const copyLogsCallback = useCallback(async () => {
    await copyContentToClipboard(logs);
  }, [logs]);
  const shouldShowLiveTailToggle = mode === PodLogsMode.Live;
  const isLiveTailEnabledByUser = useLogsViewerStore(
    selectIsLiveTailEnabledByUser
  );
  const isAnalyzingLogs = useLogsViewerStore(selectIsAnalyzingLogs);
  const setLiveTailEnabledCB = useLogsViewerStore(setLiveTailEnabledByUser);

  const { newLogsSettings } = useOverridableFlags();

  const oldLogsActions = useLogsActionsOptions(); //CU-86c14fqqq: this needs to be removed once we remove newLogsSettings FF

  return (
    <>
      {shouldShowLiveTailToggle && (
        <SwitchContainer
          aria-label={
            isLiveTailEnabledByUser
              ? logsViewerAriaLabels.liveTailToggle.pause
              : logsViewerAriaLabels.liveTailToggle.resume
          }
          role="switch"
        >
          <Typography variant="body2" color={muiPalette.text?.secondary}>
            Live tail
          </Typography>
          <Switch
            checked={isLiveTailEnabledByUser}
            onChange={() => setLiveTailEnabledCB(!isLiveTailEnabledByUser)}
            disabled={isAnalyzingLogs}
          />
        </SwitchContainer>
      )}
      <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content="Logs settings">
        {newLogsSettings ? (
          <LogsOptions mode={mode} />
        ) : (
          <SettingsButton
            options={oldLogsActions}
            iconColor={muiColors.gray[500]}
          />
        )}
        {/*CU-86c14fqqq: this condition needs to be removed once we remove newLogsSettings FF*/}
      </TooltipWrapper>
      <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content="Download logs">
        <IconButton
          icon={Download24}
          onClick={() =>
            downloadContentToTextFile(
              logs,
              `Logs${podName ? `_${podName}` : ""}.txt`
            )
          }
          disabled={!logs}
          noBorder
          aria-label={AriaLabels.LogsViewer.DownloadLogsButton}
        />
      </TooltipWrapper>
      <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content="Copy logs">
        <IconButton
          icon={Copy24}
          onClick={copyLogsCallback}
          noBorder
          disabled={!logs}
          aria-label={AriaLabels.LogsViewer.CopyLogsButton}
        />
      </TooltipWrapper>
      {onEnlarge && !isInModal && (
        <TooltipWrapper tooltipId={DEFAULT_TOOLTIP} content="Pop-out logs">
          <IconButton
            icon={OpenInFullComp}
            noBorder
            disabled={!logs}
            aria-label={AriaLabels.LogsViewer.EnlargeLogsButton}
            onClick={onEnlarge}
          />
        </TooltipWrapper>
      )}
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default LogsActions;
