import { useMemo } from "react";

import { MetricName, MetricType } from "../../../../Metrics/types";
import { useIsMetricsDrawerSupported } from "../../../../Metrics/metricsHooks";
import {
  MetricsApiGetWorkloadContainersMetricsRequest,
  MetricsApiGetWorkloadMemoryMetricsRequest,
} from "../../../../../generated/metricsApi";
import { useWorkloadMetricsTabContext } from "../context/useWorkloadMetricsTabContext";
import { useGetLineList } from "../../../../Metrics/hooks/workload/workloadMetricsTabHooks";
import { useGetWorkloadMetrics } from "../../../../../shared/hooks/metrics-api/client/useGetWorkloadMetrics";
import { useGetWorkloadContainersMetrics } from "../../../../../shared/hooks/metrics-api/client/useGetWorkloadContainersMetrics";

export const useLinesList = (): MetricName[] => {
  const { selectedAggregationMetric } = useWorkloadMetricsTabContext();

  return useGetLineList(selectedAggregationMetric);
};

type HasValidDataParams = {
  clusterName: string;
  resourceName: string;
};

export const useHasValidData = ({
  clusterName,
  resourceName,
}: HasValidDataParams) => {
  const isMetricsSupported = useIsMetricsDrawerSupported(clusterName);
  return useMemo(
    () => clusterName && resourceName && isMetricsSupported,
    [clusterName, isMetricsSupported, resourceName]
  );
};

type FetchDataParams = {
  requestParams: MetricsApiGetWorkloadMemoryMetricsRequest;
  type: MetricType;
};

export const useFetchWorkloadMetricsData = ({
  requestParams,
  type,
}: FetchDataParams) => {
  const hasValidData = useHasValidData({
    clusterName: requestParams.clusterName,
    resourceName: requestParams.komodorServiceName,
  });

  return useGetWorkloadMetrics({
    type,
    params: requestParams,
    enabled: !!hasValidData,
  });
};

export const useFetchWorkloadContainersMetricsData = (
  params: MetricsApiGetWorkloadContainersMetricsRequest
) => {
  const hasValidData = useHasValidData({
    clusterName: params.clusterName,
    resourceName: params.komodorServiceName,
  });

  return useGetWorkloadContainersMetrics({
    params,
    enabled: !!hasValidData,
  });
};
