import {
  ApiServerUrlClusterErrorResponse,
  RbacClusterStatus,
} from "@/generated/auth";
import { agentAndClusterStatusToClusterForRbacSync } from "@/components/Settings/General/utils/rbacClusterStatusSync";
import { AgentType } from "@/shared/hooks/useAgents";

export type RbacClusterStatusComputedData = Omit<
  RbacClusterStatus,
  | "accountId"
  | "lastUpdatedKomodorRbac"
  | "lastUpdatedCluster"
  | "lastSyncAttemptedAt"
> & {
  id: string; // DataGrid requires a unique id for each row
  isSynced: boolean;
  lastUpdatedKomodorRbac: Date | null;
  lastUpdatedCluster: Date | null;
  lastSyncAttemptedAt: Date | null;

  isConnected: boolean;
  updateError?: string;
};

export const prepareRbacClusterStatusRowsForDataGrid: (
  activeAgents: AgentType[],
  data?: RbacClusterStatus[],
  clustersThatFailedToUpdate?: ApiServerUrlClusterErrorResponse[]
) => RbacClusterStatusComputedData[] = (
  activeAgents,
  data,
  clustersThatFailedToUpdate
) => {
  if (!data) {
    return [];
  }
  return data.map((clusterStatus) => {
    const agent = activeAgents.find(
      (activeAgent) => activeAgent.clusterName === clusterStatus.clusterName
    );
    const clusterForRbacSync = agentAndClusterStatusToClusterForRbacSync({
      agent,
      clusterStatus,
    });

    const updateError = clustersThatFailedToUpdate?.find(
      (cluster) => cluster.clusterName === clusterStatus.clusterName
    )?.error;

    return {
      ...clusterStatus,
      id: `${clusterStatus.accountId}-${clusterStatus.clusterName}`, // Database table "id" is a composite primary key
      isSynced: clusterForRbacSync.isSynced,
      lastUpdatedKomodorRbac: clusterStatus.lastUpdatedKomodorRbac
        ? new Date(clusterStatus.lastUpdatedKomodorRbac)
        : null,
      lastUpdatedCluster: clusterStatus.lastUpdatedCluster
        ? new Date(clusterStatus.lastUpdatedCluster)
        : null,
      lastSyncAttemptedAt: clusterStatus.lastSyncAttemptedAt
        ? new Date(clusterStatus.lastSyncAttemptedAt)
        : null,
      updateError,
      isConnected: !!agent,
    };
  });
};
