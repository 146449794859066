import { useCallback } from "react";

import { ReliabilityError } from "../ReliabilityTypes";
import { reliabilityErrors } from "../constants/reliabilityConstants";

import { notifyDDError } from "@/shared/hooks/exceptionManagement";
import { useSetSnackbarNotification } from "@/shared/store/snackbarNotificationStore/snackbarNotificationHooks";

export const useReportError = () => {
  const setNotification = useSetSnackbarNotification();

  return useCallback(
    (error: unknown, errorReportKey: ReliabilityError) => {
      const { action, message } = reliabilityErrors[errorReportKey];

      notifyDDError(error as Error, { action });
      setNotification({ message, severity: "error" });
    },
    [setNotification]
  );
};
