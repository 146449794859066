// General
export const ACCOUNT_PARAM_KEY = "accountId";
export const TIME_WINDOW_PARAM_KEY = "timeWindow";
export const TIMEFRAME_PARAM_KEY = "timeframe";

// Components
export const EVENT_PARAM_KEY = "eventId";
export const FILTERS_BAR_FILTERS_PARAM_KEY = "filters";
export const PROCESS_LIST_SORT_PARAM_KEY = "sortBy";
export const RELATED_DEPLOY_FILTER_PARAM_KEY = "filter";

// Explore services
export const EXPLORE_SERVICES_FILTER_TERM_PARAM_KEY = "textFilter";
export const EXPLORE_SERVICES_SORT_PARAM_KEY = "sortStrategy";

// Events view
export const EVENTS_VIEW_PREFIX = "events-view-";
export const EVENTS_VIEW_SHRINK_TIMELINE_PARAM_KEY = "shrinkTimeline";
export const EVENTS_VIEW_PODS_LABEL_PARAM_KEY = "podsLabel";
export const EVENTS_VIEW_CLUSTER_PARAM_KEY = "clusterName";

export const PODS_SELECTION_SEARCH_PARAM_KEY = "search";
export const PODS_SELECTION_SHOW_DELETED_PODS_PARAM_KEY = "showDeletedPods";

// Monitors
export const MONITORS_RULE_PARAM_KEY = "ruleId";
export const MONITORS_RUN_CHECK_PARAM_KEY = "check";
export const MONITORS_AVAILABILITY_RUN_POD_PARAM_KEY = "pod";
export const MONITORS_AVAILABILITY_RUN_CONTAINER_PARAM_KEY = "container";

// Inspection
export const INSPECTION_NAMESPACES_PARAM_KEY = "namespaces";
export const INSPECTION_STATUS_PARAM_KEY = "status";
export const INSPECTION_GROUP_PARAM_KEY = "group";
export const INSPECTION_LABELS_PARAM_KEY = "labels";
export const INSPECTION_MATCH_EXACT_LABELS_PARAM_KEY = "matchExactLabels";
export const INSPECTION_SEARCH_TERM_PARAM_KEY = "searchTerm";
export const INSPECTION_ATM_LIVE_DATA_PARAM_KEY = "isLiveData";
export const INSPECTION_SHOW_DELETED_PODS_PARAM_KEY = "showDeletedPods";
export const DELETED_PODS_PREFIX = "deleted-pods-";
export const INSPECTION_CR_NAME_PARAM_KEY = "inspectionCRName";
export const INSPECTION_CR_NAMESPACE_PARAM_KEY = "inspectionCRNamespace";
export const INSPECTION_POD_IP_PARAM_KEY = "ips";

// Resource view
export const RESOURCE_PREFIX = "resource-";
export const RESOURCE_DRAWER_POSTFIX = "-drawer";
export const RESOURCE_TAB_POSTFIX = "-tab";
export const EVENTS_TAB_PREFIX = "eventsTab-";
export const PODS_TAB_PREFIX = "podsTab-";
export const NODES_TAB_PREFIX = "nodesTab-";

export const SELECTED_TAB_PARAM_KEY = "selectedTab";
export const FILTERS_PARAM_KEY = "filter";
export const SEARCH_TERM_PARAM_KEY = "searchTerm";
export const STATUS_FILTER_TAB_PARAM_KEY = "statusFilter";
export const LABEL_FILTER_TAB_PARAM_KEY = "labelFilter";

export const EVENTS_TAB_TIMEFRAME = EVENTS_TAB_PREFIX + TIMEFRAME_PARAM_KEY;
export const EVENTS_TAB_TIME_WINDOW = EVENTS_TAB_PREFIX + TIME_WINDOW_PARAM_KEY;

export const SELECTED_CHART_PARAM_KEY = "chartId";

// Comparison
export const COMPARISON_COMPARATOR_PARAM_KEY = "comparatorId";
export const COMPARISON_COMPARAND_PARAM_KEY = "comparandId";

// Static prevention
export const STATIC_PREVENTION_PARAM_KEY = "staticPreventionId";

// Related resources
export const RELATED_RESOURCES_PODS_PARAM_KEY = "eventsPodIds";
export const RELATED_RESOURCES_HPAS_PARAM_KEY = "eventsHpaIds";
export const RELATED_RESOURCES_CONFIGMAPS_PARAM_KEY = "eventsConfigMapIds";
export const RELATED_RESOURCES_SERVICES_PARAM_KEY = "eventsServicesIds";
export const RELATED_RESOURCES_SECRETS_PARAM_KEY = "eventsSecretIds";
export const RELATED_RESOURCES_PVCS_PARAM_KEY = "eventsPvcIds";
export const RELATED_RESOURCES_INGRESSES_PARAM_KEY = "eventsIngressIds";

// Services View
export const EXPLORE_SERVICES_FILTERS_KEY = "exploreServicesQS";

// Jobs View
export const EXPLORE_JOBS_FILTERS_KEY = "exploreJobsQS";

// App Views
export const APP_VIEWS_PARAM_KEY = "appViewsQS";

// Cost Optimization
export const COST_OPTIMIZATION_PARAM_KEY = "costOptimizationQS";

// Investigation mode
export const INVESTIGATION_MODE_PREFIX = "investigation-mode-";
export const SNAPSHOT_KEY = "snapshot";

// Triage
export const INVESTIGATED = "investigated";

// Argo Workflows
export const ARGO_WORKFLOW_CLUSTER_PARAM_KEY = "argoCluster";
export const ARGO_WORKFLOW_STATUS_PARAM_KEY = "argoStatus";
export const ARGO_WORKFLOW_TYPE_PARAM_KEY = "argoType";
export const ARGO_WORKFLOW_SEARCH_TERM_PARAM_KEY = "argoSearchTerm";
export const ARGO_WORKFLOWS_EVENTS_TAB_VIEW_TOGGLE = "viewToggle";

export const WORKFLOW_RUN_PARAM_KEY = "workflowRunId";

// Metrics
export const METRICS_AGGREGATION_TYPE_PARAM_KEY = "aggregationType";

// Expert Mode
export const EXPERT_OVERVIEW_PARAM_KEY = "overview";

// Reliability
export const RELIABILITY_PARAM_KEY = "reliabilityQS";

//Drawers Stack
export const DRAWER_STACK_STATE_URL_PARAM = "drawers";

// ClustersView
export const INVENTORY_SECTION_PREFIX = "inventory-section-";
export const CLUSTERS_FILTERS_PARAM_KEY = "filters";

// K8s Add-Ons
export const K8S_ADD_ONS_FILTERS_PARAM_KEY = "k8sAddOns";
export const K8S_ADD_ONS_FILTERS_QS_KEY = "k8sAddOnsQS";
export const K8S_ADD_ONS_IS_EXTERNAL_DNS_REFERRER =
  "isExternalDNSAddonReferrer";

// Klaudia
export const KLAUDIA_SESSION_ID = "klaudiaSessionId";

// Rbac
export const RBAC_ROLE = "rbacRole";
export const RBAC_POLICY = "rbacPolicy";
export const RBAC_ACTION = "rbacAction";
export const VIEWED_USER = "viewedUser";
