import Resource from "./resources";

import { useCanIDoInScope } from "@/shared/hooks/auth-service/client/auth/useCanIDoInScope";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

interface overrideNamespaceInterface {
  Resources: string[];
}
export const useHasPermissionToViewResource = (resource: Resource) => {
  const { cluster, namespace, kind } = resource ?? {};
  const { isKomodorAdmin } = useUserMetadata();
  const { hasPermissionToViewResource, overrideNamespaceScopeForResource } =
    useOverridableFlags();
  const overrideValue =
    overrideNamespaceScopeForResource as overrideNamespaceInterface;

  const { data, isLoading } = useCanIDoInScope(
    {
      action: "view:all",
      scope: [{ cluster, ...(namespace && { namespaces: [namespace] }) }],
    },
    { enabled: !!cluster, staleTime: 0 }
  );

  if (!hasPermissionToViewResource) {
    return {
      canViewResource: true,
      isLoadingCanViewResource: false,
    };
  }
  if (overrideValue?.Resources.includes(kind)) {
    return {
      canViewResource: true,
      isLoadingCanViewResource: false,
    };
  }
  return {
    canViewResource: data?.canDo || isKomodorAdmin,
    isLoadingCanViewResource: isLoading,
  };
};
