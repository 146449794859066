import { useEffect, useRef } from "react";
import { isEqual } from "lodash";

import { useWebSocketContext } from "../context/WebSocketsProvider";

import { useCloseSocketSessionById } from "./useCloseSocketById";

type Props = {
  identifier: string;
  dependencies: unknown;
  connectionCleanupCb: () => void;
};

export const useCloseSessionOnDependenciesChange = ({
  identifier,
  dependencies,
  connectionCleanupCb,
}: Props) => {
  const currentDependencies = useRef<unknown | null>(null);
  const sockets = useWebSocketContext();
  const closeSocketSession = useCloseSocketSessionById();

  useEffect(() => {
    // This condition ensures the hook reinitiates the socket connection when dependencies change and the socket is active.
    // The assumption is that closing the current connection will trigger another hook to establish a new connection.
    // This creates a seamless reinitialization process: the current connection closes, and the other hook opens a new one.
    if (!isEqual(currentDependencies.current, dependencies)) {
      if (
        sockets.getSocket(identifier) &&
        currentDependencies.current !== null
      ) {
        closeSocketSession(identifier);
        sockets.removeSocket(identifier);
        connectionCleanupCb();
      }
      currentDependencies.current = dependencies;
    }
  }, [
    dependencies,
    sockets,
    identifier,
    connectionCleanupCb,
    closeSocketSession,
  ]);
};
