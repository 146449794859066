import {
  CheckType,
  ImpactGroupIdentifier,
} from "../../../../../../../generated/reliabilityApi";
import { GroupByOptions } from "../../../../../ReliabilityTypes";
import { impactGroupIdentifierToText } from "../../../../../constants/impactGroupIdentifierToText";

import { checkTypeToLabel } from "@/components/reliability/constants/reliabilityConstants";

type CardTexts = {
  title: string;
};

export const getCardTexts = (
  id: string,
  groupBy: GroupByOptions
): CardTexts => {
  switch (groupBy) {
    case "Cluster":
      return { title: id };
    case "CheckType":
      return { title: checkTypeToLabel[id as CheckType] };
    case "Impact":
    case "RealTimeIssues":
      return impactGroupIdentifierToText[id as ImpactGroupIdentifier];
  }
};
