import { PodLogsStore } from "./types";

export const selectPodLogs = (state: PodLogsStore) => state.logs;
export const selectPodLogsDirection = (state: PodLogsStore) => state.direction;
export const selectLogsParams = (state: PodLogsStore) => state.logsParams;
export const selectIsLogsLoading = (state: PodLogsStore) => state.isLoading;

export const resetPodLogs = (state: PodLogsStore) => state.resetLogs;
export const addPodLogs = (state: PodLogsStore) => state.addLogs;
export const setPodLogsDirection = (state: PodLogsStore) => state.setDirection;
export const setPodLogsParams = (state: PodLogsStore) => state.setPodLogsParams;
export const setIsLogsLoading = (state: PodLogsStore) => state.setIsLoading;
