import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { AlertColor } from "@mui/material/Alert/Alert";
import { SnackbarProps } from "@mui/material/Snackbar/Snackbar";

export type SnackbarNotification = {
  message: string;
  severity?: AlertColor;
  snackbarProps?: SnackbarProps;
};

export type SnackbarNotificationStoreState = {
  snackbarNotifications: SnackbarNotification[];
};

type SnackbarNotificationStoreActions = {
  setSnackbarNotification: (notification: SnackbarNotification) => void;
  removeSnackbarNotification: (notification: SnackbarNotification) => void;
};

export type SnackbarNotificationStore = SnackbarNotificationStoreState &
  SnackbarNotificationStoreActions;

const initialState: SnackbarNotificationStoreState = {
  snackbarNotifications: [],
};

export const useSnackbarNotificationStore = create(
  immer<SnackbarNotificationStore>((set) => ({
    ...initialState,
    setSnackbarNotification: (notification: SnackbarNotification) => {
      set((state) => {
        return {
          snackbarNotifications: [...state.snackbarNotifications, notification],
        };
      });
    },
    removeSnackbarNotification: (notification: SnackbarNotification) =>
      set((state) => ({
        snackbarNotifications: state.snackbarNotifications.filter(
          (n) => n.message !== notification.message
        ),
      })),
  }))
);
