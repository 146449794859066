import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import { isEqual } from "lodash";
import {
  DisabledItemsTitle,
  MenuListContainer,
  StickyContainer,
} from "components/komodor-ui/Select/AdvancedMultiSelect/advancedMultiSelectStyles";
import { ItemSelectionMenu } from "components/komodor-ui/Select/AdvancedMultiSelect/components/ItemSelectionMenu";
import {
  useGetDisabledItems,
  useGetEnabledItems,
  useGetOptionsBySearchTerm,
} from "components/komodor-ui/Select/AdvancedMultiSelect/hooks/advancedMultiSelectHooks";
import { useControlAutoFocus } from "components/komodor-ui/Select/AdvancedMultiSelect/hooks/useControlAutoFocus";
import { useIsMenuHiddenByTopContent } from "components/komodor-ui/Select/AdvancedMultiSelect/hooks/useIsMenuHiddenByTopContent";
import { useOnItemSelect } from "components/komodor-ui/Select/AdvancedMultiSelect/hooks/useOnItemSelect";
import { SearchBar } from "components/komodor-ui/Select/shared/components/SearchBar/SearchBar";
import { SelectPopover } from "components/komodor-ui/Select/shared/components/SelectPopOver";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "components/komodor-ui/Select/shared/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { gray } from "themes/colors";
import { MultiSelectPopoverProps } from "components/komodor-ui/Select/AdvancedMultiSelect/multiSelectTypes";
import { selectAriaLabels } from "components/komodor-ui/Select/shared/ariaLabels";
import { popoverTransitionDuration } from "index";
import { VirtualizedMultiSelectMenuList } from "../AdvancedMultiSelect/VirtualizedMultiSelectMenuList";
import { paperClasses } from "@mui/material";
import styled from "styled-components";

const MENU_LIST_PADDING = "16px";
const SEARCH_BAR_HEIGHT = "42px";
const SEARCH_WITH_SELECT_ALL_HEIGHT = "106.13px";

const Scroller = styled.div`
  height: 100%;
`;

export const MultiSelectPopover = <T extends MuiSelectionOptionValue>({
  anchorEl,
  value,
  options,
  onChange,
  onClose,
  renderDisabledOptions,
  texts,
  classNames,
  selectPopoverProps,
  enableSelectAll = true,
  selectionLimit,
  enableSearch = true,
  searchHeight = SEARCH_BAR_HEIGHT,
  searchWithSelectAllHeight = SEARCH_WITH_SELECT_ALL_HEIGHT,
  menuListPadding = MENU_LIST_PADDING,
  enableFreeSolo = false,
  customOptionElement,
  enableVirtualization = false,
  virtualizerProps,
}: MultiSelectPopoverProps<T>) => {
  const [localValue, setLocalValue] = useState(value ?? []);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [freeSoloOptions, setFreeSoloOptions] = useState<
    MuiSelectionOption<T>[]
  >([]);
  const menuListRef = useRef<HTMLUListElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    value && setLocalValue(value);
  }, [value]);

  const { shouldAutoFocusFirstItem, setShouldAutoFocusFirstItem } =
    useControlAutoFocus(open);

  const { isMenuHiddenByTopContent, handleScroll } =
    useIsMenuHiddenByTopContent(menuListRef);

  const { enabledOptions, disabledOptions } = useGetOptionsBySearchTerm({
    options: [...options, ...freeSoloOptions],
    searchTerm,
    freeSolo: enableFreeSolo,
  });

  const onChangeLocalValue = useCallback(
    (selectedOptions: MuiSelectionOption<T>[]) => {
      setLocalValue(selectedOptions);
      onChange?.(selectedOptions);
    },
    [onChange]
  );

  useEffect(() => {
    if (open) return;
    const selectedFreeSoloOptions = freeSoloOptions.filter((option) =>
      localValue.find((opt) => opt.value === option.value)
    );
    !isEqual(freeSoloOptions, selectedFreeSoloOptions) &&
      setFreeSoloOptions(selectedFreeSoloOptions);
  }, [freeSoloOptions, open, localValue]);

  const handleClose = () => {
    setTimeout(() => {
      setSearchTerm("");
    }, popoverTransitionDuration);
    onClose();
  };

  const onItemSelect = useOnItemSelect({
    options,
    freeSolo: enableFreeSolo,
    freeSoloOptions,
    selectedOptions: localValue,
    onSetSelectedOptions: onChangeLocalValue,
    setFreeSoloOptions,
  });

  const onSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const onSelectAll = (selected: boolean) => {
    if (selected) {
      if (selectionLimit) {
        onChangeLocalValue(enabledOptions.slice(0, selectionLimit));
      } else {
        onChangeLocalValue(enabledOptions);
      }
    } else {
      onChangeLocalValue([]);
    }
  };

  const enabledItems = useGetEnabledItems({
    enabledOptions: enabledOptions,
    selectedOptions: localValue,
    onItemSelect,
    customOptionElement: customOptionElement,
  });

  const disabledItems = useGetDisabledItems({
    disabledOptions,
    pause: !!renderDisabledOptions,
    customOptionElement,
    selectedOptions: localValue,
  });

  const virtualizedMaxHeight = selectPopoverProps.maxHeight
    ? `calc(${selectPopoverProps.maxHeight} - ${menuListPadding}${
        enableSearch && !enableSelectAll
          ? ` - ${searchHeight}`
          : enableSearch && enableSelectAll
          ? ` - ${searchWithSelectAllHeight}`
          : ""
      })`
    : undefined;

  return (
    <SelectPopover
      anchorEl={anchorEl}
      onClose={handleClose}
      onScroll={handleScroll}
      {...selectPopoverProps}
      sx={{
        ...(selectPopoverProps.sx ?? {}),
        ...{
          [`& .${paperClasses.root}`]: {
            height: selectPopoverProps.maxHeight,
          },
        },
      }}
    >
      <Scroller onScroll={handleScroll}>
        <StickyContainer
          $showShadow={isMenuHiddenByTopContent}
          className={classNames?.stickyContainer}
        >
          {enableSearch && (
            <SearchBar
              searchValue={searchTerm}
              onSearch={onSearchTermChange}
              className={classNames?.searchBar}
              searchPlaceholder={texts?.searchPlaceholder}
              onFocus={() => setShouldAutoFocusFirstItem(false)}
            />
          )}
          {enableSelectAll && (
            <ItemSelectionMenu
              className={classNames?.itemSelectionMenu}
              totalSelected={localValue.length}
              totalOptions={enabledOptions.length}
              onSelectAll={onSelectAll}
              texts={texts}
            />
          )}
        </StickyContainer>
        <MenuListContainer
          enableVirtualization={enableVirtualization}
          className={classNames?.menuListContainer}
        >
          {enableVirtualization ? (
            <VirtualizedMultiSelectMenuList
              options={enabledOptions}
              selectedOptions={localValue}
              onItemSelect={onItemSelect}
              className={classNames?.enabledItems}
              classes={classNames}
              ariaLabel={selectAriaLabels.enabledOptions}
              customOptionElement={customOptionElement}
              autoFocusItem={shouldAutoFocusFirstItem}
              maxHeight={virtualizedMaxHeight}
              virtualizerProps={virtualizerProps}
            />
          ) : (
            <MenuList
              className={classNames?.enabledItems}
              aria-label={selectAriaLabels.enabledOptions}
              ref={menuListRef}
              autoFocusItem={shouldAutoFocusFirstItem}
            >
              {enabledItems}
            </MenuList>
          )}

          {!!disabledOptions.length && (
            <>
              <Divider />
              {texts?.hiddenItemsTitle ? (
                <DisabledItemsTitle variant={"h6"} color={gray[600]}>
                  {texts?.hiddenItemsTitle}
                </DisabledItemsTitle>
              ) : null}
              {enableVirtualization && !renderDisabledOptions ? (
                <VirtualizedMultiSelectMenuList
                  options={disabledOptions}
                  selectedOptions={localValue}
                  onItemSelect={onItemSelect}
                  className={classNames?.disabledItems}
                  classes={classNames}
                  ariaLabel={selectAriaLabels.disabledOptions}
                  customOptionElement={customOptionElement}
                  maxHeight={virtualizedMaxHeight}
                  virtualizerProps={virtualizerProps}
                />
              ) : (
                <MenuList
                  className={classNames?.disabledItems}
                  aria-label={selectAriaLabels.disabledOptions}
                >
                  {renderDisabledOptions
                    ? renderDisabledOptions(disabledOptions)
                    : disabledItems}
                </MenuList>
              )}
            </>
          )}
        </MenuListContainer>
      </Scroller>
    </SelectPopover>
  );
};
