import { SnackbarNotificationStore } from "@/shared/store/snackbarNotificationStore/snackbarNotificationStore";

// getters
export const selectSnackbarNotifications = (state: SnackbarNotificationStore) =>
  state.snackbarNotifications;

// setters
export const selectSetSnackbarNotification = (
  state: SnackbarNotificationStore
) => state.setSnackbarNotification;
export const selectRemoveSnackbarNotification = (
  state: SnackbarNotificationStore
) => state.removeSnackbarNotification;
