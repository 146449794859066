import { GroupData, ReliabilityStore } from "../ReliabilityTypes";

import { getInitialPaginationModel } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/violationTableConstants";

export const initialState: ReliabilityStore = {
  initialTime: Date.now(),
  hasSubscribedToAppViewChanges: false,
  violationsState: {
    groups: {
      Impact: {
        groupData: {},
      },
      Cluster: { groupData: {} },
      None: { groupData: {} },
      CheckType: { groupData: {} },
      RealTimeIssues: { groupData: {} },
    },
    ungroupedTotalViolationsCount: undefined,
  },
  addEditIgnoreRuleDialogState: {
    isOpen: false,
    checkType: undefined,
    existingIgnoreRule: undefined,
  },
  setTopSectionIsShown: () => undefined,
  setGroupExpandedState: () => undefined,
  setGroupState: () => undefined,
  setUngroupedTotalViolationsCount: () => undefined,
  setAddEditIgnoreRuleDialogState: () => undefined,
  setHasSubscribedToAppViewChanges: () => undefined,
};

export const initialGroupDataState: GroupData = {
  isExpanded: false,
  statuses: [],
  checkTypes: [],
  searchTerm: "",
  paginationModel: getInitialPaginationModel("None"),
};
