import { GridRowId } from "@mui/x-data-grid-pro";

import { getRandRange } from "@/shared/utils/getRandRange";

export type GetColumnRandWidthParams<T> = {
  id: GridRowId;
  columnName: keyof T;
  randRangeColumns: Partial<Record<keyof T, number[]>>;
};

export const getColumnRandWidth = <T>({
  id,
  columnName,
  randRangeColumns,
}: GetColumnRandWidthParams<T>) => {
  const idAsNumber = parseInt(id.toString());
  const column = randRangeColumns[columnName];
  if (!column?.length) return 0;
  return column[idAsNumber % column.length];
};

export const generateRandRangeList = (min: number, max: number, length = 3) => {
  return Array.from({ length }, () => getRandRange(min, max));
};
