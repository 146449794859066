/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from "react";
import { theme, muiColors, palette } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";

import InfoIcon from "../../../monitorsView/assets/infoOutlined.svg?react";
import {
  StepContainer,
  StepContent,
  StepFooter,
  StepHeader,
} from "../../styles";
import { useSnapshotTabs } from "../common/useSnapshotTabs";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { issueSelector } from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { LogsViewer } from "../../../common/LogsViewer/LogsViewer";
import { useQueryRefreshWithVariables } from "../../../../shared/hooks/useQueryWithVariables";
import { useFetchPodLogsQuery } from "../../../../generated/graphql";
import { useNextStep } from "../../StepsLogic";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { useScrollTracking } from "../common/useScrollTracking";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import {
  MONITORS_AVAILABILITY_RUN_CONTAINER_PARAM_KEY,
  MONITORS_AVAILABILITY_RUN_POD_PARAM_KEY,
} from "../../../../shared/config/urlSearchParamsKeys";
import { useDDRumViewAndTimingListener } from "../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { usePodLogsStore } from "../../../../shared/hooks/podLogs/store/podLogsStore";
import { resetPodLogs } from "../../../../shared/hooks/podLogs/store/podLogsStoreSelectors";
import { dataDogViewNames } from "../../../../shared/constants/datadog";

import { LogsSnapshotOutput, containerLogs, podLogs } from "./types";
import {
  ContainerMenuItem,
  ContainersSelect,
  PodMenuItem,
  PodsSelect,
} from "./LogsMultiSelect";

import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { LogsType } from "@/components/common/LogsViewer/logsTypes";

const Details = styled.div`
  display: flex;
  column-gap: 1.5rem;
  align-items: center;
`;

const DetailItem = styled.div`
  display: flex;
  column-gap: 0.25rem;
  align-items: baseline;
`;
const StyledDivider = styled(Divider)`
  margin-bottom: 0;
  && {
    &.MuiDivider-root {
      margin-bottom: 0;
    }
  }
`;
const StyledWarningMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background-color: ${muiColors.gray[100]};
`;

export interface AutoAnalyzeContainerData {
  podName?: string;
  containerName?: string;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useFetchLogsForSnapshot = (
  logsSnapshot: LogsSnapshotOutput | undefined
) => {
  const taskVariables = useMemo(
    () =>
      logsSnapshot?.logTaskId ? { taskId: logsSnapshot?.logTaskId } : null,
    [logsSnapshot?.logTaskId]
  );

  const [data] = useQueryRefreshWithVariables(
    useFetchPodLogsQuery,
    taskVariables
  );

  return data?.pod_logs ?? [];
};

export const Logs: React.FC = () => {
  const { availabilityIssueLogsDisplayEvent } = useOverridableFlags();
  const [selectedPodName, setSelectedPodName] = useStateInSearchParams(
    MONITORS_AVAILABILITY_RUN_POD_PARAM_KEY
  );
  const [selectedContainer, setSelectedContainer] = useState<
    containerLogs | undefined
  >(undefined);
  const resetLogsInStore = usePodLogsStore(resetPodLogs);

  const [autoAnalyzeContainer, setAutoAnalyzeContainer] =
    useState<AutoAnalyzeContainerData>();

  const [selectedContainerName, setSelectedContainerName] =
    useStateInSearchParams(MONITORS_AVAILABILITY_RUN_CONTAINER_PARAM_KEY);

  const issue = useInvestigationModeStore(issueSelector);
  const { nextStep, goToNextStep } = useNextStep();

  const { firstSnapshot, lastSnapshot } = issue?.results.logs.output ?? {};
  const [selectedSnapshot, SnapshotTabs] = useSnapshotTabs<LogsSnapshotOutput>(
    firstSnapshot,
    lastSnapshot
  );

  const podsData = useMemo(() => {
    if (!selectedSnapshot) {
      return undefined;
    }
    if (
      selectedSnapshot.isLogsExist &&
      (!selectedSnapshot.pods || selectedSnapshot.pods.length === 0)
    ) {
      // Backward compatibility for existing issues with single container logs
      return [
        {
          podName: selectedSnapshot.podName,
          containers: [
            {
              containerName: selectedSnapshot.containerName,
              isLogsExist: selectedSnapshot.isLogsExist,
              logTaskId: selectedSnapshot.logTaskId,
            },
          ],
        } as unknown as podLogs,
      ];
    } else {
      return selectedSnapshot.pods;
    }
  }, [selectedSnapshot]);

  const selectedPod: podLogs | undefined = useMemo(() => {
    return (
      podsData?.find((p) => p.podName === selectedPodName) ??
      podsData?.[0] ??
      undefined
    );
  }, [podsData, selectedPodName]);

  useEffect(() => {
    if (!selectedPodName && podsData?.[0]) {
      const filtered = podsData?.find((p) =>
        p.containers.some((c) => c.isLogsExist)
      );
      setSelectedPodName(filtered?.podName ?? null, true);
      setAutoAnalyzeContainer({
        podName: filtered?.podName ?? "",
      });
    }
    if (!selectedContainerName && selectedPod) {
      const containerName =
        selectedPod?.containers.find((c) => c.isLogsExist && !c.isHealthy)
          ?.containerName ??
        selectedPod?.containers.find((c) => c.isLogsExist)?.containerName ??
        null;
      setSelectedContainerName(containerName, true);
      setAutoAnalyzeContainer((prevState) => ({
        ...prevState,
        containerName: containerName ?? "",
      }));
    }
  }, [
    issue?.cluster,
    issue?.namespace,
    podsData,
    selectedContainer,
    selectedContainerName,
    selectedPod,
    selectedPodName,
    setSelectedContainerName,
    setSelectedPodName,
  ]);

  useEffect(() => {
    if (selectedPod) {
      setSelectedContainer(
        selectedPod.containers.find(
          (c) => c.containerName === selectedContainerName && c.isLogsExist
        ) ??
          selectedPod.containers.find((c) => c.isLogsExist && !c.isHealthy) ??
          selectedPod.containers.find((c) => c.isLogsExist) ??
          selectedPod.containers?.[0] ??
          null
      );
    }
  }, [selectedPod, setSelectedContainer, selectedContainerName]);

  const taskVariables = useMemo(() => {
    return selectedContainer?.logTaskId
      ? { taskId: selectedContainer.logTaskId }
      : null;
  }, [selectedContainer?.logTaskId]);

  const [data] = useQueryRefreshWithVariables(
    useFetchPodLogsQuery,
    taskVariables
  );
  const elementRef = useScrollTracking<HTMLDivElement>();

  useDDRumViewAndTimingListener({
    viewName: dataDogViewNames.investigationModeLogs,
    isResourceFetching: !data,
    addTimingParams: {
      enable: !!issue && !!data,
    },
  });

  return (
    <StepContainer>
      <StepHeader>
        <Typography variant="h2" color={theme.foreground.fgPrimary}>
          Check the container logs
        </Typography>
        <Typography variant={"subtitle1"} color={muiColors.gray[600]}>
          Verify the container's activity leading up to the issue
        </Typography>
      </StepHeader>
      <StepContent ref={elementRef}>
        {SnapshotTabs}
        {selectedSnapshot && (
          <>
            <Details>
              <DetailItem>
                <Typography variant="overline" color={muiColors.gray[500]}>
                  Pod:
                </Typography>
                {podsData && podsData.length > 1 ? (
                  <PodsSelect
                    selectedValue={selectedPodName}
                    setValue={(value) => {
                      setAutoAnalyzeContainer(undefined);
                      setSelectedPodName(value, true);
                    }}
                  >
                    {podsData.map((option) => (
                      <MenuItem key={option.podName} value={option.podName}>
                        <PodMenuItem
                          podName={option.podName}
                          phase={option.phase}
                          unhealthyContainersNumber={
                            option.containers.filter((x) => {
                              return !x.isHealthy;
                            }).length
                          }
                        ></PodMenuItem>
                      </MenuItem>
                    ))}
                    <StyledDivider />
                    <StyledWarningMessage>
                      <InfoIcon width="14px" height="14px"></InfoIcon>
                      <Typography variant="body2">
                        Healthy pods’ logs are not saved, and are therefore not
                        shown in this list.
                      </Typography>
                    </StyledWarningMessage>
                  </PodsSelect>
                ) : (
                  <Typography variant="body2">{selectedPodName}</Typography>
                )}
              </DetailItem>
              <DetailItem>
                <Typography variant="overline" color={muiColors.gray[500]}>
                  Container:
                </Typography>
                {selectedContainer &&
                selectedPod &&
                selectedPod.containers.length > 1 ? (
                  <ContainersSelect
                    containers={selectedPod.containers}
                    isHealthy={selectedContainer?.isHealthy}
                    selectedValue={selectedContainer?.containerName}
                    setValue={(value) => {
                      setAutoAnalyzeContainer(undefined);
                      setSelectedContainerName(value, true);
                      resetLogsInStore();
                    }}
                  >
                    {selectedPod.containers.map((option) => (
                      <MenuItem
                        key={option.containerName}
                        value={option.containerName}
                        disabled={!option.isLogsExist}
                      >
                        <ContainerMenuItem
                          containerName={option.containerName}
                          isHealthy={option.isHealthy}
                        />
                      </MenuItem>
                    ))}
                  </ContainersSelect>
                ) : (
                  <Typography variant="body2">
                    {selectedContainer?.containerName}
                  </Typography>
                )}
              </DetailItem>
            </Details>
            <LogsViewer
              podLogsRes={data?.pod_logs ?? []}
              loading={!data?.pod_logs}
              podName={selectedPod?.podName ?? ""}
              containerName={selectedContainer?.containerName ?? ""}
              toolbarTopOffset="-1.5rem"
              toolbarBgColor={palette.gray[50]}
              logsType={LogsType.Issue}
              clusterName={issue?.cluster ?? ""}
              namespace={issue?.namespace ?? ""}
              events={
                availabilityIssueLogsDisplayEvent && issue?.eventTime
                  ? [
                      {
                        eventTime: issue?.eventTime,
                        eventMessage: "Availability issue started here",
                      },
                    ]
                  : []
              }
              runAutoAnalyze={
                autoAnalyzeContainer?.podName === selectedPodName &&
                autoAnalyzeContainer?.containerName === selectedContainerName
              }
              showEnlargeButton={false}
            />
          </>
        )}
      </StepContent>
      <StepFooter>
        {nextStep && (
          <Button
            variant="outlined"
            size="large"
            onClick={() => goToNextStep()}
            aria-label={AriaLabels.InvestigationMode.LogsStep.SkipStepButton}
          >
            Skip for now
          </Button>
        )}
      </StepFooter>
    </StepContainer>
  );
};
