import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  K8S_ADDONS_CERT_MANAGER,
  K8S_ADDONS_EXTERNAL_DNS,
  K8S_ADDONS_NODE_AUTOSCALERS,
  K8S_ADDONS_WORKFLOWS,
} from "@/components/routes/routes";
import { CertManager } from "@/components/k8sAddons/addons/CertManager/CertManager";
import { Workflows } from "@/components/k8sAddons/addons/workflows/Workflows";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { ExternalDNS } from "@/components/k8sAddons/addons/externalDns/ExternalDNS";
import { ExternalDNSComingSoon } from "@/pages/coming-soon/external-dns/ExternalDNSComingSoon";
import { NodeAutoscalersComingSoon } from "@/pages/coming-soon/node-autoscalers/NodeAutoscalersComingSoon";
import { NodeAutoscalers } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalers";

export const K8sAddonsView: React.FC = () => {
  const {
    showWorkflows,
    comingSoonStaticPageNodeAutoscalers,
    comingSoonStaticPageExternalDns,
    showExternalDns,
    showK8SAddonAutoscaler,
  } = useOverridableFlags();

  const showAutoscalersDemo =
    !!comingSoonStaticPageNodeAutoscalers && !showK8SAddonAutoscaler;
  const showAutoscalersPage = !!showK8SAddonAutoscaler;
  return (
    <Routes>
      <Route path={`${K8S_ADDONS_CERT_MANAGER}/*`} element={<CertManager />} />
      {!!showWorkflows && (
        <Route path={`${K8S_ADDONS_WORKFLOWS}/*`} element={<Workflows />} />
      )}
      {(!!comingSoonStaticPageExternalDns || !!showExternalDns) && (
        <Route
          path={K8S_ADDONS_EXTERNAL_DNS}
          element={
            showExternalDns ? <ExternalDNS /> : <ExternalDNSComingSoon />
          }
        />
      )}
      {(showAutoscalersDemo || showAutoscalersPage) && (
        <Route
          path={`${K8S_ADDONS_NODE_AUTOSCALERS}/*`}
          element={
            showAutoscalersPage ? (
              <NodeAutoscalers />
            ) : (
              <NodeAutoscalersComingSoon />
            )
          }
        />
      )}
    </Routes>
  );
};
