import { Dictionary } from "lodash";

import { InspectionStore, ReportedAction } from "./inspectionStore";

import { ResourceActions } from "@/shared/constants/resourceActions";

//getters
export const selectedNamespacesSelector = (state: InspectionStore): string[] =>
  state.selectedNamespaces;
export const selectReportedActionsSelector = (
  state: InspectionStore
): Dictionary<ResourceActions> => state.reportedActions;

//setters
export const setNamespacesSelector = (
  state: InspectionStore
): ((namespaces: string[]) => void) => state.setSelectedNamespaces;
export const setReportedActionSelector = (
  state: InspectionStore
): ((reportedAction: ReportedAction) => void) => state.setReportedAction;
export const removeReportedActionSelector = (
  state: InspectionStore
): ((reportedActionId: string) => void) => state.removeReportedAction;
