//CU-86c14fqqq: this hook needs to be removed once we remove newLogsSettings FF

import { useMemo } from "react";

import { useLogsViewerStore } from "../store/logsViewerStore";
import {
  selectViewSettings,
  setViewSettings,
} from "../store/logsViewerStoreSelectors";
import {
  storedLineNumberEnabled,
  storedTextWrappingEnabled,
} from "../logsViewerGlobalSettings";

export const useLogsActionsOptions = () => {
  const setLogsDisplaySettings = useLogsViewerStore(setViewSettings);
  const viewSettings = useLogsViewerStore(selectViewSettings);

  const { showTimestamp, showLineNumber, wrapText } = viewSettings;

  return useMemo(
    () => [
      {
        name: "Timestamps",
        value: showTimestamp,
        setValue: (val: boolean) =>
          setLogsDisplaySettings({ ...viewSettings, showTimestamp: val }),
      },
      {
        name: "Line Numbers",
        value: showLineNumber,
        setValue: (val: boolean) => {
          setLogsDisplaySettings({ ...viewSettings, showLineNumber: val });
          storedLineNumberEnabled.set(val.toString());
        },
      },
      {
        name: "Text Wrap",
        value: wrapText,
        setValue: (val: boolean) => {
          setLogsDisplaySettings({ ...viewSettings, wrapText: val });
          storedTextWrappingEnabled.set(val.toString());
        },
      },
    ],
    [
      setLogsDisplaySettings,
      showLineNumber,
      showTimestamp,
      viewSettings,
      wrapText,
    ]
  );
};
