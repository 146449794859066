import { useCallback, useMemo } from "react";
import { ActionMetadataSuspendResumeCronjob, ActionTypes } from "komodor-types";

import {
  ResourceData,
  SuspendResumeActionType,
} from "@/components/Actions/modals/SuspendResumeCronjobModal/suspendResumeCronjobTypes";
import { useInspectionStore } from "@/shared/store/inspection/inspectionStore";
import { setReportedActionSelector } from "@/shared/store/inspection/inspectionSelectors";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { KubernetesCronJobResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCronJobResource";

export const useActionMetadata = (
  actionType: SuspendResumeActionType,
  resourceData: ResourceData
) => {
  return useMemo<ActionMetadataSuspendResumeCronjob>(() => {
    const action =
      actionType === "suspend"
        ? ActionTypes.SuspendCronJob
        : ActionTypes.ResumeCronJob;

    return {
      namespace: resourceData.namespace,
      cluster: resourceData.cluster,
      type: action,
      cronjobName: resourceData.name,
    };
  }, [
    actionType,
    resourceData.cluster,
    resourceData.name,
    resourceData.namespace,
  ]);
};

export const useReportAction = () => {
  const setReportedAction = useInspectionStore(setReportedActionSelector);

  return useCallback(
    (resourceData: ResourceData) => {
      const resourceId = buildKomodorUid({
        kind: KubernetesCronJobResource.Kind,
        clusterName: resourceData.cluster,
        namespace: resourceData.namespace,
        resourceName: resourceData.name,
      });
      setReportedAction({
        id: resourceId,
        action: "changeCronjobSuspendState",
      });
    },
    [setReportedAction]
  );
};
