import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";

import WorkflowDag from "@/components/ResourceView/resources/workflowDag";
import ResourceView from "@/components/ResourceView/ResourceView";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import { Addon, Entity, WorkflowDagFromJSON } from "@/generated/addonsApi";
import { parseKomodorWorkflowId } from "@/components/k8sAddons/addons/workflows/utils";
import { PageLoader } from "@/shared/components/PageLoader";
import { PathNotFound } from "@/components/404/PathNotFound";
import { WORKFLOW_ID_PARAM } from "@/components/routes/routes";

export const DagView: React.FC = () => {
  const navigate = useNavigate();
  const { komodorWorkflowId } = useParams<{
    [WORKFLOW_ID_PARAM]: string;
  }>();

  const { cluster, dagId } = parseKomodorWorkflowId(komodorWorkflowId ?? "");

  const { data, isFetching } = useGetAddonFullLiveState(
    {
      addon: Addon.Workflow,
      entity: Entity.WorkflowDag,
      uid: komodorWorkflowId ?? "",
      name: dagId,
      getEntityRequest: {
        clusterName: cluster,
      },
    },
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: WorkflowDagFromJSON(json?.data?.data),
    })
  );

  if (isFetching) {
    return <PageLoader />;
  }
  if (data?.data) {
    const workflowDag = new WorkflowDag(data.data);
    return (
      <ResourceView
        resource={workflowDag}
        leftHeaderActions={
          <LightTooltip title="Back to Workflows" placement="top">
            <IconButton
              size="small"
              onClick={() => navigate(workflowDag.getBackUrl())}
              sx={{ padding: 0, "&:hover": { backgroundColor: "transparent" } }}
            >
              <ArrowBack fontSize="medium" color="action" />
            </IconButton>
          </LightTooltip>
        }
      />
    );
  }
  return <PathNotFound />;
};
