import Close from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import React from "react";

export type ModalHeaderProps = {
  title: string;
  onClose: () => void;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onClose }) => {
  return (
    <DialogTitle
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 32px",
      }}
    >
      <Box display="flex" gap="8px" alignItems="center">
        <CompareArrowsIcon
          sx={{
            fontSize: "32px",
            color: "primary.main",
          }}
        />
        <Typography variant="h3">{title}</Typography>
      </Box>
      <IconButton onClick={onClose} sx={{ color: "action.active" }}>
        <Close />
      </IconButton>
    </DialogTitle>
  );
};
