import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import {
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";

import { MaxNumOfLinesValue, OnlyNumbersInput } from "../OnlyNumbersInput";
import { LabelCheckbox, LabelContainer } from "../inputStyles";

import { unitOptions } from "@/components/common/LogsViewer/logsViewConstants";
import { logsViewerAriaLabels } from "@/components/common/LogsViewer/logsViewerAriaLabels";

const { sinceToggle: sinceToggleAriaLabel } =
  logsViewerAriaLabels.dialog.checkbox;

const INPUT_WIDTH = 80;
const UNIT_SELECTOR_WIDTH = "150px";

const options = Object.values(unitOptions);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const InputsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

type Props = {
  sinceNumberValue: MaxNumOfLinesValue;
  setSinceNumberValue: (sinceNumberValue: MaxNumOfLinesValue) => void;
  unit: MuiSelectionOption<string> | undefined;
  setUnit: (unit: MuiSelectionOption<string> | undefined) => void;
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  error?: string;
};

export const SinceInputs: React.FC<Props> = ({
  sinceNumberValue,
  setSinceNumberValue,
  unit,
  setUnit,
  enabled,
  setEnabled,
  error,
}) => {
  return (
    <Container>
      <LabelContainer
        aria-label={sinceToggleAriaLabel}
        onClick={() => setEnabled((prev) => !prev)}
      >
        <LabelCheckbox size="small" checked={enabled} />
        <Typography variant="body2">Show logs from the last:</Typography>
      </LabelContainer>
      <InputsContainer>
        <OnlyNumbersInput
          value={sinceNumberValue}
          setValue={setSinceNumberValue}
          error={error}
          inputWidth={INPUT_WIDTH}
          disabled={!enabled}
        />
        <SingleSelect
          options={options}
          value={unit}
          onChange={setUnit}
          width={UNIT_SELECTOR_WIDTH}
          disabled={!enabled}
        />
      </InputsContainer>
    </Container>
  );
};
