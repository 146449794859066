import { useEffect, useState } from "react";
import { debounce } from "lodash";

import { useAuthorization } from "../hooks/roles/useAuthorization";
import {
  useWebSocketContext,
  WebSocketCloseCode,
} from "../context/WebSocketsProvider";
import { setCookie, getCookieValue } from "../utils/cookie/cookie";
import { CookieKeys } from "../utils/cookie/cookieKeys";
import { notifyDDError } from "../hooks/exceptionManagement";
import { ONE_DAY_IN_MINUTES } from "../constants/time";

import { CLOSE_DEBOUNCE_TIMEOUT_MS } from "./constants/debounceTimeout";
import { useHandleConnectionOnOpen } from "./handleWsConnectionIsOpen";
import { getWebSocketsHubURL } from "./utils";
import { SocketMessagePayload, SocketMessageType } from "./types";

import { sleep } from "@/shared/utils/sleep";

const shouldReconnectByCode = (code: number) => {
  return ![
    WebSocketCloseCode.ClientSideTermination,
    WebSocketCloseCode.AgentTermination,
  ].includes(code);
};

export interface Args {
  identifier: string;
  containerName?: string;
  agentId?: string | null;
  cluster: string;
  namespace: string;
  podName: string;
  handleIncomingMessage: (message: MessageEvent<SocketMessagePayload>) => void;
  refreshKeepAliveSession: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initData: Record<string, any>;
  initMessageType: SocketMessageType;
  shouldReconnect?: boolean;
  maxRetries?: number;
  onReconnect?: (id: string) => void;
  onReconnectFailure?: (id: string, error: Error) => void;
  onConnectionConfirmedByTheAgent?: () => void;
  onAck?: () => void;
}

interface Res {
  isSessionInitialized: boolean;
  isReconnecting: boolean;
}

const retriesMap = new Map<string, number>();
const MAX_RETRIES = 10;
const getRetriesCount = (id: string) => retriesMap.get(id) || 0;
const doesReachesMaxRetries = (id: string, maxRetries: number) => {
  return getRetriesCount(id) >= maxRetries;
};

// sleep with exponential backoff for retries
const waitWithExponentialBackoff = async (identifier: string) =>
  sleep(Math.pow(2, getRetriesCount(identifier)) * 1000);

export const useInitiateSocketSession = ({
  identifier,
  agentId,
  cluster,
  namespace,
  podName,
  handleIncomingMessage,
  refreshKeepAliveSession,
  containerName,
  initData,
  initMessageType,
  shouldReconnect = true,
  maxRetries = MAX_RETRIES,
  onReconnect,
  onReconnectFailure,
  onConnectionConfirmedByTheAgent,
  onAck,
}: Args): Res => {
  const sockets = useWebSocketContext();
  const authorization = useAuthorization();
  const [isSessionInitialized, setIsInitialized] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const handleConnectionOnOpen = useHandleConnectionOnOpen();

  useEffect(() => {
    if (
      !agentId ||
      !cluster ||
      !namespace ||
      !podName ||
      !identifier ||
      !containerName
    ) {
      return;
    }

    const connect = (): Promise<void> => {
      return new Promise((resolve, reject) => {
        const { sessionId } = sockets.getSocketContext(identifier) || {};

        if (!identifier) {
          resolve();
          return;
        }

        const hasSocket = sockets.getSocket(identifier);
        const hasCookie = getCookieValue(CookieKeys.JWT);
        if (authorization && (!hasSocket || !hasCookie)) {
          setCookie(CookieKeys.JWT, authorization, ONE_DAY_IN_MINUTES);
        }

        const newConnection = sockets.addSocket({
          id: identifier,
          accessToken: authorization,
          path: getWebSocketsHubURL({
            agentId: agentId || "",
            query: sessionId ? { sessionId } : undefined,
            authorization,
          }),
        });

        if (!newConnection) {
          return;
        }

        newConnection.addEventListener("message", handleIncomingMessage);

        handleConnectionOnOpen({
          sessionId,
          connection: newConnection,
          onInitialized: () => {
            setIsInitialized(true);
            retriesMap.set(identifier, 0);
          },
          resolve,
          reject,
          initData,
          messageType: initMessageType,
          onConnectionConfirmedByTheAgent,
          onAck,
        });

        newConnection.addEventListener(
          "close",
          debounce(async ({ code }: CloseEvent) => {
            if (doesReachesMaxRetries(identifier, maxRetries)) {
              return;
            } else {
              retriesMap.set(identifier, getRetriesCount(identifier) + 1);
            }

            if (shouldReconnectByCode(code) && shouldReconnect) {
              onReconnect?.(identifier);
              setIsReconnecting(true);

              await waitWithExponentialBackoff(identifier);
              // remove the socket, keep its context
              sockets.removeSocket(identifier, false);

              try {
                await connect();
                sockets.addContextToSocket(identifier, { sessionId });
                refreshKeepAliveSession();
              } catch (err) {
                // context cleanup, since we didn't do so once we deleted the socket
                sockets.removeSocketContext(identifier);
                onReconnectFailure?.(identifier, err as Error);
              } finally {
                setIsReconnecting(false);
              }
            }
          }, CLOSE_DEBOUNCE_TIMEOUT_MS)
        );
      });
    };

    connect().catch((err: Error) => {
      notifyDDError(err, {
        session_details: {
          agentId,
          cluster,
          namespace,
          podName,
          identifier,
          containerName,
        },
      });
    });
  }, [
    sockets,
    authorization,
    identifier,
    agentId,
    cluster,
    namespace,
    podName,
    handleIncomingMessage,
    setIsInitialized,
    refreshKeepAliveSession,
    containerName,
    initData,
    initMessageType,
    shouldReconnect,
    maxRetries,
    onReconnect,
    onReconnectFailure,
    handleConnectionOnOpen,
    onAck,
    onConnectionConfirmedByTheAgent,
  ]);

  return { isSessionInitialized, isReconnecting };
};
