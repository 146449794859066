import { useState } from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridPaginationModel,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION_MODEL,
  DEFAULT_SORT_DIRECTION,
  EMPTY_CELL,
} from "./constants";
import { DefaultCell } from "components/komodor-ui/DataGrid/cells/DefaultCell";

export interface DataGridProComponent {
  <R extends GridValidRowModel = any>(
    props: DataGridProProps<R> & React.RefAttributes<HTMLDivElement>
  ): React.JSX.Element;
  propTypes?: any;
}

export const DataGrid: DataGridProComponent = ({
  columns,
  initialState,
  ...props
}) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    DEFAULT_PAGINATION_MODEL
  );
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const defaultSortModel: GridSortModel = [
    { field: columns[0].field, sort: DEFAULT_SORT_DIRECTION },
  ];

  const customInitialState = {
    ...initialState,
    pagination: {
      ...initialState?.pagination,
      paginationModel:
        initialState?.pagination?.paginationModel ?? DEFAULT_PAGINATION_MODEL,
    },
    sorting: {
      ...initialState?.sorting,
      sortModel: initialState?.sorting?.sortModel ?? defaultSortModel,
    },
  };

  const customColumns = columns.map((c) => ({
    ...c,
    renderCell: (params) =>
      params.value ? (
        c.renderCell ? (
          c.renderCell(params)
        ) : (
          <DefaultCell {...params} />
        )
      ) : (
        EMPTY_CELL
      ),
  }));

  const enablePagination =
    props.paginationMode === "server"
      ? true
      : props.rows.length > Math.min(...DEFAULT_PAGE_SIZES);

  return (
    <DataGridPro
      columns={customColumns}
      initialState={customInitialState}
      disableColumnMenu
      hideFooter={!enablePagination}
      pagination={enablePagination}
      pageSizeOptions={DEFAULT_PAGE_SIZES}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => {
        setSortModel(newSortModel);
        if (enablePagination) {
          setPaginationModel((prev) => ({
            ...prev,
            page: DEFAULT_PAGINATION_MODEL.page,
          }));
        }
      }}
      aria-label="data-grid"
      {...props}
    />
  );
};
