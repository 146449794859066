import React from "react";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

export const CompareArrowsIconComponent: React.FC = () => {
  return (
    <CompareArrowsIcon
      sx={{ marginLeft: "-4px", height: "16px", width: "16px" }}
    />
  );
};
