import React, { ComponentProps } from "react";
import Skeleton from "@mui/material/Skeleton";

import {
  getColumnRandWidth,
  GetColumnRandWidthParams,
} from "@/components/k8sAddons/utils/tableUtils";
import { LOADING_COLUMN_SKELETON_HEIGHT } from "@/components/k8sAddons/constants/table";

type LoaderSkeletonProps<T> = GetColumnRandWidthParams<T> & {
  skeletonProps?: ComponentProps<typeof Skeleton>;
};

export function LoaderSkeleton<T>({
  columnName,
  randRangeColumns,
  id,
  skeletonProps,
}: LoaderSkeletonProps<T>) {
  return (
    <Skeleton
      variant={"rounded"}
      height={LOADING_COLUMN_SKELETON_HEIGHT}
      {...skeletonProps}
      width={getColumnRandWidth({
        id,
        columnName,
        randRangeColumns,
      })}
    />
  );
}
