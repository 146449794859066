/* eslint-disable max-lines */
import {
  ChipList,
  Compared,
  ComparisonTable,
  ComparisonTableProps,
  LabeledSwitch,
  LightTooltip,
  RowData,
} from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import { useEffect, useMemo, useState } from "react";

import { useServicesComparisonContext } from "../context/useServicesComparisonContext";
import {
  CELL_HEIGHT,
  CELL_MAX_WIDTH,
  CELL_WIDTH,
  MAX_COMPARED_SERVICES,
  MAX_CONTAINER_NAMES,
  SHOW_ONLY_DRIFT_KEY,
} from "../constants";

import { EmptyState } from "./EmptyState";
import { DiffModal } from "./DiffModal";
import { ContainersAttributesComparison } from "./ContainersAttributesComparison";
import { NameFieldCell } from "./styles";

import { useServiceDriftComparison } from "@/shared/hooks/workspaces-api/drift/useServiceDriftComparison";
import { useKomodorServices } from "@/shared/hooks/useKomodorServices";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import {
  DrawerStatePush,
  DrawerType,
} from "@/shared/store/drawersStackStore/types";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

const StyledChipList = styled(ChipList)`
  && {
    max-width: 100%;
    & .MuiChip-label {
      color: ${muiColors.gray[600]};
    }
  }
`;

type ComparisonService = {
  name: string;
  cluster: string;
  namespace: string;
  kind: string;
  desiredReplicas: number | undefined;
  labels: Record<string, string> | undefined;
  containersNames: string[] | undefined;
};

type CellRenderer =
  ComparisonTableProps<ComparisonService>["attributes"][number]["renderCell"];

const CellContainer = styled(Box).attrs({
  width: CELL_WIDTH,
  height: CELL_HEIGHT,
  maxHeight: CELL_HEIGHT,
  maxWidth: CELL_MAX_WIDTH,
})``;

const ContainerNames = styled.ul`
  margin-block: 0;
  padding-inline-start: 12px;
`;

const ContainerName = styled.li`
  &::marker {
    color: ${muiColors.gray[600]};
  }
`;

const cellRenderer =
  (field: keyof ComparisonService): CellRenderer =>
  ({ data, isBaseline }) => {
    if (field === "labels") {
      return (
        <CellContainer
          sx={{
            overflow: "hidden",
            boxSizing: "border-box",
            maxHeight: "72px",
          }}
        >
          <StyledChipList
            restrictedDimension="height"
            labels={Object.entries(
              !isBaseline
                ? data.data?.labels?.value ?? ({} as Record<string, string>)
                : data.labels ?? ({} as Record<string, string>)
            ).map(([key, value]) => `${key}=${value}`)}
          />
        </CellContainer>
      );
    }

    if (field === "containersNames") {
      const names = isBaseline
        ? data.containersNames
        : data.data?.containersNames?.value;
      return (
        <CellContainer sx={{ height: "100%" }}>
          <ContainerNames>
            {names
              // take the first MAX_CONTAINER_NAMES names, and if there's just 1 extra
              // display it, instead of a "+1"
              ?.slice(
                0,
                (names?.length ?? 0) === MAX_CONTAINER_NAMES + 1
                  ? MAX_CONTAINER_NAMES + 1
                  : MAX_CONTAINER_NAMES
              )
              .map((name) => (
                <ContainerName key={name}>
                  <Typography
                    title={name}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    variant="body2"
                    color={isBaseline ? muiColors.gray[600] : "text.primary"}
                  >
                    {name}
                  </Typography>
                </ContainerName>
              ))}
          </ContainerNames>
          {names != null && names.length > MAX_CONTAINER_NAMES + 1 ? (
            <LightTooltip
              placement="bottom-start"
              title={names.slice(MAX_CONTAINER_NAMES).map((name) => (
                <>
                  {name}
                  <br />
                </>
              ))}
            >
              <Typography
                variant="body2"
                color={isBaseline ? "text.secondary" : "text.primary"}
                sx={{ cursor: "pointer" }}
              >
                +{(names?.length ?? 0) - MAX_CONTAINER_NAMES}
              </Typography>
            </LightTooltip>
          ) : null}
        </CellContainer>
      );
    }

    if (field === "name") {
      return (
        <NameFieldCell>
          {isBaseline ? data[field] : data.data?.[field]?.value}
        </NameFieldCell>
      );
    }

    const centerText = field === "desiredReplicas";
    return (
      <Typography
        variant="body2"
        color={isBaseline ? muiColors.gray[600] : "text.primary"}
        textAlign={centerText ? "center" : "left"}
      >
        {isBaseline ? data[field] : data.data?.[field]?.value}
      </Typography>
    );
  };

const getDrawerData = ({
  data,
  isBaseline,
}: RowData<ComparisonService>): DrawerStatePush => {
  return {
    drawerType: DrawerType.ResourceDrawerByData,
    cluster: isBaseline ? data.cluster : data.data?.cluster?.value ?? "",
    namespace: isBaseline ? data.namespace : data.data?.namespace?.value ?? "",
    resourceType: isBaseline ? data.kind : data.data?.kind?.value ?? "",
    resourceName: isBaseline ? data.name : data.data?.name?.value ?? "",
  };
};

export const AttributesComparison: React.FC = () => {
  const {
    baselineServiceId,
    comparedServicesIds,
    setIsBaselineSelectionOpen,
    setIsComparedSelectionOpen,
  } = useServicesComparisonContext();
  const [showOnlyDrift, setShowOnlyDrift] =
    useStringifiedStateInSearchParams<boolean>(SHOW_ONLY_DRIFT_KEY);
  const services = useKomodorServices().servicesAsDictionary.services;

  const baselineParsedKomdorUid = useMemo(
    () =>
      baselineServiceId ? parseKomodorUid(baselineServiceId.value) : undefined,
    [baselineServiceId]
  );

  const { data, isLoading } = useServiceDriftComparison(
    {
      baselineServiceKomodorUid: baselineServiceId?.value ?? "",
      targetServicesKomodorUid:
        comparedServicesIds?.map((service) => service.value) ?? [],
    },
    {
      enabled: baselineServiceId !== null,
    }
  );

  const baseline: ComparisonService | undefined = data?.baselineService
    ? {
        name: data.baselineService.name,
        cluster: data.baselineService.cluster,
        namespace: data.baselineService.namespace,
        kind: baselineParsedKomdorUid?.kind ?? "",
        desiredReplicas: data.baselineService.desiredReplicas?.value,
        labels: data.baselineService.labels.value,
        containersNames: data.baselineService.containersNames.value,
      }
    : undefined;

  const [diffModal, setDiffModal] = useState<{
    title: string;
    comparedUid: string;
    oldObj: unknown;
    newObj: unknown;
  } | null>(null);

  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  const compared: Compared<ComparisonService>[] = useMemo(
    () =>
      comparedServicesIds
        ?.map((uid, i) => {
          const service = data?.targetServices?.[i];
          if (!service) {
            return {
              isLoading: true,
              isMissingData: false,
              data: {
                name: { value: uid.value, isDiff: false },
              },
            };
          }
          return {
            isLoading: false,
            isMissingData: false,
            data: {
              name: { value: service.name, isDiff: false },
              kind: {
                value: baselineParsedKomdorUid?.kind ?? "",
                isDiff: false,
              },

              // TODO - get isDiff value from backend for cluster and namespace
              cluster: { value: service.cluster, isDiff: false },
              namespace: { value: service.namespace, isDiff: false },
              desiredReplicas: service.desiredReplicas,
              labels: service.labels,
              containersNames: service.containersNames,
            },
          };
        })
        .filter((_, i) => {
          if (!showOnlyDrift) {
            return true;
          }
          return data?.targetServices?.[i]?.isDiff === true;
        }) ?? [],
    [
      baselineParsedKomdorUid?.kind,
      comparedServicesIds,
      data?.targetServices,
      showOnlyDrift,
    ]
  );

  const attributes: ComparisonTableProps<ComparisonService>["attributes"] =
    useMemo(
      () =>
        (
          [
            {
              headerName: "Service/Attribute",
              field: "name",
              renderCell: cellRenderer("name"),
              showValueAlways: true,
              onClick: (data) => {
                pushDrawer(getDrawerData(data));
              },
              alignTop: true,
            },
            {
              headerName: "Cluster",
              field: "cluster",
              renderCell: cellRenderer("cluster"),
              showValueAlways: true,
            },
            {
              headerName: "Namespace",
              field: "namespace",
              renderCell: cellRenderer("namespace"),
              showValueAlways: true,
            },
            {
              headerName: "Desired Replicas",
              field: "desiredReplicas",
              renderCell: cellRenderer("desiredReplicas"),
              optional: true,
            },
            {
              headerName: "Labels",
              field: "labels",
              hideValueIfDifferent: true,
              renderCell: cellRenderer("labels"),
              onClick: ({ data, isBaseline, column }) => {
                if (isBaseline) {
                  return;
                }
                setDiffModal({
                  title: "Labels",
                  comparedUid: comparedServicesIds?.[column]?.value ?? "",
                  oldObj: baseline?.labels,
                  newObj: data.data?.labels?.value,
                });
              },
            },
            {
              headerName: "Containers",
              field: "containersNames",
              renderCell: cellRenderer("containersNames"),
              alignTop: true,
            },
          ] as ComparisonTableProps<ComparisonService>["attributes"]
        ).filter((attribute) => {
          if (
            !showOnlyDrift ||
            attribute.field === "name" ||
            attribute.field === "cluster" ||
            attribute.field === "namespace" ||
            attribute.field === "kind"
          ) {
            return true;
          }

          return (
            data?.baselineService?.[attribute.field]
              ?.isDiffAcrossTargetServices === true
          );
        }),
      [
        baseline?.labels,
        comparedServicesIds,
        data?.baselineService,
        pushDrawer,
        showOnlyDrift,
      ]
    );

  const servicesLength = useMemo(
    () => Object.keys(services).length,
    [services]
  );
  const isDriftDetected = useMemo(() => {
    return (
      data?.targetServices?.some((service) => service.isDiff === true) ?? false
    );
  }, [data]);

  useEffect(() => {
    if (showOnlyDrift && !isDriftDetected) {
      setShowOnlyDrift(false);
    }
  }, [isDriftDetected, setShowOnlyDrift, showOnlyDrift]);

  const showOnlyDriftTooltipTitle = useMemo(() => {
    if (isDriftDetected) {
      return "";
    }
    if (
      data?.targetServices === undefined ||
      data.targetServices.length === 0
    ) {
      return "No services to compare";
    }
    return "No drift detected";
  }, [data?.targetServices, isDriftDetected]);

  return (
    <Box display="flex" gap="16px" flexDirection="column">
      <ComparisonTable
        title="Service Attributes"
        titleRightElement={
          <LightTooltip title={showOnlyDriftTooltipTitle}>
            <div>
              <LabeledSwitch
                label="Show only drift"
                disabled={!isDriftDetected}
                sx={{
                  typography: {
                    color: isDriftDetected ? "primary.main" : "text.secondary",
                    fontSize: "12px",
                    fontWeight: 500,
                  },
                }}
                checked={showOnlyDrift ?? false}
                onCheck={(checked) => {
                  setShowOnlyDrift(checked);
                }}
              />
            </div>
          </LightTooltip>
        }
        limit={MAX_COMPARED_SERVICES}
        isLoading={isLoading && baselineServiceId !== null}
        attributes={attributes}
        baseline={baseline}
        compared={compared}
        noBaselineElement={
          <Button
            variant="contained"
            size="small"
            sx={{ padding: "5px 8px", fontSize: "12px" }}
            onClick={() => setIsBaselineSelectionOpen(true)}
            disabled={servicesLength === 0}
          >
            Add Baseline Service
          </Button>
        }
        noComparedItemsElement={
          comparedServicesIds != null &&
          comparedServicesIds.length > 0 ? null : (
            <EmptyState
              withButton={baselineServiceId !== null}
              onButtonClick={() => setIsComparedSelectionOpen(true)}
            />
          )
        }
      />
      <ContainersAttributesComparison
        data={data}
        isLoading={isLoading && baselineServiceId !== null}
        showOnlyDrift={showOnlyDrift ?? false}
      />
      {diffModal ? (
        <DiffModal
          title={diffModal?.title}
          baselineUid={baselineServiceId?.value ?? ""}
          comparedUid={diffModal.comparedUid}
          oldObj={diffModal?.oldObj}
          newObj={diffModal?.newObj}
          onClose={() => setDiffModal(null)}
        />
      ) : null}
    </Box>
  );
};
