import Typography from "@mui/material/Typography";
import React from "react";
import { EMPTY_CELL } from "@komodorio/design-system/komodor-ui";

import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { AccordionContentItemContainer } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerStyles";
import { ExternalDnsRecordFull } from "@/generated/addonsApi";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";

export const SettingsSection: React.FC<{
  externalDns: ExternalDnsRecordFull;
}> = ({ externalDns }) => {
  const { sources, dnsProvider, domainFilters } = externalDns;
  return (
    <AccordionWrapper
      title={"Settings"}
      accordionContent={
        <>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Sources
            </Typography>
            <Typography variant="body2">
              {sources.length ? sources.join(", ") : EMPTY_CELL}
            </Typography>
          </AccordionContentItemContainer>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              DNS provider
            </Typography>
            <Typography variant="body2">
              {dnsProvider ? dnsProvider : EMPTY_CELL}
            </Typography>
          </AccordionContentItemContainer>
          <AccordionContentItemContainer>
            <Typography variant="h5" color="text.secondary">
              Domain filters
            </Typography>
            <Typography variant="body2">
              {domainFilters.length ? domainFilters.join(", ") : EMPTY_CELL}
            </Typography>
          </AccordionContentItemContainer>
        </>
      }
      ariaLabel={externalDnsListPageAriaLabels.drawer.sections.settings}
    />
  );
};
