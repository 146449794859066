import React, { ReactElement } from "react";
import { TabType } from "@komodorio/design-system/deprecated";
import { MetadataTooltipProps } from "@komodorio/design-system/komodor-ui";
import { IconProps } from "@komodorio/design-system/icons";
import { OwnerReference } from "kubernetes-types/meta/v1.d";

import { AvailableActions } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { HelmTabNames } from "../../Inspection/Helm/helmChart";

import { KomodorServicesResponseDataInner } from "@/generated/resourcesApi";

export type KomodorServiceType = KomodorServicesResponseDataInner;

export interface Label {
  name: string;
  value: string | ReactElement | undefined;
  tooltipProps?: MetadataTooltipProps;
}

export enum ResourceTabName {
  Events = "Events",
  Info = "Info",
  Describe = "Describe",
  Pods = "Pods",
  Logs = "Logs",
  Nodes = "Nodes",
  Details = "Details",
  Yaml = "YAML",
  Resources = "Resources",
  Dependencies = "Dependencies",
  Insights = "Insights",
  Metrics = "Metrics",
  RootCauseAnalysis = "Root Cause Analysis",
  Phases = "Phases",
  AddonSummary = "Add-on Summary",
}

export type ResourceTab = TabType & { label: ResourceTabName | HelmTabNames };

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default interface Resource {
  readonly agentId: string;
  readonly id: string;
  readonly cluster: string;
  readonly namespace: string;
  readonly ownerReferences?: OwnerReference[];
  readonly kind: string;
  readonly name: string;
  readonly annotations: { [key: string]: string };
  readonly labels: { [key: string]: string };
  /**
   * @deprecated
   */
  readonly icon?: React.FC<IconProps>;
  readonly drawerTabs: ResourceTab[];
  readonly actions: AvailableActions[];
  /**
   * @description The full object as returned from the API - used for the YAML tab
   */
  readonly fullObj?: unknown;
  readonly defaultTab: number;
  readonly getFullScreenUrl?: () => string;

  readonly renderDrawerHeader: (leftActions?: JSX.Element) => JSX.Element;
  readonly renderDrawerMessage?: () => JSX.Element;
  readonly renderDrawerContent: (selectedTab: number) => JSX.Element;
  readonly renderStatus?: () => JSX.Element;
}
