import React from "react";

import {
  IssuesTableContextProvider,
  IssuesTableContextProviderProps,
} from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/context/IssuesTableContext";
import { IssuesTableHandler } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/IssuesTableWithContext/IssuesTableHandler";

type IssuesTableWithContextProps = Omit<
  IssuesTableContextProviderProps,
  "children"
> & {
  className?: string;
};

export const IssuesTableWithContext: React.FC<IssuesTableWithContextProps> = ({
  className,
  ...rest
}) => {
  return (
    <div className={className}>
      <IssuesTableContextProvider {...rest}>
        <IssuesTableHandler />
      </IssuesTableContextProvider>
    </div>
  );
};
