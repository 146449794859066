import WorkflowIssueEventGroup, {
  groupWorkflowIssuesEvents,
} from "../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { WorkflowConfigType } from "../../../monitorsView/common/types";
// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";
import { AvailabilityIssueBanner } from "../AvailabilityIssueBanner";

import { StandalonePodIssueBanner } from "./StandalonePodIssueBanner";
import { useOpenIssuesForPod } from "./useOpenIssuesForPod";

const bannerForIssue = (issueEventGroup: WorkflowIssueEventGroup) => {
  switch (issueEventGroup.workflowType) {
    case WorkflowConfigType.Availability:
      return (
        <AvailabilityIssueBanner
          key={issueEventGroup.id}
          issueId={issueEventGroup.id}
          serviceId={issueEventGroup.serviceId}
          text="The service controlling this pod is experiencing an Availability issue"
        />
      );
    case WorkflowConfigType.Workflow:
    case WorkflowConfigType.Pod:
      return (
        <StandalonePodIssueBanner
          key={issueEventGroup.id}
          issueEventGroup={issueEventGroup}
        />
      );
    default:
      return null;
  }
};

type IssueBannersForPodProps = {
  pod: Pod;
};
export const IssueBannersForPod: React.FC<IssueBannersForPodProps> = ({
  pod,
}) => {
  const { openIssues } = useOpenIssuesForPod(pod);
  if (!openIssues.length) {
    return null;
  }

  const openIssuesEventGroups = groupWorkflowIssuesEvents(openIssues, pod.id);
  if (!openIssuesEventGroups.length) {
    return null;
  }

  return <>{openIssuesEventGroups.map((eg) => bannerForIssue(eg))}</>;
};
