import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { useMonitorsApiClient } from "../apiClient";
import {
  MonitorApiApiV1TypeIdGetRequest,
  apiV1TypeIdGetUrl,
  MonitorResponse,
} from "../../../../../generated/monitorsApi";

const WORKFLOW_RUN_ID_PATH = "/:type/:id";

const fetchMonitorRunById = async (
  apiClient: AxiosInstance,
  params: MonitorApiApiV1TypeIdGetRequest
): Promise<MonitorResponse> => {
  const { data } = await apiClient.get(
    apiV1TypeIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );

  return data;
};

export const useGetMonitorRunById = (
  params: MonitorApiApiV1TypeIdGetRequest,
  options?: { enabled?: boolean }
) => {
  const apiClient = useMonitorsApiClient();
  return useQuery(
    [WORKFLOW_RUN_ID_PATH, params],
    () => fetchMonitorRunById(apiClient, params),
    options
  );
};
