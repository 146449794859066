import React, { useCallback, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { datadogRum } from "@datadog/browser-rum";

import { StyledDialog } from "../../styles";
import {
  ClusterForRbacSync,
  agentToClusterForRbacSync,
  isClusterValid,
  RbacClusterSyncFeatureFlag,
} from "../utils/rbacClusterStatusSync";

import { ariaLabels } from "./ariaLabels";
import {
  DownloadKubeconfigButton,
  errorNotificationMessage,
  OpenKubeconfigDownloadClusterSelectionButton,
} from "./DownloadKubeconfigButton";
import { SelectClustersList } from "./SelectClustersList";

import { useGetRbacClusterStatuses } from "@/shared/hooks/auth-service/client/rbacClusterStatus/useGetRbacClusterStatuses";
import { useActiveAgents } from "@/shared/hooks/useAgents";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";
import { AnalyticEvents } from "@/shared/config/analyticsEvents";

const tenMinutes = 10 * 60 * 1000;

export interface RenderButtonProps {
  onClick: () => void;
}
const formatTimeRemaining = (milliseconds: number) => {
  const msInDate = new Date(milliseconds);
  const formatter = new Intl.DateTimeFormat("en", {
    minute: "2-digit",
    second: "2-digit",
  });
  return formatter.format(msInDate);
};

export const OpenDownloadKubeconfigModalButton: React.FC<{
  renderButton?: (props: RenderButtonProps) => React.ReactNode;
}> = ({ renderButton }) => {
  const { canGetKubeconfig } = useHasPermissions();
  const { data: accountFeatures } = useAccountFeatures();
  const { rbacClusterSync } = useOverridableFlags();

  const closeResetTimeoutMs = 250; // animation duration for the Modal Dialog is 225ms, being close to it will prevent flickering
  const [renderKey, setRenderKey] = useState(0);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);

  const { userId } = useUserMetadata();
  const { data: user } = useGetUserById({ id: userId });

  useEffect(() => {
    if (!user?.createdAt) return;
    const createdAtDate = new Date(user.createdAt);
    const interval = setInterval(() => {
      const now = new Date();
      const diff = now.getTime() - createdAtDate.getTime();
      if (diff < tenMinutes) {
        setTimeRemaining(tenMinutes - diff);
      } else {
        setTimeRemaining(0);
        setTimeModalOpen(false);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [user?.createdAt]);

  if (
    !canGetKubeconfig ||
    !accountFeatures?.rbacClusterSync ||
    !(rbacClusterSync as RbacClusterSyncFeatureFlag)?.allowUsersDownload
  ) {
    return null;
  }
  const openKubeconfOrError = () => {
    datadogRum.addAction(AnalyticEvents.RbacClusterSync.GetKubeconfig);
    timeRemaining === 0 ? setDownloadModalOpen(true) : setTimeModalOpen(true);
  };

  const GetKubeconfigButton = () =>
    renderButton ? (
      renderButton({
        onClick: openKubeconfOrError,
      })
    ) : (
      <OpenKubeconfigDownloadClusterSelectionButton
        onClick={openKubeconfOrError}
        aria-label={ariaLabels.getKubeConfButton}
      >
        Get Kubeconfig...
      </OpenKubeconfigDownloadClusterSelectionButton>
    );

  return (
    <React.Fragment>
      <GetKubeconfigButton />
      <SelectClustersModal
        key={renderKey}
        isOpen={downloadModalOpen}
        closeModal={() => {
          setDownloadModalOpen(false);
          setTimeout(() => {
            setRenderKey(renderKey + 1);
          }, closeResetTimeoutMs);
        }}
      />
      <ErrorTimeRemainingModal
        isOpen={timeModalOpen}
        time={timeRemaining}
        closeModal={() => {
          setTimeModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export const ErrorTimeRemainingModal: React.FC<{
  isOpen: boolean;
  closeModal: () => void;
  time: number;
}> = ({ isOpen, closeModal, time }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"xs"}
      aria-label={ariaLabels.timeRemaining.dialog}
    >
      <DialogTitle aria-label={ariaLabels.timeRemaining.title}>
        <Typography variant="h3">Download Kubeconfig file</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ paddingTop: 0 }}
        aria-label={ariaLabels.timeRemaining.content}
      >
        <Stack rowGap={1}>
          <Typography variant="body2">
            The first time sync of your clusters will take up to 10 min.
          </Typography>
          <Typography
            variant="body2"
            aria-label={ariaLabels.timeRemaining.contentTimer}
          >
            The Kubeconfig file will ready in: {formatTimeRemaining(time)}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: "16px", paddingRight: "24px" }}>
        <Button
          variant="contained"
          onClick={closeModal}
          size="medium"
          aria-label={ariaLabels.timeRemaining.closeButton}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SelectClustersModal: React.FC<{
  isOpen: boolean;
  closeModal: () => void;
}> = ({ isOpen, closeModal }) => {
  const [errorDownloading, setErrorDownloading] = useState(false);
  const [selectedClusters, setSelectedClusters] = useState<string[]>([]);

  const { data: rbacClusterSyncStatuses } = useGetRbacClusterStatuses();

  const activeAgents = useActiveAgents();

  const activeSelectableClusters: ClusterForRbacSync[] = useMemo(() => {
    if (!activeAgents || !rbacClusterSyncStatuses) {
      return [];
    }

    return activeAgents.map((agent) =>
      agentToClusterForRbacSync({
        agent,
        rbacClusterStatuses: rbacClusterSyncStatuses,
      })
    );
  }, [activeAgents, rbacClusterSyncStatuses]);

  const onSelectCluster = useCallback(
    (cluster: string) => {
      if (selectedClusters.includes(cluster)) {
        return;
      }
      setSelectedClusters([...selectedClusters, cluster]);
    },
    [selectedClusters]
  );
  const onUnselectCluster = useCallback(
    (cluster: string) =>
      setSelectedClusters(selectedClusters.filter((c) => c !== cluster)),
    [selectedClusters]
  );

  const selectAndReplaceMultiple = useCallback(
    (clusters: ClusterForRbacSync[]) => {
      const clusterNames = clusters
        .filter(isClusterValid)
        .map((cluster) => cluster.name);
      setSelectedClusters(clusterNames);
    },
    [setSelectedClusters]
  );

  const unselectAll = useCallback(() => {
    setSelectedClusters([]);
  }, [setSelectedClusters]);

  const onDownloaded = useCallback(
    (args: { success: boolean }) => {
      setErrorDownloading(!args.success);
      if (args.success) {
        closeModal();
      }
    },
    [closeModal]
  );

  return (
    <StyledDialog open={isOpen} onClose={closeModal}>
      <DialogTitle sx={{ fontSize: "20px" }}>
        Download Kubeconfig file
      </DialogTitle>
      <DialogContent sx={{ height: "50vh" }}>
        <SelectClustersList
          availableClusters={activeSelectableClusters}
          selectedClusters={selectedClusters}
          onSelectCluster={onSelectCluster}
          onUnselectCluster={onUnselectCluster}
          selectAndReplaceMultiple={selectAndReplaceMultiple}
          unselectAll={unselectAll}
        />
      </DialogContent>
      {errorDownloading && (
        <Typography
          variant="subtitle2"
          color="error"
          sx={{ textAlign: "center" }}
        >
          {errorNotificationMessage}
        </Typography>
      )}
      <DialogActions>
        <Button variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <DownloadKubeconfigButton
          clusters={selectedClusters}
          onDownloaded={onDownloaded}
        />
      </DialogActions>
    </StyledDialog>
  );
};
