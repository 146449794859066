import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import React, { createContext, useState } from "react";

import {
  BASELINE_SERVICE_OPTION_KEY,
  COMPARED_SERVICES_OPTION_KEY,
} from "../constants";

import { initialServicesComparisonState } from "./initialState";
import { ServicesComparisonState } from "./types";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";

export const ServicesComparisonContext = createContext<ServicesComparisonState>(
  initialServicesComparisonState
);

export const ServicesComparisonContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [baselineServiceId, setBaselineServiceId] =
    useStringifiedStateInSearchParams<MuiSelectionOption<string>>(
      BASELINE_SERVICE_OPTION_KEY
    );
  const [comparedServicesIds, setComparedServicesIds] =
    useStringifiedStateInSearchParams<MuiSelectionOption<string>[]>(
      COMPARED_SERVICES_OPTION_KEY
    );

  const [isBaselineSelectionOpen, setIsBaselineSelectionOpen] =
    useState<boolean>(false);
  const [isComparedSelectionOpen, setIsComparedSelectionOpen] =
    useState<boolean>(false);

  const contextState: ServicesComparisonState = {
    baselineServiceId,
    comparedServicesIds,
    isBaselineSelectionOpen,
    isComparedSelectionOpen,
    setBaselineServiceId,
    setComparedServicesIds,
    setIsBaselineSelectionOpen,
    setIsComparedSelectionOpen,
  };

  return (
    <ServicesComparisonContext.Provider value={contextState}>
      {children}
    </ServicesComparisonContext.Provider>
  );
};
