import { useState, useEffect, createContext } from "react";

import { useAgentServicePolling } from "../../agents-api/agents/useAgentsPolling";
import { agentResponse } from "../../agents-api/utils";
import { useIsAccountSetUp } from "../../useUserMetadata/utils";
import { useStateInLocalStorage } from "../../state/useStateInLocalStorage";

import { isAgentsInstalledLocalStorage } from "./consts";

import { isSandbox } from "@/shared/utils/sandbox";

export const AgentsContext = createContext<agentResponse | undefined>(
  undefined
);

const useSaveLocalStorageAgentsState = (
  agents: agentResponse | undefined
): void => {
  const [isAgentsInstalled, setIsAgentsInstalled] = useStateInLocalStorage(
    isAgentsInstalledLocalStorage,
    ""
  );
  useEffect(() => {
    if (isSandbox()) return;
    if (isAgentsInstalled === "true") return;
    const isActiveAgents = agents?.activeAgents?.length ? "true" : "";
    setIsAgentsInstalled(isActiveAgents);
  }, [agents, isAgentsInstalled, setIsAgentsInstalled]);
};

export const AgentsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const isAccountSetUp = useIsAccountSetUp();
  const [agentsData, setAgentsData] = useState<agentResponse | undefined>();
  const { data } = useAgentServicePolling(1000 * 15, !isAccountSetUp); // 15 seconds
  // avoid rerendering when data is the same
  useEffect(() => {
    if (JSON.stringify(data) === JSON.stringify(agentsData)) {
      return;
    }
    setAgentsData(data);
  }, [agentsData, data]);
  useSaveLocalStorageAgentsState(data);
  return (
    <AgentsContext.Provider value={agentsData}>
      {children}
    </AgentsContext.Provider>
  );
};
