import { useMemo } from "react";

import {
  ClusterData,
  useGetAllClusters,
} from "./useGetAllClusters/useGetAllClusters";

import { useClusterOptionsByAppView } from "@/shared/hooks/useGetAllClusters/useClusterOptionsByAppView";
import { isDevMode } from "@/shared/utils/isDevMode";

const mapClusterToOption = (cluster: ClusterData) => ({
  label: cluster.clusterName,
  value: cluster.clusterName,
});

export const useGetAllClusterNamesAsOptionsScopedByWorkspace = () => {
  const allClusters = useClusterOptionsByAppView(isDevMode());

  return useMemo(() => allClusters.map(mapClusterToOption), [allClusters]);
};

export const useGetAllClusterNamesAsOptions = () => {
  const clusterOptions = useGetAllClusters({ shouldUpdate: false });

  return useMemo(
    () => clusterOptions.map(mapClusterToOption),
    [clusterOptions]
  );
};
