import { PodLogLine } from "../types";

import { DurationOption } from "@/components/common/LogsViewer/logsTypes";

export type LogsParams = {
  tail: number;
  sinceNumber: number;
  sinceUnit: DurationOption;
  sendSince: boolean;
  sendTail: boolean;
};

export type PodLogsStoreState = {
  logs: PodLogLine[];
  direction: PodLogsDirection;
  logsParams: LogsParams;
  isLoading: boolean;
};

export enum PodLogsDirection {
  NewestFirst = "newestFirst",
  EarliestFirst = "earliestFirst",
}

export type PodLogsStoreActions = {
  resetLogs: () => void;
  addLogs: (logs: PodLogLine[]) => void;
  setDirection: (direction: PodLogsDirection) => void;
  setPodLogsParams: (logsParams: LogsParams) => void;
  setIsLoading: (isLoading: boolean) => void;
};

export type PodLogsStore = PodLogsStoreState & PodLogsStoreActions;
