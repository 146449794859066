import { AvailabilityIssueBanner } from "@/components/ResourceView/banners/AvailabilityIssueBanner";
import type Deployment from "@/components/ResourceView/resources/deployment";
import type Rollout from "@/components/ResourceView/resources/rollout";
import { MonitorType } from "@/generated/monitorsApi";
import { K8S_ADD_ONS_IS_EXTERNAL_DNS_REFERRER } from "@/shared/config/urlSearchParamsKeys";
import { useGetMonitorIssues } from "@/shared/hooks/monitors-api/client/issues/useGetMonitorIssues";
import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";

export const IssueBannersForService: React.FC<{
  service: Deployment | Rollout;
}> = ({ service }) => {
  const [isExternalDNSAddonReferrer] = useDrawerUrlState<boolean>(
    K8S_ADD_ONS_IS_EXTERNAL_DNS_REFERRER
  );

  const { data: monitorIssues } = useGetMonitorIssues(
    {
      types: [MonitorType.Availability],
      includeOpen: true,
      includeClose: false,
      serviceIds: [service.id],
      order: "DESC",
    },
    {
      enabled: isExternalDNSAddonReferrer ?? false,
    }
  );
  const openAvailabilityIssue = monitorIssues?.data?.[0];

  return isExternalDNSAddonReferrer && openAvailabilityIssue ? (
    <AvailabilityIssueBanner
      issueId={openAvailabilityIssue.id}
      serviceId={openAvailabilityIssue.services?.[0] ?? ""}
      triageData={openAvailabilityIssue.triageData}
      text="This service is experiencing an Availability Issue"
    />
  ) : null;
};
