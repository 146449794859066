import { VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS } from "./ViolationTableWithContext/violationTableConstants";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const {
  violationsTable: { violationsTable: tableAriaLabel },
} = reliabilityArialLabels;

export const tableProps = {
  "aria-label": tableAriaLabel,
  disableColumnMenu: true,
  pageSizeOptions: VIOLATIONS_TABLE_PAGE_SIZE_OPTIONS,
  pagination: true,
  disableColumnReorder: true,
  disableColumnResize: true,
};
