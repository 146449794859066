import React from "react";
import { cloneDeep, get, set } from "lodash";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Switch from "@mui/material/Switch";

import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";
import { DataKeyToLabel } from "@/components/Settings/email/emailSettingsConstants";

const FlexContainer = styled.div`
  display: flex;
  gap: 4px;
`;

type CheckboxControllerProps = {
  dataKey: string;
  containerAriaLabel?: string;
  buttonAriaLabel?: string;
};

export const SwitchController: React.FC<CheckboxControllerProps> = ({
  dataKey,
  containerAriaLabel,
  buttonAriaLabel,
}) => {
  const { setConfigurationData, ...context } = useEmailSettingsContext();
  const value: boolean = get(context, dataKey) ?? false;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    const newContent = set(cloneDeep(context), dataKey, value);
    setConfigurationData(newContent);
  };

  const label: string = get(DataKeyToLabel, dataKey) ?? "";

  return (
    <FlexContainer aria-label={`${containerAriaLabel} checked: ${value}`}>
      <Typography variant={"body2"}>{label}</Typography>
      <Switch
        onChange={onChange}
        checked={value}
        role={"switch"}
        aria-label={buttonAriaLabel}
      />
    </FlexContainer>
  );
};
