import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { CertManagerTabs } from "@/components/k8sAddons/addons/CertManager/CertManagerTabs";
import { CertManagerRoutes } from "@/components/k8sAddons/addons/CertManager/certManagerConstants";
import { CertificatesPage } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/CertificatesPage";
import { ControllersPage } from "@/components/k8sAddons/addons/CertManager/ControllersPage/ControllersPage";
import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { Operator } from "@/generated/addonsApi";
import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";

export const CertManager: React.FC = () => {
  return (
    <ResponsiveLayout>
      <FiltersContextProvider
        urlParamKey={K8S_ADD_ONS_FILTERS_PARAM_KEY}
        searchTypeFilterOperator={Operator.Like}
      >
        <CertManagerTabs />
        <Routes>
          <Route
            path={CertManagerRoutes.certificates}
            element={<CertificatesPage />}
          />
          <Route
            path={CertManagerRoutes.controllers}
            element={<ControllersPage />}
          />
          <Route
            path="*"
            element={<Navigate to={CertManagerRoutes.certificates} replace />}
          />
        </Routes>
      </FiltersContextProvider>
    </ResponsiveLayout>
  );
};
