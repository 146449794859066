import React, { useMemo } from "react";
import styled from "styled-components";

import { ResourceUsageProps } from "@/components/k8sAddons/components/table/ResourceUsageBar/resourceUsageTypes";
import { ResourceUsageSingleMetric } from "@/components/k8sAddons/components/table/ResourceUsageBar/ResourceUsageSingleMetric";

const Container = styled.div`
  width: 100%;
`;

type ResourceUsageBarProps = {
  memoryResourceData?: ResourceUsageProps;
  cpuResourceData?: ResourceUsageProps;
  classname?: string;
};

export const ResourceUsageBar: React.FC<ResourceUsageBarProps> = ({
  memoryResourceData,
  cpuResourceData,
  classname,
}) => {
  const content = useMemo(() => {
    return Object.values({ memoryResourceData, cpuResourceData })
      .filter(Boolean)
      .map((resourceData) => {
        return (
          resourceData && (
            <ResourceUsageSingleMetric
              title={resourceData.title}
              type={resourceData.type}
              resourceData={resourceData.resourceData}
            />
          )
        );
      });
  }, [cpuResourceData, memoryResourceData]);

  return <Container className={classname}>{content}</Container>;
};
