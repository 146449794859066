import { useMemo } from "react";

import type Resource from "@/components/ResourceView/resources";
import { useFilterRelevantActions } from "@/components/ResourceView/common/Header/Actions/hooks/useFilterCronJobActions";
import { AvailableActions } from "@/components/Inspection/inspectionConfiguration/SupportedResourcesTypes";

export const useFilterAvailableActions = (resource: Resource) => {
  const filterRelevantActions = useFilterRelevantActions();

  return useMemo<AvailableActions[]>(() => {
    return filterRelevantActions(resource);
  }, [filterRelevantActions, resource]);
};
