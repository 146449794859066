import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const Container = styled.div`
  position: absolute;
  width: 100%;
`;

type PlaceholderTextProps = {
  placeholder?: string;
  hasOptionSelected: boolean;
};

export const PlaceholderText: React.FC<PlaceholderTextProps> = ({
  placeholder,
  hasOptionSelected,
}) => {
  const shouldShow = placeholder && !hasOptionSelected;
  if (!shouldShow) return null;

  return (
    <Container>
      <Typography variant={"body2"} color="text.secondary">
        {placeholder}
      </Typography>
    </Container>
  );
};
