import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { palette } from "@komodorio/design-system";
import styled from "styled-components";
import {
  ActionMetadataDeleteResource,
  ActionTypes,
  TaskType,
} from "komodor-types";
import pluralize from "pluralize";

import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import { ErrorMessage } from "../../common/ErrorMessage";
import { useFindService } from "../../../../shared/hooks/useFindService";

import {
  useExecuteAgentTaskAndReport,
  useHandleAgentTaskResponse,
} from "@/components/Actions/modals/actionModalHooks";

const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  gap: 1rem;
`;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 0.15rem 0.5rem;
  justify-content: start;
  color: ${palette.gray[800]};
`;

export const DeleteValidationTitle = styled(Typography).attrs({
  color: palette.gray[600],
  size: "medium",
  bold: true,
})`
  margin-block-end: 0.25rem;
`;
const PinkSpan = styled.span`
  color: ${palette.pink[500]};
`;

interface DeleteResourceModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  agentId: string;
  controlledBy?: string;
  afterEffect?: () => void;
}

const DeleteResourceModal: React.FC<DeleteResourceModalProps> = ({
  isModalOpen,
  handleClose,
  resourceName,
  resourceType,
  namespace,
  cluster,
  agentId,
  controlledBy,
  afterEffect,
}) => {
  const [actionSent, setActionSent] = useState(false);
  const [deleteValidation, setDeleteValidation] = useState("");

  const service = useFindService({
    value: resourceName,
    clusterName: cluster,
    pageName: resourceType,
    namespace,
  });

  const metadata: ActionMetadataDeleteResource = {
    namespace: namespace,
    cluster: cluster,
    serviceId: service?.id ?? "",
    type: ActionTypes.DeleteResource,
    resourceType: pluralize.singular(resourceType),
    resourceName: resourceName,
    controlledBy: controlledBy ?? "",
  };

  const resourceDetails = [
    {
      title: "Resource name:",
      data: resourceName,
    },
    controlledBy
      ? {
          title: "Controlled by:",
          data: controlledBy,
        }
      : {},
    {
      title: "Namespace:",
      data: namespace,
    },
    {
      title: "Cluster:",
      data: cluster,
    },
  ];

  const { execute, result, deniedObject } = useAgentTask(
    agentId,
    TaskType.ACTION_COMMAND,
    metadata
  );

  const executeAndReport = useExecuteAgentTaskAndReport({
    execute,
    resourceType: "deleteResource",
    reportType: resourceType,
  });

  const { showErrorMessage } = useHandleAgentTaskResponse({
    result,
    deniedObject,
    handleClose,
    setActionSent,
    afterEffect,
  });

  return (
    <>
      <Modal isOpen={isModalOpen} width="28rem" onRequestClose={handleClose}>
        <ModalHeader>
          <Typography variant="headline">Delete {resourceType}</Typography>
        </ModalHeader>
        <ModalContent>
          <Typography size="medium">
            Are you sure you want to delete the {resourceType}:
            <br />
            <br />
            <Container>
              <ModalGrid>
                {resourceDetails.map((e, i) => (
                  <React.Fragment key={i}>
                    <Typography size="medium" color={palette.gray[600]}>
                      {e.title}
                    </Typography>
                    <Typography size="medium" color={palette.gray[800]}>
                      {e.data}
                    </Typography>
                  </React.Fragment>
                ))}
              </ModalGrid>
            </Container>
          </Typography>
          {showErrorMessage && deniedObject !== undefined && (
            <ErrorMessage reason={deniedObject} />
          )}
          <br />
          <DeleteValidationTitle>
            Please type <PinkSpan>delete </PinkSpan>
            to confirm
          </DeleteValidationTitle>
          <Input
            size="small"
            width="23rem"
            placeholder="delete"
            value={deleteValidation}
            onChange={(e) => setDeleteValidation(e.target.value)}
          />
        </ModalContent>
        <ModalActions>
          <Button variant="secondary" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            size="small"
            disabled={actionSent || deleteValidation !== "delete"}
            onClick={() => {
              executeAndReport();
              setActionSent(true);
            }}
          >
            {actionSent ? "Deleting..." : "Delete"}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DeleteResourceModal;
