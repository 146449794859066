import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Code24, InfoCircle24 } from "@komodorio/design-system/icons";

import { LazyEditor } from "@/components/common/LazyEditor";
import { AgentDrawerSectionHeader } from "@/pages/organization-settings/account/AgentsPage/components/AgentDrawerSectionHeader";
import { ariaLabels } from "@/pages/organization-settings/account/AgentsPage/constants";
import { AgentDrawerDetails } from "@/pages/organization-settings/account/AgentsPage/components/AgentDrawerDetails";
import { MARGIN_LEFT } from "@/pages/organization-settings/account/AgentsPage/components/constants";
import { AgentDrawerProps } from "@/pages/organization-settings/account/AgentsPage/components/types";
import { AgentDrawerHeader } from "@/pages/organization-settings/account/AgentsPage/components/AgentDrawerHeader";

export const AgentDrawer: React.FC<AgentDrawerProps> = ({
  agent,
  onClose,
  restartAgent,
}) => {
  const stringifiedConfiguration = useMemo(
    () => JSON.stringify(agent?.configuration || {}, null, 2),
    [agent]
  );

  return (
    <Drawer
      anchor="right"
      open={!!agent}
      onClose={onClose}
      aria-label={ariaLabels.drawer}
    >
      <Stack
        sx={{ width: 800, padding: 2, height: "100%" }}
        direction="column"
        rowGap={3}
      >
        {!!agent && (
          <AgentDrawerHeader
            agent={agent}
            onClose={onClose}
            restartAgent={restartAgent}
          />
        )}
        <Divider sx={{ margin: "0 -16px" }} />
        <Stack direction="column" rowGap={1}>
          <AgentDrawerSectionHeader Icon={InfoCircle24} title="Details" />
          {!!agent && <AgentDrawerDetails agent={agent} />}
        </Stack>
        <Stack direction="column" rowGap={1} flexGrow={1}>
          <AgentDrawerSectionHeader Icon={Code24} title="JSON" />
          <Box
            sx={{
              marginLeft: MARGIN_LEFT,
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,

              // Override the height of the editor to fill the remaining space
              "& > div": {
                height: "100%",
              },
            }}
          >
            <LazyEditor
              value={stringifiedConfiguration}
              readOnly
              width="100%"
              height="100%"
              mode="json"
              variant="dark"
            />
          </Box>
        </Stack>
      </Stack>
    </Drawer>
  );
};
