import { useState } from "react";
import Link from "@mui/material/Link";

import UpgradeRequired, { UpgradeRequiredModalStep } from "./upgradeRequired";

export const UpgradeAgentButton: React.FC<{
  command: string;
  desiredFeature: string;
}> = ({ command, desiredFeature }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Link
        variant="body2"
        underline="hover"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        sx={{ cursor: "pointer", textWrap: "initial" }}
      >
        Upgrade Agent
      </Link>
      {open && (
        <UpgradeRequired
          handleClose={() => setOpen(false)}
          updateMessage={null}
          upgradeCommand={command}
          desiredFeature={desiredFeature}
          initialStep={UpgradeRequiredModalStep.ShowCommand}
        />
      )}
    </>
  );
};
