import React from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import { useNavigate } from "react-router-dom";

import { TextButton } from "../../../monitorsView/common/Button";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";

const BlueTextButton = styled(TextButton)`
  color: ${palette.blue[500]};
  padding: 1rem 0 0 0;
`;

export const NavigateToApiKeys: React.FC = () => {
  const { showApiKeys } = useOverridableFlags();
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/settings/api-keys");
  };

  if (!showApiKeys) return null;
  return <BlueTextButton onClick={onClick}>Manage API keys</BlueTextButton>;
};
