import { useMemo } from "react";

import {
  GenericViolationFilterCategory,
  ImpactGroupType,
} from "@/generated/reliabilityApi";
import { useGetImpactGroupType } from "@/components/reliability/hooks/useSelectedTab";

export const useAddImpactGroupTypeFilter =
  (): GenericViolationFilterCategory => {
    const impactGroupType = useGetImpactGroupType();

    return useMemo(() => {
      const additionalImpactGroupValues =
        impactGroupType === ImpactGroupType.Dynamic
          ? [ImpactGroupType.Realtime]
          : [];

      return {
        category: "impactGroupType",
        operator: "include",
        value: [impactGroupType, ...additionalImpactGroupValues],
      };
    }, [impactGroupType]);
  };
