import React from "react";

import { getK8sVersionShort } from "../utils";

import { useGetHelmInstallationCommand } from "@/shared/hooks/useGetHelmInstallationCommand";
import { UpgradeCommands } from "@/components/common/upgradeAgentCta/UpgradeAgentCTA";
import { UpgradeAgentButton } from "@/components/common/UpdateAgentModal/UpgradeAgentButton";

export const K8sVersionCell: React.FC<{
  k8sVersion: string;
}> = ({ k8sVersion }) => {
  if (!k8sVersion) return <UpgradeClusterCtaK8sVersion />;

  return <>{getK8sVersionShort(k8sVersion)}</>;
};

export const AgentVersionCell: React.FC<{
  chartVersion: string;
}> = ({ chartVersion }) => {
  if (!chartVersion) return <UpgradeClusterCtaK8sVersion />;

  return <>{chartVersion}</>;
};

export const UpgradeClusterCtaK8sVersion: React.FC = () => {
  const command = useGetHelmInstallationCommand(
    UpgradeCommands.NEW_UPGRADE_COMMAND
  );
  return (
    <UpgradeAgentButton
      command={command}
      desiredFeature="kubernetes metadata"
    />
  );
};
