import React, { useMemo } from "react";

import generateCustomLinkWithParameters from "../../../../common/ProcessList/details/components/LinkSection/CustomLink/CustomLinkUtil";
import { SmallIntegrationLinkView } from "../../../../common/ProcessList/details/components/LinkSection/IntegrationLink";
import Resource from "../../../resources";
import { useGetUserById } from "../../../../../shared/hooks/auth-service/client/users/useGetUserById";
import { useUserMetadata } from "../../../../../shared/hooks/useUserMetadata/useUserMetadata";

import { Link } from "./links/useKomodorAnnotations";

const MetadataCustomLink: React.FC<{
  resource: Resource;
  link: Link;
  newSpec: Record<string, unknown> | undefined;
  dispatchLinkMetric: () => void;
}> = ({ resource, link, newSpec, dispatchLinkMetric }) => {
  const { userId } = useUserMetadata();
  const { data: user } = useGetUserById({ id: userId });
  const { href, errors } = useMemo(() => {
    if (!resource) {
      return { href: "#loading", errors: [] };
    }

    const { href, errors } = generateCustomLinkWithParameters(
      link.url,
      resource.name,
      resource.cluster,
      resource.namespace,
      false,
      newSpec,
      undefined,
      undefined,
      undefined,
      user?.email
    );
    return { href, errors };
  }, [resource, link, newSpec, user]);

  return (
    <SmallIntegrationLinkView
      icon={link.icon}
      href={href}
      title={link.text}
      error={errors?.[0]}
      dispatchLinkMetric={dispatchLinkMetric}
    />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default MetadataCustomLink;
