import React, { useRef } from "react";

import {
  GraphsContainer,
  MainContainer,
  MetricsContainer,
} from "../../MetricsTab/styles";
import { useReportTabLoadingTime } from "../hooks/useReportTabLoadingTime";
import { LoadingStateIds } from "../workloadMetricsTabConstants";
import { useSetInitialLoadTime } from "../hooks/useSetInitialLoadTime";
import { useIsElementInViewport } from "../../../../../shared/hooks/useIntersectionObserver";

import { WorkloadMetricsPreferences } from "./WorkloadMetricsPreferences/WorkloadMetricsPreferences";
import { MetricsPerContainer } from "./MetricsPerContainer/MetricsPerContainer";
import { WorkloadGraph } from "./graphs/WorkloadGraph";

import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";

interface WorkloadMetricsTabContentProps {
  komodorUidParams: KomodorUidParams;
}

export const WorkloadMetricsTabContent: React.FC<
  WorkloadMetricsTabContentProps
> = ({
  komodorUidParams = { cluster: "", name: "", namespace: "", kind: "" },
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const isDisplayed = useIsElementInViewport({ ref: divRef });

  useReportTabLoadingTime(komodorUidParams.kind, isDisplayed);
  useSetInitialLoadTime();

  const syncId = `${komodorUidParams.cluster}/${komodorUidParams.namespace}/${komodorUidParams.name}`;

  return (
    <MetricsContainer ref={divRef}>
      <MainContainer>
        <WorkloadMetricsPreferences />
        <GraphsContainer>
          <WorkloadGraph
            komodorUidParams={komodorUidParams}
            loadingStateId={LoadingStateIds.workLoadMemory}
            type={"memory"}
            metricGraphProps={{
              syncId: syncId,
            }}
          />
          <WorkloadGraph
            komodorUidParams={komodorUidParams}
            loadingStateId={LoadingStateIds.workLoadCPU}
            type={"cpu"}
            metricGraphProps={{
              syncId: syncId,
            }}
          />
        </GraphsContainer>
      </MainContainer>
      <MetricsPerContainer komodorUidParams={komodorUidParams} id={syncId} />
    </MetricsContainer>
  );
};
