import React, { useState } from "react";
import styled, { css } from "styled-components";

import { dispatchEvent } from "../../shared/hooks/analytics";
import ModalComponent from "../common/Modal";
import StrictExternalLink from "../common/Link/ExternalLink";
import Button from "../common/controls/Button";
import { H1, H3Light } from "../common/typography";
import { AnalyticEvents } from "../../shared/config/analyticsEvents";
import { blackGreyText, transparentGray1 } from "../../Colors";
import { useUserMetadata } from "../../shared/hooks/useUserMetadata/useUserMetadata";

import trialEnded from "./assets/trial-ended.svg";

const AllContentContainer = styled.div`
  display: grid;
  height: 28rem;
  width: 30rem;
  grid-template-rows: repeat(10, 1fr);
`;

const TrialEndedImage = styled.div`
  height: 12rem;
  width: 15rem;
  background-image: url(${trialEnded});
  background-repeat: no-repeat;
  justify-self: center;
  grid-column: 1 / -1;
  grid-row: 1;
`;

const TextsContainer = styled.div`
  display: grid;
  justify-self: center;
  grid-rows: repeat(10, 1fr);
  grid-row: 3 / span 5;
`;

const Headline = styled(H1)`
  height: 1rem;
  font-family: Poppins;
  font-weight: 500;
  color: ${blackGreyText};
  font-size: 2.2rem;
  grid-column: 1 / -1;
  grid-row: 1;
`;

const TextStyle = css`
  height: 1rem;
  font-weight: 100;
  font-size: 1rem;
  color: ${blackGreyText};
`;

const UpperText = styled(H3Light)`
  ${TextStyle};
  grid-column: 1 / -1;
  grid-row: 4;
`;

const BottomText = styled(H3Light)`
  ${TextStyle};
  grid-column: 1 / -1;
  grid-row: 7;
`;

const ContactUsButton = styled(Button)`
  height: 2rem;
  width: 8rem;
  justify-self: center;
  grid-column: 1 / -1;
  grid-row: 9;
`;

const ContactUsLink = styled(StrictExternalLink)`
  color: white;
  text-decoration: none;
`;

export const TrialEndedModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { isKomodorAdmin } = useUserMetadata();
  dispatchEvent(AnalyticEvents.UserManagement.TrialEndedModalOpened);
  return (
    <ModalComponent
      isOpen={isOpen}
      handleCloseModal={
        isKomodorAdmin
          ? () => {
              setIsOpen(false);
            }
          : undefined
      }
      customStyle={{
        borderRadius: "1.5rem",
      }}
      overlayStyle={{ background: transparentGray1 }}
    >
      <AllContentContainer>
        <TrialEndedImage />
        <TextsContainer>
          <Headline>Your Free Trial Has Ended</Headline>
          <UpperText>Don’t worry, your data is still here!</UpperText>
          <BottomText>
            Contact us to continue your Kubernetes journey with us.
          </BottomText>
        </TextsContainer>
        <ContactUsButton
          onClick={() => {
            dispatchEvent(
              AnalyticEvents.UserManagement.TrialEndedModalContactUsButtonClick
            );
          }}
        >
          <ContactUsLink href="https://meetings.hubspot.com/timi-petrov/">
            Contact Us
          </ContactUsLink>
        </ContactUsButton>
      </AllContentContainer>
    </ModalComponent>
  );
};
