import React from "react";
import { muiColors } from "@komodorio/design-system";
import { subDays } from "date-fns";
import {
  Boxes16,
  Nodes16,
  Storage16,
  Workflows16,
  Workloads16,
} from "@komodorio/design-system/icons";

import { CheckType, ImpactGroupIdentifier } from "@/generated/reliabilityApi";

export const HEALTH_SECTION_TITLE = "Health";
export const HEALTH_SECTION_EMPTY_MESSAGE = "All the services are healthy";
export const HEALTH_SECTION_WORKLOADS_EMPTY_MESSAGE =
  "All the workloads are healthy";
export const HEALTH_SECTION_INFRA_EMPTY_MESSAGE =
  "All the infrastructure resources are healthy";

export type CheckItem = {
  label: string;
  color: string;
  icon?: React.ReactNode;
};

export const workloadRealtimeIssueTypes: Record<string, CheckItem> = {
  [CheckType.UnhealthyService]: {
    label: "Unhealthy Services",
    color: muiColors.indigo[600] ?? "",
    icon: <Workloads16 />,
  },
  [CheckType.UnhealthyWorkflow]: {
    label: "Unhealthy Workflows",
    color: muiColors.blue[400] ?? "",
    icon: <Workflows16 />,
  },
  [CheckType.FailedJob]: {
    label: "Failed Jobs",
    color: muiColors.lightBlue[200] ?? "",
    icon: <Boxes16 />,
  },
};

export type WorkloadImpactIds =
  | typeof ImpactGroupIdentifier.DegradedService
  | typeof ImpactGroupIdentifier.NodePressure;

export const workloadReliabilityRiskTypes: Record<
  WorkloadImpactIds,
  CheckItem
> = {
  [ImpactGroupIdentifier.DegradedService]: {
    label: "Degraded Services",
    color: muiColors.indigo[600] ?? "",
  },
  [ImpactGroupIdentifier.NodePressure]: {
    label: "Node Pressure",
    color: muiColors.blue[400] ?? "",
  },
};

export const infraRealtimeIssueTypes: Record<string, CheckItem> = {
  [CheckType.UnhealthyNode]: {
    label: "Nodes with Issues",
    color: muiColors.indigo[600] ?? "",
    icon: <Nodes16 />,
  },
  [CheckType.UnhealthyPvc]: {
    label: "PVCs with Issues",
    color: muiColors.blue[400] ?? "",
    icon: <Storage16 />,
  },
};

export type InfraImpactIds =
  | typeof ImpactGroupIdentifier.EolDeprecationOutdated
  | typeof ImpactGroupIdentifier.NodePressure;

export const infraReliabilityRiskTypes: Record<InfraImpactIds, CheckItem> = {
  [ImpactGroupIdentifier.EolDeprecationOutdated]: {
    label: "Cluster Upgrades",
    color: muiColors.indigo[600] ?? "",
  },
  [ImpactGroupIdentifier.NodePressure]: {
    label: "Node Pressure",
    color: muiColors.blue[400] ?? "",
  },
};

const midnight = new Date();
midnight.setHours(0, 0, 0, 0);

export const MONTH_AGO_WINDOW = {
  from: subDays(midnight, 30).getTime(),
  to: midnight.getTime(),
};
