/* eslint-disable max-lines */
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import cn from "classnames";
import { AccountPlan } from "komodor-types";
import { useAuth0 } from "@auth0/auth0-react";

import { notifyDDError } from "../../../shared/hooks/exceptionManagement";
import { FlexColumns, FlexRows } from "../../common/FlexContainers";
import { Input } from "../../common/Input";
import {
  blackGreyText,
  blueBrand,
  blueForUIText,
  disabledBlue,
  grayAppBackground2,
  offWhite,
  pinkBrand,
} from "../../../Colors";
import { H2Poppins, TextRegular } from "../../common/typography";
import Button from "../../common/controls/Button";
import {
  BackgroundContainer,
  CenteredContainer,
  ErrorText,
  PageBottom,
} from "../Common/common";
import { useCreateNewUserAccount } from "../../../shared/hooks/signup/useCreateNewUserAccount";
import { LinesLoader } from "../../common/loaders/Line";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import KomodorLogoWhite from "../Common/komodor_logo_white.svg?react";
import { LinkButton } from "../../monitorsView/common/styles";
import { LeftSpace } from "../../common/Spaces/leftSpace";
import { LocalStorageItem } from "../../../shared/utils/localStorageSettings";
import { APP_BAR_HEIGHT_IN_REM } from "../../../constants";
import useAnalyticsApi from "../../../shared/context/analyticsProvider";
import {
  useUserMetadata,
  decodeAccessToken,
} from "../../../shared/hooks/useUserMetadata/useUserMetadata";
import { getIsFreemiumAccount } from "../../Freemium/Utils/createFreeAccount";
import { useUserLoginInfo } from "../../../shared/hooks/useUserMetadata/useUserLoginInfo";
import backArrow from "../Common/back-arrow.svg";

import {
  calcValidSuggestions,
  isAccountNameUsable,
  normalizeName,
} from "./helpers";

import { useIsAnonymousUser } from "@/shared/hooks/useIsAnonymousUser";
import { useStateInLocalStorage } from "@/shared/hooks/state/useStateInLocalStorage";
import { useLogout } from "@/components/Logout/useLogout";

const privacyPolicyUrl = "https://komodor.com/privacy-policy/";
styled(KomodorLogoWhite)`
  width: 12.85rem;
  height: 2.18rem;
  margin-top: calc(7.67rem - ${APP_BAR_HEIGHT_IN_REM}rem);
  margin-bottom: 4.5rem;
`;
const SizedCenteredContainer = styled(CenteredContainer)`
  width: 28.57rem;
  height: calc(29.7rem - 5.7rem); /* Total 416px (- padding) */
`;

const ContentContainer = styled(FlexColumns)`
  margin-top: 2.28rem;
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  justify-items: space-between;
  align-items: center;
  gap: 4rem;
`;

const BackButton = styled.button`
  width: 0.913rem;
  height: 1rem;
  background-image: url(${backArrow});
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Title = styled(H2Poppins)`
  width: 17rem;
  text-align: center;
  color: ${blackGreyText};
`;

const PrimaryText = styled(TextRegular)`
  color: ${blackGreyText};
`;

const TeamNameInput = styled(Input)`
  margin-top: 0.28rem;
  width: 100%;
  height: calc(1.2rem); /* == Total height of 40px (- padding - border) */
  padding: 0.57rem;
  border-radius: 4px;

  &.error {
    outline: none;
    border-color: ${pinkBrand};
  }
`;

const Suggestions = styled(FlexRows)`
  height: 1.42rem; /* Keep static height so components don't jump when filling the container */
  align-items: center;
  justify-items: center;
  background-color: ${grayAppBackground2};
  padding: 0.57rem;
  margin-top: 1.14rem;
`;

const SuggestionsContainer = styled.div`
  height: 1.42rem; /* Keep static height so components don't jump when filling the container */
`;

const ContinueButton = styled(Button)`
  width: 100%;

  &.loading {
    background-color: ${blueForUIText};
    opacity: 0.3;
    color: ${disabledBlue};
  }
`;

export const TermsText = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 0.8rem;
  margin-bottom: 1rem;
`;

const Suggestion = styled.span`
  align-items: center;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.85rem;
  margin: 0.2rem;

  color: ${blueBrand};

  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

const CREATION_ERROR_MESSAGE =
  "Something went wrong when creating your account, try another name or contact us.";

const sentEventOfUserOrigin = new LocalStorageItem("sentEventOfUserOrigin");

export const ChooseAccountNameScene: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const user = useUserLoginInfo();
  const logout = useLogout();
  const isAnonymousUser = useIsAnonymousUser();

  const [hasSentEventOfUserOrigin, setHasSentEventOfUserOrigin] =
    useStateInLocalStorage(sentEventOfUserOrigin, "");

  const [errorMessage, setErrorMessage] = useState("");
  const [accountName, setAccountName] = useState("");

  const nameSuggestions = useMemo(() => {
    return calcValidSuggestions(user?.email ?? "", user?.name ?? "");
  }, [user?.email, user?.name]);

  const {
    createNewUserAccount,
    loading: loadingUserAccountCreation,
    error: createUserAccountError,
    doneSuccessfully,
  } = useCreateNewUserAccount();

  const getNonCachedUserMetadata = useCallback(async () => {
    const token = await getAccessTokenSilently({ ignoreCache: true });
    return decodeAccessToken(token);
  }, [getAccessTokenSilently]);
  const isUserFreshlyAuthorizedAfterBeingAnonymous = useCallback(async () => {
    const freshUserMetadata = await getNonCachedUserMetadata();
    return isAnonymousUser && !!freshUserMetadata.accountId;
  }, [getNonCachedUserMetadata, isAnonymousUser]);

  const canContinue = useMemo(() => {
    return (
      (errorMessage === "" || errorMessage === CREATION_ERROR_MESSAGE) &&
      accountName &&
      !loadingUserAccountCreation
    );
  }, [errorMessage, accountName, loadingUserAccountCreation]);

  const { callback: debouncedIsNameUsable } = useDebouncedCallback(
    (newValue: string) => {
      if (newValue === "") {
        setErrorMessage("");
        return;
      }
      const { errorMessage: errMsg } = isAccountNameUsable(newValue);
      setErrorMessage(errMsg);
    },
    300
  );

  const setNormalizedAccountName = useCallback(
    (value: string) => {
      setAccountName(normalizeName(value));
    },
    [setAccountName]
  );

  const analytics = useAnalyticsApi();
  const { accountPlan } = useUserMetadata();

  useEffect(() => {
    setTimeout(() => {
      if (!user?.email) return;
      if (hasSentEventOfUserOrigin === user.email) return;
      analytics.dispatchEventViaBackend(
        AnalyticEvents.Signup.NewUserAccount,
        {
          origin: "self-serve",
          email: user.email,
        },
        true,
        true
      );
      analytics.identifySegment(
        { KomodorFirstUser: true, userId: user?.email },
        "identify",
        user.email,
        true
      );

      try {
        const initialAccountPlan = getIsFreemiumAccount()
          ? AccountPlan.free
          : AccountPlan.trial;
        analytics.identifySegment(
          {
            userId: user?.email,
            accountPlan: initialAccountPlan,
            traits: {
              initialAccountPlan,
            },
          },
          "identify",
          user?.email,
          true
        );
      } catch (error) {
        notifyDDError(error as Error);
      }

      setHasSentEventOfUserOrigin(user.email);
    }, 1000);
  }, [
    accountPlan,
    analytics,
    hasSentEventOfUserOrigin,
    setHasSentEventOfUserOrigin,
    user?.email,
  ]);

  useEffect(() => {
    (async () => {
      if (!createUserAccountError) return;

      // If the user was anonymous and now is authorized, we want to refresh the page even though there was an error
      // This is a workaround for a bug in the backend where the account might have been created but an error is returned
      const userIsAuthorized =
        await isUserFreshlyAuthorizedAfterBeingAnonymous();
      if (userIsAuthorized) {
        window.location.reload();
        return;
      } else {
        setErrorMessage(CREATION_ERROR_MESSAGE);
      }
    })();
  }, [createUserAccountError, isUserFreshlyAuthorizedAfterBeingAnonymous]);

  useEffect(() => {
    if (doneSuccessfully) {
      window.location.reload();
    }
  }, [doneSuccessfully]);

  const setAccountNameFromSuggestion = (value: string) => {
    dispatchEvent(AnalyticEvents.Signup.ChooseAccountName.Click_Suggestion, {
      suggestion: value,
    });
    setErrorMessage("");
    setNormalizedAccountName(value);
  };

  const onAccountNameChange = (newValue: string) => {
    setNormalizedAccountName(newValue);
    debouncedIsNameUsable(newValue);
  };

  const onContinue = async () => {
    if (!canContinue || loadingUserAccountCreation) return;
    dispatchEvent(
      AnalyticEvents.Signup.ChooseAccountName.Click_Continue_Button,
      { accountName }
    );
    await createNewUserAccount(accountName);
  };

  return (
    <BackgroundContainer>
      <SizedCenteredContainer>
        <HeaderContainer>
          <BackButton onClick={logout} />
          <Title data-e2e-selector="signup-wizard-teamname">Team name</Title>
        </HeaderContainer>
        <ContentContainer>
          <PrimaryText>What is the name for your company or team?</PrimaryText>
          <TeamNameInput
            dataE2ESelector={"signup-wizard-teamname-input"}
            onChange={onAccountNameChange}
            value={accountName ?? ""}
            backgroundColor={offWhite}
            className={cn({ error: errorMessage })}
            placeholder={"Team name (at least 3 letters)"}
            autoFocus={true}
            onKeyPress={async (e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                await onContinue();
              }
            }}
          />
          {errorMessage && (
            <ErrorText data-e2e-selector={"signup-wizard-teamname-error"}>
              {errorMessage}
            </ErrorText>
          )}
          <SuggestionsContainer>
            {" "}
            {!isEmpty(nameSuggestions) && (
              <Suggestions>
                <PrimaryText>
                  Suggestions:
                  <LeftSpace spaces={2} />
                </PrimaryText>
                {nameSuggestions.map((sugg) => (
                  <Suggestion
                    data-e2e-selector={"signup-wizard-teamname-suggestion"}
                    onClick={() => setAccountNameFromSuggestion(sugg)}
                    key={sugg}
                  >
                    {sugg}
                  </Suggestion>
                ))}
              </Suggestions>
            )}
          </SuggestionsContainer>
        </ContentContainer>
        <PageBottom>
          <TermsText>
            By creating an account, you agree to Komodor's{" "}
            <LinkButton
              href={privacyPolicyUrl}
              target="_blank"
              fontSize="0.75rem"
            >
              Terms of Use
            </LinkButton>
            {"."}
          </TermsText>
          <ContinueButton
            data-e2e-selector={"signup-wizard-teamname-submit-button"}
            onClick={onContinue}
            disabled={!canContinue}
            className={cn({ loading: loadingUserAccountCreation })}
          >
            {loadingUserAccountCreation ? (
              <LinesLoader marginTop={"0px"} />
            ) : (
              "Continue"
            )}
          </ContinueButton>
        </PageBottom>
      </SizedCenteredContainer>
    </BackgroundContainer>
  );
};
