import { PodLogsDirection, PodLogsStore } from "./types";

import { DurationOption } from "@/components/common/LogsViewer/logsTypes";

export const initialValues = {
  logs: [],
  direction: PodLogsDirection.NewestFirst, // TODO: Yossi to change that to be the opposite
  logsParams: {
    tail: 100,
    sinceNumber: 168,
    sinceUnit: "h" as DurationOption,
    sendSince: false,
    sendTail: true,
  },
  isLoading: true,
};

// currently the store of the live logs only, but once we deprecated the old logs so the name will be OK
export const initialState: PodLogsStore = {
  ...initialValues,
  resetLogs: () => undefined,
  addLogs: () => undefined,
  setDirection: () => undefined,
  setPodLogsParams: () => undefined,
  setIsLoading: () => undefined,
};
