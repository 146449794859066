import { DigestFrequency } from "@/generated/emailDigestService";
import { SnackbarMessage } from "@/components/Settings/email/context/SnackbarMessageContext";

export const DataKeyToLabel = {
  isSubscribed: "Subscribe to the Komodor digest",
  frequency: "Send me the digest",
  scope: {
    clusters: "Digest scope",
  },
  content: {
    failedDeploys: { isEnabled: "Failed Deploys" },
    issues: { isEnabled: "Unhealthy services" },
    reliability: {
      isEnabled: "Reliability violations",
      violations: {
        severities: {
          high: "High Severity Issues",
          medium: "Medium Severity Issues",
          low: "Low Severity Issues",
        },
      },
    },
  },
};

export const frequencySelectOptions = [
  { value: DigestFrequency.Daily, label: "Daily" },
  { value: DigestFrequency.Weekly, label: "Weekly" },
];

export const updateEmailConfigurationsMessage: Record<
  "success" | "failure",
  SnackbarMessage
> = {
  success: {
    message: "Email settings updated successfully",
    color: "info",
  },
  failure: {
    message: "Email settings update failed",
    color: "error",
  },
};
