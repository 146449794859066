import React, { useEffect } from "react";
import { Refresh16 } from "@komodorio/design-system/icons";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { DriftMetricsLoadingStateIds } from "../../consts";

import { WorkloadsMetricsGraph } from "./WorkloadsMetricsGraph";

import { GraphContainerWithStatus } from "@/components/Metrics/GraphContainerWithStatus";
import { MetricsGraphProps, MetricType } from "@/components/Metrics/types";
import { useWorkloadMetricsTabContext } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";

const LoadingRotatingIcon = styled(Refresh16)`
  animation: rotate 2s linear infinite;
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
interface WorkloadsMetricsGraphWithStatusProps {
  loadingStateId: keyof typeof DriftMetricsLoadingStateIds;
  graphProps: MetricsGraphProps;
  error: boolean;
  loadingStates: boolean[];
  isLoading: boolean;
  hasGraphData: boolean;
  linesContent: React.ReactNode[];
  title: string;
  komodorUids: string[];
  type: MetricType;
  syncId: string;
  onEnlargeGraph: (() => void) | undefined;
}

export const WorkloadsMetricsGraphWithStatus: React.FC<
  WorkloadsMetricsGraphWithStatusProps
> = ({
  loadingStateId,
  graphProps,
  error,
  loadingStates,
  isLoading,
  hasGraphData,
  linesContent,
  title,
  komodorUids,
  type,
  syncId,
  onEnlargeGraph,
}) => {
  const { setGraphLoadingState } = useWorkloadMetricsTabContext();
  useEffect(() => {
    loadingStates.forEach((loadingState, index) => {
      setGraphLoadingState(`${loadingStateId}-${index}`, loadingState);
    });
  }, [loadingStates, loadingStateId, setGraphLoadingState]);

  return (
    <GraphContainerWithStatus
      error={error}
      loading={isLoading && !hasGraphData}
      hasGraphData={hasGraphData}
      title={title}
      onEnlargeGraph={onEnlargeGraph}
      uid={undefined}
      info={
        isLoading ? <LoadingRotatingIcon color={muiColors.blue[600]} /> : null
      }
    >
      <WorkloadsMetricsGraph
        komodorUids={komodorUids}
        linesContent={linesContent}
        type={type}
        {...graphProps}
        syncId={syncId}
      />
    </GraphContainerWithStatus>
  );
};
