import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  ACTIONS_ROUTE,
  AGENTS_SETTINGS_ROUTE,
  AUDIT_ROUTE,
  FEATURES_ROUTE,
  INTEGRATIONS_ROUTE,
  IP_WHITELIST_ROUTE,
  POLICIES_ROUTE,
  RELIABILITY_SETTINGS_ROUTE,
  ROLES_ROUTE,
  USAGE_ROUTE,
  USERS_ROUTE,
} from "@/components/routes/routes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useHasPermissions } from "@/shared/hooks/useUserMetadata/rbac";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { Permissions } from "@/shared/constants/permissions";

const SETTINGS_SECTIONS = {
  accessManagement: "ACCESS MANAGEMENT",
  account: "ACCOUNT",
  configurations: "CONFIGURATIONS",
};

interface Page {
  title: string;
  route: string;
  disabled?: boolean;
  permissions?: string[];
  flags?: string[];
  accountFeatures?: string[];
  tooltip?: string;
}

const PAGES: {
  [key: string]: Page[];
} = {
  [SETTINGS_SECTIONS.accessManagement]: [
    {
      title: "Users",
      route: USERS_ROUTE,
      permissions: [Permissions.canManageUsers],
    },
    {
      title: "Roles",
      route: ROLES_ROUTE,
      permissions: [Permissions.canManageUsers],
    },
    {
      title: "Policies",
      route: POLICIES_ROUTE,
      permissions: [Permissions.canManageUsers],
    },
    {
      title: "Actions",
      route: ACTIONS_ROUTE,
      permissions: [Permissions.canManageUsers],
      accountFeatures: ["rbacClusterSync"],
      tooltip:
        "You need to enable the RBAC Cluster Sync feature to view this section",
    },
    {
      title: "IP Whitelist",
      route: IP_WHITELIST_ROUTE,
      permissions: [Permissions.canManageAccountAccess],
    },
  ],
  [SETTINGS_SECTIONS.account]: [
    {
      title: "Usage",
      route: USAGE_ROUTE,
      permissions: [Permissions.canManageUsage],
    },
    {
      title: "Audit",
      route: AUDIT_ROUTE,
      permissions: [Permissions.canViewAudit],
    },
    {
      title: "Agents",
      route: AGENTS_SETTINGS_ROUTE,
      permissions: [Permissions.canManageAgents],
    },
  ],
  [SETTINGS_SECTIONS.configurations]: [
    {
      title: "Health Policies",
      route: RELIABILITY_SETTINGS_ROUTE,
      permissions: [
        "canManageReliabilityPolicies",
        "canManageReliability",
        "canManageMonitors",
      ],
      tooltip:
        "You need at least one of the following permissions to view this section:\n" +
        "‘manage:reliability’ | ’manage:monitors’ | ‘manage:reliability-policies’",
    },
    {
      title: "Integrations",
      route: INTEGRATIONS_ROUTE,
      permissions: [Permissions.canManageIntegrations],
    },
    {
      title: "Features",
      route: FEATURES_ROUTE,
      permissions: [Permissions.canManageFeatures],
    },
  ],
};

export const useSettings = () => {
  const flags = useOverridableFlags();
  const permissions = useHasPermissions();
  const { data: accountFeatures } = useAccountFeatures();
  const location = useLocation();

  const items = useMemo(
    () =>
      Object.entries(PAGES).map(([section, pages]) => ({
        title: section,
        pages: pages.map((page) => ({
          title: page.title,
          route: page.route,
          disabled:
            page.accountFeatures?.some(
              (feature) => !accountFeatures[feature]
            ) ||
            page.permissions?.every((permission) => !permissions[permission]) ||
            page.flags?.some((flag) => !flags[flag]) ||
            false,
          tooltip: page.tooltip,
        })),
      })),
    [accountFeatures, flags, permissions]
  );

  const linkTabs = React.useMemo(() => {
    return items.flatMap((section) => section.pages);
  }, [items]);

  const getTabByRoute = React.useCallback(
    (route: string) => {
      const tab = linkTabs.find((t) => t.route === route);
      if (tab) {
        return tab;
      }

      return linkTabs.find((t) => route.includes(t.route)) || { route: false };
    },
    [linkTabs]
  );

  const currentTab = React.useMemo(
    () => getTabByRoute(location.pathname).route,
    [getTabByRoute, location.pathname]
  );

  return {
    items,
    currentTab,
  };
};
