/* eslint-disable max-lines */
import React from "react";
import Check from "@mui/icons-material/Check";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { Components, Theme } from "@mui/material";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

import { gray, common, pink } from "./colors";
import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    code1: true;
    code1bold: true;
    body3: true;
    body0: true;
    overline2: true;
    overline0: true;
  }
}

// Add "running" alert color option
declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    running: true;
  }
}
declare module "@mui/material/Switch" {
  interface SwitchPropsColorOverrides {
    running: true;
  }
}

// This selector is used to fight specificity of the builtin theme and set the
// size of a button's start and end icons. It is used in the MuiButton
// styleOverrides below.
const startAndEndIconSelector =
  "& .MuiButton-startIcon>*:nth-of-type(1), & .MuiButton-endIcon>*:nth-of-type(1)";

export const components: Components<Omit<Theme, "components">> = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true, // Disable ripples in all buttons
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 500,
        boxSizing: "border-box",
        border: "1px solid transparent",
      },
      sizeLarge: {
        // Total height 40px = 24px + 8px + 8px - 1px*2 border
        // Line height matches height of icon to maintain equal height of
        // buttons with icons and without icons
        lineHeight: "24px",
        padding: "7px 11px", // Compensates for 1px border
        fontSize: "14px",
        [startAndEndIconSelector]: {
          fontSize: "24px",
        },
      },
      sizeMedium: {
        // Total height = 32px
        lineHeight: "16px",
        fontSize: "12px",
        padding: "7px 11px", // Compensates for 1px border
        [startAndEndIconSelector]: {
          fontSize: "16px",
        },
      },
      sizeSmall: {
        // Total height = 24px
        lineHeight: "16px",
        fontSize: "11px",
        padding: "3px 7px", // Compensates for 1px border
        [startAndEndIconSelector]: {
          fontSize: "16px",
        },
      },
      outlined: {
        backgroundColor: common.white,
        borderColor: gray[300],
      },
      textError: {
        color: palette.error["dark"],
      },
    },
    variants: [
      {
        props: { variant: "contained", color: "error" },
        style: {
          backgroundColor: pink[600],
          color: common.white,
          "&:hover": {
            backgroundColor: palette.error["dark"],
          },
        },
      },
    ],
  },

  MuiTypography: {
    defaultProps: {
      variantMapping: {
        code1: "code",
        code1bold: "code",
        body0: "p",
        body3: "p",
      },
    },
  },

  MuiStepConnector: {
    styleOverrides: {
      line: {
        minHeight: "6px",
      },
    },
  },

  MuiStepLabel: {
    styleOverrides: {
      root: {
        padding: "4px 0",
      },
    },
  },

  MuiToggleButtonGroup: {
    defaultProps: {},
    styleOverrides: {
      root: {
        backgroundColor: gray[100],
        padding: "4px",
        gap: "4px",
      },
      grouped: {
        "&.MuiButtonBase-root": {
          // This is used to fight specificity
          borderTopRightRadius: "4px",
          borderTopLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
        },
      },
    },
    variants: [
      {
        props: { size: "small" },
        style: {
          ".MuiSvgIcon-root": {
            fontSize: "12px",
          },
          ".MuiButtonBase-root": {
            padding: "8px",
            height: 28,
          },
        },
      },
      {
        props: { size: "medium" },
        style: {
          ".MuiSvgIcon-root": {
            fontSize: "12px",
          },
          ".MuiButtonBase-root": {
            padding: "10px",
            height: 32,
          },
        },
      },
      {
        props: { size: "large" },
        style: {
          ".MuiSvgIcon-root": {
            fontSize: "24px",
          },
          ".MuiButtonBase-root": {
            padding: "8px",
            height: 40,
          },
        },
      },
    ],
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        lineHeight: 1,
        border: "none",
        "&.Mui-selected": {
          boxShadow: shadows[1],
          backgroundColor: common.white,
          "&:hover": {
            backgroundColor: common.white,
          },
        },
        "&.Mui-disabled": {
          border: "none",
        },
      },
    },
  },

  MuiSwitch: {
    styleOverrides: {
      root: {
        height: "var(--switch-height)",
        width: "calc(var(--switch-height) * 2)",
        padding: 0,

        "& .MuiSwitch-switchBase": {
          padding: 0,
          margin: 2,
          transitionDuration: "250ms",
          "&.Mui-checked": {
            transform: "translateX(var(--switch-height))",
            // Thumb color when checked
            color: common.white,

            "& + .MuiSwitch-track": {
              backgroundColor: palette.primary,
              opacity: 1,
              border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            // Focus thumb button color (when it has keyboard focus)
            color: palette.primary,
            border: `calc(var(--switch-height) / 4) solid ${palette.secondary["contrastText"]}`,
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            // Disabled thumb button color
            color: gray[100],
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 1.0,
          },
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          width: "calc(var(--switch-height) - 4px)",
          height: "calc(var(--switch-height) - 4px)",
        },
        "& .MuiSwitch-track": {
          borderRadius: "calc(var(--switch-height) / 2)",
          backgroundColor: gray[200],
          opacity: 1,
        },
      },
    },
    variants: [
      {
        props: { size: "small" },
        style: {
          "--switch-height": "16px",
        },
      },
      {
        props: { size: "medium" },
        style: {
          "--switch-height": "20px",
        },
      },
    ],
  },

  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
    styleOverrides: {
      tooltip: {
        ...typography.body3,
        background: "rgba(97,97,97,0.9)",
      },
    },
  },

  MuiAlert: {
    defaultProps: {
      iconMapping: {
        // TODO: Change to use Komodor's icons
        error: <WarningAmber fontSize="inherit" />,
        success: <Check fontSize="inherit" color="inherit" />,
        warning: <ErrorOutline fontSize="inherit" />,
        info: <InfoOutlined fontSize="inherit" />,
      },
    },

    styleOverrides: {
      root: {
        // Setting a sane default for the alert's font
        ...typography.body1,
      },
    },

    variants: [
      {
        props: { variant: "outlined" },
        style: {
          backgroundColor: common.white,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: palette.divider,
          padding: 4,
          color: palette.text?.primary,
          lineHeight: "normal",

          "& .MuiAlert-icon": {
            fontSize: "16px",
            padding: 8,
            alignSelf: "flex-start",
            borderRadius: 4,
          },
          "& .MuiAlert-message": {
            padding: "6px",
          },
          "& .MuiAlert-action": {
            marginRight: 0,
            padding: "2px 0 0 0",
          },
        },
      },
      {
        props: { variant: "standard" },
        style: {
          // Set a default border so it can be overridden later
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "transparent",
        },
      },
      {
        props: { severity: "error" },
        style: {
          borderColor: palette.error["main"],
          color: palette.error["dark"],
          "& .MuiAlert-icon": {
            backgroundColor: palette.error["light"],
            color: palette.error["dark"],
          },
        },
      },
      {
        props: { severity: "error", variant: "standard" },
        style: {
          backgroundColor: palette.error["light"],
          borderColor: palette.error["main"],
          color: palette.error["dark"],
        },
      },
      {
        props: { severity: "error", variant: "filled" },
        style: {
          backgroundColor: palette.error["dark"],
          color: palette.error["light"],
          "& .MuiAlert-icon": {
            color: palette.error["light"],
          },
        },
      },
      {
        props: { severity: "warning" },
        style: {
          borderColor: palette.warning["main"],
          color: palette.warning["dark"],
          "& .MuiAlert-icon": {
            backgroundColor: palette.warning["light"],
            color: palette.warning["dark"],
          },
        },
      },
      {
        props: { severity: "warning", variant: "standard" },
        style: {
          backgroundColor: palette.warning["light"],
          color: palette.warning["dark"],
        },
      },
      {
        props: { severity: "warning", variant: "filled" },
        style: {
          backgroundColor: palette.warning["dark"],
          color: palette.warning["light"],
          "& .MuiAlert-icon": {
            color: palette.warning["light"],
          },
        },
      },
      {
        props: { severity: "info" },
        style: {
          color: palette.info["dark"],
          borderColor: palette.info["main"],
          "& .MuiAlert-icon": {
            backgroundColor: palette.info["light"],
            color: palette.info["dark"],
          },
        },
      },
      {
        props: { severity: "info", variant: "standard" },
        style: {
          backgroundColor: palette.info["light"],
          color: palette.info["dark"],
        },
      },
      {
        props: { severity: "info", variant: "filled" },
        style: {
          backgroundColor: palette.info["dark"],
          color: palette.info["light"],
          "& .MuiAlert-icon": {
            color: palette.info["light"],
          },
        },
      },
      {
        props: { severity: "success" },
        style: {
          color: palette.success["dark"],
          borderColor: palette.success["main"],
        },
      },
      {
        props: { severity: "success", variant: "outlined" },
        style: {
          "& .MuiAlert-icon": {
            backgroundColor: palette.success["light"],
            color: palette.success["dark"],
          },
        },
      },
      {
        props: { severity: "success", variant: "standard" },
        style: {
          backgroundColor: palette.success["light"],
          "& .MuiAlert-icon": {
            color: palette.success["dark"],
            backgroundColor: palette.success["light"],
          },
        },
      },
      {
        props: { severity: "success", variant: "filled" },
        style: {
          backgroundColor: palette.success["dark"],
          color: palette.success["light"],
          "& .MuiAlert-icon": {
            color: palette.success["light"],
          },
        },
      },
      {
        props: { color: "running" },
        style: {
          color: palette.running["dark"],
          borderColor: palette.running["main"],
        },
      },
      {
        props: { color: "running", variant: "outlined" },
        style: {
          "& .MuiAlert-icon": {
            backgroundColor: palette.running["light"],
            color: palette.running["dark"],
          },
        },
      },
      {
        props: { color: "running", variant: "standard" },
        style: {
          backgroundColor: palette.running["light"],
          "& .MuiAlert-icon": {
            color: palette.running["dark"],
            backgroundColor: palette.running["light"],
          },
        },
      },
      {
        props: { color: "running", variant: "filled" },
        style: {
          backgroundColor: palette.running["dark"],
          color: palette.running["light"],
          "& .MuiAlert-icon": {
            color: palette.running["light"],
          },
        },
      },
      {
        // This entry is at the end to override certain other settings for the
        // background color of icons
        props: { variant: "filled" },
        style: {
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "transparent",
          "& .MuiAlert-icon": {
            backgroundColor: "transparent",
          },
        },
      },
    ],
  },

  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: gray[200],
      },
    },
  },

  MuiSnackbar: {
    styleOverrides: {
      root: {
        "& .MuiSnackbarContent-root": {
          background: "#2C2C2C",
        },
      },
    },
  },

  MuiSelect: {
    defaultProps: {
      MenuProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      },
    },
  },

  MuiChip: {
    styleOverrides: {
      sizeMedium: {
        // Total height = 24px
        height: "24px",
        lineHeight: "18px",
        fontSize: "12px",
        padding: "3px 0",
      },
      sizeSmall: {
        // Total height = 20px
        height: "20px",
        lineHeight: "16px",
        fontSize: "12px",
        padding: "2px 0",
      },
    },
  },

  MuiLink: {
    styleOverrides: {
      root: {
        cursor: "pointer",
      },
    },
  },

  MuiDataGrid: {
    defaultProps: {
      disableRowSelectionOnClick: true,
      getDetailPanelHeight: () => "auto",
    },
    styleOverrides: {
      root: {
        "& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeaderDraggableContainer":
          {
            outline: "none",
          },
        "&.MuiDataGrid-root, .MuiDataGrid-row, .MuiDataGrid-withBorderColor, .MuiDataGrid-footerContainer":
          {
            borderColor: gray[200],
          },
        "& .MuiDataGrid-row:last-of-type .MuiDataGrid-cell": {
          borderBottom: "none",
        },
      },
      panel: {
        // hide the "Manage columns --> Hide all" button
        "& .MuiDataGrid-panelFooter > button:first-child": {
          display: "none",
        },
      },
    },
  },
};
