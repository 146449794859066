import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const DisabledItemsTitle = styled(Typography)`
  && {
    text-transform: uppercase;
    margin: 10px 0 0;
    font-size: 10px;
  }
`;

export const MenuListContainer = styled.div<{ enableVirtualization: boolean }>`
  padding: ${({ enableVirtualization }) =>
    enableVirtualization ? "0 0px 12px 20px" : "0 20px 12px 20px"};
`;

export const StickyContainer = styled.div<{ $showShadow: boolean }>`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  padding: 12px 20px;
  transition: box-shadow 0.2s;
  box-shadow: ${({ $showShadow }) =>
    $showShadow
      ? "0px 7px 5px -4px rgba(0,0,0,0.05)"
      : "0px 7px 5px -4px rgba(0,0,0,0)"};
`;
