import { number, object, string, InferType, ValidationError } from "yup";

const MAX_HOURS_BACK = 24 * 7 * 30; // 30 days in hours
const MAX_MINUTES_BACK = 60 * MAX_HOURS_BACK; // 30 days in minutes
const MAX_SECONDS_BACK = 60 * 60 * MAX_HOURS_BACK; // 30 days in seconds
const MAX_TAIL = 2_147_483_647; // Max value for a 32-bit signed integer

const schema = object().shape({
  tail: number().min(1).max(MAX_TAIL).required(),
  sinceUnit: string().oneOf(["s", "m", "h"]).required(),
  sinceNumber: number()
    .min(1)
    .when("sinceUnit", ([sinceUnit]: string[], schema) => {
      switch (sinceUnit) {
        case "s":
          return schema.max(
            MAX_SECONDS_BACK,
            `Since cannot be more than 30 days in seconds (${MAX_SECONDS_BACK})`
          );
        case "m":
          return schema.max(
            MAX_MINUTES_BACK,
            `Since cannot be more than 30 days in minutes (${MAX_MINUTES_BACK})`
          );
        case "h":
          return schema.max(
            MAX_HOURS_BACK,
            `Since cannot be more than 30 days in hours (${MAX_HOURS_BACK})`
          );
        default:
          return schema;
      }
    })
    .required(),
});

export type LogOptionsForm = InferType<typeof schema>;
export type ErrorsMap = Record<keyof LogOptionsForm, string>;

export const validateLogsOptions = async (
  data: Partial<LogOptionsForm>
): Promise<ErrorsMap> => {
  try {
    await schema.validate(data, {
      abortEarly: false,
    });

    return {} as ErrorsMap;
  } catch (err) {
    const validationError = err as ValidationError;
    return validationError.inner.reduce(
      (allErrors, currentError) => ({
        ...allErrors,
        [currentError.path || ""]: currentError.message,
      }),
      {} as ErrorsMap
    );
  }
};
