import React from "react";
import styled from "styled-components";

import { ViolationTableWithContext } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/ViolationTableWithContext";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";

const StyledViolationTable = styled(ViolationTableWithContext)`
  padding: 16px;
  background: white;
  border-radius: 4px;
`;

const {
  groups: { singleGroup: singleGroupArialLabel },
} = reliabilityArialLabels;

export const GroupedViolationsByNone: React.FC = () => {
  return (
    <div aria-label={`${singleGroupArialLabel} violations table`}>
      <StyledViolationTable violationsGroupBy="None" uid={"GroupedByNone"} />
    </div>
  );
};
