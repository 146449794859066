import React from "react";

import { useSummaryItems } from "./hooks/useSummaryItems";

import { SinglePointOfFailureSupportingData } from "@/generated/reliabilityApi";
import { StatsList } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/StatsList";

interface Props {
  supportingData: SinglePointOfFailureSupportingData | undefined;
}

export const WhatHappenedSummary: React.FC<Props> = ({ supportingData }) => {
  const { unavailableMinutes, correlatedIssues } = supportingData || {};

  const summaryItems = useSummaryItems(correlatedIssues, unavailableMinutes);

  if (!supportingData || !summaryItems.length) return null;

  return <StatsList stats={summaryItems} />;
};
