import { useMemo } from "react";
import { groupBy } from "lodash";

import { MonitorType } from "@/generated/monitorsApi";
import { CorrelatedIssueSupportingData } from "@/generated/reliabilityApi";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { Stat } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/StatsList";

const {
  violationDrawer: {
    singlePointOfFailure: {
      whatHappenedSummary: {
        availabilityIssue: availabilityIssueAriaLabel,
        nodeTerminations: nodeTerminationsAriaLabel,
        unhealthyMinutes: unhealthyMinutesAriaLabel,
      },
    },
  },
} = reliabilityArialLabels;

export const useSummaryItems = (
  correlatedIssues: CorrelatedIssueSupportingData[] | undefined,
  unavailableMinutes: number | undefined
) =>
  useMemo<Stat[]>(() => {
    const groups = groupBy(correlatedIssues, "issueType");

    return [
      ...(groups[MonitorType.Availability]?.length
        ? [
            {
              label: "Availability issues",
              value: groups[MonitorType.Availability].length,
              ariaLabel: availabilityIssueAriaLabel,
            },
          ]
        : []),
      ...(groups[MonitorType.Node]?.length
        ? [
            {
              label: "Node terminations",
              value: groups[MonitorType.Node].length,
              ariaLabel: nodeTerminationsAriaLabel,
            },
          ]
        : []),
      ...(unavailableMinutes && unavailableMinutes > 0
        ? [
            {
              label: "Service unavailable",
              value: `${unavailableMinutes?.toLocaleString()} min`,
              ariaLabel: unhealthyMinutesAriaLabel,
            },
          ]
        : []),
    ];
  }, [correlatedIssues, unavailableMinutes]);
