export const updateAgentVersionWarningText =
  "Metrics data is required to start using the Cost Optimization feature. Metrics collection may not be enabled, or there might not be enough data received yet to provide Cost Optimization suggestions.";

export const updateNetworkMapperWarningText =
  "Network mapper is required for the Network Map feature. Please update your agent to enable the mapper.";

export const updateAgentVersionInfoText =
  "It looks like some of your clusters do not support the Cost Optimization feature. Please update your agent to enable metrics.";

export const updateAgentDetailsTitle =
  "The following clusters require an agent update:";

export const updateArgoWorkflowsWarningText =
  "This cluster does not support Argo Workflows. Please update your agent to enable the feature.";

export const updateAgentToEnableMetrics =
  "Your current agent version doesn't grant access to metrics. To use this feature, update your agent";

export const updateAgentToEnableRBACClusterSync =
  "An agent update is required for some of the clusters to support this feature";
