import { Entity } from "@/generated/addonsApi";

export const ENTITY_TABLE_LABELS: Record<Entity, string> = {
  [Entity.Certificate]: "certificates",
  [Entity.Controller]: "controllers",
  [Entity.ExternalDnsOperator]: "external DNSs",
  [Entity.WorkflowDag]: "workflows",
  [Entity.WorkflowPod]: "workflows",
  [Entity.WorkflowRun]: "workflows",
  [Entity.AutoScaler]: "auto scalers",
};
