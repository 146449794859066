import { useMemo } from "react";

import { HighlightedLines } from "../../logsTypes";

import { HighlightTypes } from "@/components/common/HighlightedText";

type Props = {
  scrollIndex: number | undefined;
  highlightedLines: HighlightedLines[];
  cumulativeMatchCount: number[];
};

export const useFullyHighlightedLines = ({
  scrollIndex,
  highlightedLines,
  cumulativeMatchCount,
}: Props) => {
  return useMemo(() => {
    const realScrollIndex = (scrollIndex ?? 1) - 1;
    return highlightedLines.map((line, i) => {
      if (
        realScrollIndex >= (cumulativeMatchCount[i - 1] ?? 0) &&
        realScrollIndex < cumulativeMatchCount[i]
      ) {
        return {
          ...line,
          parts: line.parts.map((part) => {
            if (
              typeof part.matchIndex === "number" &&
              realScrollIndex ===
                (cumulativeMatchCount[i - 1] ?? 0) + part.matchIndex
            ) {
              return { ...part, type: HighlightTypes.SearchedWord };
            }
            return part;
          }),
        };
      }
      return line;
    });
  }, [scrollIndex, highlightedLines, cumulativeMatchCount]);
};
