import React from "react";
import IconButton from "@mui/material/IconButton";
import { ContextMenu } from "@komodorio/design-system/komodor-ui";
import { Link } from "react-router-dom";

import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { ProfileIcon } from "@/components/ProfileIcon/ProfileIcon";
import { AriaLabels } from "@/shared/config/ariaLabels";
import {
  GetKubeconfigItem,
  LogOutItem,
} from "@/components/TopBar/buttons/profileButtonComponents";
import { createNameComponent } from "@/components/TopBar/buttons/profileButtonHelpers";
import {
  USER_API_KEYS_ROUTE,
  USER_EMAIL_SETTINGS_ROUTE,
  USER_GET_KUBECONFIG_ROUTE,
  USER_LOGOUT_ROUTE,
  USER_PROFILE_ROUTE,
} from "@/components/routes/routes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useUserLoginInfo } from "@/shared/hooks/useUserMetadata/useUserLoginInfo";

export const ProfileButton: React.FC = () => {
  const { userId } = useUserMetadata();
  const loginInfo = useUserLoginInfo();
  const { data: user } = useGetUserById({ id: userId });
  const { displayName, email } = user ?? {};
  const { showUserEmailSettings } = useOverridableFlags();

  const { TopBar } = AriaLabels;

  const menuItems = React.useMemo(() => {
    return [
      {
        title: "Name",
        route: "",
        CustomComponent: createNameComponent({
          email: email || loginInfo?.email,
          displayName: displayName || loginInfo?.name,
        }),
        disabled: true,
      },
      {
        title: "Profile",
        route: USER_PROFILE_ROUTE,
      },
      {
        title: "Email Settings",
        route: USER_EMAIL_SETTINGS_ROUTE,
        disabled: !showUserEmailSettings,
        hide: !showUserEmailSettings,
        ariaLabel: TopBar.emailSettings,
      },
      {
        title: "API Keys",
        route: USER_API_KEYS_ROUTE,
        divider: true,
      },
      {
        title: "Get Kubeconfig file...",
        route: USER_GET_KUBECONFIG_ROUTE,
        CustomComponent: GetKubeconfigItem,
        divider: true,
      },
      {
        title: LogOutItem,
        route: USER_LOGOUT_ROUTE,
        ariaLabel: TopBar.innerButtons.logOut,
      },
    ].filter((item) => !item.hide);
  }, [
    email,
    loginInfo?.email,
    loginInfo?.name,
    displayName,
    showUserEmailSettings,
    TopBar.emailSettings,
    TopBar.innerButtons.logOut,
  ]);

  return (
    <ContextMenu
      tooltip="User settings"
      id="user-settings"
      items={menuItems}
      LinkComponent={Link}
      buttonComponent={IconButton}
      ariaLabels={{
        button: TopBar.ProfileButton,
        menu: TopBar.ProfileMenu,
      }}
    >
      <ProfileIcon />
    </ContextMenu>
  );
};
