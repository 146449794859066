/* tslint:disable */
/* eslint-disable */
/**
 * Workspaces API
 * An API that exposes workspaces functionality for the Komodor platform
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        /**
 * 
 * @export
 * @interface AdditionalInfo
 */
export interface AdditionalInfo {
    /**
     * Indicates whether metrics are installed on the cluster
     * @type {boolean}
     * @memberof AdditionalInfo
     */
    'isMetricsInstalled': boolean;
    /**
     * Indicates whether the cluster will deprecate
     * @type {boolean}
     * @memberof AdditionalInfo
     */
    'approachingEol'?: boolean;
    /**
     * Indicates whether a deprecated cluster is detected
     * @type {boolean}
     * @memberof AdditionalInfo
     */
    'reachedEol'?: boolean;
    /**
     * Indicates whether agent version supports pods statuses
     * @type {boolean}
     * @memberof AdditionalInfo
     */
    'isPodsEnabled': boolean;
    /**
     * The chart version of the cluster
     * @type {string}
     * @memberof AdditionalInfo
     */
    'chartVersion': string;
}
        /**
 * 
 * @export
 * @interface BaselineContainerAttributeObject
 */
export interface BaselineContainerAttributeObject {
    /**
     * 
     * @type {boolean}
     * @memberof BaselineContainerAttributeObject
     */
    'isDiffAcrossTargetServices'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaselineContainerAttributeObject
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {object}
     * @memberof BaselineContainerAttributeObject
     */
    'value'?: object;
}
        /**
 * 
 * @export
 * @interface BaselineContainerAttributeString
 */
export interface BaselineContainerAttributeString {
    /**
     * 
     * @type {boolean}
     * @memberof BaselineContainerAttributeString
     */
    'isDiffAcrossTargetServices'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaselineContainerAttributeString
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaselineContainerAttributeString
     */
    'value'?: string;
}
        /**
 * 
 * @export
 * @interface ClusterFilterData
 */
export interface ClusterFilterData {
    /**
     * 
     * @type {string}
     * @memberof ClusterFilterData
     */
    'name': string;
    /**
     * 
     * @type {Array<ClusterFilterDataValuesInner>}
     * @memberof ClusterFilterData
     */
    'values': Array<ClusterFilterDataValuesInner>;
}
        /**
 * 
 * @export
 * @interface ClusterFilterDataValuesInner
 */
export interface ClusterFilterDataValuesInner {
    /**
     * 
     * @type {string}
     * @memberof ClusterFilterDataValuesInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ClusterFilterDataValuesInner
     */
    'count': number;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ClusterProviderType = {
    Aws: 'AWS',
    Gcp: 'GCP',
    Azure: 'AZURE',
    Kubernetes: 'KUBERNETES',
    Openshift: 'OPENSHIFT',
    Vmware: 'VMWARE',
    Oracle: 'ORACLE',
    Vcluster: 'VCLUSTER',
    K3S: 'K3S',
    Rancher: 'RANCHER'
} as const;

export type ClusterProviderType = typeof ClusterProviderType[keyof typeof ClusterProviderType];


        /**
 * 
 * @export
 * @interface ClusterSummary
 */
export interface ClusterSummary {
    /**
     * The unique identifier for the cluster summary
     * @type {string}
     * @memberof ClusterSummary
     */
    'id': string;
    /**
     * The account ID related to the cluster
     * @type {string}
     * @memberof ClusterSummary
     */
    'accountId'?: string;
    /**
     * The name of the cluster
     * @type {string}
     * @memberof ClusterSummary
     */
    'clusterName': string;
    /**
     * The provider of the cluster
     * @type {string}
     * @memberof ClusterSummary
     */
    'provider': string;
    /**
     * The version of Kubernetes running on the cluster
     * @type {string}
     * @memberof ClusterSummary
     */
    'k8sVersion': string;
    /**
     * Indicates whether the cluster is inactive
     * @type {boolean}
     * @memberof ClusterSummary
     */
    'inactive': boolean;
    /**
     * The timestamp when the cluster was last seen alive
     * @type {string}
     * @memberof ClusterSummary
     */
    'lastSeenAlive': string;
    /**
     * 
     * @type {NodeStatus}
     * @memberof ClusterSummary
     */
    'nodes': NodeStatus;
    /**
     * 
     * @type {PodData}
     * @memberof ClusterSummary
     */
    'pods': PodData;
    /**
     * 
     * @type {ResourceUtilization}
     * @memberof ClusterSummary
     */
    'resources'?: ResourceUtilization;
    /**
     * 
     * @type {ServiceData}
     * @memberof ClusterSummary
     */
    'services': ServiceData;
    /**
     * 
     * @type {JobsData}
     * @memberof ClusterSummary
     */
    'jobs': JobsData;
    /**
     * 
     * @type {number}
     * @memberof ClusterSummary
     */
    'potentialSavings': number;
    /**
     * 
     * @type {ViolationStatus}
     * @memberof ClusterSummary
     */
    'violations': ViolationStatus;
    /**
     * 
     * @type {UnhealthyWorkloadsData}
     * @memberof ClusterSummary
     */
    'unhealthyWorkloads': UnhealthyWorkloadsData;
    /**
     * 
     * @type {AdditionalInfo}
     * @memberof ClusterSummary
     */
    'additionalInfo': AdditionalInfo;
    /**
     * 
     * @type {string}
     * @memberof ClusterSummary
     */
    'region': string;
}
        /**
 * 
 * @export
 * @interface ClusterSummaryByNameResponse
 */
export interface ClusterSummaryByNameResponse {
    /**
     * 
     * @type {ClusterSummary}
     * @memberof ClusterSummaryByNameResponse
     */
    'data': ClusterSummary;
}
        /**
 * 
 * @export
 * @interface ClusterWorkspace
 */
export interface ClusterWorkspace {
    /**
     * 
     * @type {string}
     * @memberof ClusterWorkspace
     */
    'clusterName': string;
}
        /**
 * 
 * @export
 * @interface ClustersFilters
 */
export interface ClustersFilters {
    /**
     * 
     * @type {Array<ClusterFilterData>}
     * @memberof ClustersFilters
     */
    'values'?: Array<ClusterFilterData>;
}
        /**
 * 
 * @export
 * @interface ClustersFiltersBody
 */
export interface ClustersFiltersBody {
    /**
     * 
     * @type {CommonClustersFilters}
     * @memberof ClustersFiltersBody
     */
    'filter'?: CommonClustersFilters;
}
        /**
 * 
 * @export
 * @interface ClustersFiltersResponse
 */
export interface ClustersFiltersResponse {
    /**
     * 
     * @type {ClustersFilters}
     * @memberof ClustersFiltersResponse
     */
    'data': ClustersFilters;
}
        /**
 * 
 * @export
 * @interface ClustersGroupWorkspace
 */
export interface ClustersGroupWorkspace {
    /**
     * A list of clusters, returned on single workspace API
     * @type {Array<string>}
     * @memberof ClustersGroupWorkspace
     */
    'clusters'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClustersGroupWorkspace
     */
    'patterns': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ClustersGroupWorkspace
     */
    'totalClusters': number;
    /**
     * 
     * @type {number}
     * @memberof ClustersGroupWorkspace
     */
    'authorizedClusters': number;
}
        /**
 * 
 * @export
 * @interface ClustersSummaryBody
 */
export interface ClustersSummaryBody {
    /**
     * 
     * @type {ClustersSummaryFilters}
     * @memberof ClustersSummaryBody
     */
    'filter'?: ClustersSummaryFilters;
}
        /**
 * 
 * @export
 * @interface ClustersSummaryFilters
 */
export interface ClustersSummaryFilters {
    /**
     * Sort order for the results
     * @type {string}
     * @memberof ClustersSummaryFilters
     */
    'sort'?: string;
    /**
     * Limit the number of results returned
     * @type {number}
     * @memberof ClustersSummaryFilters
     */
    'limit'?: number;
    /**
     * Offset for the results returned
     * @type {number}
     * @memberof ClustersSummaryFilters
     */
    'offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClustersSummaryFilters
     */
    'clusterName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClustersSummaryFilters
     */
    'provider'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClustersSummaryFilters
     */
    'k8sVersion'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ClustersSummaryFilters
     */
    'includeDisconnected'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClustersSummaryFilters
     */
    'clusterNames'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface ClustersSummaryResponse
 */
export interface ClustersSummaryResponse {
    /**
     * 
     * @type {Array<ClusterSummary>}
     * @memberof ClustersSummaryResponse
     */
    'data': Array<ClusterSummary>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof ClustersSummaryResponse
     */
    'meta'?: ResponseMeta;
}
        /**
 * 
 * @export
 * @interface CommonClustersFilters
 */
export interface CommonClustersFilters {
    /**
     * 
     * @type {string}
     * @memberof CommonClustersFilters
     */
    'clusterName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonClustersFilters
     */
    'provider'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonClustersFilters
     */
    'k8sVersion'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CommonClustersFilters
     */
    'includeDisconnected'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonClustersFilters
     */
    'clusterNames'?: Array<string>;
}
        /**
 * 
 * @export
 * @enum {string}
 */

export const ComparisonAttribute = {
    Image: 'image',
    EnvVars: 'envVars',
    LivenessProbe: 'livenessProbe',
    ReadinessProbe: 'readinessProbe',
    StartupProbe: 'startupProbe',
    MemoryRequests: 'memoryRequests',
    MemoryLimits: 'memoryLimits',
    CpuRequests: 'cpuRequests',
    CpuLimits: 'cpuLimits'
} as const;

export type ComparisonAttribute = typeof ComparisonAttribute[keyof typeof ComparisonAttribute];


        /**
 * 
 * @export
 * @interface DeleteWorkspaceResponse
 */
export interface DeleteWorkspaceResponse {
    /**
     * the status of the deletion operation, success or failure
     * @type {boolean}
     * @memberof DeleteWorkspaceResponse
     */
    'status': boolean;
}
        /**
 * 
 * @export
 * @interface JobsData
 */
export interface JobsData {
    /**
     * Total number of jobs in the cluster
     * @type {number}
     * @memberof JobsData
     */
    'count': number;
    /**
     * 
     * @type {KindComponent}
     * @memberof JobsData
     */
    'green': KindComponent;
    /**
     * 
     * @type {KindComponent}
     * @memberof JobsData
     */
    'gray': KindComponent;
    /**
     * 
     * @type {KindComponent}
     * @memberof JobsData
     */
    'red': KindComponent;
}
        /**
 * 
 * @export
 * @interface KindComponent
 */
export interface KindComponent {
    /**
     * 
     * @type {Array<SingleKind>}
     * @memberof KindComponent
     */
    'kinds': Array<SingleKind>;
    /**
     * The count of the component
     * @type {number}
     * @memberof KindComponent
     */
    'count': number;
}
        /**
 * 
 * @export
 * @interface LabelsWorkspace
 */
export interface LabelsWorkspace {
    /**
     * 
     * @type {Array<string>}
     * @memberof LabelsWorkspace
     */
    'labels': Array<string>;
}
        /**
 * 
 * @export
 * @interface NamespacesWorkspace
 */
export interface NamespacesWorkspace {
    /**
     * 
     * @type {Array<string>}
     * @memberof NamespacesWorkspace
     */
    'namespaces': Array<string>;
}
        /**
 * 
 * @export
 * @interface NodeStatus
 */
export interface NodeStatus {
    /**
     * Total number of nodes in the cluster
     * @type {number}
     * @memberof NodeStatus
     */
    'count': number;
    /**
     * Number of nodes ready in the cluster
     * @type {number}
     * @memberof NodeStatus
     */
    'ready': number;
    /**
     * Number of nodes not ready in the cluster
     * @type {number}
     * @memberof NodeStatus
     */
    'notReady': number;
}
        /**
 * 
 * @export
 * @interface PodData
 */
export interface PodData {
    /**
     * 
     * @type {ReasonComponent}
     * @memberof PodData
     */
    'green': ReasonComponent;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof PodData
     */
    'yellow': ReasonComponent;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof PodData
     */
    'red': ReasonComponent;
    /**
     * 
     * @type {number}
     * @memberof PodData
     */
    'count': number;
}
        /**
 * 
 * @export
 * @interface ReasonComponent
 */
export interface ReasonComponent {
    /**
     * Total number in reason
     * @type {number}
     * @memberof ReasonComponent
     */
    'count': number;
    /**
     * 
     * @type {Array<SingleReason>}
     * @memberof ReasonComponent
     */
    'reasons': Array<SingleReason>;
}
        /**
 * 
 * @export
 * @interface ResolveClustersRequest
 */
export interface ResolveClustersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResolveClustersRequest
     */
    'patterns': Array<string>;
}
        /**
 * 
 * @export
 * @interface ResolvedClustersResponse
 */
export interface ResolvedClustersResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResolvedClustersResponse
     */
    'clusters': Array<string>;
}
        /**
 * 
 * @export
 * @interface ResourceComponent
 */
export interface ResourceComponent {
    /**
     * Allocated resource
     * @type {number}
     * @memberof ResourceComponent
     */
    'allocated': number;
    /**
     * Total resource capacity
     * @type {number}
     * @memberof ResourceComponent
     */
    'capacity': number;
    /**
     * Resource utilization percentage
     * @type {number}
     * @memberof ResourceComponent
     */
    'percentage': number;
}
        /**
 * 
 * @export
 * @interface ResourceUtilization
 */
export interface ResourceUtilization {
    /**
     * 
     * @type {ResourceComponent}
     * @memberof ResourceUtilization
     */
    'cpu': ResourceComponent;
    /**
     * 
     * @type {ResourceComponent}
     * @memberof ResourceUtilization
     */
    'memory': ResourceComponent;
}
        /**
 * 
 * @export
 * @interface ResponseMeta
 */
export interface ResponseMeta {
    /**
     * The offset of the current page
     * @type {number}
     * @memberof ResponseMeta
     */
    'nextOffset': number;
    /**
     * Indicates if there are more pages
     * @type {boolean}
     * @memberof ResponseMeta
     */
    'hasMore': boolean;
    /**
     * Total number of items
     * @type {number}
     * @memberof ResponseMeta
     */
    'total'?: number;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonRequest
 */
export interface ServiceComparisonRequest {
    /**
     * The baseline service komodor uid to compare
     * @type {string}
     * @memberof ServiceComparisonRequest
     */
    'baselineServiceKomodorUid': string;
    /**
     * The target services komodor uids to compare
     * @type {Array<string>}
     * @memberof ServiceComparisonRequest
     */
    'targetServicesKomodorUid'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponse
 */
export interface ServiceComparisonResponse {
    /**
     * 
     * @type {ServiceComparisonResponseBaselineService}
     * @memberof ServiceComparisonResponse
     */
    'baselineService': ServiceComparisonResponseBaselineService;
    /**
     * 
     * @type {Array<ServiceComparisonResponseTargetServicesInner>}
     * @memberof ServiceComparisonResponse
     */
    'targetServices'?: Array<ServiceComparisonResponseTargetServicesInner>;
    /**
     * 
     * @type {Array<ServiceComparisonResponseContainersInner>}
     * @memberof ServiceComparisonResponse
     */
    'containers'?: Array<ServiceComparisonResponseContainersInner>;
}
        /**
 * The baseline service data
 * @export
 * @interface ServiceComparisonResponseBaselineService
 */
export interface ServiceComparisonResponseBaselineService {
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseBaselineService
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseBaselineService
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseBaselineService
     */
    'name': string;
    /**
     * 
     * @type {ServiceComparisonResponseBaselineServiceDesiredReplicas}
     * @memberof ServiceComparisonResponseBaselineService
     */
    'desiredReplicas'?: ServiceComparisonResponseBaselineServiceDesiredReplicas;
    /**
     * 
     * @type {ServiceComparisonResponseBaselineServiceLabels}
     * @memberof ServiceComparisonResponseBaselineService
     */
    'labels': ServiceComparisonResponseBaselineServiceLabels;
    /**
     * 
     * @type {ServiceComparisonResponseBaselineServiceContainersNames}
     * @memberof ServiceComparisonResponseBaselineService
     */
    'containersNames': ServiceComparisonResponseBaselineServiceContainersNames;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponseBaselineServiceContainersNames
 */
export interface ServiceComparisonResponseBaselineServiceContainersNames {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseBaselineServiceContainersNames
     */
    'isDiffAcrossTargetServices': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseBaselineServiceContainersNames
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceComparisonResponseBaselineServiceContainersNames
     */
    'value'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponseBaselineServiceDesiredReplicas
 */
export interface ServiceComparisonResponseBaselineServiceDesiredReplicas {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseBaselineServiceDesiredReplicas
     */
    'isDiffAcrossTargetServices': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseBaselineServiceDesiredReplicas
     */
    'isEmpty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceComparisonResponseBaselineServiceDesiredReplicas
     */
    'value'?: number;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponseBaselineServiceLabels
 */
export interface ServiceComparisonResponseBaselineServiceLabels {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseBaselineServiceLabels
     */
    'isDiffAcrossTargetServices': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseBaselineServiceLabels
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServiceComparisonResponseBaselineServiceLabels
     */
    'value'?: { [key: string]: string; };
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponseContainersInner
 */
export interface ServiceComparisonResponseContainersInner {
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseContainersInner
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseContainersInner
     */
    'isDiff'?: boolean;
    /**
     * 
     * @type {ServiceComparisonResponseContainersInnerBaselineServiceContainer}
     * @memberof ServiceComparisonResponseContainersInner
     */
    'baselineServiceContainer': ServiceComparisonResponseContainersInnerBaselineServiceContainer;
    /**
     * 
     * @type {Array<ServiceComparisonResponseContainersInnerTargetServicesContainerInner>}
     * @memberof ServiceComparisonResponseContainersInner
     */
    'targetServicesContainer'?: Array<ServiceComparisonResponseContainersInnerTargetServicesContainerInner>;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponseContainersInnerBaselineServiceContainer
 */
export interface ServiceComparisonResponseContainersInnerBaselineServiceContainer {
    /**
     * 
     * @type {BaselineContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerBaselineServiceContainer
     */
    'image': BaselineContainerAttributeString;
    /**
     * 
     * @type {BaselineContainerAttributeObject}
     * @memberof ServiceComparisonResponseContainersInnerBaselineServiceContainer
     */
    'envVars': BaselineContainerAttributeObject;
    /**
     * 
     * @type {BaselineContainerAttributeObject}
     * @memberof ServiceComparisonResponseContainersInnerBaselineServiceContainer
     */
    'livenessProbe': BaselineContainerAttributeObject;
    /**
     * 
     * @type {BaselineContainerAttributeObject}
     * @memberof ServiceComparisonResponseContainersInnerBaselineServiceContainer
     */
    'readinessProbe': BaselineContainerAttributeObject;
    /**
     * 
     * @type {BaselineContainerAttributeObject}
     * @memberof ServiceComparisonResponseContainersInnerBaselineServiceContainer
     */
    'startupProbe': BaselineContainerAttributeObject;
    /**
     * 
     * @type {BaselineContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerBaselineServiceContainer
     */
    'memoryRequests': BaselineContainerAttributeString;
    /**
     * 
     * @type {BaselineContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerBaselineServiceContainer
     */
    'memoryLimits': BaselineContainerAttributeString;
    /**
     * 
     * @type {BaselineContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerBaselineServiceContainer
     */
    'cpuRequests': BaselineContainerAttributeString;
    /**
     * 
     * @type {BaselineContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerBaselineServiceContainer
     */
    'cpuLimits': BaselineContainerAttributeString;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponseContainersInnerTargetServicesContainerInner
 */
export interface ServiceComparisonResponseContainersInnerTargetServicesContainerInner {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'isContainerExists': boolean;
    /**
     * 
     * @type {TargetContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'image': TargetContainerAttributeString;
    /**
     * 
     * @type {TargetContainerAttributeObject}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'envVars': TargetContainerAttributeObject;
    /**
     * 
     * @type {TargetContainerAttributeObject}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'livenessProbe': TargetContainerAttributeObject;
    /**
     * 
     * @type {TargetContainerAttributeObject}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'readinessProbe': TargetContainerAttributeObject;
    /**
     * 
     * @type {TargetContainerAttributeObject}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'startupProbe': TargetContainerAttributeObject;
    /**
     * 
     * @type {TargetContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'memoryRequests': TargetContainerAttributeString;
    /**
     * 
     * @type {TargetContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'memoryLimits': TargetContainerAttributeString;
    /**
     * 
     * @type {TargetContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'cpuRequests': TargetContainerAttributeString;
    /**
     * 
     * @type {TargetContainerAttributeString}
     * @memberof ServiceComparisonResponseContainersInnerTargetServicesContainerInner
     */
    'cpuLimits': TargetContainerAttributeString;
}
        /**
 * The target services comparison data
 * @export
 * @interface ServiceComparisonResponseTargetServicesInner
 */
export interface ServiceComparisonResponseTargetServicesInner {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesInner
     */
    'isDiff': boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseTargetServicesInner
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseTargetServicesInner
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceComparisonResponseTargetServicesInner
     */
    'name': string;
    /**
     * 
     * @type {ServiceComparisonResponseTargetServicesInnerDesiredReplicas}
     * @memberof ServiceComparisonResponseTargetServicesInner
     */
    'desiredReplicas': ServiceComparisonResponseTargetServicesInnerDesiredReplicas;
    /**
     * 
     * @type {ServiceComparisonResponseTargetServicesInnerLabels}
     * @memberof ServiceComparisonResponseTargetServicesInner
     */
    'labels': ServiceComparisonResponseTargetServicesInnerLabels;
    /**
     * 
     * @type {ServiceComparisonResponseTargetServicesInnerContainersNames}
     * @memberof ServiceComparisonResponseTargetServicesInner
     */
    'containersNames': ServiceComparisonResponseTargetServicesInnerContainersNames;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponseTargetServicesInnerContainersNames
 */
export interface ServiceComparisonResponseTargetServicesInnerContainersNames {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesInnerContainersNames
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesInnerContainersNames
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceComparisonResponseTargetServicesInnerContainersNames
     */
    'value'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponseTargetServicesInnerDesiredReplicas
 */
export interface ServiceComparisonResponseTargetServicesInnerDesiredReplicas {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesInnerDesiredReplicas
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesInnerDesiredReplicas
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceComparisonResponseTargetServicesInnerDesiredReplicas
     */
    'value'?: number;
}
        /**
 * 
 * @export
 * @interface ServiceComparisonResponseTargetServicesInnerLabels
 */
export interface ServiceComparisonResponseTargetServicesInnerLabels {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesInnerLabels
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceComparisonResponseTargetServicesInnerLabels
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServiceComparisonResponseTargetServicesInnerLabels
     */
    'value'?: { [key: string]: string; };
}
        /**
 * 
 * @export
 * @interface ServiceData
 */
export interface ServiceData {
    /**
     * Total number of services in the cluster
     * @type {number}
     * @memberof ServiceData
     */
    'count': number;
    /**
     * 
     * @type {KindComponent}
     * @memberof ServiceData
     */
    'unhealthy': KindComponent;
    /**
     * 
     * @type {KindComponent}
     * @memberof ServiceData
     */
    'healthy': KindComponent;
}
        /**
 * 
 * @export
 * @interface ServiceIdWorkspace
 */
export interface ServiceIdWorkspace {
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceIdWorkspace
     */
    'serviceIds'?: Array<string>;
}
        /**
 * 
 * @export
 * @interface SingleKind
 */
export interface SingleKind {
    /**
     * 
     * @type {string}
     * @memberof SingleKind
     */
    'kind': string;
    /**
     * 
     * @type {number}
     * @memberof SingleKind
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof SingleKind
     */
    'reason': string;
}
        /**
 * 
 * @export
 * @interface SingleReason
 */
export interface SingleReason {
    /**
     * 
     * @type {string}
     * @memberof SingleReason
     */
    'reason': string;
    /**
     * 
     * @type {number}
     * @memberof SingleReason
     */
    'count': number;
}
        /**
 * 
 * @export
 * @interface TargetContainerAttributeObject
 */
export interface TargetContainerAttributeObject {
    /**
     * 
     * @type {boolean}
     * @memberof TargetContainerAttributeObject
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TargetContainerAttributeObject
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {object}
     * @memberof TargetContainerAttributeObject
     */
    'value'?: object;
}
        /**
 * 
 * @export
 * @interface TargetContainerAttributeString
 */
export interface TargetContainerAttributeString {
    /**
     * 
     * @type {boolean}
     * @memberof TargetContainerAttributeString
     */
    'isDiff': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TargetContainerAttributeString
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof TargetContainerAttributeString
     */
    'value'?: string;
}
        /**
 * 
 * @export
 * @interface UnhealthyWorkloadsData
 */
export interface UnhealthyWorkloadsData {
    /**
     * 
     * @type {number}
     * @memberof UnhealthyWorkloadsData
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof UnhealthyWorkloadsData
     */
    'services': number;
    /**
     * 
     * @type {number}
     * @memberof UnhealthyWorkloadsData
     */
    'jobs': number;
    /**
     * 
     * @type {number}
     * @memberof UnhealthyWorkloadsData
     */
    'workflows': number;
}
        /**
 * 
 * @export
 * @interface ViolationStatus
 */
export interface ViolationStatus {
    /**
     * Total number of violations
     * @type {number}
     * @memberof ViolationStatus
     */
    'count': number;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof ViolationStatus
     */
    'high': ReasonComponent;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof ViolationStatus
     */
    'medium': ReasonComponent;
    /**
     * 
     * @type {ReasonComponent}
     * @memberof ViolationStatus
     */
    'low': ReasonComponent;
}
        /**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    'description'?: string;
    /**
     * 
     * @type {WorkspaceKind}
     * @memberof Workspace
     */
    'kind': WorkspaceKind;
    /**
     * 
     * @type {WorkspaceValue}
     * @memberof Workspace
     */
    'value': WorkspaceValue;
}


        /**
 * 
 * @export
 * @enum {string}
 */

export const WorkspaceKind = {
    Namespace: 'namespace',
    Label: 'label',
    ServiceId: 'serviceId',
    Cluster: 'cluster',
    ClustersGroup: 'clustersGroup'
} as const;

export type WorkspaceKind = typeof WorkspaceKind[keyof typeof WorkspaceKind];


        /**
 * @type WorkspaceValue
 * @export
 */
export type WorkspaceValue = ClusterWorkspace | ClustersGroupWorkspace | LabelsWorkspace | NamespacesWorkspace | ServiceIdWorkspace;

        /**
 * 
 * @export
 * @interface WorkspacesResponse
 */
export interface WorkspacesResponse {
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof WorkspacesResponse
     */
    'data': Array<Workspace>;
}
    
        /**
* Retrieve an array of cluster filters
* @summary Get Cluster Filters
* @param params ClustersApiApiV1ClustersFiltersSearchPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersFiltersSearchPostUrl = (params: ClustersApiApiV1ClustersFiltersSearchPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/clusters-filters/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params ClustersApiApiV1ClustersSummaryNameGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersSummaryNameGetUrl = (params: ClustersApiApiV1ClustersSummaryNameGetRequest, baseUrl: string): string => {
    // verify required parameter 'name' is not null or undefined
    assertParamExists('apiV1ClustersSummaryNameGet', 'name', params.name)
    const localVarPath = `/api/v1/clusters-summary/:name`
        .replace(`:${"name"}`, encodeURIComponent(String(params.name)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Retrieve an array of cluster summaries
* @summary Get Cluster Summaries
* @param params ClustersApiApiV1ClustersSummarySearchPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1ClustersSummarySearchPostUrl = (params: ClustersApiApiV1ClustersSummarySearchPostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/clusters-summary/search`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1ClustersFiltersSearchPost operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersFiltersSearchPostRequest
*/
export interface ClustersApiApiV1ClustersFiltersSearchPostRequest {
    /**
    * 
    * @type {ClustersFiltersBody}
    * @memberof ClustersApiApiV1ClustersFiltersSearchPost
    */
    readonly clustersFiltersBody?: ClustersFiltersBody
}

/**
* Request parameters for apiV1ClustersSummaryNameGet operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersSummaryNameGetRequest
*/
export interface ClustersApiApiV1ClustersSummaryNameGetRequest {
    /**
    * The name of the cluster to retrieve
    * @type {string}
    * @memberof ClustersApiApiV1ClustersSummaryNameGet
    */
    readonly name: string
}

/**
* Request parameters for apiV1ClustersSummarySearchPost operation in ClustersApi.
* @export
* @interface ClustersApiApiV1ClustersSummarySearchPostRequest
*/
export interface ClustersApiApiV1ClustersSummarySearchPostRequest {
    /**
    * 
    * @type {ClustersSummaryBody}
    * @memberof ClustersApiApiV1ClustersSummarySearchPost
    */
    readonly clustersSummaryBody?: ClustersSummaryBody
}


    
        /**
* Compare services and return the drift
* @summary Compare baseline and target services
* @param params DriftApiApiV1DriftComparisonServicePostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1DriftComparisonServicePostUrl = (params: DriftApiApiV1DriftComparisonServicePostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/drift/comparison/service`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1DriftComparisonServicePost operation in DriftApi.
* @export
* @interface DriftApiApiV1DriftComparisonServicePostRequest
*/
export interface DriftApiApiV1DriftComparisonServicePostRequest {
    /**
    * 
    * @type {ServiceComparisonRequest}
    * @memberof DriftApiApiV1DriftComparisonServicePost
    */
    readonly serviceComparisonRequest?: ServiceComparisonRequest
}


    
        /**
* Resolve user authorized clusters by patterns
* @summary Resolve Cluster names from patterns
* @param params WorkspacesApiApiV1WorkspacesClustersResolvePostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesClustersResolvePostUrl = (params: WorkspacesApiApiV1WorkspacesClustersResolvePostRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workspaces/clusters/resolve`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params WorkspacesApiApiV1WorkspacesGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesGetUrl = (params: WorkspacesApiApiV1WorkspacesGetRequest, baseUrl: string): string => {
    const localVarPath = `/api/v1/workspaces`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params WorkspacesApiApiV1WorkspacesIdClustersGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesIdClustersGetUrl = (params: WorkspacesApiApiV1WorkspacesIdClustersGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkspacesIdClustersGet', 'id', params.id)
    const localVarPath = `/api/v1/workspaces/:id/clusters`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Deletes a workspace by its id
* @summary Delete a Workspace
* @param params WorkspacesApiApiV1WorkspacesIdDeleteRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesIdDeleteUrl = (params: WorkspacesApiApiV1WorkspacesIdDeleteRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkspacesIdDelete', 'id', params.id)
    const localVarPath = `/api/v1/workspaces/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* 
* @param params WorkspacesApiApiV1WorkspacesIdGetRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesIdGetUrl = (params: WorkspacesApiApiV1WorkspacesIdGetRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkspacesIdGet', 'id', params.id)
    const localVarPath = `/api/v1/workspaces/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Updates an existing Workspace and returns its data
* @summary Updates an existing Workspace
* @param params WorkspacesApiApiV1WorkspacesIdPutRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesIdPutUrl = (params: WorkspacesApiApiV1WorkspacesIdPutRequest, baseUrl: string): string => {
    // verify required parameter 'id' is not null or undefined
    assertParamExists('apiV1WorkspacesIdPut', 'id', params.id)
    // verify required parameter 'workspace' is not null or undefined
    assertParamExists('apiV1WorkspacesIdPut', 'workspace', params.workspace)
    const localVarPath = `/api/v1/workspaces/:id`
        .replace(`:${"id"}`, encodeURIComponent(String(params.id)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Creates a new Workspace and returns its data
* @summary Create a new Workspace
* @param params WorkspacesApiApiV1WorkspacesPostRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const apiV1WorkspacesPostUrl = (params: WorkspacesApiApiV1WorkspacesPostRequest, baseUrl: string): string => {
    // verify required parameter 'workspace' is not null or undefined
    assertParamExists('apiV1WorkspacesPost', 'workspace', params.workspace)
    const localVarPath = `/api/v1/workspaces`;
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for apiV1WorkspacesClustersResolvePost operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesClustersResolvePostRequest
*/
export interface WorkspacesApiApiV1WorkspacesClustersResolvePostRequest {
    /**
    * 
    * @type {ResolveClustersRequest}
    * @memberof WorkspacesApiApiV1WorkspacesClustersResolvePost
    */
    readonly resolveClustersRequest?: ResolveClustersRequest
}

/**
* Request parameters for apiV1WorkspacesGet operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesGetRequest
*/
export interface WorkspacesApiApiV1WorkspacesGetRequest {
    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1WorkspacesIdClustersGet operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesIdClustersGetRequest
*/
export interface WorkspacesApiApiV1WorkspacesIdClustersGetRequest {
    /**
    * The ID of the workspace to retrieve
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdClustersGet
    */
    readonly id: string
}

/**
* Request parameters for apiV1WorkspacesIdDelete operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesIdDeleteRequest
*/
export interface WorkspacesApiApiV1WorkspacesIdDeleteRequest {
    /**
    * uuid of a workspace
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdDelete
    */
    readonly id: string

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdDelete
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1WorkspacesIdGet operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesIdGetRequest
*/
export interface WorkspacesApiApiV1WorkspacesIdGetRequest {
    /**
    * The ID of the workspace to retrieve
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdGet
    */
    readonly id: string

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdGet
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1WorkspacesIdPut operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesIdPutRequest
*/
export interface WorkspacesApiApiV1WorkspacesIdPutRequest {
    /**
    * The ID of the workspace to retrieve
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdPut
    */
    readonly id: string

    /**
    * 
    * @type {Workspace}
    * @memberof WorkspacesApiApiV1WorkspacesIdPut
    */
    readonly workspace: Workspace

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesIdPut
    */
    readonly accountId?: string
}

/**
* Request parameters for apiV1WorkspacesPost operation in WorkspacesApi.
* @export
* @interface WorkspacesApiApiV1WorkspacesPostRequest
*/
export interface WorkspacesApiApiV1WorkspacesPostRequest {
    /**
    * 
    * @type {Workspace}
    * @memberof WorkspacesApiApiV1WorkspacesPost
    */
    readonly workspace: Workspace

    /**
    * Account ID to override in case the requester is an admin
    * @type {string}
    * @memberof WorkspacesApiApiV1WorkspacesPost
    */
    readonly accountId?: string
}


    
