import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { PlayBold16 } from "@komodorio/design-system/icons";

import {
  AnalyticEvents,
  dispatchEvent,
} from "../../../../shared/hooks/analytics";
import { PodLogsMode } from "../../../../shared/hooks/podLogs/types";
import { useLogsViewerStore } from "../store/logsViewerStore";
import {
  selectIsLiveTailEnabledByUser,
  selectViewSettings,
} from "../store/logsViewerStoreSelectors";

import { useAutoFreezeLiveLogsScreen } from "./hooks/useAutoFreezeLiveLogsScreen";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ListItemProps, LogsListLine } from "./LogsListLine";
import { LoadMoreButton } from "./MoreLogsBtn";
import {
  CONTAINER_ID,
  getEstimateLogHeight,
  getLogElementHeight,
} from "./logsListUtils";

import { usePodLogsStore } from "@/shared/hooks/podLogs/store/podLogsStore";
import {
  selectPodLogs,
  selectPodLogsDirection,
} from "@/shared/hooks/podLogs/store/podLogsStoreSelectors";
import { PodLogsDirection } from "@/shared/hooks/podLogs/store/types";

const ListContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height + "px"};
  overflow: auto;
`;

const FloatingButton = styled(Button)`
  && {
    position: absolute;
    top: 2rem;
    right: 50%;
    transform: translateX(50%);
    border-color: white;
  }
`;

const ListContainerInner = styled.div<{ height: number }>`
  width: 100%;
  position: relative;
  height: ${({ height }) => height + "px"};
`;

export interface LogsListProps extends ListItemProps {
  indexToScrollTo: number;
  mode: PodLogsMode;
  hasFailureMessage: boolean;
  containerHeight: number;
  containerWidth: number;
  moreLogsLoading: boolean;
  onLoadMoreClicked?: () => void;
}

export const LogsList: React.FC<LogsListProps> = ({
  indexToScrollTo,
  mode,
  hasFailureMessage,
  containerHeight,
  containerWidth,
  onLoadMoreClicked,
  moreLogsLoading,
  ...props
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { wrapText } = useLogsViewerStore(selectViewSettings);

  const prevEnableTextWrapping = useRef(wrapText);
  const prevContainerWidth = useRef(containerWidth);
  const [newLogsArrived, setNewLogsArrived] = useState(false);

  const livePodLogsDirection = usePodLogsStore(selectPodLogsDirection);
  const direction =
    mode === PodLogsMode.Live
      ? livePodLogsDirection
      : PodLogsDirection.NewestFirst;

  const rowVirtualizer = useVirtualizer({
    count: props.logs.length,
    getScrollElement: () => parentRef.current ?? null,
    paddingStart: 10,
    paddingEnd: 10,
    estimateSize: (index: number) =>
      getEstimateLogHeight(wrapText, props.logs[index], containerWidth),
  });

  const virtualElements = rowVirtualizer.getVirtualItems();

  // without this manual resize, the virtualizer will not resize the items, meaning that text-wrapping will not work
  useEffect(() => {
    const textWrapChanged = prevEnableTextWrapping.current !== wrapText;

    const containerWidthChanged = prevContainerWidth.current !== containerWidth;

    if (textWrapChanged || containerWidthChanged) {
      prevEnableTextWrapping.current = wrapText;
      prevContainerWidth.current = containerWidth;
      rowVirtualizer.measure();
    }
  }, [containerWidth, wrapText, props.logs, rowVirtualizer, virtualElements]);

  // set the correct height for each log element from the actual DOM element
  useEffect(() => {
    if (!wrapText) {
      return;
    }

    virtualElements.forEach((virtualItem, idx) => {
      const elHeight = getLogElementHeight(idx);
      if (elHeight !== undefined) {
        rowVirtualizer.resizeItem(virtualItem.index, elHeight);
      }
    });
  }, [wrapText, rowVirtualizer, virtualElements]);

  const isLastLineDisplayed =
    virtualElements[virtualElements.length - 1]?.index ===
    props.logs.length - 1;
  const isFirstLineDisplayed = virtualElements[0]?.index === 0;
  const isNewestLogLineDisplayed =
    livePodLogsDirection === PodLogsDirection.NewestFirst
      ? isFirstLineDisplayed
      : isLastLineDisplayed;
  const isLiveTailEnabledByUser = useLogsViewerStore(
    selectIsLiveTailEnabledByUser
  );

  // cancel live tail when the user scrolls towards the oldest logs
  useAutoFreezeLiveLogsScreen({
    isNewestLogLineDisplayed,
    logsLength: props.logs.length,
  });

  // in order to support freeze, we cut the original logs array in this case.
  // so we have two log lists: the original one and the one that is displayed
  // we need to know when the original logs array is updated in order to update newLogsArrived state
  const allLogs = usePodLogsStore(selectPodLogs);
  useEffect(() => {
    setNewLogsArrived(true);
  }, [allLogs.length, rowVirtualizer, setNewLogsArrived]);

  const scrollToTheNewestLogLine = useCallback(() => {
    setNewLogsArrived(false);
    const scrollIndex =
      direction === PodLogsDirection.NewestFirst ? 0 : props.logs.length;
    rowVirtualizer.scrollToIndex(scrollIndex, {
      align: "end",
    });
  }, [props.logs.length, rowVirtualizer, direction]);

  // auto focus on new logs - scroll to bottom when new logs arrive
  useEffect(() => {
    if (mode === PodLogsMode.Live && isLiveTailEnabledByUser) {
      scrollToTheNewestLogLine();
    }
  }, [
    props.logs.length,
    mode,
    isLiveTailEnabledByUser,
    scrollToTheNewestLogLine,
  ]);

  const logs = useMemo(
    () =>
      virtualElements.map((virtualItem) => (
        <LogsListLine
          containerWidth={containerWidth}
          {...props}
          direction={direction}
          key={virtualItem.key}
          index={virtualItem.index}
          style={{
            height: `${virtualItem.size}px`,
            transform: `translateY(${virtualItem.start}px)`,
          }}
        />
      )),
    [containerWidth, direction, props, virtualElements]
  );

  useEffect(() => {
    if (indexToScrollTo !== -1) {
      rowVirtualizer.scrollToIndex(indexToScrollTo, {
        align: "start",
      });
    }
  }, [indexToScrollTo, rowVirtualizer]);

  const shouldShowScrollToNewestLog =
    mode === PodLogsMode.Live &&
    !isNewestLogLineDisplayed &&
    props.logs.length > 0 &&
    !hasFailureMessage &&
    newLogsArrived;

  const shouldShowLoadMoreButton =
    mode === PodLogsMode.ByDemand && onLoadMoreClicked && isLastLineDisplayed;

  return (
    <>
      <ListContainer ref={parentRef} height={containerHeight}>
        <ListContainerInner
          height={rowVirtualizer.getTotalSize()}
          id={CONTAINER_ID}
        >
          {logs}
        </ListContainerInner>
      </ListContainer>
      {shouldShowScrollToNewestLog && (
        <FloatingButton
          variant="contained"
          onClick={() => {
            dispatchEvent(
              AnalyticEvents.Logs.Live.ResourceLogsNewLogsAvailableClicked
            );
            scrollToTheNewestLogLine();
          }}
          startIcon={<PlayBold16 />}
          size="medium"
        >
          New logs available
        </FloatingButton>
      )}
      {shouldShowLoadMoreButton && (
        <LoadMoreButton onClick={onLoadMoreClicked} loading={moreLogsLoading} />
      )}
    </>
  );
};
