import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { responsiveLayoutHorizontalMarginPx } from "../../ResponsiveLayout";

import { HighlightColor } from "./utils";
import { logsAriaLabels } from "./logsAriaLabels";

const Container = styled.div<{
  enableTextWrapping: boolean;
  containerWidth: number;
}>`
  display: grid;
  grid-template-columns: 1rem max-content auto;
  word-break: break-all;
  align-items: baseline;
  padding-bottom: 14px;
  width: ${({ enableTextWrapping, containerWidth }) =>
    enableTextWrapping
      ? containerWidth - responsiveLayoutHorizontalMarginPx * 2 + "px"
      : "max-content"};
`;

const LogTimestamp = styled(Typography).attrs({
  variant: "code1",
  color: muiColors.gray[400],
  lineHeight: "130%",
})`
  padding-right: 0.75rem;
`;

const LogLineNumber = styled.span`
  color: ${muiColors.gray[300]};
  font-weight: bold;
  font-size: 13px;
  padding-right: 0.75rem;
`;

const LeftBoxStyle = {
  maxWidth: "0.1875rem",
  height: "0.875rem",
  borderRadius: "100px",
};

interface LogLineProps {
  showTimestamp: boolean;
  showLineNumber: boolean;
  mainHighlightColor: HighlightColor;
  lineNumber: string | null;
  logTime: string;
  children?: React.ReactNode;
  enableTextWrapping: boolean;
  containerWidth: number;
}
const LogLine: React.FC<LogLineProps> = ({
  showTimestamp,
  showLineNumber,
  mainHighlightColor,
  lineNumber,
  logTime,
  children,
  enableTextWrapping,
  containerWidth,
}) => {
  return (
    <Container
      enableTextWrapping={enableTextWrapping}
      containerWidth={containerWidth}
      aria-label={logsAriaLabels.logLine}
    >
      <Box
        sx={{
          ...LeftBoxStyle,
          backgroundColor: mainHighlightColor,
        }}
      />
      <span>
        {showLineNumber &&
          (lineNumber ? (
            <LogLineNumber>{lineNumber}</LogLineNumber>
          ) : (
            <span style={{ paddingRight: "2.2rem" }}></span>
          ))}
        {showTimestamp && <LogTimestamp>{logTime}</LogTimestamp>}
      </span>
      {children}
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default LogLine;
