import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { MultiSelectPopoverTexts } from "../multiSelectTypes";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;
const StyledButton = styled(Button)`
  && {
    margin-left: -12px;
  }
`;

const StyledTypography = styled(Typography)`
  && {
    margin-left: auto;
  }
`;

type ItemSelectionMenuProps = {
  onSelectAll: (select: boolean) => void;
  totalOptions: number;
  totalSelected: number;
  className?: string;
  texts?: Pick<MultiSelectPopoverTexts, "selectAllText" | "deselectAllText">;
};

export const ItemSelectionMenu: React.FC<ItemSelectionMenuProps> = ({
  onSelectAll,
  totalSelected,
  totalOptions,
  className,
  texts,
}) => {
  const { selectAllText = "Select all", deselectAllText = "Deselect all" } =
    texts ?? {};
  const buttonText = totalSelected > 0 ? deselectAllText : selectAllText;

  return (
    <Container className={className}>
      {totalOptions > 0 && (
        <StyledButton
          variant={"text"}
          onClick={() => onSelectAll(totalSelected === 0)}
        >
          {buttonText}
        </StyledButton>
      )}
      {totalSelected > 0 && (
        <StyledTypography
          variant={"body3"}
          color="text.secondary"
        >{`${totalSelected} selected`}</StyledTypography>
      )}
    </Container>
  );
};
