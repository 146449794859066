import { useMemo } from "react";

import { Timeframe } from "../../../../shared/types/TimeWindow";
import {
  Datapoint,
  OptimizationStrategy,
  WorkloadMetricsDataPoints,
} from "../../../../generated/metricsApi";
import { useTransformMetricsData } from "../../../ResourceView/tabs/MetricsTab/hooks/metricsTabHooks";
import {
  FilterOutMetricsMapper,
  GraphDataPoint,
  MetricName,
  MetricsAggregationType,
  MetricsData,
} from "../../types";
import { getLineList } from "../../../ResourceView/tabs/WorkloadMetricsTab/workloadMetricsTabUtils";
import { timeWindowToEpochs } from "../../utils";

import { workloadDataKeyToMetricsDataKey } from "./types";

import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { KubernetesServiceTypeEnum } from "@/shared/types/ServiceVertex";
import { useWorkloadMetricsTabContext } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";

const initialMetricsData: MetricsData = {
  limit: [],
  request: [],
  usage: [],
};

const useWorkloadMetricsDataToMetricsData = (
  workloadMetricsData?: WorkloadMetricsDataPoints
): MetricsData => {
  return useMemo(() => {
    if (!workloadMetricsData) return initialMetricsData;
    return Object.entries(workloadMetricsData).reduce<MetricsData>(
      (acc, [key, value]) => {
        if (!value) return acc;

        const metricsKey =
          workloadDataKeyToMetricsDataKey[
            key as keyof WorkloadMetricsDataPoints
          ];

        acc[metricsKey] = value.map((datapoint: Datapoint) => {
          return {
            x: datapoint.timestampMs,
            y: datapoint.value,
          };
        });
        return acc;
      },
      {
        ...initialMetricsData,
      }
    );
  }, [workloadMetricsData]);
};

export const useTransformWorkloadMetricsData = (
  workloadMetricsData: WorkloadMetricsDataPoints | undefined,
  timeWindow: Timeframe,
  recommendationStrategy?: OptimizationStrategy
): GraphDataPoint[] => {
  const metricsDataAsMetricsData =
    useWorkloadMetricsDataToMetricsData(workloadMetricsData);

  return useTransformMetricsData(
    metricsDataAsMetricsData,
    timeWindow,
    recommendationStrategy
  );
};

export const useHasGraphData = (data?: WorkloadMetricsDataPoints) => {
  return useMemo(() => {
    if (!data) return false;
    return Object.values(data).some((dataPoints) => dataPoints?.length > 0);
  }, [data]);
};

export const useGetLineList = (
  selectedAggregationMetric: MetricsAggregationType,
  filterOutMetricsMapper?: FilterOutMetricsMapper
): MetricName[] => {
  return useMemo(
    () => getLineList(selectedAggregationMetric, filterOutMetricsMapper),
    [filterOutMetricsMapper, selectedAggregationMetric]
  );
};

export const useEpochsFromWorkloadMetricsTimeWindow = (endTime?: number) => {
  const { timeWindow } = useWorkloadMetricsTabContext();
  return useMemo(
    () =>
      timeWindowToEpochs(
        timeWindow.timeframe,
        endTime ? new Date(endTime) : undefined
      ),
    [timeWindow, endTime]
  );
};

export const generateWorkloadMetricsRequestParams = (
  komodorUid: KomodorUidParams,
  fromEpoch: number,
  toEpoch: number
) => {
  return {
    clusterName: komodorUid.cluster,
    namespace: komodorUid.namespace,
    komodorServiceName: komodorUid.name,
    /*
        KubernetesServiceTypeEnum.Rollout is an ugly fix for a bug in brain, which in case when pod's owner is replicaset,
        always maps it to Deployment: https://app.clickup.com/t/86bx47fta
     */
    komodorServiceKind:
      komodorUid.kind === KubernetesServiceTypeEnum.Rollout
        ? KubernetesServiceTypeEnum.Deployment
        : komodorUid.kind,
    fromEpoch,
    toEpoch,
  };
};
