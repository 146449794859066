import React, { useMemo } from "react";
import styled from "styled-components";

import { useGroups } from "../../../../hooks/reliabilityHooks";
import { LoaderContainer } from "../../../shared/LoaderContainer";
import { ErrorContainer } from "../../../shared/ErrorContainer";
import { reliabilityArialLabels } from "../../../../reliabilityArialLabels";
import { GroupByOptions } from "../../../../ReliabilityTypes";
import { useGetGroupBy } from "../../../../hooks/useGetGroupBy";
import { NoViolationsEmptyState } from "../../../shared/GroupEmptyState/GroupEmptyState";

import { ViolationsGroup } from "./ViolationsGroup/ViolationsGroup";

import { ImpactGroupType } from "@/generated/reliabilityApi";
import { IssuesGroup } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/IssuesGroup/IssuesGroup";
import { useReportGroupLoadingState } from "@/components/reliability/hooks/useReportGroupLoadingState";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const {
  impactGroups: { mainContainer: impactGroupsMainContainer },
  clusterGroups: { mainContainer: clusterGroupsMainContainer },
  checkTypeGroups: { mainContainer: checkTypeGroupsMainContainer },
  realTimeGroups: { mainContainer: realTimeGroupsAriaLabel },
} = reliabilityArialLabels;

const groupsMapper: Record<GroupByOptions, { ariaLabel: string }> = {
  Impact: {
    ariaLabel: impactGroupsMainContainer,
  },
  Cluster: {
    ariaLabel: clusterGroupsMainContainer,
  },
  CheckType: {
    ariaLabel: checkTypeGroupsMainContainer,
  },
  RealTimeIssues: {
    ariaLabel: realTimeGroupsAriaLabel,
  },
};

type GroupedViolationsProps = {
  overrideImpactGroupType?: ImpactGroupType;
};

export const GroupedViolations: React.FC<GroupedViolationsProps> = ({
  overrideImpactGroupType,
}) => {
  const result = useGroups(overrideImpactGroupType);
  const groupBy = useGetGroupBy(overrideImpactGroupType);
  const groupByAsGroupByOptions = groupBy as GroupByOptions;
  const { data, isFetching, error } = result;
  const { ariaLabel } = groupsMapper[groupByAsGroupByOptions] ?? {};

  useReportGroupLoadingState(result, groupByAsGroupByOptions);

  const content = useMemo(() => {
    if (isFetching) return <LoaderContainer />;
    if (error) return <ErrorContainer />;
    if (!data?.data) return null;

    if (data.data.groups.length === 0)
      return (
        <NoViolationsEmptyState impactGroupType={overrideImpactGroupType} />
      );

    return data.data.groups.map((group, idx) => {
      const GroupComponent =
        overrideImpactGroupType === ImpactGroupType.Realtime
          ? IssuesGroup
          : ViolationsGroup;

      return (
        <GroupComponent
          group={group}
          groupBy={groupByAsGroupByOptions}
          key={`${group.id}-${idx}`}
        />
      );
    });
  }, [
    isFetching,
    error,
    data?.data,
    overrideImpactGroupType,
    groupByAsGroupByOptions,
  ]);

  return <Container aria-label={ariaLabel}>{content}</Container>;
};
