import React, { useMemo, useState } from "react";
import { GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import Stack from "@mui/material/Stack";
import { DataGrid, SearchField } from "@komodorio/design-system/komodor-ui";

import { ExternalDnsManagedRecord } from "@/generated/addonsApi";
import { SourceCell } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/SourceCell";
import { StatusCell } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/StatusCell";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";
import { ConnectedWorkloadsCell } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/ConnectedWorkloadsCell/ConnectedWorkloadsCell";
import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";

const DEFAULT_SORT_MODEL: GridSortModel = [{ field: "status", sort: "desc" }];

const columns: GridColDef<ExternalDnsManagedRecord>[] = [
  {
    field: "host",
    headerName: "Host",
    flex: 1,
  },
  {
    field: "source",
    headerName: "Source",
    flex: 1,
    renderCell: ({ row: { source } }) => (
      <SourceCell sourceKomodorUid={source} />
    ),
  },
  {
    field: "namespace",
    headerName: "Namespace",
    flex: 1,
    valueGetter: ({ row: { source } }) => {
      const { namespace } = parseKomodorUid(source ?? "") ?? {};
      return namespace;
    },
  },
  {
    field: "connectedResources",
    headerName: "Connected Workloads",
    flex: 1,
    renderCell: ({ row: { host, connectedResources } }) => (
      <ConnectedWorkloadsCell
        host={host}
        connectedWorkloadsUids={connectedResources}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    maxWidth: 100,
    renderCell: ({ row: { status } }) => <StatusCell status={status} />,
  },
];

export const ManagedRecordsTable: React.FC<{
  managedRecords: ExternalDnsManagedRecord[];
}> = ({ managedRecords }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredRecords = useMemo(
    () => managedRecords.filter((record) => record.host.includes(searchTerm)),
    [managedRecords, searchTerm]
  );

  return (
    <Stack gap="8px">
      <SearchField
        value={searchTerm}
        onSearch={setSearchTerm}
        placeholder="Host name"
      />
      <DataGrid
        rows={filteredRecords}
        columns={columns}
        getRowId={(row) => `${row.host}-${row.source}`}
        initialState={{
          sorting: { sortModel: DEFAULT_SORT_MODEL },
        }}
        aria-label={
          externalDnsListPageAriaLabels.drawer.sections.managedRecords.table
        }
      />
    </Stack>
  );
};
