import React, { useCallback, useEffect, useMemo } from "react";
import { camelCase } from "lodash";
import { ResourceTableModelRow } from "komodor-types";

import { LABELS_COLUMN } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { ResourceListTableProps } from "../ResourceListTableProps";
import { Direction } from "../../ProcessList/SortTitle";
import { INSPECTION_SHOW_DELETED_PODS_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";

import { useDrawerUrlState } from "@/shared/hooks/state/useDrawerUrlState";
import { isFocusOnFilterKeysCombination } from "@/components/Inspection/utils/isFocusOnFilterKeysCombination";
import { InspectionViewContext } from "@/components/Inspection/InspectionViewContext";

type GetRawHeaderProps = Pick<
  ResourceListTableProps,
  "resourceType" | "overridingColumns"
> & {
  headersOrder: string[];
};

export const useGetRawHeaders = ({
  resourceType,
  overridingColumns,
  headersOrder,
}: GetRawHeaderProps): string[] => {
  return useMemo(() => {
    const columnsToExclude = [LABELS_COLUMN]
      .concat(resourceType.UnnecessaryColumns)
      .map(camelCase);

    return (
      overridingColumns?.map(({ name }) => name) ??
      headersOrder.filter((h) => !columnsToExclude.includes(h))
    );
  }, [resourceType.UnnecessaryColumns, overridingColumns, headersOrder]);
};

type TableSortOrderProps = Pick<
  ResourceListTableProps,
  "initialSortOrder" | "resourceType"
>;

export const useTableSortOrder = ({
  initialSortOrder,
  resourceType,
}: TableSortOrderProps): number => {
  const [showDeletedPodsParam] = useDrawerUrlState<boolean>(
    INSPECTION_SHOW_DELETED_PODS_PARAM_KEY
  );
  const adjustInitialDirection = showDeletedPodsParam ? -1 : 1;
  return useMemo(
    () =>
      (initialSortOrder?.order ??
        resourceType.DefaultSort?.Direction ??
        Direction.down) * adjustInitialDirection,
    [
      adjustInitialDirection,
      initialSortOrder?.order,
      resourceType.DefaultSort?.Direction,
    ]
  );
};

type ToggleAllRowsSelectionProps = Pick<
  ResourceListTableProps,
  "rowSelection"
> & {
  allRowsSelected: boolean;
  rowsOnPage: ResourceTableModelRow[];
};

export const useToggleAllRowsSelection = ({
  rowSelection,
  allRowsSelected,
  rowsOnPage,
}: ToggleAllRowsSelectionProps): (() => void) => {
  return useCallback(() => {
    if (!rowSelection) return;

    if (!allRowsSelected) {
      const {
        selectedRowNames: selectedRowIds,
        unselectableRowNames: unselectableRowIds,
        onRowSelection,
        maxSelectableRows,
      } = rowSelection;
      toggleAllUnselectedRows(
        rowsOnPage,
        selectedRowIds,
        unselectableRowIds,
        maxSelectableRows,
        onRowSelection
      );
    } else {
      rowsOnPage
        .filter(
          ({ uid: rowUid }) =>
            !rowSelection.unselectableRowNames?.includes(rowUid)
        )
        .forEach(({ uid: rowUid }) => {
          rowSelection.onRowSelection(rowUid);
        });
    }
  }, [allRowsSelected, rowSelection, rowsOnPage]);
};

export const toggleAllUnselectedRows = (
  rows: ResourceTableModelRow[],
  selectedRowIds: string[],
  unselectableRowIds: string[] | undefined,
  maxSelectableRows: number,
  toggleRowSelection: (rowName: string) => void
): void =>
  rows
    .map(({ uid: rowUid }) => rowUid)
    .filter(
      (rowUid) =>
        !selectedRowIds.includes(rowUid) &&
        !unselectableRowIds?.includes(rowUid)
    )
    .slice(0, maxSelectableRows)
    .forEach((rowName) => {
      toggleRowSelection(rowName);
    });

export const useTableNavigation = ({
  page,
  totalPages,
  setPage,
  onRowClick,
}: {
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
  onRowClick: (row: ResourceTableModelRow) => void;
}) => {
  const { tbodyRef, focusOnTable } = React.useContext(InspectionViewContext);

  // We want to focus on the first row when the page changes
  useEffect(() => {
    // In case the search filter ("INPUT") is focused, we don't want to focus on the row
    if (document.activeElement?.nodeName === "INPUT") return;
    focusOnTable?.();

    // ↓ this is on purpose, because we want to focus on the first row only when the page changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLTableRowElement>,
      row: ResourceTableModelRow,
      rowDisabled?: boolean
    ) => {
      // Ignore ctrl events and filter focus event
      if (
        isFocusOnFilterKeysCombination(event) ||
        event.ctrlKey ||
        event.shiftKey ||
        event.metaKey
      ) {
        return;
      }

      // Ignore if not focused on a row
      if (!["TR", "TBODY"].includes(document.activeElement?.nodeName ?? ""))
        return;

      event.stopPropagation();
      const currentRow = tbodyRef.current?.children.namedItem(row.id);
      switch (event.key) {
        case "ArrowUp":
          (currentRow?.previousElementSibling as HTMLTableRowElement)?.focus();
          break;
        case "ArrowDown":
          (currentRow?.nextElementSibling as HTMLTableRowElement)?.focus();
          break;
        case "ArrowRight":
          if (page < totalPages - 1) setPage(page + 1);
          break;
        case "ArrowLeft":
          if (page > 0) setPage(page - 1);
          break;
        case "Enter":
          if (!rowDisabled) onRowClick(row);
          break;
        default:
          break;
      }
    },
    [page, setPage, onRowClick, totalPages, tbodyRef]
  );

  return { tbodyRef, handleKeyDown, focusOnTable };
};
