import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

import Komo from "../../../../shared/assets/komo.svg?react";

import { AriaLabels } from "@/shared/config/ariaLabels";

export const NoLivePodsToDisplay: React.FC = () => (
  <Stack
    alignItems="center"
    aria-label={AriaLabels.ResourceView.ServiceLogsTab.EmptyState}
  >
    <Komo />
    <Typography variant="h5" color={muiColors.gray[800]} fontWeight={500}>
      The are no live pods to display
    </Typography>
    <Typography variant="body3" color={muiColors.gray[800]}>
      All the pods have been deleted
    </Typography>
  </Stack>
);
