import styled from "styled-components";
import { useMemo } from "react";

import { ComparisonSelection } from "./ComparisonSelection";
import { ServicesComparisonContextProvider } from "./context/ServicesSelectionContext";
import { ComparisonTabs } from "./ComparisonTabs";

import Divider from "@/components/monitorsView/common/Divider";
import { useKomodorServices } from "@/shared/hooks/useKomodorServices";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-block-start: 24px;
`;

export const DriftAnalysisView: React.FC = () => {
  const services = useKomodorServices().servicesAsDictionary.services;
  const numServices = useMemo(() => Object.keys(services).length, [services]);
  return (
    <ServicesComparisonContextProvider>
      <Container>
        <ComparisonSelection key={`comparison-selection-${numServices}`} />
        <Divider />
        <ResponsiveLayout>
          <ComparisonTabs />
        </ResponsiveLayout>
      </Container>
    </ServicesComparisonContextProvider>
  );
};
