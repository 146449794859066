import React, { useState } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import { muiColors } from "@komodorio/design-system";

import { LogsSettingsDialog } from "./LogsSettingsDialog";

import { PodLogsMode } from "@/shared/hooks/podLogs/types";

const StyledSettingsIcon = styled(SettingsIcon)`
  && {
    color: ${muiColors.gray[500]};
  }
`;

type Props = {
  mode: PodLogsMode;
};

export const LogsOptions: React.FC<Props> = ({ mode }) => {
  const [openLogsSettings, setOpenLogsSettings] = useState(false);

  return (
    <>
      <IconButton
        data-testid="SettingsButton"
        color="primary"
        onClick={() => setOpenLogsSettings(true)}
      >
        <StyledSettingsIcon />
      </IconButton>
      {openLogsSettings && (
        <LogsSettingsDialog
          isOpen={openLogsSettings}
          handleClose={() => setOpenLogsSettings(false)}
          mode={mode}
        />
      )}
    </>
  );
};
