import Tab from "@mui/material/Tab";
import { useCallback, useEffect } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import styled from "styled-components";

import { SELECTED_TAB_KEY, ServicesComparisonTabs } from "./constants";
import { AttributesComparison } from "./Attributes/AttributesComparison";
import { MetricsComparisonTabContent } from "./Metrics/MetricsComparisonTabContent";

import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { WorkloadMetricsTabContextProvider } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/WorkloadMetricsTabContext";
const EagerTabPanel = styled.div<{ selected: boolean }>`
  padding: 24px 0;
  display: ${({ selected }) => (selected ? "block" : "none")};
`;
export const ComparisonTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] =
    useStringifiedStateInSearchParams<ServicesComparisonTabs>(SELECTED_TAB_KEY);

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(ServicesComparisonTabs.Attributes, true);
    }
  }, [selectedTab, setSelectedTab]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: ServicesComparisonTabs) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  return selectedTab ? (
    <TabContext value={selectedTab}>
      <TabList onChange={handleChange}>
        <Tab
          aria-label={""}
          key={ServicesComparisonTabs.Attributes}
          label={"Attributes Comparison"}
          value={ServicesComparisonTabs.Attributes}
        />
        <Tab
          aria-label={""}
          key={ServicesComparisonTabs.Metrics}
          label={"Metrics Comparison"}
          value={ServicesComparisonTabs.Metrics}
        />
      </TabList>
      <EagerTabPanel
        selected={selectedTab === ServicesComparisonTabs.Attributes}
      >
        <AttributesComparison />
      </EagerTabPanel>
      <EagerTabPanel selected={selectedTab === ServicesComparisonTabs.Metrics}>
        <WorkloadMetricsTabContextProvider>
          <MetricsComparisonTabContent />
        </WorkloadMetricsTabContextProvider>
      </EagerTabPanel>
    </TabContext>
  ) : null;
};
