import React, { createContext, useCallback, useState } from "react";

import { Dictionary } from "@/shared/types/Dictionary";
import { useReportLoadingTime } from "@/shared/context/datadogReportLoadingTime/hooks/useReportLoadingTime";
import {
  DatadogReportLoadingTimeContextProviderProps,
  DatadogReportLoadingTimeContextState,
} from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { initialState } from "@/shared/context/datadogReportLoadingTime/initialState";

export const DatadogReportLoadingTimeContext =
  createContext<DatadogReportLoadingTimeContextState>(initialState);

export const DatadogReportLoadingTimeContextProvider: React.FC<
  DatadogReportLoadingTimeContextProviderProps
> = ({ children, shouldPreventReporting, ...rest }) => {
  const [loadingStateDic, setLoadingStateDic] = useState<Dictionary<boolean>>(
    initialState.loadingState
  );

  const reportLoadingState = useCallback(
    (componentName: string, loadingState: boolean) => {
      setLoadingStateDic((prev) => {
        if (prev[componentName] === loadingState) {
          return prev;
        }
        return {
          ...prev,
          [componentName]: loadingState,
        };
      });
    },
    []
  );

  const removeFromLoadingState = useCallback(
    (componentName: string) => {
      if (loadingStateDic[componentName]) {
        setLoadingStateDic((prev) => {
          const newState = { ...prev };
          delete newState[componentName];
          return newState;
        });
      }
    },
    [loadingStateDic]
  );

  const resetLoadingState = useCallback(() => {
    setLoadingStateDic({});
  }, []);

  const getCurrentViewOptions = useCallback(() => {
    if (rest.viewOptions) {
      return rest.viewOptions;
    }

    return rest.getCurrentViewOptions();
  }, [rest]);

  const { triggerStartView } = useReportLoadingTime({
    loadingState: loadingStateDic,
    shouldPreventReporting,
    getCurrentViewOptions,
  });

  const contextState: DatadogReportLoadingTimeContextState = {
    reportLoadingState,
    removeFromLoadingState,
    resetLoadingState,
    triggerStartView,
    loadingState: loadingStateDic,
    getCurrentViewOptions,
    shouldPreventReporting,
  };

  return (
    <DatadogReportLoadingTimeContext.Provider value={contextState}>
      {children}
    </DatadogReportLoadingTimeContext.Provider>
  );
};
