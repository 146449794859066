import { useMemo } from "react";

import { HealthType, useHealthType } from "./useHealthType";

import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

type Pages = {
  violations: boolean;
  bestPractices: boolean;
};

export const useReliabilityPages = (): Pages | undefined => {
  const { reliabilityBestPracticesTab } = useOverridableFlags();
  const healthType = useHealthType();

  return useMemo(() => {
    if (healthType === HealthType.INFRA) return undefined;

    return {
      violations: true,
      bestPractices: !!reliabilityBestPracticesTab,
    };
  }, [healthType, reliabilityBestPracticesTab]);
};
