import { ALL_TAB_VALUE } from "../context/IssuesTableContext";
import { useIssuesTableContext } from "../context/useIssuesTableContext";

import { CheckType } from "@/generated/reliabilityApi";

export const useCheckTypeParam = (): CheckType[] => {
  const { tabValue } = useIssuesTableContext();

  if (tabValue === ALL_TAB_VALUE) {
    return [];
  }
  return [tabValue];
};
