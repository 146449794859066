import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { useViolations } from "../../../../../hooks/reliabilityHooks";
import { useOnContainerResizeWidth } from "../../../../../../common/LogsViewer/LogsBlackText/hooks/useContainerResizeWidth";

import { ViolationsTable } from "./ViolationsTable/ViolationsTable";
import { ViolationsTableTabs } from "./ViolationsTableTabs";
import {
  useGetCountByViolationFilters,
  useResetPageOnRequestParamsChange,
  useSetAllUngroupedSeveritiesCount,
} from "./hooks/violationTableHooks";
import { useSetInitialGroupState } from "./hooks/useSetInitialGroupState";
import { useViolationsTableContext } from "./context/useViolationsTableContext";
import { ViolationsTableFilters } from "./ViolationsTableFilters";

import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";

const Container = styled.div`
  background: white;
  border-radius: 4px;
`;

export const ViolationsTableHandler: React.FC = () => {
  const violationsRes = useViolations();
  const violationsCount = useGetCountByViolationFilters();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = useOnContainerResizeWidth(containerRef);
  const { onInternalStateChange } = useViolationsTableContext();
  const isBestPracticeTab = useIsBestPracticesTab();

  useResetPageOnRequestParamsChange();
  useSetAllUngroupedSeveritiesCount(violationsCount);
  useSetInitialGroupState();

  useEffect(() => {
    onInternalStateChange({ count: violationsCount });
  }, [onInternalStateChange, violationsCount]);

  return (
    <Container ref={containerRef}>
      <ViolationsTableFilters />
      {!isBestPracticeTab && (
        <ViolationsTableTabs severitiesCount={violationsCount} />
      )}
      <ViolationsTable
        violationsRes={violationsRes}
        containerWidth={containerWidth}
      />
    </Container>
  );
};
