import { useCallback, useMemo } from "react";

import { useGetViolations } from "../../../shared/hooks/reliability-api/violations/useGetViolations";
import { useGetGroups } from "../../../shared/hooks/reliability-api/groups/useGetGroups";
import { useViolationsRequestParams } from "../components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/hooks/useViolationsRequestParams";
import { useReliabilityStore } from "../store/reliabilityStore";
import { selectSetAddEditIgnoreRuleDialogState } from "../store/reliabilityStoreSelectors";
import {
  AddEditIgnoreRuleDialogAction,
  GroupByOptions,
} from "../ReliabilityTypes";
import {
  GroupViolationsByOptions,
  ImpactGroupType,
  ViolationCountBy,
  ViolationsApiGetViolationsRequest,
  ViolationStatus,
} from "../../../generated/reliabilityApi";
import { useRealtimeIssuesRequestParams } from "../components/pages/violations/ViolationsBreakdown/RealtimeSection/hooks/useRealtimeIssuesRequestParams";

import { useScopeFilters } from "./useScopeFilters";
import { useTimeWindowToEpoch } from "./useTimeWindowToEpoch";
import { useIsFiltersStateSet } from "./useIsFiltersStateSet";
import { useGetGroupBy } from "./useGetGroupBy";
import { useGetImpactGroupType, useIsBestPracticesTab } from "./useSelectedTab";
import { useCheckCategory } from "./useCheckCategory";
import { useRelevantImpactGroupType } from "./useRelevantImpactGroupType";

export const useViolations = () => {
  const violationsRequestParams = useViolationsRequestParams();
  return useGetViolationsByRequestParams(violationsRequestParams);
};

export const useRealtimeIssues = () => {
  const issuesRequestParams = useRealtimeIssuesRequestParams();
  return useGetViolationsByRequestParams(issuesRequestParams);
};

const useGetViolationsByRequestParams = (
  requestParams: ViolationsApiGetViolationsRequest
) => {
  const enable = useIsFiltersStateSet();
  return useGetViolations(requestParams, enable);
};

const getBreakDownFactor = (
  impactGroupType: ImpactGroupType,
  isBestPracticesTab: boolean
): ViolationCountBy[] => {
  if (impactGroupType === ImpactGroupType.Realtime) return ["checkType"];

  if (isBestPracticesTab) return ["hasDependentViolations"];

  return ["severity"];
};

const groupByMapper: Record<GroupByOptions, GroupViolationsByOptions> = {
  Impact: "impact-group",
  Cluster: "cluster",
  CheckType: "checkType",
  RealTimeIssues: "impact-group",
};

export const useGroups = (overrideImpactGroupType?: ImpactGroupType) => {
  const groupBy = useGetGroupBy(overrideImpactGroupType);
  const {
    impactGroupType,
    namespace,
    clusterName,
    resourceType,
    shortResourceName,
    createdAt,
    status,
  } = useScopeFilters();
  const timeWindowToEpoch = useTimeWindowToEpoch();
  const isBestPracticesTab = useIsBestPracticesTab();

  const impactGroupTypeByTab = useGetImpactGroupType(overrideImpactGroupType);

  const epochs = useMemo(
    () => timeWindowToEpoch(createdAt?.[0]),
    [createdAt, timeWindowToEpoch]
  );

  const enable = useIsFiltersStateSet();

  const checkCategory = useCheckCategory();

  const impactGroupsToUse = useRelevantImpactGroupType({
    impactGroupTypeFilter: impactGroupType,
    impactGroupTypeByTab,
  });

  const breakdownFactors = getBreakDownFactor(
    impactGroupTypeByTab,
    isBestPracticesTab
  );

  return useGetGroups(
    {
      groupViolationsBy: groupByMapper[groupBy as GroupByOptions],
      breakdownFactors: breakdownFactors,
      impactGroupType: impactGroupsToUse,
      checkCategory,
      ...(namespace?.length && { namespace }),
      ...(clusterName?.length && { clusterName }),
      ...(resourceType?.length && { resourceType }),
      ...(shortResourceName?.length && { shortResourceName }),
      ...(!!epochs && { ...epochs }),
      ...(status?.length && { status: status as ViolationStatus[] }),
    },
    { enabled: enable }
  );
};

export const useSetAddEditIgnoreRuleDialogState = () => {
  const setAddEditIgnoreRuleDialogState = useReliabilityStore(
    selectSetAddEditIgnoreRuleDialogState
  );
  return useCallback(
    (newState: AddEditIgnoreRuleDialogAction) => {
      setAddEditIgnoreRuleDialogState(newState);
    },
    [setAddEditIgnoreRuleDialogState]
  );
};
