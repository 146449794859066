import { Divider } from "@komodorio/design-system/deprecated";
import React, { useCallback, useMemo, useState } from "react";

import { AriaLabels } from "../../../../../../../shared/config/ariaLabels";
import {
  RESOURCE_PREFIX,
  EVENTS_TAB_PREFIX,
  EVENT_PARAM_KEY,
} from "../../../../../../../shared/config/urlSearchParamsKeys";
import { useDateFormatter } from "../../../../../../../shared/hooks/useDateFormatter";
import { useResourceView } from "../../../../../../ResourceView/ResourceProvider";
import MonitorRuleDrawer from "../../../../../../monitorsView/monitorsConfiguration/MonitorRuleDrawer";
import { WorkflowRunWithResults } from "../../../../../../monitorsView/useWorkflowsRuns";
import WorkflowCheck from "../../../../../../monitorsView/workflowRunView/checks";
import { PodCheckType } from "../../../../../../monitorsView/workflowRunView/checks/useWorkflowsChecks";
import { UnhealthyPodOutput } from "../../../../../../monitorsView/workflowRunView/checks/pod/types";
import { useHasPermissions } from "../../../../../../../shared/hooks/useUserMetadata/rbac";
import { useWorkflowConfigurationById } from "../../../../../../monitorsView/useWorkflowConfigurations";
import { Container, SectionName, SectionColumn } from "../common/styles";
import {
  HeaderSection,
  getReasonSubtitleComponentByIssueSummary,
} from "../common/sections/HeaderSection/HeaderSection";
import { SnapshotInfoDetail } from "../../../../../Alert/SnapshotInfo";
import PodEventsTable from "../../../../../table/PodEventsTable/PodEventsTable";
import ContainersTable from "../../../../../../monitorsView/common/availabilityComponents/ContainersTable";
import { useDDRumStartViewAndAddTiming } from "../../../../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { parseIssueSummary } from "../../../../../../monitorsView/workflowRunView/checks/availability/detailsComponents/utils";
import { dataDogViewNames } from "../../../../../../../shared/constants/datadog";

import FailedContainerLogsSection from "./sections/FailedContainerLogsSection";
import InfoSection from "./sections/InfoSection";
import FailedContainerMetricsSection from "./sections/FailedContainerMetricsSection";

import { PodWhereSection } from "@/components/common/ProcessList/details/PodPhaseDetails/PodWhereSection";
import { useGetPodResource } from "@/components/common/ProcessList/details/PodPhaseDetails/useGetPodResource";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";

export const PodIssueDrawer: React.FC<{
  allChecks: WorkflowCheck[];
  wfRun: WorkflowRunWithResults;
  onClose?: () => void;
}> = ({ allChecks, wfRun, onClose }) => {
  const { canManageMonitors } = useHasPermissions();
  const [selectedRuleId, setSelectedRuleId] = useState<string>();

  const resourceFromResourceView = useResourceView();
  const [, setEventId] = useStateInSearchParams(
    (resourceFromResourceView.id ? RESOURCE_PREFIX + EVENTS_TAB_PREFIX : "") +
      EVENT_PARAM_KEY
  );
  const closed = !!wfRun?.closedAt;

  const podResource = useGetPodResource({
    fromEpoch: wfRun.eventTime?.getTime().toString(),
    clusterName: wfRun.cluster ?? "",
    namespace: wfRun.namespace ?? "",
    podName: wfRun.shortResourceName,
  });

  const podCheckOutput = useMemo(() => {
    const podsHealthyCheck = allChecks.find(
      (c) => c.type === PodCheckType.PodHealthy
    );
    return podsHealthyCheck?.output
      ? (podsHealthyCheck?.output as UnhealthyPodOutput)
      : undefined;
  }, [allChecks]);

  const opinionatedSummary = useMemo(() => {
    if (!podCheckOutput?.issueSummary) return [];
    return [parseIssueSummary(podCheckOutput?.issueSummary)];
  }, [podCheckOutput?.issueSummary]);
  const { format } = useDateFormatter();

  const subtitle = useMemo(() => {
    const startedAt = `Started at ${format(wfRun.eventTime)}`;
    const endedAt =
      closed && wfRun.closedAt ? `, Closed at ${format(wfRun.closedAt)}` : "";
    return startedAt + endedAt;
  }, [closed, format, wfRun.closedAt, wfRun.eventTime]);

  const onEditMonitorClick = useCallback(() => {
    if (!canManageMonitors) {
      return;
    }
    setSelectedRuleId(wfRun?.workflowConfiguration?.id);
  }, [canManageMonitors, wfRun?.workflowConfiguration?.id]);
  const { workflowConfiguration: workflowRuleUpToDate, refreshFromNetwork } =
    useWorkflowConfigurationById(wfRun?.workflowConfiguration?.id);

  const failedContainer = podCheckOutput?.issueSummary?.containersLogs?.[0];

  const subtitleComponent = useMemo(
    () => getReasonSubtitleComponentByIssueSummary(opinionatedSummary),
    [opinionatedSummary]
  );

  useDDRumStartViewAndAddTiming({
    viewName: dataDogViewNames.eventDrawerPodIssue,
    addTimingParams: {
      enable: true,
    },
  });

  return (
    <>
      <Container>
        <HeaderSection
          wfRun={wfRun}
          reasons={wfRun.reasons}
          secondSubtitle={subtitleComponent}
          canManageMonitors={canManageMonitors}
          subtitle={subtitle}
          onEditMonitorClick={onEditMonitorClick}
          onClose={
            onClose ??
            (() => {
              setEventId(null);
            })
          }
        />
        <Divider />
        <PodWhereSection
          podResource={podResource}
          podNameAriaLabel={AriaLabels.StandalonePod.ResourceLink}
        />
        <Divider />
        {closed && wfRun?.eventTime && (
          <SnapshotInfoDetail>
            All information presented here is a snapshot from{" "}
            <b>{format(new Date(wfRun?.eventTime))}</b> when this issue
            occurrence was triggered
          </SnapshotInfoDetail>
        )}
        <InfoSection
          startTime={wfRun.eventTime}
          endTime={wfRun.closedAt}
          errorMessage={podCheckOutput?.issueSummary?.errorMessage}
        />
        <Divider />
        {failedContainer && (
          <>
            <FailedContainerLogsSection
              failedContainer={failedContainer}
              clusterName={wfRun.cluster ?? ""}
              namespace={wfRun.namespace ?? ""}
            />
            <FailedContainerMetricsSection
              failedContainer={failedContainer}
              clusterName={wfRun.cluster ?? ""}
              namespace={wfRun.namespace ?? ""}
              eventTime={wfRun.eventTime}
            />
          </>
        )}
        {podCheckOutput?.pod?.podEvents &&
          podCheckOutput?.pod?.podEvents?.length > 0 && (
            <>
              <SectionColumn>
                <SectionName>pod events</SectionName>
                <PodEventsTable podEvents={podCheckOutput.pod.podEvents} />
              </SectionColumn>
              <Divider />
            </>
          )}
        {podCheckOutput?.pod?.containers &&
          podCheckOutput?.pod?.containers?.length > 0 && (
            <SectionColumn>
              <SectionName>containers</SectionName>
              <ContainersTable
                containers={podCheckOutput.pod.containers}
                clusterName={wfRun.cluster ?? ""}
                namespace={wfRun.namespace ?? ""}
                podName={podCheckOutput.pod.name}
                eventTime={wfRun.eventTime}
              />
            </SectionColumn>
          )}
      </Container>
      {workflowRuleUpToDate && (
        <MonitorRuleDrawer
          isOpen={!!selectedRuleId}
          handleClose={() => {
            setSelectedRuleId(undefined);
          }}
          isTypeStatic={true}
          refreshMonitors={refreshFromNetwork}
          initRule={workflowRuleUpToDate}
        />
      )}
    </>
  );
};
