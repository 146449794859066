import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography/Typography";
import pluralize from "pluralize";

import { DependentViolationsIcon } from "../../shared/DependentViolationsIcon";

import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { Dictionary } from "@/shared/types/Dictionary";
import { relatedViolationsKey } from "@/components/reliability/constants/reliabilityConstants";

export const RELATED_VIOLATIONS_SUFFIX = "related violation";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
`;

const RelatedViolationsContainer = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const {
  dependentViolations: {
    container: dependentViolationsContainerArialLabel,
    relatedViolations: relatedViolationsArialLabel,
  },
} = reliabilityArialLabels;

interface Props {
  dependecies: Dictionary<number> | undefined;
}

export const DependentViolations: React.FC<Props> = ({ dependecies }) => {
  const relatedViolations: number | undefined =
    dependecies?.[relatedViolationsKey];

  return (
    <Container aria-label={dependentViolationsContainerArialLabel}>
      {relatedViolations && (
        <RelatedViolationsContainer>
          <DependentViolationsIcon />
          <Typography variant="body1" aria-label={relatedViolationsArialLabel}>
            {relatedViolations}{" "}
            {pluralize(RELATED_VIOLATIONS_SUFFIX, relatedViolations)}
          </Typography>
        </RelatedViolationsContainer>
      )}
    </Container>
  );
};
