import { Dictionary } from "@/shared/types/Dictionary";

export const isCurrentlyDeploying = (
  lastDeployStartTime: string | undefined | null,
  lastDeployEndTime: string | undefined | null
): boolean => {
  return (
    !!lastDeployStartTime &&
    (!lastDeployEndTime || lastDeployStartTime > lastDeployEndTime)
  );
};

export const isExternalDNS = (labels: Dictionary<string>): boolean => {
  return Object.entries(labels).some((entry) => {
    const label = entry.join(":");
    return [
      "app.kubernetes.io/name:external-dns",
      "komodor.io/detect:external-dns",
    ].includes(label);
  });
};
