import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { useUserMetadata } from "../../shared/hooks/useUserMetadata/useUserMetadata";

const useTiersVisible = (): boolean => {
  const { accountPlan } = useUserMetadata();
  const { freemiumFlow } = useOverridableFlags();
  if (accountPlan === "") return false;
  if (!freemiumFlow) return false;
  return true;
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useTiersVisible;
