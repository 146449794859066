/* eslint-disable max-lines */
import { useMemo } from "react";
import {
  Deployment as DeploymentType,
  StatefulSet as StatefulSetType,
  DaemonSet as DaemonSetType,
  ReplicaSet as ReplicaSetType,
} from "kubernetes-types/apps/v1.d";
import { HorizontalPodAutoscaler as HPAType } from "kubernetes-types/autoscaling/v1.d";
import {
  Pod as PodType,
  PersistentVolumeClaim as PVCType,
  PersistentVolume as PVType,
  ConfigMap as ConfigMapType,
  Secret as SecretType,
  ResourceQuota as ResourceQuotaType,
  LimitRange as LimitRangeType,
  Service as ServiceType,
  Endpoints as EndpointsType,
  Node as NodeType,
  ServiceAccount as ServiceAccountType,
  Namespace as NamespaceType,
} from "kubernetes-types/core/v1.d";
import { EndpointSlice as EndpointSliceType } from "kubernetes-types/discovery/v1.d";
import {
  Job as JobType,
  CronJob as CronJobType,
} from "kubernetes-types/batch/v1.d";
import { PodDisruptionBudget } from "kubernetes-types/policy/v1.d";
import {
  Ingress as IngressType,
  NetworkPolicy as NetworkPolicyType,
} from "kubernetes-types/networking/v1.d";
import {
  ClusterRole as ClusterRoleType,
  Role as RoleType,
  ClusterRoleBinding as ClusterRoleBindingType,
  RoleBinding as RoleBindingType,
} from "kubernetes-types/rbac/v1.d";
import { StorageClass as StorageClassType } from "kubernetes-types/storage/v1.d";
import { CustomResourceDefinition } from "kubernetes-types/apiextensions/v1.d";

import Resource, {
  KomodorServiceType,
} from "../../../components/ResourceView/resources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Deployment from "../../../components/ResourceView/resources/deployment";
import StatefulSet from "../../../components/ResourceView/resources/statefulset";
import DaemonSet from "../../../components/ResourceView/resources/daemonset";
import Pod from "../../../components/ResourceView/resources/pod";
import Job from "../../../components/ResourceView/resources/job";
import CronJob from "../../../components/ResourceView/resources/cronjob";
import ReplicaSet from "../../../components/ResourceView/resources/replicaSet";
import PVC from "../../../components/ResourceView/resources/pvc";
import PV from "../../../components/ResourceView/resources/pv";
import StorageClass from "../../../components/ResourceView/resources/storageClass";
import ConfigMap from "../../../components/ResourceView/resources/configMap";
import Secret from "../../../components/ResourceView/resources/secret";
import ResourceQuota from "../../../components/ResourceView/resources/resourceQuota";
import LimitRange from "../../../components/ResourceView/resources/limitRange";
import HPA from "../../../components/ResourceView/resources/hpa";
import PDB from "../../../components/ResourceView/resources/pdb";
import Node from "../../../components/ResourceView/resources/node";
import Service from "../../../components/ResourceView/resources/service";
import Endpoint from "../../../components/ResourceView/resources/endpoint";
import Ingress from "../../../components/ResourceView/resources/ingress";
import NetworkPolicy from "../../../components/ResourceView/resources/networkPolicies";
import EndpointSlice from "../../../components/ResourceView/resources/endpointSlice";
import Rollout, {
  RolloutType,
} from "../../../components/ResourceView/resources/rollout";
import { useOverridableFlags } from "../../context/featureFlags/OverridableFlags";
import CRD from "../../../components/ResourceView/resources/crd";
import { KubernetesCRDResource } from "../../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesCRDResource";
import { KubernetesServiceAccountsResource } from "../../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesServiceAccountsResource";
import CustomResource, {
  CustomResourceBasic,
} from "../../../components/ResourceView/resources/customResource";
import ServiceAccounts from "../../../components/ResourceView/resources/ServiceAccount";
import { KubernetesClusterRolesResource } from "../../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesClusterRolesResource";
import ClusterRole from "../../../components/ResourceView/resources/clusterRole";
import Role from "../../../components/ResourceView/resources/role";
import { KubernetesRolesResource } from "../../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesRolesResource";
import { KubernetesClusterRoleBindingsResource } from "../../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesClusterRoleBindingsResource";
import ClusterRoleBinding from "../../../components/ResourceView/resources/clusterRoleBinding";
import { KubernetesRoleBindingsResource } from "../../../components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesRoleBindingsResource";
import RoleBinding from "../../../components/ResourceView/resources/roleBinding";
import ArgoWorkflow from "../../../components/ResourceView/resources/argoWorkflow";
import ArgoWorkflowTemplate from "../../../components/ResourceView/resources/argoWorkflowTemplate";
import ArgoClusterWorkflowTemplate from "../../../components/ResourceView/resources/argoClusterWorkflowTemplate";
import ArgoCronWorkflow from "../../../components/ResourceView/resources/argoCronWorkflow";
import DeletedPod, {
  DELETED_POD_KIND,
  DeletedPodType,
} from "../../../components/ResourceView/resources/deletedPod";

import CertManagerCertificate from "@/components/ResourceView/resources/certManagerCertificate";
import CertManagerOrder from "@/components/ResourceView/resources/certManagerOrder";
import CertManagerCertificateRequests from "@/components/ResourceView/resources/certManagerCertificateRequests";
import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { KubernetesNamespaceResource } from "@/components/Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNamespaceResource";
import { Namespace } from "@/components/ResourceView/resources/namespace";

export const useParsedKomodorService = (
  komodorService: KomodorServiceType | undefined,
  featureFlags: Record<string, unknown>,
  resultData?: unknown
): Resource | undefined => {
  return useMemo(() => {
    if (!komodorService) return undefined;
    switch (komodorService.kind) {
      case "Deployment":
        return new Deployment({
          deployment: resultData as DeploymentType,
          komodorService,
          featureFlags,
        });
      case "StatefulSet":
        return new StatefulSet({
          statefulset: resultData as StatefulSetType,
          komodorService,
          featureFlags,
        });
      case "DaemonSet":
        return new DaemonSet({
          daemonset: resultData as DaemonSetType,
          komodorService,
          featureFlags,
        });
      case "Job":
        return new Job(undefined, undefined, undefined, komodorService);
      case "CronJob":
        return new CronJob(
          undefined,
          resultData as CronJobType,
          undefined,
          komodorService
        );
      case "Rollout":
        return new Rollout({ komodorService, featureFlags });
      case "ArgoWorkflows.Workflows":
        return new ArgoWorkflow(
          undefined,
          undefined,
          undefined,
          komodorService
        );
      case "ArgoWorkflows.WorkflowTemplates":
        return new ArgoWorkflowTemplate(
          undefined,
          undefined,
          undefined,
          komodorService
        );
      case "ArgoWorkflows.ClusterWorkflowTemplates":
        return new ArgoClusterWorkflowTemplate(
          undefined,
          undefined,
          undefined,
          komodorService
        );
      case "ArgoWorkflows.CronWorkflows":
        return new ArgoCronWorkflow(
          undefined,
          undefined,
          undefined,
          komodorService
        );
      default:
        return;
    }
  }, [featureFlags, komodorService, resultData]);
};

interface ParseResourceParams {
  result: unknown;
  kind: string;
  cluster: string;
  agentId: string;
  calculatedStatus: string;
  isCustomResource?: boolean;
  featureFlags: Record<string, unknown>;
  accountFeatures?: Record<string, unknown>;
}

export const getParsedResource = ({
  result,
  kind,
  cluster,
  agentId,
  calculatedStatus,
  isCustomResource,
  featureFlags,
  accountFeatures,
}: ParseResourceParams): Resource | undefined => {
  if (!result) return;
  try {
    let object;
    if (typeof result === "string") {
      object = JSON.parse(result);
    } else {
      object = result;
    }
    switch (kind) {
      case "Deployment":
        return new Deployment({
          cluster,
          deployment: object as DeploymentType,
          agentId,
          featureFlags,
        });
      case "StatefulSet":
        return new StatefulSet({
          cluster,
          statefulset: object as StatefulSetType,
          agentId,
          featureFlags,
        });
      case "Rollout":
        return new Rollout({
          cluster,
          rollout: object as RolloutType,
          agentId,
          featureFlags,
        });
      case "ReplicaSet":
        return new ReplicaSet(cluster, object as ReplicaSetType, agentId);
      case "Node":
        return new Node(cluster, object as NodeType, agentId);
      case "DaemonSet":
        return new DaemonSet({
          cluster,
          daemonset: object as DaemonSetType,
          agentId,
          featureFlags,
        });
      case "Pod":
        return new Pod(
          cluster,
          object as PodType,
          agentId,
          featureFlags,
          calculatedStatus,
          accountFeatures
        );
      case DELETED_POD_KIND:
        return new DeletedPod(
          cluster,
          kind,
          object as DeletedPodType,
          agentId,
          featureFlags,
          calculatedStatus
        );
      case "PersistentVolumeClaim":
        return new PVC(cluster, object as PVCType, agentId);
      case "PersistentVolume":
        return new PV(cluster, object as PVType, agentId);
      case "StorageClass":
        return new StorageClass(cluster, object as StorageClassType, agentId);
      case "Job":
        return new Job(cluster, object as JobType, agentId);
      case "CronJob":
        return new CronJob(cluster, object as CronJobType, agentId);
      case "ConfigMap":
        return new ConfigMap(cluster, object as ConfigMapType, agentId);
      case "Secret":
        return new Secret(cluster, object as SecretType, agentId, featureFlags);
      case "LimitRange":
        return new LimitRange(cluster, object as LimitRangeType, agentId);
      case "ResourceQuota":
        return new ResourceQuota(cluster, object as ResourceQuotaType, agentId);
      case "HorizontalPodAutoscaler":
        return new HPA(cluster, object as HPAType, agentId);

      case "PodDisruptionBudget":
        return new PDB(cluster, object as PodDisruptionBudget, agentId);
      case "Service":
        return new Service(
          cluster,
          object as ServiceType,
          agentId,
          featureFlags
        );
      case "Endpoints":
        return new Endpoint(cluster, object as EndpointsType, agentId);
      case "Ingress":
        return new Ingress(cluster, object as IngressType, agentId);
      case "NetworkPolicy":
        return new NetworkPolicy(cluster, object as NetworkPolicyType, agentId);
      case "EndpointSlice":
        return new EndpointSlice(cluster, object as EndpointSliceType, agentId);
      case KubernetesCRDResource.Kind:
        return new CRD(cluster, object as CustomResourceDefinition, agentId);
      case KubernetesNamespaceResource.Kind:
        return new Namespace(cluster, object as NamespaceType, agentId);
      case KubernetesServiceAccountsResource.Kind:
        return new ServiceAccounts(
          cluster,
          object as ServiceAccountType,
          agentId
        );
      case KubernetesClusterRolesResource.Kind:
        return new ClusterRole(cluster, object as ClusterRoleType, agentId);
      case KubernetesRolesResource.Kind:
        return new Role(cluster, object as RoleType, agentId);
      case KubernetesClusterRoleBindingsResource.Kind:
        return new ClusterRoleBinding(
          cluster,
          object as ClusterRoleBindingType,
          agentId
        );
      case KubernetesRoleBindingsResource.Kind:
        return new RoleBinding(cluster, object as RoleBindingType, agentId);
      case "Workflow":
        return new ArgoWorkflow(cluster, object, agentId);
      case "WorkflowTemplate":
        return new ArgoWorkflowTemplate(cluster, object, agentId);
      case "ClusterWorkflowTemplate":
        return new ArgoClusterWorkflowTemplate(cluster, object, agentId);
      case "CronWorkflow":
        return new ArgoCronWorkflow(cluster, object, agentId);
      case "Certificate":
        return new CertManagerCertificate(cluster, object, agentId);
      case "Order":
        return new CertManagerOrder(cluster, object, agentId);
      case "CertificateRequest":
        return new CertManagerCertificateRequests(cluster, object, agentId);
    }

    if (isCustomResource) {
      return new CustomResource(
        cluster,
        object as CustomResourceBasic,
        agentId,
        kind,
        undefined,
        featureFlags
      );
    }

    return;
  } catch (err) {
    return;
  }
};

const useParsedResource = (
  result: unknown,
  kind: string,
  cluster: string,
  agentId: string,
  calculatedStatus: string,
  isCustomResource?: boolean
): Resource | undefined => {
  const featureFlags = useOverridableFlags();
  const { data: accountFeatures } = useAccountFeatures();
  return useMemo(
    () =>
      getParsedResource({
        result,
        kind,
        cluster,
        agentId,
        calculatedStatus,
        featureFlags,
        isCustomResource,
        accountFeatures,
      }),
    [
      result,
      kind,
      cluster,
      agentId,
      calculatedStatus,
      featureFlags,
      isCustomResource,
      accountFeatures,
    ]
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default useParsedResource;
