import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";
import { Dictionary } from "lodash";
import { useMemo } from "react";

import { CustomServiceSelection } from "./CustomServiceSelection";
import { MAX_COMPARED_SERVICES } from "./constants";

import { ServiceInfo } from "@/shared/types/ServiceInfo";
import {
  buildKomodorUid,
  parseKomodorUid,
} from "@/shared/hooks/resources-api/resourcesAPIUtils";

export const buildKomodorUidFromServiceInfo = (
  serviceInfo: ServiceInfo
): string => {
  const namespace = serviceInfo?.env ?? "";
  const kind = serviceInfo?.kind ?? "";
  return buildKomodorUid({
    kind,
    namespace,
    resourceName: serviceInfo?.title ?? "",
    clusterName: serviceInfo?.k8sCluster ?? "",
  });
};

export const compareServiceInfo = (a: ServiceInfo, b: ServiceInfo): number => {
  return (
    a.k8sCluster.localeCompare(b.k8sCluster) ||
    a.env.localeCompare(b.env) ||
    a.title.localeCompare(b.title)
  );
};

export const useComparedServicesOptions = (
  comparedServicesIds: MuiSelectionOption<string>[] | null,
  services: ServiceInfo[],
  baselineServiceId: MuiSelectionOption<string> | null
): MuiSelectionOption<string>[] => {
  return useMemo(
    () =>
      services
        .filter((service) => {
          const uid = buildKomodorUidFromServiceInfo(service);
          const baselineParsedKomodorUid = baselineServiceId
            ? parseKomodorUid(baselineServiceId.value)
            : null;
          return (
            uid !== baselineServiceId?.value &&
            baselineParsedKomodorUid?.kind === service.kind
          );
        })
        .map<MuiSelectionOption<string> | null>((serviceInfo) => {
          if (!serviceInfo) {
            return null;
          }
          const value = buildKomodorUidFromServiceInfo(serviceInfo);
          return {
            render: () => (
              <CustomServiceSelection
                clusterName={serviceInfo.k8sCluster}
                namespace={serviceInfo.env}
                serviceName={serviceInfo.title}
              />
            ),
            label: serviceInfo.title,
            value: value,
            disabled:
              !!comparedServicesIds &&
              comparedServicesIds?.length >= MAX_COMPARED_SERVICES &&
              comparedServicesIds.length > 0 &&
              !comparedServicesIds.some((service) => {
                const uid = buildKomodorUidFromServiceInfo(serviceInfo);
                return service.value === uid;
              }),
          };
        })
        .filter((s): s is MuiSelectionOption<string> => s !== null),
    [baselineServiceId, comparedServicesIds, services]
  );
};

export const useBaselineServiceOptions = (
  baselineServiceId: MuiSelectionOption<string> | null,
  services: Dictionary<ServiceInfo>
): MuiSelectionOption<string>[] => {
  return useMemo(() => {
    return Object.values(services)
      .sort((a, b) => {
        const aUid = buildKomodorUidFromServiceInfo(a);
        const bUid = buildKomodorUidFromServiceInfo(b);
        if (aUid === baselineServiceId?.value) {
          return -1;
        }
        if (bUid === baselineServiceId?.value) {
          return 1;
        }
        return compareServiceInfo(a, b);
      })
      .map((serviceInfo) => {
        return {
          label: serviceInfo.title,
          value: buildKomodorUidFromServiceInfo(serviceInfo) ?? "",
        };
      });
  }, [baselineServiceId?.value, services]);
};
