import React from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useWorkloadsContainersMetrics } from "../../hooks/useWorkloadsContainersMetrics";
import { WorkloadsMetricsGraph } from "../WorkloadsGraph/WorkloadsMetricsGraph";

import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { EnlargedMetricsGraph } from "@/components/Metrics/graphs/EnlargedMetricsGraph";
import { MetricType } from "@/components/Metrics/types";
import { WorkloadMetricsPreferences } from "@/components/ResourceView/tabs/WorkloadMetricsTab/components/WorkloadMetricsPreferences/WorkloadMetricsPreferences";
import { useFetchContainersData } from "@/components/ResourceView/tabs/WorkloadMetricsTab/hooks/useFetchContainersData";
import { ContainerSelector } from "@/components/ResourceView/tabs/WorkloadMetricsTab/components/MetricsPerContainer/ContainerSelector";

type EnlargedContainerGraphProps = {
  komodorUids: string[];
  baselineKomodorUid: KomodorUidParams;
  type: MetricType;
  initialWantedContainer: MuiSelectionOption<string> | undefined;
  endTime: number;
  onClose: () => void;
  title?: string;
};

export const EnlargedWorkloadsContainersGraph: React.FC<
  EnlargedContainerGraphProps
> = ({
  baselineKomodorUid,
  komodorUids,
  type,
  initialWantedContainer,
  endTime,
  onClose,
  title,
}) => {
  const { containers, wantedContainer, setWantedContainer } =
    useFetchContainersData({
      komodorUidParams: baselineKomodorUid,
      initialWantedContainer,
      endTime,
    });

  const { graphProps, error, isLoading, hasGraphData, linesContent } =
    useWorkloadsContainersMetrics({
      komodorUids,
      type,
      wantedContainer,
      endTime: endTime,
    });

  return (
    <EnlargedMetricsGraph
      title={title}
      controls={[
        <ContainerSelector
          containers={containers}
          wantedContainer={wantedContainer}
          onChange={setWantedContainer}
          key={"containerSelector"}
        />,
        <WorkloadMetricsPreferences key={"metricsPreferences"} />,
      ]}
      onClose={onClose}
      uid={undefined}
      hasGraphData={hasGraphData}
      loading={isLoading}
      error={error}
    >
      <WorkloadsMetricsGraph
        komodorUids={komodorUids}
        type={type}
        linesContent={linesContent}
        {...graphProps}
      />
    </EnlargedMetricsGraph>
  );
};
