import { useState } from "react";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import { useWorkloadMetricsTabContext } from "../context/useWorkloadMetricsTabContext";
import { useMetricsGraphRequestParams } from "../components/graphs/useWorkloadMetrics";
import { useGetContainerOptions } from "../components/MetricsPerContainer/metricsPerContainerHooks";

import { useFetchWorkloadContainersMetricsData } from "./fetchWorkloadMetricsHooks";

import { useEpochsFromWorkloadMetricsTimeWindow } from "@/components/Metrics/hooks/workload/workloadMetricsTabHooks";
import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";

type UseFetchContainersDataProps = {
  komodorUidParams: KomodorUidParams;
  initialWantedContainer?: MuiSelectionOption<string>;
  endTime?: number;
};

export const useFetchContainersData = ({
  komodorUidParams,
  initialWantedContainer,
  endTime,
}: UseFetchContainersDataProps) => {
  const [wantedContainer, setWantedContainer] = useState<
    MuiSelectionOption<string> | undefined
  >(initialWantedContainer);
  const { initialLoadTime } = useWorkloadMetricsTabContext();
  const { fromEpoch, toEpoch } = useEpochsFromWorkloadMetricsTimeWindow(
    endTime || initialLoadTime
  );
  const requestParams = useMetricsGraphRequestParams(
    komodorUidParams,
    fromEpoch,
    toEpoch
  );

  // fetch data for both memory and cpu containers, which will be cached
  const { data: memoryRes } = useFetchWorkloadContainersMetricsData({
    ...requestParams,
    kind: "memory",
  });

  const { data: cpuRes } = useFetchWorkloadContainersMetricsData({
    ...requestParams,
    kind: "cpu",
  });
  const containers = useGetContainerOptions(memoryRes?.data || cpuRes?.data);

  return {
    wantedContainer: wantedContainer ?? containers?.[0],
    setWantedContainer,
    containers,
  };
};
