import { muiColors } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

export const Title = styled(Typography).attrs({
  variant: "h4",
  color: "text.primary",
})``;

export const TextContainer = styled(Typography).attrs({
  variant: "body2",
  color: "text.primary",
  component: "span",
})`
  display: block;
`;

export const LIST_PADDING = "16px";

export const StyledOrderedList = styled.ol`
  margin-block-start: 0;
  padding-left: ${LIST_PADDING};
`;

export const StyledUnorderedList = styled.ul<{ $customPaddingLeft?: string }>`
  margin-block-start: 0;
  padding-left: ${({ $customPaddingLeft }) =>
    $customPaddingLeft ?? LIST_PADDING};

  li::marker {
    font-size: 12px;
  }
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #0000ee;
`;

export const MetricsContentContainer = styled.div`
  display: grid;
  grid-template-columns: 200px auto;
  column-gap: 24px;
  row-gap: 32px;
`;

export const TextElementContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
`;

export const DiagramImg = styled.img`
  max-width: 720px;
  align-self: center;
`;

const CodeContainer = styled.div`
  padding: 0px 4px;
  border-radius: 4px;
  border: 1px solid ${muiColors.gray[200]};
  background-color: ${muiColors.gray[50]};
`;

const CodeText = styled(Typography).attrs({ variant: "code1" })``;

export const Code: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <CodeContainer>
    <CodeText>{children}</CodeText>
  </CodeContainer>
);

export const ProcessListContainerContainer = styled.div`
  width: 100%;
  background-color: ${muiColors.gray["50"]};
  border: 4px solid ${muiColors.gray["50"]};
  padding: 0 16px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  max-height: 300px;
`;
