import {
  ViolationsGroupBy,
  ViolationsState,
} from "@/components/reliability/ReliabilityTypes";
import { ImpactGroupIdentifier } from "@/generated/reliabilityApi";

type GetGroupDataParams = {
  groupState: ViolationsState["groups"];
  violationsGroupBy: ViolationsGroupBy;
  uid: string | undefined;
};
export const getGroupData = ({
  groupState,
  violationsGroupBy,
  uid,
}: GetGroupDataParams) => {
  const groupId = (uid ?? "") as ImpactGroupIdentifier;
  return groupState[violationsGroupBy].groupData[groupId];
};
