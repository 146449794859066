import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  AnalyticEvents,
  SegmentIntegrations,
} from "@/shared/config/analyticsEvents";
import { extractPathFromLocation } from "@/components/Actions/common/utils";
import useAnalyticsApi from "@/shared/context/analyticsProvider";
import { UseAgentTaskResult } from "@/shared/hooks/useAgentTask/useAgentTask";

type ExecuteAndReportParams = {
  execute: () => void;
  resourceType: string;
  reportType: string;
};

export const useExecuteAgentTaskAndReport = ({
  execute,
  resourceType,
  reportType,
}: ExecuteAndReportParams) => {
  const location = useLocation();
  const analytics = useAnalyticsApi();

  return useCallback(() => {
    execute();
    analytics.dispatchEventViaBackend(
      AnalyticEvents.Actions.Actions_fired,
      {
        type: reportType,
        path: extractPathFromLocation(location),
        resourceType: resourceType,
      },
      true,
      false,
      [SegmentIntegrations.Hubspot]
    );
  }, [analytics, execute, location, reportType, resourceType]);
};

type HandleAgentTaskResponseParams = {
  result: UseAgentTaskResult["result"];
  deniedObject: UseAgentTaskResult["deniedObject"];
  afterEffect: (() => void) | undefined;
  handleClose(): void;
  setActionSent: (value: boolean) => void;
};

export const useHandleAgentTaskResponse = ({
  result,
  deniedObject,
  afterEffect,
  handleClose,
  setActionSent,
}: HandleAgentTaskResponseParams) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (result && !deniedObject) {
      afterEffect?.();
      handleClose();
    }
  }, [afterEffect, deniedObject, handleClose, result]);

  useEffect(() => {
    if (deniedObject) {
      setActionSent(false);
      setShowErrorMessage(true);
    }
  }, [deniedObject, setActionSent]);

  return { showErrorMessage, setShowErrorMessage };
};
