import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  AreaChart,
  CartesianGrid,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";

import useAnalyticsApi from "../../../shared/context/analyticsProvider";
import { SegmentIntegrations } from "../../../shared/config/analyticsEvents";
import { MetricsGraphContainerProps } from "../types";
import {
  BorderStyle,
  GraphBackgroundColor,
  GraphChartMargin,
  GraphGridColor,
} from "../styles";
import { useMetricsZoom } from "../Zoom";
import { lastTickFormatter } from "../../common/EventsChart/ticks";
import { useMouseHover } from "../../common/EventsChart/useMouseHover";
import { SmartPopper } from "../../common/EventsChart/SmartPopper";
import { useGraphEvents } from "../hooks/useGraphEvents";
import { minimalEventIconsHeight } from "../constants";

import { useAvoidNoData } from "./hooks";

const EVENTS_GRAPH_HEIGHT = 48;

export const MetricsGraphContainer: React.FC<MetricsGraphContainerProps> = ({
  data,
  zoomProps,
  timelineTicksNum,
  children,
  tooltipContent,
  events,
  ariaLabels,
  tickFormatter,
  label,
  minimized,
  disableZoom,
  disableFormatTick,
  setTimeframe,
  xAxisProps,
  yAxisProps,
  syncId,
  constrictHeightForEvents = true,
  tickComponent: CustomTickComponent,
  areaChart,
}) => {
  const {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    zoom,
    currentData,
    ticks,
    TickComponent,
    domain,
    eventsData,
  } = useMetricsZoom({
    data,
    zoomProps,
    timelineTicksNum,
    events,
    setTimeframe,
  });
  const analytics = useAnalyticsApi();

  useEffect(() => {
    document.addEventListener(
      "mouseover",
      () => {
        const element = document.querySelector(
          `[aria-label='${ariaLabels.Graph}']`
        );
        if (element) {
          analytics.dispatchEventViaBackend(
            ariaLabels.Graph,
            {
              graph: ariaLabels.Name,
            },
            true,
            false,
            [SegmentIntegrations.Hubspot]
          );
        }
      },
      { once: true }
    );
  }, [analytics, ariaLabels]);

  const graphMargins = useMemo(() => {
    if (minimized) {
      return {};
    }

    const hasEvents = (events?.length ?? 0) > 0;
    const topMarginForEvents = constrictHeightForEvents
      ? EVENTS_GRAPH_HEIGHT
      : minimalEventIconsHeight;
    return {
      ...GraphChartMargin,
      top: hasEvents ? topMarginForEvents : 0,
    };
  }, [constrictHeightForEvents, events?.length, minimized]);

  const eventsOnGraph = useGraphEvents(events);

  const getGraphHeight = useCallback(
    (height: number) => {
      if (!constrictHeightForEvents) return height;
      return eventsData ? height * (minimized ? 0.8 : 0.85) : height;
    },
    [eventsData, minimized, constrictHeightForEvents]
  );

  const cleanData = useAvoidNoData(currentData);
  const { mouseOver, setMouseOver, mousePos, setMousePos } = useMouseHover();

  const componentRef = useRef<HTMLDivElement>(null);
  const boundingRect = componentRef.current?.getBoundingClientRect();

  const Chart = areaChart ? AreaChart : LineChart;
  return (
    <div
      ref={componentRef}
      style={{ height: "100%" }}
      aria-label={ariaLabels.Graph}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => {
        setMouseOver(false);
        setMousePos({ x: 0, y: 0 });
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <Chart
            width={width}
            height={getGraphHeight(height)}
            data={cleanData}
            margin={graphMargins}
            style={minimized && BorderStyle}
            syncId={syncId}
            onMouseDown={disableZoom ? undefined : onMouseDown}
            onMouseMove={(state, event: React.MouseEvent<SVGElement>) => {
              setMousePos({ x: event.clientX, y: event.clientY });
              !disableZoom && onMouseMove(state);
            }}
            onMouseUp={disableZoom ? undefined : onMouseUp}
          >
            <CartesianGrid
              stroke={GraphGridColor}
              strokeWidth="1"
              fill={GraphBackgroundColor}
            />
            <XAxis
              aria-label={ariaLabels.Xaxis}
              scale="linear"
              dataKey="time"
              domain={domain}
              type="number"
              tick={
                CustomTickComponent ? (
                  <CustomTickComponent />
                ) : (
                  <TickComponent />
                )
              }
              ticks={[...new Set(ticks)]}
              tickLine={false}
              axisLine={false}
              interval={0}
              hide={minimized}
              tickFormatter={lastTickFormatter}
              {...xAxisProps}
            />
            <YAxis
              aria-label={ariaLabels.Yaxis}
              tickFormatter={disableFormatTick ? undefined : tickFormatter}
              tickLine={false}
              axisLine={false}
              hide={minimized}
              fontSize={12}
              label={{
                value: label,
                style: { textAnchor: "middle", fontSize: 12 },
                angle: -90,
                position: "left",
                offset: -10,
              }}
              allowDataOverflow={true}
              padding={{ top: 5 }}
              {...yAxisProps}
            />
            <Tooltip
              isAnimationActive={false}
              content={(props) => (
                <SmartPopper
                  {...props}
                  open={mouseOver}
                  tooltipContent={tooltipContent}
                  mousePos={mousePos}
                  boundingRect={boundingRect}
                />
              )}
            />
            {eventsOnGraph}
            {children}
            {!minimized && !disableZoom && zoom}
          </Chart>
        )}
      </AutoSizer>
    </div>
  );
};
