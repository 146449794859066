import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import { PageRoutes } from "../../ReliabilityTypes";
import { PagePadding } from "../../ReliabilityStyles";
import { ReliabilityRoutes } from "../../constants/reliabilityConstants";
import { useReliabilityPages } from "../../hooks/useReliabilityPages";

const Container = styled.div`
  ${PagePadding};
  padding-block: 16px 0;
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledDivider = styled(Divider)`
  && {
    margin-top: -1px;
  }
`;

export const TopSection: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);
  const tabs = useReliabilityPages();

  const isRouteSelected = (route: PageRoutes) =>
    location.pathname.includes(route);

  const tabValue = useMemo(() => {
    if (location.pathname.includes(ReliabilityRoutes.bestPractices))
      return ReliabilityRoutes.bestPractices;
    return ReliabilityRoutes.violations;
  }, [location.pathname]);

  const onTabChange = (_: React.SyntheticEvent, newValue: string) => {
    if (isRouteSelected(newValue as PageRoutes)) return;
    navigate(newValue);
  };

  return (
    <Container ref={divRef}>
      {tabs && (
        <>
          <TabContext value={tabValue}>
            <TabList onChange={onTabChange}>
              <Tab
                label={"Issues"}
                value={ReliabilityRoutes.violations}
                aria-label={`${ReliabilityRoutes.violations} tab`}
              />
              {tabs.bestPractices && (
                <Tab
                  label={"Standards"}
                  value={ReliabilityRoutes.bestPractices}
                  aria-label={`${ReliabilityRoutes.bestPractices} tab`}
                />
              )}
            </TabList>
          </TabContext>
          <ButtonsContainer></ButtonsContainer>
          <StyledDivider />
        </>
      )}
    </Container>
  );
};
