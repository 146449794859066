import React, { useMemo } from "react";
import styled from "styled-components";
import { muiColors, muiPalette } from "@komodorio/design-system";
import Warning from "@mui/icons-material/Warning";

import { useCertificateData } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateData";
import { useSelectedCondition } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/CertificateDetails/StatusSection/useSelectedCondition";
import { FailureMessageLine } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/pages/FailureMessage/FailureMessageLine";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";

const Container = styled.div`
  padding: 16px 24px;
  background-color: ${muiColors.pink[25]};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  border-top: 1px solid ${muiColors.gray[200]};
  border-bottom: 1px solid ${muiColors.gray[200]};
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const { failedStatusMessage: failedStatusMessageAriaLabel } =
  certificatesPageAriaLabels.drawer;

export const FailureMessage: React.FC = () => {
  const { data: certificateData } = useCertificateData();
  const certificateStatus = certificateData?.data?.object.status;
  const conditionToUse = useSelectedCondition(certificateStatus?.conditions);

  const errorMessage = useMemo(() => {
    if (!conditionToUse) return null;
    if (conditionToUse?.type !== "Ready" || conditionToUse.status !== "False")
      return null;
    if (!conditionToUse.reason && !conditionToUse.message) return null;

    return (
      <FlexContainer>
        <FailureMessageLine
          type={"Reason:"}
          explanation={conditionToUse.reason}
        />
        <FailureMessageLine
          type={"Message:"}
          explanation={conditionToUse.message}
        />
      </FlexContainer>
    );
  }, [conditionToUse]);

  if (!errorMessage) return null;

  return (
    <Container aria-label={failedStatusMessageAriaLabel}>
      <Warning
        sx={{ width: "20px", height: "20px", color: muiPalette.error?.dark }}
      />
      {errorMessage}
    </Container>
  );
};
