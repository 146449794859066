import React, { useEffect } from "react";
import styled from "styled-components";
import { isUndefined } from "lodash";

import useIsFreeTier from "../Freemium/useIsFreeTier";
import { CenteredLoader } from "../../shared/components/CenteredLoader";

import { CostOptimizationTabs } from "./components/CostOptimizationTabs";
import { TopPageSection } from "./components/TopPageSection/TopPageSection";
import { selectSetLastVisitTime } from "./store/costOptimizationStoreSelectors";
import {
  resetCostOptimizationStore,
  useCostOptimizationStore,
} from "./store/costOptimizationStore";
import { CostOptimizationStaticPreview } from "./components/CostOptimizationStaticPreview/CostOptimizationStaticPreview";
import { useSupportedAgentVersions } from "./hooks/useSupportedAgentVersions";
import { UpgradeAgentVersionPrompt } from "./components/UpgradeAgentVersionPrompt/UpgradeAgentVersionPrompt";
import { RecommendationAccuracyPrompt } from "./components/RecommendationAccuracyPrompt/RecommendationAccuracyPrompt";
import { useInitialClustersEarliestRowTime } from "./hooks/earliestRowTimeHooks";
import { useReportLoadTime } from "./hooks/pageDataLoadedHooks";
import { ServiceResourceDrawer } from "./components/ServiceResourceDrawer/ServiceResourceDrawer";
import { useSetInitialTab } from "./hooks/useSetInitialTab";

import { useAgentsContext } from "@/shared/hooks/agents/context/useAgentsContext";

const StyledContainer = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LoaderContainer = styled.div`
  margin-top: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CostOptimizationView: React.FC = () => {
  const setLastVisitTime = useCostOptimizationStore(selectSetLastVisitTime);

  const allAgents = useAgentsContext();

  const { hasClustersData, hasMetricsSupprtedAgents } =
    useSupportedAgentVersions();

  useInitialClustersEarliestRowTime();
  useSetInitialTab();
  useReportLoadTime();

  useEffect(() => {
    setLastVisitTime(new Date().getTime());
  }, [setLastVisitTime]);

  useEffect(() => {
    return () => {
      resetCostOptimizationStore();
    };
  }, []);

  const isFreemiumAccount = useIsFreeTier();

  if (
    !hasClustersData &&
    (isUndefined(allAgents) || !!allAgents.activeAgents.length)
  ) {
    return (
      <LoaderContainer>
        <CenteredLoader />
      </LoaderContainer>
    );
  }

  if (!hasMetricsSupprtedAgents || isFreemiumAccount) {
    return (
      <CostOptimizationStaticPreview
        shouldShowNoClusterDetected={!allAgents?.activeAgents.length}
        isFreemiumAccount={isFreemiumAccount}
      />
    );
  }

  return (
    <StyledContainer>
      <TopPageSection />
      <UpgradeAgentVersionPrompt />
      <RecommendationAccuracyPrompt />
      <CostOptimizationTabs />
      <ServiceResourceDrawer />
    </StyledContainer>
  );
};
