import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash";

import { useKomodorServices } from "../../../../../shared/hooks/useKomodorServices";
import { getServiceMatchByIdentifiers } from "../types/getServiceMatchByIdentifier";
import { useIsAnonymousUser } from "../../../../../shared/hooks/useIsAnonymousUser";
import { useAppViewsStore } from "../../../../../shared/store/appViewsStore/appViewsStore";
import {
  currentAppViewSelector,
  selectedAppViewIdSelector,
  serviceIdsSelector,
  setSelectedAppViewIdSelector,
  setServiceIdsSelector,
} from "../../../../../shared/store/appViewsStore/appViewStoreSelectors";
import { useInterval } from "../../../../common/useInterval";
import { WORKSPACE_ROUTE } from "../../../../routes/routes";
import { useWorkspaces } from "../../../../workspaces/WorkspacesTopBar/hooks";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useAppViewStateInLocalStorage } from "./appViewPersistentState";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useFetchAndSetServiceIds } from "./fetchAppViewServices";

import { TypedWorkspace } from "@/shared/hooks/workspaces-api/types";
import {
  ServiceIdWorkspace,
  Workspace,
  WorkspaceKind,
  WorkspaceValue,
} from "@/generated/workspacesApi";

const FETCH_APP_VIEWS_INTERVAL = 30000;

export const useGetServiceIdsByServiceIdentifier = (): ((
  workspaceValue: WorkspaceValue,
  type: WorkspaceKind
) => string[] | undefined) => {
  const komodorServices = useKomodorServices().unscopedServices;
  return useCallback(
    (workspaceValue: WorkspaceValue, type: WorkspaceKind) => {
      return komodorServices
        ?.filter((service) =>
          getServiceMatchByIdentifiers({ type, service, workspaceValue })
        )
        .map<string>((service) => service.id)
        .sort();
    },
    [komodorServices]
  );
};

export const usePollServiceIdsInAppView = (appViewId?: string) => {
  const { isNewWorkspaceKind, isLoading } = useWorkspaces();
  const { refresh, error } = useFetchAndSetServiceIds(
    appViewId,
    !isLoading && isNewWorkspaceKind
  );
  useFetchServiceIdsOnAppViewEdit(refresh);
  useInterval(refresh, appViewId ? FETCH_APP_VIEWS_INTERVAL : null);
  useHandleAppViewFetchingError(error);
};

const useHandleAppViewFetchingError = (error: string | null) => {
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);
  const { setIsFetchingServicesData, isFetchingServicesData } =
    useKomodorServices();

  if (error && isFetchingServicesData) {
    // an error has occurred on initial fetch, bail out
    setSelectedAppViewId(undefined);
    setIsFetchingServicesData(false);
  }
};

const useFetchServiceIdsOnAppViewEdit = (refresh: () => void) => {
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const servicesPerAppViewRef = useRef<Workspace | undefined>(undefined);
  useEffect(() => {
    const currentAppViewHasServices =
      currentAppView.kind == WorkspaceKind.ServiceId;
    const refHasServices =
      servicesPerAppViewRef.current?.kind == WorkspaceKind.ServiceId;

    if (
      refHasServices &&
      currentAppView.id !== servicesPerAppViewRef.current?.id
    ) {
      // app view has changed, reset ref
      servicesPerAppViewRef.current = currentAppView;
      return;
    }

    if (currentAppViewHasServices && !refHasServices) {
      // set ref to current app view
      servicesPerAppViewRef.current = currentAppView;
      return;
    }

    if (
      currentAppViewHasServices &&
      refHasServices &&
      !isEqual(
        (currentAppView.value as ServiceIdWorkspace).serviceIds,
        (servicesPerAppViewRef.current?.value as ServiceIdWorkspace).serviceIds
      )
    ) {
      // app view was edited, request new data
      servicesPerAppViewRef.current = currentAppView;
      refresh();
    }
  }, [currentAppView, refresh]);
};

type SetIsFetchingDataParams = {
  workspaces?: TypedWorkspace[];
  appViewIdNotFound: boolean;
};
export const useSetIsFetchingData = ({
  workspaces,
  appViewIdNotFound,
}: SetIsFetchingDataParams): void => {
  const serviceIds = useAppViewsStore(serviceIdsSelector);
  const currentAppView = useAppViewsStore(currentAppViewSelector);
  const appViewsIsSet = !!currentAppView?.id;
  const { setIsFetchingServicesData, all } = useKomodorServices();
  const [appViewInLocalStorage] = useAppViewStateInLocalStorage();
  const isAnonymous = useIsAnonymousUser();

  useEffect(() => {
    const hasAppViewsInLocalStorage =
      !!appViewInLocalStorage && !!Object.keys(appViewInLocalStorage).length;
    if (
      !hasAppViewsInLocalStorage ||
      workspaces?.length === 0 ||
      appViewIdNotFound ||
      isAnonymous
    ) {
      setIsFetchingServicesData(false);
    } else if (workspaces) {
      if (hasAppViewsInLocalStorage && !appViewsIsSet) return;
      if (all?.length && serviceIds.length === all.length) {
        // remove loading when appView's service ID's have been fetched
        setIsFetchingServicesData(false);
      }
    }
  }, [
    all,
    appViewIdNotFound,
    appViewInLocalStorage,
    isAnonymous,
    appViewsIsSet,
    setIsFetchingServicesData,
    serviceIds.length,
    workspaces,
  ]);
};

export const useIsInAppViewsPath = (): boolean => {
  const location = useLocation();
  return location.pathname.includes(WORKSPACE_ROUTE);
};

export const useOnAppViewChange = (cb: () => void): void => {
  const selectedAppViewId = useAppViewsStore(selectedAppViewIdSelector);
  const prevAppViewId = useRef<string | undefined>(selectedAppViewId);

  useLayoutEffect(() => {
    if (prevAppViewId.current) {
      cb();
    }
    prevAppViewId.current = selectedAppViewId;
  }, [cb, selectedAppViewId]);
};

export const useSetServiceIdsAndScope = (): ((
  serviceIds?: string[]
) => void) => {
  const setServiceIds = useAppViewsStore(setServiceIdsSelector);
  const { setServicesScope } = useKomodorServices();

  return useCallback(
    (serviceIds: string[] = []) => {
      setServiceIds(serviceIds);
      setServicesScope(serviceIds);
    },
    [setServiceIds, setServicesScope]
  );
};
