import { useMemo } from "react";

import { defaultSelectedViolationFilters } from "../constants/violationFiltersConstants";

import { useGetImpactGroupType } from "./useSelectedTab";

import {
  ImpactGroupType,
  ViolationCountBy,
  ViolationFilterOperator,
} from "@/generated/reliabilityApi";
import { Dictionary } from "@/shared/types/Dictionary";
import { FilterOptionInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

export const getImpactGroupTypeFilter = (
  impactGroupType: ImpactGroupType
): Dictionary<FilterOptionInUrl[]> => {
  const values = [
    impactGroupType,
    ...(impactGroupType === ImpactGroupType.Dynamic
      ? [ImpactGroupType.Realtime]
      : []),
  ];

  return {
    [ViolationCountBy.ImpactGroupType]: [
      {
        values,
        operator: ViolationFilterOperator.Include,
      },
    ],
  };
};

export const useDefaultFilters = (): Dictionary<FilterOptionInUrl[]> => {
  const impactGroupTypeByTab = useGetImpactGroupType();

  return useMemo(
    () => ({
      ...defaultSelectedViolationFilters,
      ...getImpactGroupTypeFilter(impactGroupTypeByTab),
    }),
    [impactGroupTypeByTab]
  );
};
