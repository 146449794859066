import React from "react";

import { PageContainer } from "../../../ReliabilityStyles";

import { ViolationsPageContent } from "./ViolationsPageContent";

import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";
import { RELIABILITY_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { SEARCH_TYPE_FILTER_OPERATOR } from "@/components/reliability/components/FiltersSection/filterSectionConstants";
import { useLoadingStateHooks } from "@/components/reliability/hooks/useLoadingStateHooks";

export const ViolationsPage: React.FC = () => {
  useLoadingStateHooks();
  return (
    <PageContainer>
      <FiltersContextProvider
        urlParamKey={RELIABILITY_PARAM_KEY}
        searchTypeFilterOperator={SEARCH_TYPE_FILTER_OPERATOR}
      >
        <ViolationsPageContent />
      </FiltersContextProvider>
    </PageContainer>
  );
};
