import { LinkCell } from "@komodorio/design-system/komodor-ui";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

export const SourceCell: React.FC<{ sourceKomodorUid: string | undefined }> = ({
  sourceKomodorUid,
}) => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const {
    cluster = "",
    namespace = "",
    kind = "",
    name = "",
  } = parseKomodorUid(sourceKomodorUid ?? "") ?? {};

  return (
    <LinkCell
      onClick={() => {
        pushDrawer({
          drawerType: DrawerType.ResourceDrawerByData,
          cluster,
          namespace,
          resourceType: kind,
          resourceName: name,
        });
      }}
    >
      {name}
    </LinkCell>
  );
};
