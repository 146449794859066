import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { capitalize } from "lodash";
import { TaskType } from "komodor-types";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  useActionMetadata,
  useReportAction,
} from "@/components/Actions/modals/SuspendResumeCronjobModal/suspendResumeCronjobHooks";
import useAgentTask from "@/shared/hooks/useAgentTask/useAgentTask";
import {
  SuspendResumeActionType,
  ResourceData,
} from "@/components/Actions/modals/SuspendResumeCronjobModal/suspendResumeCronjobTypes";
import {
  useExecuteAgentTaskAndReport,
  useHandleAgentTaskResponse,
} from "@/components/Actions/modals/actionModalHooks";
import { ErrorMessage } from "@/components/Actions/common/ErrorMessage";
import { useSetSnackbarNotification } from "@/shared/store/snackbarNotificationStore/snackbarNotificationHooks";
import { AriaLabels } from "@/shared/config/ariaLabels";

const Container = styled.div`
  padding: 16px 24px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  min-width: 400px;
  margin-bottom: 24px;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 16px;
  }
`;

const StyledIconButton = styled(IconButton)`
  height: fit-content;
`;

const { SuspendResumeCronjob: suspendResumeAriaLabel } =
  AriaLabels.Inspection.Actions.Modals;

type SuspendResumeCronjobModalProps = {
  open: boolean;
  onClose: (open: boolean) => void;
  actionType: SuspendResumeActionType;
  onActionComplete?: () => void;
  resourceData: ResourceData;
  reportAction?: boolean;
  afterEffect?: () => void;
};

export const SuspendResumeCronjobModal: React.FC<
  SuspendResumeCronjobModalProps
> = ({
  open,
  onClose,
  actionType,
  resourceData,
  afterEffect,
  reportAction,
}) => {
  const [actionSent, setActionSent] = useState(false);
  const [isWaitingForAtmResult, setIsWaitingForAtmResult] = useState(false);
  const reportActionToStore = useReportAction();
  const setSnackbarNotification = useSetSnackbarNotification();

  const title = actionType === "suspend" ? "Suspend cronjob" : "Resume cronjob";

  const metadata = useActionMetadata(actionType, resourceData);
  const { execute, result, deniedObject, isFetching, resetAgentTask } =
    useAgentTask(resourceData.agentId, TaskType.ACTION_COMMAND, metadata);

  const handleClose = () => {
    onClose(false);
    resetAgentTask();
  };

  const executeAndReport = useExecuteAgentTaskAndReport({
    execute,
    resourceType: "cronjob",
    reportType: `cronjob${capitalize(actionType)}`,
  });

  const { showErrorMessage, setShowErrorMessage } = useHandleAgentTaskResponse({
    result,
    deniedObject,
    handleClose,
    setActionSent,
    afterEffect,
  });

  const handleClick = () => {
    executeAndReport();
    setShowErrorMessage(false);
    setActionSent(true);
    setIsWaitingForAtmResult(true);
  };

  useEffect(() => {
    if (isWaitingForAtmResult && result) {
      // action was completed successfully
      setIsWaitingForAtmResult(false);
      setSnackbarNotification({
        message: `${capitalize(actionType)} action was successful`,
      });
      if (reportAction) {
        reportActionToStore(resourceData);
      }
    }
  }, [
    actionType,
    isWaitingForAtmResult,
    reportAction,
    reportActionToStore,
    resourceData,
    result,
    setSnackbarNotification,
  ]);

  const disableButton = isFetching || !!deniedObject || actionSent;

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <Container aria-label={suspendResumeAriaLabel}>
          <Content>
            <div>
              <Title variant={"h3"}> {title}</Title>
              <Typography variant={"body2"}>
                {`This action will ${actionType} the CronJob:`}
              </Typography>
              <Typography variant={"h4"} fontWeight={"bold"}>
                {resourceData.name}
              </Typography>
            </div>
            <StyledIconButton onClick={handleClose}>
              <CloseIcon />
            </StyledIconButton>
          </Content>
          {showErrorMessage && deniedObject !== undefined && (
            <ErrorMessage reason={deniedObject} />
          )}
          <DialogActions>
            <Button onClick={handleClose} variant={"text"}>
              Cancel
            </Button>
            <LoadingButton
              onClick={handleClick}
              variant={"contained"}
              autoFocus
              loading={isFetching}
              disabled={disableButton}
            >
              {capitalize(actionType)}
            </LoadingButton>
          </DialogActions>
        </Container>
      </Dialog>
    </>
  );
};
