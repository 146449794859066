import React from "react";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import { compact } from "lodash";
import Typography from "@mui/material/Typography";

import { useFetchLiveStateList } from "@/components/k8sAddons/hooks/table/useFetchLiveStateList";
import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import {
  columnsConfig,
  useLoadingColumns,
  useTableColumns,
} from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/hooks/nodeAutoscalerTableHooks";
import { useCommonTableProps } from "@/components/k8sAddons/hooks/table/useCommonTableProps";
import { useCommonLiveStateTableProps } from "@/components/k8sAddons/hooks/table/useCommonLiveStateTableProps";
import { nodeAutoscalersPageAriaLabels } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/autoscalersAriaLabels";
import {
  PageTableContainer,
  StyledLiveStateListDataGrid,
} from "@/components/k8sAddons/styles";
import { useCommonDataGridProps } from "@/components/k8sAddons/hooks/table/useCommonDataGridProps";

export const NodeAutoscalersTable: React.FC = () => {
  const { searchFilter } = useSearchFieldAsGenericFilter({
    filterName: columnsConfig.name.field,
  });

  const response = useFetchLiveStateList({
    additionalRequestFilters: compact([searchFilter]),
  });

  const { isFetchingData, rowCount, rowsToUse, columnsToUse, title, error } =
    useCommonTableProps({
      response,
      loadingStateColumns: useLoadingColumns(),
      dataStateColumns: useTableColumns(),
      tableTitle: "Autoscalers",
    });

  const commonLiveStateTableProps =
    useCommonLiveStateTableProps(isFetchingData);

  const ariaLabel = isFetchingData
    ? `${nodeAutoscalersPageAriaLabels.table} loading`
    : `${nodeAutoscalersPageAriaLabels.table} content`;

  return (
    <PageTableContainer>
      <Typography variant="h4">{title}</Typography>
      <StyledLiveStateListDataGrid
        {...useCommonDataGridProps(error)}
        {...commonLiveStateTableProps}
        aria-label={ariaLabel}
        rows={rowsToUse ?? []}
        columns={columnsToUse as GridColDef<GridValidRowModel>[]}
        rowCount={rowCount}
        getRowId={(row) => row.id}
      />
    </PageTableContainer>
  );
};
