import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { palette } from "@komodorio/design-system";
import {
  ActionMetadataCordonUncordon,
  ActionTypes,
  TaskType,
} from "komodor-types";
import { capitalize } from "lodash";

import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import { ModalGrid } from "../DeleteModal/DeleteModal";
import { ErrorMessage } from "../../common/ErrorMessage";

import {
  useExecuteAgentTaskAndReport,
  useHandleAgentTaskResponse,
} from "@/components/Actions/modals/actionModalHooks";

interface DrainModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  nodeName: string;
  cluster: string;
  agentId: string;
  afterEffect?: () => void;
}

const DrainModal: React.FC<DrainModalProps> = ({
  isModalOpen,
  handleClose,
  nodeName,

  cluster,
  agentId,
  afterEffect,
}) => {
  const [actionSent, setActionSent] = useState(false);

  const actionType = ActionTypes.Drain;
  const actionTypeCap = capitalize(actionType);

  const metadata: ActionMetadataCordonUncordon = {
    cluster: cluster,
    type: actionType,
    resourceType: "node",
    resourceName: nodeName,
  };

  const serviceDetails = [
    {
      title: "Node name:",
      data: nodeName,
    },
    {
      title: "Cluster:",
      data: cluster,
    },
  ];

  const { execute, result, deniedObject } = useAgentTask(
    agentId,
    TaskType.ACTION_COMMAND,
    metadata
  );

  const executeAndReport = useExecuteAgentTaskAndReport({
    execute,
    resourceType: "node",
    reportType: actionType,
  });

  const { showErrorMessage } = useHandleAgentTaskResponse({
    result,
    deniedObject,
    handleClose,
    setActionSent,
    afterEffect,
  });

  return (
    <Modal isOpen={isModalOpen} width="28rem" onRequestClose={handleClose}>
      <ModalHeader>
        <Typography variant="headline">{actionTypeCap} Node</Typography>
      </ModalHeader>
      <ModalContent>
        <Typography size="medium">
          This action will <b>{actionType}</b> the following node:
          <br />
          <br />
          <ModalGrid>
            {serviceDetails.map((e, i) => (
              <React.Fragment key={i}>
                <Typography size="medium" color={palette.gray[600]}>
                  {e.title}
                </Typography>
                <Typography size="medium" color={palette.gray[800]}>
                  {e.data}
                </Typography>
              </React.Fragment>
            ))}
          </ModalGrid>
        </Typography>
        {showErrorMessage && deniedObject !== undefined && (
          <ErrorMessage reason={deniedObject} />
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="small"
          disabled={actionSent}
          onClick={() => {
            executeAndReport();
            setActionSent(true);
          }}
        >
          {actionTypeCap}
          {actionSent ? "..." : ""}
        </Button>
      </ModalActions>
    </Modal>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default DrainModal;
