import React from "react";
import Stack from "@mui/material/Stack";

import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import EnableAiBanner from "@/components/ResourceView/AiInvestigation/common/EnableAiBanner";
import { useResourceAiInvestigation } from "@/components/ResourceView/AiInvestigation/common/useResourceAiInvestigation";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { InvestigationContent } from "@/components/ResourceView/AiInvestigation/common/InvestigationContent";
import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import type Resource from "@/components/ResourceView/resources";
import { InvestigationTitle } from "@/components/ResourceView/AiInvestigation/common/InvestigationTitle";
import { FlowType } from "@/components/ResourceView/AiInvestigation/common/types";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";

export const RootCauseAnalysisSection: React.FC<{
  resource: Resource;
  isHealthy: boolean;
}> = ({ resource, isHealthy }) => {
  const { data: features } = useAccountFeatures();

  const {
    enabled,
    richSession,
    isError,
    isLoading,
    onRetryCallback,
    onShareClicked,
  } = useResourceAiInvestigation({
    drawerData: {
      drawerType: DrawerType.ResourceDrawerByData,
      cluster: resource.cluster,
      namespace: resource.namespace,
      resourceType: resource.kind,
      resourceName: resource.name,
    },
    requestId: resource.id,
    resource,
    isHealthy,
    flowType: FlowType.UnsyncedExternalDns,
  });

  if (!enabled || (isHealthy && !richSession)) {
    return null;
  }

  return (
    <AccordionWrapper
      title={"Root Cause Analysis"}
      accordionSummary={
        <InvestigationTitle
          richSession={richSession}
          onShareClicked={onShareClicked}
          titleProps={{
            variant: "h5",
          }}
        />
      }
      accordionContent={
        <Stack gap="4px">
          {!features.klaudiaAi && (
            <EnableAiBanner title="Root Cause Analysis" />
          )}
          <InvestigationContent
            richSession={richSession}
            isError={isError}
            onRetryCallback={onRetryCallback}
            isLoading={isLoading}
            showBorder={false}
          />
        </Stack>
      }
      ariaLabel={
        externalDnsListPageAriaLabels.drawer.sections.rootCauseAnalysis
      }
    />
  );
};
