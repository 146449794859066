import React from "react";

import { AccordionWrapper } from "@/components/k8sAddons/LiveStateDrawer/components/AccordionWrapper";
import { ExternalDnsRecordFull } from "@/generated/addonsApi";
import { externalDnsListPageAriaLabels } from "@/components/k8sAddons/addons/externalDns/ariaLabels";
import { ManagedRecordsTable } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/ManagedRecordsTable";

export const ManagedRecordsSection: React.FC<{
  externalDns: ExternalDnsRecordFull;
}> = ({ externalDns }) => {
  const { managedRecords } = externalDns;
  if (!managedRecords) {
    return null;
  }

  return (
    <AccordionWrapper
      title={`Managed Records (${managedRecords.length})`}
      accordionContent={<ManagedRecordsTable managedRecords={managedRecords} />}
      ariaLabel={
        externalDnsListPageAriaLabels.drawer.sections.managedRecords.section
      }
    />
  );
};
