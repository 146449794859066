export const logsViewerAriaLabels = {
  liveTailToggle: {
    pause: "Pause pod logs live tail",
    resume: "Resume pod logs live tail",
  },
  dialog: {
    checkbox: {
      tailToggle: "Toggle tail",
      sinceToggle: "Toggle since",
      showLineNumber: "Show line number",
      showTimestamp: "Show timestamp",
      wrapText: "Wrap text",
    },
    button: {
      save: "Save logs settings",
      close: "Close logs settings",
    },
  },
};
