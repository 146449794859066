import React from "react";
import styled from "styled-components";

import { useGetGroupBy } from "../../../../hooks/useGetGroupBy";
import { SectionTitle } from "../../../shared/SectionTitle/SectionTitle";

import { GroupBySelector } from "./GroupBySelector";
import { TotalViolations } from "./TotalViolations";
import { GroupedViolations } from "./GroupedViolations";
import { TimeWindowSelector } from "./TimeWindowSelector";

import { useIsOldReliability } from "@/components/reliability/hooks/useHealthType";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { GroupedViolationsByNone } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/GroupedViolationsByNone";

const TOOLTIP_TEXT =
  "These risks are monitored at intervals of up to a day, capturing patterns and trends that may signal potential issues. This approach helps identify recurring problems or gradual changes that could compromise the long-term health and stability of your environment";

const TopSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const SelectorsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const {
  sections: { reliabilityRisks: reliabilityRisksSectionAriaLabel },
} = reliabilityArialLabels;

export const ViolationsBreakdown: React.FC = () => {
  const groupBy = useGetGroupBy();
  const isOldReliability = useIsOldReliability();

  return (
    <div aria-label={reliabilityRisksSectionAriaLabel}>
      {!isOldReliability && (
        <SectionTitle title="Reliability Risks" tooltipText={TOOLTIP_TEXT} />
      )}
      <TopSectionContainer>
        <TotalViolations />
        <SelectorsContainer>
          <GroupBySelector />
          {isOldReliability && <TimeWindowSelector />}
        </SelectorsContainer>
      </TopSectionContainer>
      {groupBy === "None" ? <GroupedViolationsByNone /> : <GroupedViolations />}
    </div>
  );
};
