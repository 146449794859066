import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { palette } from "@komodorio/design-system";

import { FetchPodLogsQuery } from "../../../generated/graphql";
import { PodLogsMode } from "../../../shared/hooks/podLogs/types";
import { useCloseLivePodLogsSessionIfNeeded } from "../../ResourceView/useCloseLivePodLogsSessionIfNeeded";
import { useActiveAgent } from "../../../shared/hooks/useAgents";
import { useShouldUseLivePodsLogs } from "../../../shared/hooks/podLogs/useShouldUseLivePodsLogs";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { LogsBlackText } from "./LogsBlackText";
import SearchInLog from "./PodLogsBar/SearchInLog";
import LogsActions from "./PodLogsBar/LogsActions";
import { LogsAiDemo } from "./LogsAi/LogsAiDemo";
import {
  LogsAiAnalyzer,
  LogsAiAnalyzerDelimiter,
} from "./LogsAi/LogsAiAnalyzer";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import LogsViewerModal from "./LogsViewerModal";
import { LogsViewerProvider } from "./LogsViewerContext";
import { useLogsMaxLinesNumber } from "./LogsAi/useLogsMaxLinesNumber";
import { LogEvent, LogsType } from "./logsTypes";
import { useLogsViewerStore } from "./store/logsViewerStore";
import {
  selectViewSettings,
  setViewSettings,
} from "./store/logsViewerStoreSelectors";

import { usePodLogsStore } from "@/shared/hooks/podLogs/store/podLogsStore";
import { selectPodLogsDirection } from "@/shared/hooks/podLogs/store/podLogsStoreSelectors";
import { PodLogsDirection } from "@/shared/hooks/podLogs/store/types";

const LogsViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Toolbar = styled.div<{
  toolbarTopOffset?: string;
  toolbarBgColor?: string;
}>`
  display: flex;
  justify-content: space-between;
  padding-block: 0.5rem;
  position: sticky;
  z-index: 1;
  ${({ toolbarTopOffset, toolbarBgColor }) =>
    `top: ${toolbarTopOffset ?? 0};
     background-color: ${toolbarBgColor ?? "inherit"};`}
`;

const LinesCounter = styled.div`
  padding-top: 4px;
  font-size: 12px;
  color: ${palette.gray["500"]};
`;

export const ToolbarItems = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export interface LogsViewerProps {
  podLogsRes: FetchPodLogsQuery["pod_logs"];
  podName: string;
  containerName: string;
  failureMessage?: string | null;
  containerPickerElement?: JSX.Element;
  previousContainerControl?: null | JSX.Element;
  loading?: boolean;
  requestedCount?: number;
  onLoadMoreClicked?: () => void;
  toolbarTopOffset?: string;
  toolbarBgColor?: string;
  logsType?: LogsType;
  maxHeight?: string;
  showSearchBar?: boolean;
  showEnlargeButton?: boolean;
  events?: LogEvent[];
  runAutoAnalyze?: boolean;
  clusterName: string;
  namespace: string;
}

export const LogsViewer: React.FC<LogsViewerProps> = ({
  podLogsRes,
  podName,
  containerName,
  clusterName,
  namespace,
  failureMessage = null,
  containerPickerElement,
  previousContainerControl,
  loading,
  requestedCount,
  onLoadMoreClicked,
  toolbarTopOffset,
  toolbarBgColor,
  logsType,
  maxHeight,
  showSearchBar = true,
  showEnlargeButton = true,
  events,
  runAutoAnalyze = false,
}) => {
  const useLivePodLogs = useShouldUseLivePodsLogs(clusterName);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(0);
  const [scrollIndex, setScrollIndex] = useState(1);
  const [focusSearchBar, setFocusSearchBar] = useState(false);

  const logsViewSettings = useLogsViewerStore(selectViewSettings);
  const setLogsViewSettings = useLogsViewerStore(setViewSettings);

  const mode = useLivePodLogs ? PodLogsMode.Live : PodLogsMode.ByDemand;
  const closeSocketSession = useCloseLivePodLogsSessionIfNeeded();
  const agentId = useActiveAgent(clusterName || "");

  useEffect(() => {
    return () => {
      mode === PodLogsMode.Live &&
        closeSocketSession({
          containerName: containerName,
          namespace: namespace ?? "",
          podName: podName,
          agentId,
        });
    };
  }, [agentId, closeSocketSession, containerName, mode, namespace, podName]);

  const [isOpen, setIsOpen] = useState(false);
  const onEnlarge = () => {
    setLogsViewSettings({ ...logsViewSettings, wrapText: false });
    setIsOpen(true);
  };

  const logsMaxLinesNumber = useLogsMaxLinesNumber();
  const podLogsDirection = usePodLogsStore(selectPodLogsDirection);
  const logToAnalyze = useMemo(() => {
    const logs = podLogsRes.map((r) => `${r.logTime}  ${r.message}`).sort();

    if (
      mode === PodLogsMode.ByDemand ||
      podLogsDirection === PodLogsDirection.NewestFirst
    ) {
      logs.reverse();
    }

    return logs.slice(0, logsMaxLinesNumber).join(LogsAiAnalyzerDelimiter);
  }, [podLogsRes, mode, podLogsDirection, logsMaxLinesNumber]);

  const logsViewerContent = (
    <LogsViewerContainer>
      <Toolbar
        toolbarTopOffset={toolbarTopOffset}
        toolbarBgColor={toolbarBgColor}
      >
        <ToolbarItems>
          {containerPickerElement}
          {previousContainerControl}
          {showSearchBar && (
            <SearchInLog
              isSearchFocused={focusSearchBar}
              setIsSearchFocused={setFocusSearchBar}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchResults={searchResults}
              scrollIndex={scrollIndex}
              setScrollIndex={setScrollIndex}
            />
          )}
        </ToolbarItems>
        <ToolbarItems>
          <LogsActions
            podName={podName ?? containerName}
            podLogsRes={podLogsRes}
            mode={mode}
            onEnlarge={showEnlargeButton ? onEnlarge : undefined}
          />
        </ToolbarItems>
      </Toolbar>
      <LogsAiAnalyzer
        clusterName={clusterName}
        namespace={namespace}
        podName={podName}
        containerName={containerName}
        runAutoAnalyze={runAutoAnalyze}
        numberOfLines={podLogsRes.length}
        log={logToAnalyze}
      />
      <br />
      <LogsAiDemo />
      <LogsBlackText
        logs={podLogsRes}
        failureMessage={failureMessage}
        loading={loading}
        searchTerm={searchTerm}
        setSearchResults={setSearchResults}
        scrollIndex={scrollIndex}
        onLoadMoreClicked={onLoadMoreClicked}
        containerName={containerName}
        logsType={logsType}
        maxHeight={maxHeight}
        events={events}
        mode={mode}
      />
      {mode === PodLogsMode.ByDemand && (
        <LinesCounter>{`Received ${podLogsRes.length} lines`}</LinesCounter>
      )}
    </LogsViewerContainer>
  );

  return (
    <>
      <LogsViewerProvider value={{ isInModal: false }}>
        {!isOpen && logsViewerContent}
      </LogsViewerProvider>
      <LogsViewerProvider value={{ isInModal: true }}>
        <LogsViewerModal
          isOpen={isOpen}
          handleCloseModal={() => setIsOpen(false)}
          podLogsRes={podLogsRes}
          podName={podName}
          clusterName={clusterName}
          containerName={containerName}
          namespace={namespace}
          failureMessage={failureMessage}
          containerPickerElement={containerPickerElement}
          previousContainerControl={previousContainerControl}
          loading={loading}
          requestedCount={requestedCount}
          onLoadMoreClicked={onLoadMoreClicked}
          toolbarTopOffset={toolbarTopOffset}
          toolbarBgColor={toolbarBgColor}
          logsType={logsType}
          maxHeight={maxHeight}
          showSearchBar={showSearchBar}
          events={events}
          runAutoAnalyze={runAutoAnalyze}
          logsViewer={logsViewerContent}
        />
      </LogsViewerProvider>
    </>
  );
};
