import {
  DEFAULT_BEST_PRACTICES_TAB_GROUP_BY,
  DEFAULT_VIOLATION_TAB_GROUP_BY,
} from "../constants/reliabilityConstants";

import { useReliabilityDataInUrl } from "./useReliabilityDataInUrl";
import { useSelectedTab } from "./useSelectedTab";

import { ImpactGroupType } from "@/generated/reliabilityApi";

export const useDefaultGroupBy = () => {
  const selectedTab = useSelectedTab();

  return selectedTab === "violations"
    ? DEFAULT_VIOLATION_TAB_GROUP_BY
    : DEFAULT_BEST_PRACTICES_TAB_GROUP_BY;
};

export const useGetGroupBy = (overrideImpactGroupType?: ImpactGroupType) => {
  const [{ groupBy }] = useReliabilityDataInUrl();

  const defaultGroupBy = useDefaultGroupBy();

  if (overrideImpactGroupType) {
    if (overrideImpactGroupType === ImpactGroupType.Realtime) {
      return "RealTimeIssues";
    }
  }

  return groupBy || defaultGroupBy;
};
