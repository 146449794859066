import React from "react";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import type { BreadcrumbsProps as MuiBreadcrumbsProps } from "@mui/material/Breadcrumbs";
import styled from "styled-components";

const EllipsisTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Breadcrumbs = ({
  breadcrumbs,
  className,
  allowOverflow = true,
  sx,
}: {
  className?: string;
  breadcrumbs: string[];
  allowOverflow?: boolean;
  sx?: MuiBreadcrumbsProps["sx"];
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      className={className}
    >
      {breadcrumbs.length > 0 && (
        <MuiBreadcrumbs
          sx={
            allowOverflow
              ? sx
              : {
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  "& .MuiBreadcrumbs-ol": {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                    "& .MuiBreadcrumbs-li": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      flex: "0 auto",
                      flexShrink: "1000",
                      display: "inline-block",
                    },
                  },
                  ...(sx ?? {}),
                }
          }
          separator={
            <ChevronRight fontSize="small" sx={{ width: 16, height: 16 }} />
          }
        >
          {breadcrumbs.map((breadcrumb) => (
            <EllipsisTypography
              key={breadcrumb}
              variant="body3"
              color="text.secondary"
              title={breadcrumb}
            >
              {breadcrumb}
            </EllipsisTypography>
          ))}
        </MuiBreadcrumbs>
      )}
    </Box>
  );
};
