import { Status } from "@komodorio/design-system/komodor-ui";

import { ExternalDnsManagedRecordStatusEnum } from "@/generated/addonsApi";

export const StatusCell: React.FC<{ status: string | undefined }> = ({
  status,
}) => {
  if (!status) {
    return null;
  }

  const color =
    status === ExternalDnsManagedRecordStatusEnum.Available
      ? "success"
      : status === ExternalDnsManagedRecordStatusEnum.Unavailable
      ? "error"
      : "neutral";

  return <Status label={status} textTransform="capitalize" color={color} />;
};
