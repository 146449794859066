import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";

import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "../../../shared/types";
import { useWindowResize } from "../../../../../../hooks";

const getAbsoluteDimension = (
  element: Element,
  dimension: "width" | "height"
) => {
  if (!element) return 0;
  const box = element.getBoundingClientRect();
  if (dimension === "height") {
    return box.y + box.height;
  }

  return box.x + box.width;
};

type SetMaxChildrenInContainerParams<T extends MuiSelectionOptionValue> = {
  containerEl?: HTMLElement | null;
  hiddenContentEl?: HTMLElement | null;
  hiddenContentChipDimension?: number;
  selectedItems: MuiSelectionOption<T>[];
  enableLineBreaks?: boolean;
  restrictedDimension: "width" | "height";
};

export const useSetMaxChildrenInContainer = <
  T extends MuiSelectionOptionValue
>({
  containerEl,
  hiddenContentEl,
  hiddenContentChipDimension,
  selectedItems,
  enableLineBreaks,
  restrictedDimension,
}: SetMaxChildrenInContainerParams<T>) => {
  const [maxChildren, setMaxChildren] = useState<number>(0);

  const updateMaxChildren = useCallback(() => {
    if (
      !containerEl ||
      !hiddenContentChipDimension ||
      !hiddenContentEl ||
      (enableLineBreaks && restrictedDimension === "width")
    )
      return;

    const allChildren: HTMLCollection = hiddenContentEl.children;
    const containerDimension = getAbsoluteDimension(
      containerEl,
      restrictedDimension
    );

    let maxChildren = 0,
      index = 0;
    for (const child of allChildren) {
      const childDimension = getAbsoluteDimension(child, restrictedDimension);
      if (childDimension > containerDimension) {
        const prevChildDimension = getAbsoluteDimension(
          allChildren[index - 1],
          restrictedDimension
        );
        if (
          prevChildDimension + hiddenContentChipDimension >
          containerDimension
        ) {
          maxChildren = Math.max(index - 1, 1);
        } else {
          maxChildren = index;
        }
        break;
      } else {
        maxChildren = index + 1;
      }
      index++;
    }
    setMaxChildren(maxChildren);
  }, [
    containerEl,
    enableLineBreaks,
    hiddenContentChipDimension,
    hiddenContentEl,
    selectedItems,
    restrictedDimension,
  ]);

  useEffect(() => {
    updateMaxChildren();
  }, [updateMaxChildren]);

  const { callback: debouncedOnResize } = useDebouncedCallback(
    updateMaxChildren,
    100
  );

  useEffect(() => {
    updateMaxChildren();
  }, [containerEl]);

  useWindowResize(debouncedOnResize);

  return enableLineBreaks && restrictedDimension === "width"
    ? 0
    : Math.max(maxChildren, 1);
};

export const useGetMaxContainerDimension = (
  divRef: React.RefObject<HTMLDivElement>,
  dimension: "width" | "height"
) => {
  const [maxDimension, setMaxDimension] = useState<number>(0);
  const lastWindowDimension = useRef<number>(0);

  const onResize = useCallback(() => {
    const windowDim =
      dimension === "width" ? window.innerWidth : window.innerHeight;
    if (divRef.current && windowDim !== lastWindowDimension.current) {
      lastWindowDimension.current = windowDim;
      setMaxDimension(
        dimension === "width"
          ? divRef.current.clientWidth
          : divRef.current.clientHeight
      );
    }
  }, [divRef.current, dimension]);

  const { callback: debouncedOnResize } = useDebouncedCallback(onResize, 100);

  useLayoutEffect(() => {
    onResize();
  }, [divRef.current]);

  useWindowResize(debouncedOnResize);

  return maxDimension;
};
