import { useAccountChooseHandler } from "../../../AccountSwitcher/hooks/useAccountChooseHandler";
import { useAccountCorrector } from "../../../AccountSwitcher/hooks/useAccountCorrector";
import type { CmdKEntry } from "../DevCommandBar";

import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { useAccountsService } from "@/shared/hooks/accounts-service/useAccountsService";
import { Account } from "@/generated/accounts";

export const useAccountItems = (): {
  items: CmdKEntry[] | null;
  onAccountClick: (account: { value: string; label: string }) => void;
} | null => {
  const { accountId } = useUserMetadata();
  const { data: unsortedAccounts } = useAccountsService();
  useAccountCorrector(accountId, unsortedAccounts);
  const items =
    unsortedAccounts?.map((item: Account) => ({
      title: item.displayName ?? item.name,
      value: item.id,
    })) ?? null;

  const onAccountClick = useAccountChooseHandler();

  return { items, onAccountClick };
};
