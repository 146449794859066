import { EMPTY_CELL } from "@komodorio/design-system/komodor-ui";

import { useNeedUpgradeAgent } from "@/components/common/UpdateAgentModal/hooks";
import { UpgradeAgentButton } from "@/components/common/UpdateAgentModal/UpgradeAgentButton";
import { UpgradeCommands } from "@/components/common/upgradeAgentCta/UpgradeAgentCTA";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import { useGetHelmInstallationCommand } from "@/shared/hooks/useGetHelmInstallationCommand";
import { doesAgentVersionSupportExternalDNSStatus } from "@/shared/utils/agent/agent";

export const AgentUpgradeRequiredWrapper: React.FC<{
  cluster: string;
  children: React.ReactNode;
}> = ({ cluster, children }) => {
  const agentId = useActiveAgent(cluster);
  const [agentUpgradeRequired, isLoadingAgent] = useNeedUpgradeAgent(
    agentId,
    cluster,
    doesAgentVersionSupportExternalDNSStatus
  );
  const command = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND
  );

  if (isLoadingAgent) {
    return EMPTY_CELL;
  }
  if (agentUpgradeRequired) {
    return <UpgradeAgentButton command={command} desiredFeature="this data." />;
  }
  return children;
};
