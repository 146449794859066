import React from "react";

import { InvestigationSection } from "../../AiInvestigation/common/InvestigationSection";
import type Pod from "../../resources/pod";
import EnableAiBanner from "../../AiInvestigation/common/EnableAiBanner";

import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";
import { FlowType } from "@/components/ResourceView/AiInvestigation/common/types";

export type PodAiInvestigationTabProps = {
  resource: Pod;
};

export const PodAiInvestigationTab: React.FC<PodAiInvestigationTabProps> = ({
  resource,
}) => {
  const { data: features } = useAccountFeatures();
  const isHealthy = Boolean(resource.isPodReadyOrCompleted);
  if (!resource.id) {
    return null;
  }

  return (
    <>
      {!isHealthy && !features.klaudiaAi && (
        <EnableAiBanner title="Root Cause Analysis" />
      )}
      <InvestigationSection
        requestId={resource.id}
        resource={resource}
        isHealthy={isHealthy}
        flowType={FlowType.UnhealthyPod}
        titleProps={{
          collapsible: false,
        }}
      />
    </>
  );
};
