import React, { useMemo, useState } from "react";
import { isEqual } from "lodash";
import styled from "styled-components";
import LoadingButton from "@mui/lab/LoadingButton";
import pluralize from "pluralize";

import { validateDigestConfigurations } from "./emailDigestConfigurationSchema";

import {
  useGetEmailDigestConfiguration,
  useUpsertEmailDigestConfiguration,
} from "@/shared/hooks/email-digest/useEmailDigestConfiguration";
import { useEmailSettingsContext } from "@/components/Settings/email/context/useEmailSettingsContext";
import { notifyDDError } from "@/shared/hooks/exceptionManagement";
import { useSnackbarMessageContext } from "@/components/Settings/email/context/useSnackbarMessageContext";
import { updateEmailConfigurationsMessage } from "@/components/Settings/email/emailSettingsConstants";
import { emailSettingsAriaLabels } from "@/components/Settings/email/emailSettingsAriaLabels";

const Container = styled.div``;

const combineErrorsToMessage = (errors: Record<string, Error>) => {
  const errorMessages = Object.entries(errors).map(([key, error]) => {
    return error.message;
  });

  return `Invalid ${pluralize(
    "input",
    errorMessages.length
  )}: ${errorMessages.join(", ")}`;
};

export const SubmitButton: React.FC = () => {
  const { data, refetch } = useGetEmailDigestConfiguration();
  const { setConfigurationData, ...context } = useEmailSettingsContext();
  const { setSnackbarMessage } = useSnackbarMessageContext();
  const { mutateAsync } = useUpsertEmailDigestConfiguration();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const haveValuesChanged = useMemo(() => {
    if (!data?.data) return false;
    return !isEqual(data.data, context);
  }, [context, data?.data]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const errors = await validateDigestConfigurations(context);
      if (Object.keys(errors).length) {
        setSnackbarMessage({
          color: "error",
          message: combineErrorsToMessage(errors),
        });
        setIsSubmitting(false);
        return;
      }

      await mutateAsync({
        body: context,
      });
      await refetch();
      setSnackbarMessage(updateEmailConfigurationsMessage.success);
    } catch (error) {
      notifyDDError({
        name: "update email digest configuration",
        message: `${error}`,
      });
      setSnackbarMessage(updateEmailConfigurationsMessage.failure);
    }
    setIsSubmitting(false);
  };

  return (
    <Container>
      <LoadingButton
        variant="contained"
        color="primary"
        disabled={!haveValuesChanged}
        onClick={handleSubmit}
        loading={isSubmitting}
        aria-label={emailSettingsAriaLabels.submitButton}
      >
        Update preferences
      </LoadingButton>
    </Container>
  );
};
