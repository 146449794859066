import React from "react";

import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { Addon, Entity } from "@/generated/addonsApi";
import { useAddOnsRefetchInterval } from "@/components/k8sAddons/hooks/useAddOnsRefetchInterval";
import { PageContainer } from "@/components/k8sAddons/styles";
import { IfSupportedEntityForWorkspaceKind } from "@/components/k8sAddons/components/IfSupportedEntityForWorkspaceKind";
import { Filters } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/Filters";
import { NodeAutoscalersTable } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersTable/NodeAutoscalersTable";
import {
  DEFAULT_AUTOSCALERS_SORT_BY_FIELD,
  DEFAULT_AUTOSCALERS_SORT_BY_DIRECTION,
} from "@/components/k8sAddons/addons/NodeAutoscalers/nodeAutoscalersConstants";

export const NodeAutoscalersPage: React.FC = () => {
  return (
    <AddonContextProvider
      addonType={Addon.AutoScalers}
      addonEntity={Entity.AutoScaler}
      setInitialClustersFilter={true}
      refetchInterval={useAddOnsRefetchInterval()}
      defaultSortModel={{
        field: DEFAULT_AUTOSCALERS_SORT_BY_FIELD,
        sort: DEFAULT_AUTOSCALERS_SORT_BY_DIRECTION,
      }}
    >
      <PageContainer>
        <IfSupportedEntityForWorkspaceKind>
          <Filters />
          <NodeAutoscalersTable />
        </IfSupportedEntityForWorkspaceKind>
      </PageContainer>
    </AddonContextProvider>
  );
};
