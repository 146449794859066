import { array, bool, object, string, ValidationError } from "yup";

import {
  DigestFrequency,
  EmailDigestConfiguration,
} from "@/generated/emailDigestService";

export const emailDigestConfigurationSchema = object().shape({
  scope: object().shape({
    clusters: array()
      .of(string().required("Clusters is required"))
      .min(1, "Digest scope cannot be left empty")
      .label("clusters"),
  }),
  frequency: string()
    .oneOf(Object.values(DigestFrequency))
    .required("Frequency is required"),
  isSubscribed: bool().required("subscribed is required"),
});

export const validateDigestConfigurations = async (
  data: Partial<EmailDigestConfiguration>
): Promise<Record<string, Error>> => {
  try {
    await emailDigestConfigurationSchema.validate(data, {
      abortEarly: false,
    });

    return {};
  } catch (err) {
    const validationError = err as ValidationError;
    return validationError.inner.reduce(
      (allErrors, currentError) => ({
        ...allErrors,
        [currentError.path || ""]: {
          type: currentError.type ?? "validation",
          message: currentError.message,
        },
      }),
      {}
    );
  }
};
