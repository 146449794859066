export const MAX_RETRIES = 5;
export const POD_LOGS_TIMEOUT = 5000;

export const DEFAULT_LOGS_TAIL_WHEN_USER_DOESNT_SPECIFY = 10_000;

export const logsCommandOptions = {
  tail: 100,
  since: "",
  follow: false,
  resourceId: "",
};
