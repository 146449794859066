import { muiColors } from "@komodorio/design-system";

import { MetricName } from "@/components/Metrics/types";
import { WorkloadMetricsDataPoints } from "@/generated/metricsApi";

export const DriftMetricsLoadingStateIds = {
  driftWorkloadsMemory: "driftWorkloadsMemory",
  driftWorkloadsCPU: "driftWorkloadsCPU",
  driftWorkloadsContainersMemory: "driftWorkloadsContainersMemory",
  driftWorkloadsContainersCPU: "driftWorkloadsContainersCPU",
} as const;

export const workloadsStrokesColors = [
  muiColors.indigo[500],
  muiColors.blue[400],
  muiColors.orange[600],
  muiColors.deepPurple[200],
  muiColors.deepPurple[500],
  muiColors.pink[700],
  muiColors.indigo[100],
  muiColors.teal[500],
  muiColors.yellow[400],
  muiColors.gray[600],
];

export const workloadsDataKeyToMetricsDataKey: Record<
  keyof WorkloadMetricsDataPoints,
  MetricName
> = {
  p90Utilization: "p90",
  p95Utilization: "p95",
  p99Utilization: "p99",
  avgUtilization: "usageBytes",
  maxUtilization: "max",
  limit: "limitBytes",
  request: "requestBytes",
  conservativeRecommendation: "recommendationBytes",
  moderateRecommendation: "recommendationBytes",
  aggressiveRecommendation: "recommendationBytes",
};
