import { useNeedUpgradeAgent } from "@/components/common/UpdateAgentModal/hooks";
import type DaemonSet from "@/components/ResourceView/resources/daemonset";
import type Deployment from "@/components/ResourceView/resources/deployment";
import type Rollout from "@/components/ResourceView/resources/rollout";
import type StatefulSet from "@/components/ResourceView/resources/statefulset";
import {
  Addon,
  Entity,
  ExternalDnsRecordFromJSON,
} from "@/generated/addonsApi";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import { doesAgentVersionSupportExternalDNSStatus } from "@/shared/utils/agent/agent";

export const useGetExternalDnsStatus = (
  resource: Deployment | DaemonSet | StatefulSet | Rollout,
  enabled: boolean
) => {
  const [isAgentUpgradeRequired] = useNeedUpgradeAgent(
    resource.agentId,
    resource.cluster,
    doesAgentVersionSupportExternalDNSStatus
  );

  const { data } = useGetAddonFullLiveState(
    {
      addon: Addon.ExternalDns,
      entity: Entity.ExternalDnsOperator,
      uid: resource.komodorUid,
      name: resource.name,
      getEntityRequest: {
        clusterName: resource.cluster,
      },
    },
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: ExternalDnsRecordFromJSON(json?.data?.data),
    }),
    { enabled: !isAgentUpgradeRequired && enabled }
  );

  return isAgentUpgradeRequired ? undefined : data?.data.syncStatus;
};
