import { useLocation } from "react-router-dom";
import { merge } from "lodash";
import jwtDecode from "jwt-decode";
import React, { useContext, useEffect, useMemo } from "react";
import { Partial, Record, String } from "runtypes";
import cloneDeep from "lodash/cloneDeep";
import { AccountPlan } from "komodor-types";

import { notifyDDError } from "../exceptionManagement";
import { parseUrlUtms } from "../../utils/url/urlUtms";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { isDateInThePast } from "./utils";

const SANDBOX_ACCOUNT_ID = "336d5e26-0e6f-5b86-a58f-e353668829d2";

export interface UserMetadata {
  userId: string;
  accountId: string;
  accountName: string;
  acceptedTos: boolean;
  accountDisabled: boolean;
  accountPlan: AccountPlan | "";
  accountSalesforceId?: string;
  accountAcquisitionChannel?: string;
  allowedAccounts?: string;
  originalAccountId?: string;
  trialEndAt?: string;
  isTrial?: boolean;
  isElectron?: boolean;
  source?: string;
  accountOrigin: string;
  allowedResources?: string;
  allowedActions?: string[];
  accountPurchaseDate?: string;
  accountRegistrationDate?: string;
  isKomodorAdmin: boolean;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
}

const defaultContextValue: UserMetadata = {
  userId: "",
  isKomodorAdmin: false,
  accountId: "",
  accountName: "",
  acceptedTos: false,
  accountDisabled: false,
  trialEndAt: undefined,
  isTrial: false,
  source: "unknown",
  accountOrigin: "",
  accountPlan: "",
  accountSalesforceId: "",
  accountAcquisitionChannel: "",
  accountPurchaseDate: "",
  accountRegistrationDate: "",
};

const sandboxContextValue: UserMetadata = {
  userId: "",
  isKomodorAdmin: false,
  accountId: SANDBOX_ACCOUNT_ID,
  accountName: "sandbox",
  acceptedTos: true,
  accountDisabled: false,
  trialEndAt: undefined,
  isTrial: false,
  source: "unknown",
  accountOrigin: "",
  accountPlan: AccountPlan.free,
  accountSalesforceId: "",
  accountAcquisitionChannel: "",
  accountPurchaseDate: "",
  accountRegistrationDate: "",
};

export const AccessTokenType = Record({
  "https://hasura.io/jwt/claims": Record({
    "x-hasura-account-id": String,
    "x-hasura-account-name": String,
    "x-hasura-user-id": String,
    "x-hasura-accepted-tos": String,
    "x-hasura-account-disabled": String,
    "x-hasura-account-trial-end-date": String,
    "x-hasura-account-origin": String,
    "x-hasura-account-plan": String,
    "x-hasura-account-salesforce-id": String,
    "x-hasura-account-acquisition-channel": String,
    "x-hasura-account-purchase-date": String,
    "x-hasura-account-registration-date": String,
    "x-hasura-is-komodor-admin": String,
  }).And(
    Partial({
      "x-hasura-allowed-accounts": String,
      "x-hasura-original-account-id": String,
      "x-hasura-allowed-resources": String,
      "x-hasura-allowed-actions": String,
      "x-hasura-utm-source": String,
      "x-hasura-utm-medium": String,
      "x-hasura-utm-campaign": String,
      "x-hasura-utm-term": String,
      "x-hasura-utm-content": String,
    })
  ),
});

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const decodeAccessToken = (accessToken: string): UserMetadata => {
  const decodedToken = AccessTokenType.check(jwtDecode(accessToken));

  const claims = decodedToken["https://hasura.io/jwt/claims"];
  const userId = claims["x-hasura-user-id"];
  const accountId = claims["x-hasura-account-id"];
  const accountName = claims["x-hasura-account-name"];
  const acceptedTos = claims["x-hasura-accepted-tos"].toLowerCase() === "true";
  const accountDisabled =
    claims["x-hasura-account-disabled"].toLowerCase() === "true";
  const allowedAccounts = claims["x-hasura-allowed-accounts"];
  const originalAccountId = claims["x-hasura-original-account-id"];
  const trialEndAt = claims["x-hasura-account-trial-end-date"];
  const isTrial = trialEndAt !== "null";
  const accountOrigin = claims["x-hasura-account-origin"];
  const accountPurchaseDate = claims["x-hasura-account-purchase-date"];
  const accountRegistrationDate = claims["x-hasura-account-registration-date"];
  const allowedResources = claims["x-hasura-allowed-resources"];
  const allowedActions =
    claims["x-hasura-allowed-actions"]
      ?.replace("{", "")
      ?.replace("}", "")
      ?.split(",") ?? [];
  const accountPlan = claims["x-hasura-account-plan"] as AccountPlan;
  const accountSalesforceId = claims[
    "x-hasura-account-salesforce-id"
  ] as string;
  const accountAcquisitionChannel =
    claims["x-hasura-account-acquisition-channel"];
  const isKomodorAdmin =
    claims["x-hasura-is-komodor-admin"].toLowerCase() === "true";
  const utmSource = claims["x-hasura-utm-source"];
  const utmMedium = claims["x-hasura-utm-medium"];
  const utmCampaign = claims["x-hasura-utm-campaign"];
  const utmTerm = claims["x-hasura-utm-term"];
  const utmContent = claims["x-hasura-utm-content"];

  const userMetadata: UserMetadata = {
    userId,
    accountId,
    accountName,
    acceptedTos,
    accountDisabled,
    allowedAccounts,
    originalAccountId,
    trialEndAt,
    isTrial,
    accountOrigin,
    allowedResources,
    allowedActions,
    accountPlan,
    accountSalesforceId,
    accountAcquisitionChannel,
    accountPurchaseDate,
    accountRegistrationDate,
    isKomodorAdmin,
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
  };
  return userMetadata;
};

const userContext = React.createContext(defaultContextValue);

export const UserMetadataProvider: React.FC<{
  accessToken: string | null;
  isSandbox: boolean;
  children?: React.ReactNode;
}> = ({ accessToken, isSandbox, children }) => {
  const userMetadata = useMemo(() => {
    if (isSandbox) return sandboxContextValue;
    if (!accessToken) throw new Error("No access token");
    return decodeAccessToken(accessToken);
  }, [accessToken, isSandbox]);

  const additionalUserMetadata = useAdditionalUserMetadata();
  const value = useMemo(() => {
    try {
      return merge(userMetadata, additionalUserMetadata);
    } catch (error) {
      notifyDDError(error as Error);
      return defaultContextValue;
    }
  }, [userMetadata, additionalUserMetadata]);

  useEffect(() => {
    window.komodor = Object.assign(window.komodor || {}, {
      userMetadata: cloneDeep(value),
    });
  }, [value]);

  return <userContext.Provider value={value}>{children}</userContext.Provider>;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useUserMetadata = (): UserMetadata => useContext(userContext);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useIsTrialEnded = (): boolean => {
  const { accountPlan, trialEndAt } = useUserMetadata();
  if (accountPlan !== AccountPlan.trial) {
    return false;
  }
  return isDateInThePast(trialEndAt);
};

const useAdditionalUserMetadata = (): Pick<UserMetadata, "source"> => {
  const source = useUserSource();

  return useMemo(
    () => ({
      source,
    }),
    [source]
  );
};

const useUserSource = (): string => {
  const location = useLocation();
  const UTMs = useMemo(() => parseUrlUtms(location.search), [location.search]);

  return useMemo(() => {
    if (Object.keys(UTMs).length > 0) {
      return JSON.stringify(UTMs);
    }
    return "unknown";
  }, [UTMs]);
};
