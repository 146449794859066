import React from "react";
import { useParams } from "react-router-dom";

import ResourceView from "../ResourceView/ResourceView";
import { PathNotFound } from "../404/PathNotFound";
import { useKomodorServiceAsResourceWithInterval } from "../ResourceView/useKomodorServiceAsResource";

type JobParams = {
  jobId?: string;
};
const useJobParams = (): JobParams => {
  return useParams<JobParams>();
};

const JobView: React.FC = () => {
  const { jobId } = useJobParams();
  const [resource, isFetching] = useKomodorServiceAsResourceWithInterval(jobId);

  return !resource ? (
    !isFetching ? (
      <PathNotFound />
    ) : null
  ) : (
    <ResourceView resource={resource} />
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default JobView;
