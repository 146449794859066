import React, { useMemo } from "react";
import { Line } from "recharts";
import { MuiSelectionOption } from "@komodorio/design-system/komodor-ui";

import {
  workloadsDataKeyToMetricsDataKey,
  workloadsStrokesColors,
} from "../consts";

import {
  CustomGraphDataPoint,
  MetricsAggregationType,
  MetricsGraphProps,
  metricsTypeToName,
  MetricType,
} from "@/components/Metrics/types";
import {
  timelineTicksNum,
  graphHeight,
} from "@/components/ResourceView/tabs/MetricsTab/constants";
import {
  Datapoint,
  WorkloadMetricsContainers,
  WorkloadMetricsDataPoints,
} from "@/generated/metricsApi";
import { Timeframe } from "@/shared/types/TimeWindow";
import { unixTimeToMilliseconds } from "@/shared/utils/timeUtils";
import {
  getLastNDays,
  ROUND_TO_NEAREST_SECONDS,
  roundToNearestNSeconds,
} from "@/components/Metrics/utils";
import { sharedStraightLineProps } from "@/components/Metrics/constants";
import { useWorkloadMetricsTabContext } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";

const useTransformWorkloadsMetricsDataToGraphData = (
  workloadsMetricsData: (WorkloadMetricsDataPoints | undefined)[]
): CustomGraphDataPoint[] => {
  return useMemo(() => {
    const flatData = workloadsMetricsData.flatMap(
      (workloadMetricsData, index) => {
        if (!workloadMetricsData) return [];
        return Object.entries(workloadMetricsData).flatMap(
          ([key, dataPoints]) => {
            const metricType =
              workloadsDataKeyToMetricsDataKey[
                key as keyof WorkloadMetricsDataPoints
              ];
            if (!dataPoints) return [];
            return dataPoints.map((datapoint: Datapoint) => {
              const time = unixTimeToMilliseconds(
                roundToNearestNSeconds(
                  datapoint.timestampMs,
                  ROUND_TO_NEAREST_SECONDS
                )
              );
              return { time, [`${metricType}-${index}`]: datapoint.value };
            });
          }
        );
      }
    );
    const mergedData: Record<number, CustomGraphDataPoint> = {};
    flatData.forEach((datapoint: CustomGraphDataPoint) => {
      const { time, ...metrics } = datapoint;
      if (!mergedData[time]) {
        mergedData[time] = datapoint;
        return;
      }
      Object.assign(mergedData[time], metrics);
    });

    return Object.values(mergedData).sort((a, b) => a.time - b.time);
  }, [workloadsMetricsData]);
};

export const useTransformWorkloadsMetricsData = (
  workloadMetricsData: (WorkloadMetricsDataPoints | undefined)[],
  timeWindow: Timeframe
): CustomGraphDataPoint[] => {
  const fullData =
    useTransformWorkloadsMetricsDataToGraphData(workloadMetricsData);
  return useMemo(() => {
    if (timeWindow === Timeframe.Last24Hours) {
      return getLastNDays(fullData, 1) as CustomGraphDataPoint[];
    }
    return fullData;
  }, [fullData, timeWindow]);
};

const useHasGraphData = (data: (WorkloadMetricsDataPoints | undefined)[]) => {
  return useMemo(() => {
    if (!data) return false;
    return Object.values(data).some(
      (metricsData) =>
        metricsData &&
        Object.values(metricsData).some((dataPoints) => dataPoints?.length > 0)
    );
  }, [data]);
};

export const useGraphLinesForAggregationPerService = (
  aggregationType: MetricsAggregationType,
  workloadsLength: number,
  type: MetricType
): React.ReactNode[] => {
  return useMemo(() => {
    const indexes = Array.from<number>({ length: workloadsLength });
    return indexes.map((_, index) => {
      const aggregationTypeValue = metricsTypeToName[aggregationType];
      const ariaLabelTitle = type === "cpu" ? "CPU" : "Memory";
      const key = `${aggregationTypeValue}-${index}`;
      const ariaLabel = `${ariaLabelTitle} usage for ${key}`;
      return (
        <Line
          aria-label={ariaLabel}
          dataKey={key}
          key={key}
          stroke={workloadsStrokesColors[index % workloadsLength]}
          {...sharedStraightLineProps}
        />
      );
    });
  }, [aggregationType, type, workloadsLength]);
};

export const useWorkloadsGraphData = (
  data: (WorkloadMetricsDataPoints | undefined)[],
  type: MetricType
) => {
  const { timeWindow, selectedAggregationMetric } =
    useWorkloadMetricsTabContext();
  const transformedData = useTransformWorkloadsMetricsData(
    data,
    timeWindow.timeframe
  );

  const linesContent = useGraphLinesForAggregationPerService(
    selectedAggregationMetric,
    data.length,
    type
  );

  const hasGraphData = useHasGraphData(data);
  const graphProps: MetricsGraphProps = {
    data: transformedData,
    disableZoom: true,
    timelineTicksNum: timelineTicksNum,
    graphHeight,
    aggregationType: selectedAggregationMetric,
  };

  return {
    transformedData,
    hasGraphData,
    linesContent,
    graphProps,
  };
};

export const useFilterWorkloadsMetricsByContainer = (
  wantedContainer: MuiSelectionOption<string> | undefined,
  data: (WorkloadMetricsContainers | undefined)[]
) =>
  useMemo(() => {
    return data.map((res) => {
      if (!res || !wantedContainer) return undefined;
      return res.containers.find(
        (container) => container.containerName === wantedContainer.value
      )?.dataPoints;
    });
  }, [data, wantedContainer]);
