import { ServicesComparisonState } from "./types";

export const initialServicesComparisonState: ServicesComparisonState = {
  baselineServiceId: null,
  comparedServicesIds: [],
  isBaselineSelectionOpen: false,
  isComparedSelectionOpen: false,
  setBaselineServiceId: () => undefined,
  setComparedServicesIds: () => undefined,
  setIsBaselineSelectionOpen: () => undefined,
  setIsComparedSelectionOpen: () => undefined,
};
