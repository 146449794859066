import React from "react";

import { DrawerBase } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/DrawerBase";
import { useGetCommonDrawerProps } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/drawerContentHooks";
import { useViolation } from "@/components/reliability/components/pages/violations/ViolationsDrawer/hooks/useViolation";
import { OverviewContent } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/shared/DrawerBase/OverviewContent";
import {
  getHowDescription,
  getWhyDescription,
} from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ExternalDNSNotSynced/texts";
import { WhatHappened } from "@/components/reliability/components/pages/violations/ViolationsDrawer/components/drawers/ExternalDNSNotSynced/WhatHappened";

export const ExternalDnsNotSynced: React.FC = () => {
  const { data } = useViolation();

  const { supportingData, headerProps, summaryProps, komodorUid } =
    useGetCommonDrawerProps(data?.data.violation);

  const externalDnsSupportingData = supportingData?.externalDNSNotSynced;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <WhatHappened
              supportingData={externalDnsSupportingData}
              komodorUid={komodorUid}
            />
          }
          whyDescription={getWhyDescription()}
          howDescription={getHowDescription()}
        />
      }
    />
  );
};
