import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { BasicViolation } from "../../../../../generated/reliabilityApi";
import { parseKomodorUid } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useGetViolationTitle } from "../../../hooks/useGetViolationTitle";

const Container = styled.div`
  padding: 8px 0px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledTitle = styled(Typography)`
  && {
    margin-bottom: 2px;
  }
`;

const StyledArrow = styled(KeyboardArrowRight)`
  && {
    width: 16px;
  }
`;

type ViolationColumnProps = Pick<
  BasicViolation,
  "komodorUid" | "checkType" | "supportingData"
>;

export const ViolationColumn: React.FC<ViolationColumnProps> = ({
  checkType,
  komodorUid,
  supportingData,
}) => {
  const checkLabel = useGetViolationTitle({
    checkType,
    supportingData,
    komodorUid,
  });

  return <TitleWithPath title={checkLabel} komodorUid={komodorUid} />;
};

export const TitleWithPath: React.FC<{
  title: string | undefined;
  komodorUid: string;
}> = ({ title, komodorUid }) => {
  const { cluster, namespace, name } = parseKomodorUid(komodorUid ?? "") ?? {};
  const isClusterLevelCheck = !namespace && !name;
  return (
    <Container>
      <StyledTitle variant={"h5"}>{title}</StyledTitle>
      <FlexContainer>
        <TextWithArrow
          text={cluster}
          hasNextValue={!!namespace}
          textColor={isClusterLevelCheck ? "text.primary" : undefined}
        />
        <TextWithArrow text={namespace} hasNextValue={!!name} />
        <TextWithArrow
          text={name}
          hasNextValue={false}
          textColor="text.primary"
        />
      </FlexContainer>
    </Container>
  );
};

type TextWithArrowProps = {
  text?: string;
  hasNextValue: boolean;
  textColor?: React.ComponentProps<typeof Typography>["color"];
};

const TextWithArrow: React.FC<TextWithArrowProps> = ({
  text,
  hasNextValue,
  textColor = "text.secondary",
}) => {
  if (!text) return null;
  return (
    <FlexContainer>
      <Typography variant="body2" color={textColor}>
        {text}
      </Typography>
      {hasNextValue && <StyledArrow fontSize={"small"} color={"action"} />}
    </FlexContainer>
  );
};
