import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1KomodorServicesIdGetUrl,
  KomodorServicesApiApiV1KomodorServicesIdGetRequest,
  KomodorServicesResponse,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

import { useWorkspaceQueryKey } from "@/shared/hooks/workspaces-api/useWorkspaceQueryKey";

export const KOMODOR_SERVICE_BY_ID_PATH = `/api/v1/komodor-services/:id`;

const fetchKomodorServiceById = async (
  apiClient: AxiosInstance,
  params: KomodorServicesApiApiV1KomodorServicesIdGetRequest
): Promise<KomodorServicesResponse> => {
  const { data } = await apiClient.get(
    apiV1KomodorServicesIdGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export const useGetKomodorServiceById = (
  params: KomodorServicesApiApiV1KomodorServicesIdGetRequest,
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  const apiClient = useResourcesApiClient();
  const keys = useWorkspaceQueryKey([KOMODOR_SERVICE_BY_ID_PATH, params]);
  return useQuery(
    keys,
    () => fetchKomodorServiceById(apiClient, params),
    options
  );
};
