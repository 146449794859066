import { Link, LinkProps } from "@mui/material";
import { LightTooltip } from "components/komodor-ui/LightTooltip/LightTooltip";
import { useRef } from "react";

export const LinkCell: React.FC<LinkProps> = (props) => {
  const cellRef = useRef(null);
  const isTruncated =
    cellRef.current?.scrollWidth > cellRef.current?.clientWidth;
  return (
    <LightTooltip title={isTruncated ? props.children : ""} placement="top">
      <Link ref={cellRef} variant="body2" underline="hover" noWrap {...props} />
    </LightTooltip>
  );
};
