import React, { useMemo, useState } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import ManageAccounts from "@mui/icons-material/ManageAccounts";
import { capitalize } from "lodash";

import { useAccountCorrector } from "../../hooks/useAccountCorrector";
import { ManagementModal } from "../ManagementModal/ManagementModal";
import { useCurrentFavoriteAccountAsOptions } from "../../hooks/useCurrentFavoriteAccountAsOptions";
import { useGetAccountByUsageFrequency } from "../../hooks/useGetAccountByUsageFrequency";
import { useAccountChooseHandler } from "../../hooks/useAccountChooseHandler";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { AccountsList } from "../AccountsList/AccountsList";

import { favoriteAccountsLS } from "./localStorage";
import { FREQUENTLY_USED_ACCOUNT_NAMES } from "./constants";

import { LocalStorageItem, prefix } from "@/shared/utils/localStorageSettings";
import { useUserMetadata } from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { useAccountsService } from "@/shared/hooks/accounts-service/useAccountsService";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";

const DEFAULT_SHOW_DISABLED_ACCOUNTS = false;

const showDisabledAccountsLS = new LocalStorageItem(
  "showDisabledAccounts",
  `@LongTermStorage${prefix}`
);

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const AccountOptionsMenu: React.FC = () => {
  const { accountId } = useUserMetadata();
  const { data: unsortedAccounts } = useAccountsService();
  const [showManagementModal, setShowManagementModal] = useState(false);

  const [parsedShouldShowDisabledAccount, setShouldShowDisabledAccounts] =
    useLocalStorage({
      localStorageItem: showDisabledAccountsLS,
      defaultValue: DEFAULT_SHOW_DISABLED_ACCOUNTS,
    });

  const [parsedFavoriteAccounts, setFavoriteAccounts] = useLocalStorage({
    localStorageItem: favoriteAccountsLS,
    defaultValue: FREQUENTLY_USED_ACCOUNT_NAMES,
  });

  const onClose = () =>
    dispatchEvent(AnalyticEvents.App.Account_Switcher_Close);

  const currentFavoriteAccountAsOptions = useCurrentFavoriteAccountAsOptions(
    parsedFavoriteAccounts,
    unsortedAccounts
  );

  useAccountCorrector(accountId, unsortedAccounts);

  const { infrequentlyUsedAccounts, frequentlyUsedAccounts } =
    useGetAccountByUsageFrequency(parsedFavoriteAccounts, unsortedAccounts);

  const enabledAccountsList = useMemo(
    () => infrequentlyUsedAccounts.filter((a) => !a.isDisabled),
    [infrequentlyUsedAccounts]
  );

  const disabledAccountsList = useMemo(
    () =>
      !parsedShouldShowDisabledAccount
        ? []
        : infrequentlyUsedAccounts.filter((a) => a.isDisabled),
    [parsedShouldShowDisabledAccount, infrequentlyUsedAccounts]
  );

  const onAccountClick = useAccountChooseHandler();

  const currentAccount = useMemo(() => {
    const account = unsortedAccounts?.find((a) => a.id === accountId);
    if (!account) return;
    return {
      label: capitalize(account.displayName) ?? capitalize(account.name),
      value: account.id,
    };
  }, [accountId, unsortedAccounts]);

  if (!unsortedAccounts?.length) return null;

  return (
    <Container id="account-switcher" aria-label="account switcher">
      <IconButton onClick={() => setShowManagementModal(true)} color="info">
        <ManageAccounts />
      </IconButton>
      <AccountsList
        favoriteAccounts={frequentlyUsedAccounts}
        options={[...enabledAccountsList, ...disabledAccountsList]}
        onSelect={onAccountClick}
        value={currentAccount}
        onClose={onClose}
      />
      <ManagementModal
        open={showManagementModal}
        handleClose={() => setShowManagementModal(false)}
        accounts={enabledAccountsList}
        setShouldShowDisabledAccounts={setShouldShowDisabledAccounts}
        setFavoriteAccounts={setFavoriteAccounts}
        currentFavoriteAccount={currentFavoriteAccountAsOptions ?? []}
        currentShouldShowDisabledAccounts={parsedShouldShowDisabledAccount}
      />
    </Container>
  );
};
