import React, { useEffect, useMemo, useRef } from "react";

import { GroupCard } from "../../GroupCard";

import { getTotalViolationsFromCountDict } from "@/components/reliability/utils/reliabilityUtils";
import { GroupProps } from "@/components/reliability/ReliabilityTypes";
import { useCommonViolationGroupProps } from "@/components/reliability/hooks/useCommonViolationGroupProps";
import { IssuesTableWithContext } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/IssuesTableWithContext/IssuesTableWithContext";
import { ImpactGroupIdentifier } from "@/generated/reliabilityApi";
import { useRegisterTableComponentLoadingState } from "@/components/reliability/hooks/useReportTableLoadingState";

export const IssuesGroup: React.FC<GroupProps> = ({ group, groupBy }) => {
  const hasSetAutoExpand = useRef(false);

  const { id, title, isOpen, onExpandClick, groupState } =
    useCommonViolationGroupProps({
      group,
      groupBy,
    });

  const breakdownByCheckType = group.breakdowns?.["checkType"];

  const totalIssues = useMemo(() => {
    return getTotalViolationsFromCountDict(breakdownByCheckType ?? {});
  }, [breakdownByCheckType]);

  useRegisterTableComponentLoadingState(isOpen, id);

  useEffect(() => {
    // auto-expand real time issues group
    if (
      !hasSetAutoExpand.current &&
      groupState.groupData[id as ImpactGroupIdentifier] === undefined
    ) {
      hasSetAutoExpand.current = true;
      onExpandClick();
    }
  }, [groupBy, groupState, id, onExpandClick]);

  return (
    <GroupCard
      id={id}
      onExpandClick={onExpandClick}
      isOpen={isOpen}
      title={title}
      totalContentRows={totalIssues}
      cardContent={
        <IssuesTableWithContext
          issuesBreakdown={breakdownByCheckType}
          uid={id}
          violationsGroupBy={groupBy}
        />
      }
    />
  );
};
