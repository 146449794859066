import {
  INFRA_HEALTH,
  K8S_ADDONS_WORKFLOWS,
  WORKLOAD_HEALTH,
} from "@/components/routes/routes";

export const RELATED_EVENTS_HAS_MORE_EVENTS_PANEL_EXCLUDED_ROUTES = [
  K8S_ADDONS_WORKFLOWS,
  WORKLOAD_HEALTH,
  INFRA_HEALTH,
];
