import React, { useState } from "react";

import { useUserMetadata } from "../../../../shared/hooks/useUserMetadata/useUserMetadata";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { TrialExpiredModal } from "./TrialExpiredModal";
import { TrialExpiredNonAccountOwnerModal } from "./TrialExpiredNonAccountOwnerModal";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { DowngradeToFreeModal } from "./DowngradeToFreeModal";

import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";
import { ACCOUNT_ADMIN_RBAC_ROLE } from "@/components/Settings/Roles/Roles";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum TrialEndModalsType {
  TrialExpired = "trial_expired",
  DowngradeToFree = "downgrade_to_free",
}

export const TrialEndModals: React.FC = () => {
  const [currentModal, setCurrentModal] = useState<TrialEndModalsType>(
    TrialEndModalsType.TrialExpired
  );
  const { isKomodorAdmin, userId } = useUserMetadata();
  const currentUser = useGetUserById({ id: userId });
  const userContainsAccountAdminRole = currentUser.data?.rbacRoles?.some(
    (role) => role.name === ACCOUNT_ADMIN_RBAC_ROLE
  );
  return userContainsAccountAdminRole || isKomodorAdmin ? (
    currentModal === TrialEndModalsType.TrialExpired ? (
      <TrialExpiredModal />
    ) : (
      <DowngradeToFreeModal setCurrentModal={setCurrentModal} />
    )
  ) : (
    <TrialExpiredNonAccountOwnerModal />
  );
};
