import { useMemo } from "react";

import {
  getViolationTitle,
  ViolationTitleParams,
} from "../utils/reliabilityUtils";

export const useGetViolationTitle = ({
  checkType,
  supportingData,
  komodorUid,
}: ViolationTitleParams) => {
  return useMemo(
    () => getViolationTitle({ checkType, supportingData, komodorUid }),
    [checkType, supportingData, komodorUid]
  );
};
