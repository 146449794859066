import React from "react";

import { Addon, Entity } from "@/generated/addonsApi";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { PageContainer } from "@/components/k8sAddons/styles";
import { DagsTable } from "@/components/k8sAddons/addons/workflows/DagsListPage/DagsTable";
import { Filters } from "@/components/k8sAddons/addons/workflows/DagsListPage/Filters";
import {
  WORKFLOW_DAGS_DEFAULT_SORT_BY_DIRECTION,
  WORKFLOW_DAGS_DEFAULT_SORT_BY_FIELD,
} from "@/components/k8sAddons/addons/workflows/DagsListPage/constants";
import { IfSupportedEntityForWorkspaceKind } from "@/components/k8sAddons/components/IfSupportedEntityForWorkspaceKind";

export const DagsListPage: React.FC<{ isCustomEngines?: boolean }> = ({
  isCustomEngines = false,
}) => {
  return (
    <AddonContextProvider
      addonType={Addon.Workflow}
      addonEntity={Entity.WorkflowDag}
      defaultSortModel={{
        field: WORKFLOW_DAGS_DEFAULT_SORT_BY_FIELD,
        sort: WORKFLOW_DAGS_DEFAULT_SORT_BY_DIRECTION,
      }}
    >
      <PageContainer>
        <IfSupportedEntityForWorkspaceKind>
          <Filters isCustomEngines={isCustomEngines} />
          <DagsTable isCustomEngines={isCustomEngines} />
        </IfSupportedEntityForWorkspaceKind>
      </PageContainer>
    </AddonContextProvider>
  );
};
