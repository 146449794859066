import React from "react";

import { LogoIconProps } from "../types";

export const DigitalOcean: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="DigitalOcean logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M12.0014 22V18.1219C16.1063 18.1219 19.2927 14.051 17.7165 9.73069C17.1382 8.14316 15.8568 6.8618 14.258 6.28348C9.93763 4.71864 5.86676 7.89369 5.86676 11.9986H2C2 5.4557 8.32743 0.352935 15.1878 2.4961C18.1814 3.43727 20.5741 5.81856 21.5039 8.81219C23.6471 15.6726 18.5443 22 12.0014 22Z"
        fill="#0080FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0128 18.1446H8.146V14.2778H12.0128V18.1446Z"
        fill="#0080FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.14601 21.1041H5.1864V18.1445H8.14601V21.1041Z"
        fill="#0080FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.18635 18.1447H2.703V15.6614H5.18635V18.1447Z"
        fill="#0080FF"
      />
    </svg>
  );
};
