import { useCallback, useEffect, useMemo, useRef } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { DatadogReportLoadingTimeContextState } from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import { KOMODOR_LOADING_TIME } from "@/shared/constants/datadog";

type UseReportLoadingTimeProps = {
  loadingState: DatadogReportLoadingTimeContextState["loadingState"];
  getCurrentViewOptions: DatadogReportLoadingTimeContextState["getCurrentViewOptions"];
  shouldPreventReporting: DatadogReportLoadingTimeContextState["shouldPreventReporting"];
};
export const useReportLoadingTime = ({
  loadingState,
  getCurrentViewOptions,
  shouldPreventReporting,
}: UseReportLoadingTimeProps) => {
  const shouldReportLoadingTime = useRef<boolean>(false);

  const values = Object.values(loadingState);

  const { allComponentsAreLoading, loadingStateIsComplete } = useMemo(() => {
    if (values.length === 0) {
      return { allComponentsAreLoading: false, loadingStateIsComplete: false };
    }

    return {
      allComponentsAreLoading: values.every((loading) => loading),
      loadingStateIsComplete: values.every((loading) => !loading),
    };
  }, [values]);

  const triggerStartView = useCallback(() => {
    if (shouldPreventReporting?.()) {
      return;
    }
    shouldReportLoadingTime.current = true;
    datadogRum.startView(getCurrentViewOptions());
  }, [getCurrentViewOptions, shouldPreventReporting]);

  useEffect(() => {
    if (shouldReportLoadingTime.current) {
      return;
    }
    if (allComponentsAreLoading) {
      triggerStartView();
    }
  }, [allComponentsAreLoading, loadingState, triggerStartView]);

  useEffect(() => {
    if (!shouldReportLoadingTime.current) return;

    if (loadingStateIsComplete) {
      shouldReportLoadingTime.current = false;
      datadogRum.addTiming(KOMODOR_LOADING_TIME);
    }
  }, [loadingState, loadingStateIsComplete]);

  return { triggerStartView };
};
