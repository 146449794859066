import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { Fragment, useMemo } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDivider = styled(Divider)`
  && {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export type Stat = {
  label: string;
  value: string | number;
  ariaLabel?: string;
};

const StatItem: React.FC<{ stat: Stat }> = ({ stat }) => {
  return (
    <ItemContainer aria-label={stat.ariaLabel}>
      <Typography variant="h4">{stat.value}</Typography>
      <Typography variant="body2" color="text.secondary">
        {stat.label}
      </Typography>
    </ItemContainer>
  );
};

export const StatsList: React.FC<{ stats: Stat[] }> = ({ stats }) => {
  const items = useMemo(
    () =>
      stats.map((stat, index) => {
        return (
          <Fragment key={stat.label}>
            <StatItem stat={stat} />
            {index < stats.length - 1 && (
              <StyledDivider orientation="vertical" variant="middle" flexItem />
            )}
          </Fragment>
        );
      }),
    [stats]
  );

  return <Container>{items}</Container>;
};
