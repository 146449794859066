import React from "react";
import styled from "styled-components";

import { AvailabilityResource } from "@/shared/store/investigationModeStore/investigationModeStore";
import { InvestigationContent } from "@/components/ResourceView/AiInvestigation/common/InvestigationContent";
import { useResourceAiInvestigation } from "@/components/ResourceView/AiInvestigation/common/useResourceAiInvestigation";
import { InvestigationTitle } from "@/components/ResourceView/AiInvestigation/common/InvestigationTitle";
import { ResultTitle } from "@/components/ResourceView/AiInvestigation/pod/styles";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { WorkflowRunWithResults } from "@/components/monitorsView/useWorkflowsRuns";
import type WorkflowIssueEventGroup from "@/components/common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { MonitorType } from "@/generated/monitorsApi";
// eslint-disable-next-line import/no-cycle
import { WorkflowIssueStatus } from "@/components/common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { FlowType } from "@/components/ResourceView/AiInvestigation/common/types";

export interface KlaudiaSessionSectionProps {
  wfRun: WorkflowRunWithResults;
  eventGroup: WorkflowIssueEventGroup;
  resource: AvailabilityResource;
  sessionId?: string;
  children?: React.ReactNode;
}

const Root = styled.div`
  padding: 16px 24px;
`;

const StyledInvestigationContent = styled(InvestigationContent)`
  && {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  ${ResultTitle} {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }
`;

export const KlaudiaSessionSection: React.FC<KlaudiaSessionSectionProps> = ({
  wfRun,
  eventGroup,
  resource,
  sessionId,
  children,
}) => {
  const {
    enabled,
    richSession,
    onShareClicked,
    isError,
    isLoading,
    onRetryCallback,
  } = useResourceAiInvestigation({
    drawerData: {
      drawerType: DrawerType.EventDrawerById,
      eventIds: [eventGroup.id],
      eventType: MonitorType.Availability,
      serviceId: eventGroup.serviceId,
    },
    sessionId: sessionId,
    requestId: wfRun.id,
    resource,
    isHealthy: false,
    flowType: FlowType.AvailabilityIssue,
    disableTrigger: eventGroup.status === WorkflowIssueStatus.closed,
  });

  if (!enabled || (!isLoading && !richSession)) {
    return null;
  }

  return (
    <>
      <Root>
        <InvestigationTitle
          richSession={richSession}
          onShareClicked={onShareClicked}
        />
        <StyledInvestigationContent
          richSession={richSession}
          isError={isError}
          onRetryCallback={onRetryCallback}
          isLoading={isLoading}
          showBorder={false}
        />
      </Root>
      {children}
    </>
  );
};
