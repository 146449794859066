import { ContainerStatus } from "kubernetes-types/core/v1";
import { useMemo } from "react";

export const useShouldShowPreviousContainer = (
  containerStatuses: ContainerStatus[],
  selectedContainerName?: string
): boolean => {
  return useMemo(() => {
    const selectedContainerStatus = containerStatuses.find(
      (c) => c.name === selectedContainerName
    );

    return Boolean(
      selectedContainerStatus && selectedContainerStatus?.restartCount > 0
    );
  }, [selectedContainerName, containerStatuses]);
};
