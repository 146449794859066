import { useMemo } from "react";

import { useGetKomodorServiceById } from "@/shared/hooks/resources-api/client/komodor-services/useGetKomodorServiceById";
import { KomodorServicesResponseDataInner } from "@/generated/resourcesApi";

const SERVICE_POLLING_INTERVAL = 7500;

export enum Status {
  Loading,
  NotFound,
  Found,
}

export type CurrentService = KomodorServicesResponseDataInner | undefined;
export const useCurrentService = (
  serviceId: string
): {
  currentService: CurrentService;
  serviceFetchStatus: Status;
} => {
  const { data, isInitialLoading } = useGetKomodorServiceById(
    {
      id: serviceId,
      fields: [
        "k8sClusterName",
        "namespace",
        "kind",
        "updatedAt",
        "k8s_metadata",
        "deploy_state",
        "datadog_data",
        "health_state",
        "sentry_data",
      ],
    },
    {
      refetchInterval: SERVICE_POLLING_INTERVAL,
    }
  );

  return useMemo(() => {
    if (isInitialLoading) {
      return {
        currentService: undefined,
        serviceFetchStatus: Status.Loading,
        serviceType: undefined,
      };
    }

    const service = data?.data?.[0];

    let status: Status;
    if (service) {
      status = Status.Found;
    } else {
      status = Status.NotFound;
    }

    return {
      currentService: service,
      serviceFetchStatus: status,
    };
  }, [data?.data, isInitialLoading]);
};
