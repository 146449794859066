import { muiColors } from "@komodorio/design-system";
import Chip from "@mui/material/Chip";
import { Typography } from "@komodorio/design-system/deprecated";
import { Eye16, Pencil16 } from "@komodorio/design-system/icons";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { sortBy } from "lodash";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import DataTable, { Column } from "../../common/table/DataTable";
import { ActionsCol, CopyText, Header } from "../styles";
import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";
import useIsFreeTier from "../../Freemium/useIsFreeTier";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";
import { useGetRbacPolicies } from "../../../shared/hooks/auth-service/client/rbacPolicies/useGetRbacPolicies";
import { RbacPolicy } from "../../../generated/auth";

import PolicyFormModal from "./policyForm/PolicyFormModal";
import DeleteRoleModal from "./DeletePolicyModal";
import { policyTagsToArray } from "./policyForm/PolicyTags";
import { SetStateFn } from "./policyForm/common";

import { RBAC_POLICY } from "@/shared/config/urlSearchParamsKeys";
import { DELETE_ICON_STYLE } from "@/components/Settings/constants";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";

const DEFAULT_ALLOW_ALL_RBAC_POLICY = "default-allow-all";
const isPolicyEditable = (policy: RbacPolicy) =>
  policy.name !== DEFAULT_ALLOW_ALL_RBAC_POLICY;

const PolicyTypeTitle = styled(Typography).attrs({
  variant: "uppercase",
  size: "small",
})``;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`;

const TagChip = styled(Chip)`
  && {
    border: 1px solid ${muiColors.blue[300]};
    background-color: ${muiColors.blue[50]};
    color: ${muiColors.blue[600]};
    font-size: 12px;
    font-weight: 500;
    height: 16px;
  }
`;

const buildRbacPolicyExtraTypesColumns = (
  showExtraPolicyTypes: boolean
): Column<RbacPolicy>[] => {
  if (!showExtraPolicyTypes) {
    return [];
  }

  return [
    {
      name: "Type",
      selector: (p: RbacPolicy) => (
        <PolicyTypeTitle>{p.type.replace("_", " ")}</PolicyTypeTitle>
      ),
    },
    {
      name: "Tags",
      selector: (p: RbacPolicy) => (
        <TagsContainer>
          {policyTagsToArray(p.tags).map((t) => (
            <TagChip size={"small"} key={t} label={t} />
          ))}
        </TagsContainer>
      ),
    },
  ];
};

const buildRbacPolicyColumns = (
  isFreeTier: boolean,
  setPolicyToDelete: SetStateFn<RbacPolicy>,
  setPolicyToEdit: SetStateFn<RbacPolicy>,
  showExtraPolicyTypes: boolean
): Column<RbacPolicy>[] => {
  return [
    { name: "Name", selector: (p: RbacPolicy) => p.name },
    ...buildRbacPolicyExtraTypesColumns(showExtraPolicyTypes),
    { name: "ID", selector: (p: RbacPolicy) => <CopyText text={p.id} /> },
    {
      name: "",
      selector: (p: RbacPolicy) => (
        <ActionsCol>
          <IconButton onClick={() => setPolicyToEdit(p)}>
            {isPolicyEditable(p) && !isFreeTier ? <Pencil16 /> : <Eye16 />}
          </IconButton>

          {isPolicyEditable(p) && !isFreeTier && (
            <IconButton onClick={() => setPolicyToDelete(p)}>
              <DeleteOutlineIcon sx={DELETE_ICON_STYLE} fontSize="small" />
            </IconButton>
          )}
        </ActionsCol>
      ),
    },
  ];
};

export const Policies: React.FC = () => {
  const { showExtraPolicyTypes } = useOverridableFlags();
  const [policySearch, setPolicySearch] = useStateInSearchParams(RBAC_POLICY);
  const { data: policies, refetch } = useGetRbacPolicies();

  const [openAddPolicy, setOpenAddPolicy] = useState(false);
  const [policyToEdit, setPolicyToEdit] = useState<RbacPolicy>();
  const [policyToDelete, setPolicyToDelete] = useState<RbacPolicy>();
  const isFreeTier = useIsFreeTier();

  const columns: Column<RbacPolicy>[] = buildRbacPolicyColumns(
    isFreeTier,
    setPolicyToDelete,
    setPolicyToEdit,
    showExtraPolicyTypes as boolean
  );

  const filteredPolicies = useMemo(() => {
    if (!policySearch) {
      return policies || [];
    }

    const lowerCaseFilterOrs = policySearch.toLowerCase().split("|");

    return policies
      ? sortBy(
          policies.filter((p) => {
            return lowerCaseFilterOrs.some((filter) => {
              return p.name.toLowerCase().includes(filter);
            });
          }),
          (p) => p.name
        )
      : [];
  }, [policies, policySearch]);

  return (
    <SettingsViewVerticalLayout title={"Policies"}>
      <Header>
        <SearchField
          size="medium"
          width="15rem"
          placeholder="Search"
          value={policySearch || ""}
          showCloseIcon={true}
          ariaLabel="Search policies"
          onSearch={setPolicySearch}
        />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenAddPolicy(true)}
        >
          Add policy
        </Button>
      </Header>
      <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
      <DataTable
        data={filteredPolicies}
        columns={columns}
        getRowId={(u) => u.id}
        highlightedRows
      />
      {openAddPolicy && (
        <ValidationsProvider>
          <PolicyFormModal
            open
            handleClose={() => setOpenAddPolicy(false)}
            refreshPolicies={refetch}
          />
        </ValidationsProvider>
      )}
      {!!policyToEdit && (
        <ValidationsProvider>
          <PolicyFormModal
            open
            handleClose={() => setPolicyToEdit(undefined)}
            policy={policyToEdit}
            readOnly={!isPolicyEditable(policyToEdit) || isFreeTier}
            refreshPolicies={refetch}
          />
        </ValidationsProvider>
      )}
      {policyToDelete && (
        <DeleteRoleModal
          open
          handleClose={() => setPolicyToDelete(undefined)}
          policy={policyToDelete}
          refreshPolicies={refetch}
        />
      )}
    </SettingsViewVerticalLayout>
  );
};
