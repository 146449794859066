import { useMemo } from "react";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";

import { useReliabilityStore } from "@/components/reliability/store/reliabilityStore";
import { selectViolationsState } from "@/components/reliability/store/reliabilityStoreSelectors";
import { useIssuesTableContext } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/context/useIssuesTableContext";
import { DEFAULT_ISSUES_TABLE_PAGINATION_MODEL } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/context/initialState";
import { getGroupData } from "@/components/reliability/utils/getGroupData";

export const useIssuesTablePaginationModel = (
  customPaginationModel?: GridPaginationModel
) => {
  const { groups } = useReliabilityStore(selectViolationsState);
  const { uid, violationsGroupBy } = useIssuesTableContext();

  return useMemo(() => {
    if (customPaginationModel) {
      return customPaginationModel;
    }

    return (
      getGroupData({
        groupState: groups,
        violationsGroupBy,
        uid,
      })?.paginationModel ?? DEFAULT_ISSUES_TABLE_PAGINATION_MODEL
    );
  }, [customPaginationModel, groups, uid, violationsGroupBy]);
};
