import React, { useMemo } from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import { DisplayOption } from "../../../logsTypes";

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 5px;
  }
`;

const CheckboxOption: React.FC<DisplayOption> = ({
  name,
  enabled,
  setEnabled,
  ariaLabel,
}) => {
  return (
    <OptionContainer
      aria-label={ariaLabel}
      onClick={() => setEnabled(!enabled)}
    >
      <StyledCheckbox size="small" checked={enabled} defaultChecked />
      <Typography variant="body2">{name}</Typography>
    </OptionContainer>
  );
};

export const CheckboxOptions: React.FC<{
  displayOptions: DisplayOption[];
}> = ({ displayOptions }) => {
  const options = useMemo(
    () =>
      displayOptions.map((option) => (
        <CheckboxOption key={option.name} {...option} />
      )),
    [displayOptions]
  );

  return <Container>{options}</Container>;
};
