import React from "react";
import { Box, Typography } from "@mui/material";
import { muiTheme } from "themes";

export type StackedComparisonTableProps = {
  title: string;
  children: React.ReactNode;
};

export const StackedComparisonTable: React.FC<StackedComparisonTableProps> = ({
  title,
  children,
}) => {
  return (
    <div>
      <Box
        border={`1px solid ${muiTheme.palette.divider}`}
        padding="14px 12px"
        sx={{
          borderBottom: "none",
          backgroundColor: muiTheme.palette.common.white,
          borderRadius: 0,
        }}
      >
        <Typography variant="h4">{title}</Typography>
      </Box>
      <div>{children}</div>
    </div>
  );
};
