import React from "react";
import { muiColors, palette } from "@komodorio/design-system";
import { Link, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { WorkflowConfiguration } from "../../../../monitorsView/common/types";
import { useGetTriggerText } from "../../../../monitorsView/ManageMonitors/MonitorRules/RuleRow";
import { MONITORS_RULE_PARAM_KEY } from "../../../../../shared/config/urlSearchParamsKeys";
import { lightMuiTooltipStyle } from "../../../../../shared/styles/tooltip";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { RELIABILITY_SETTINGS_ROUTE } from "../../../../routes/routes";

const TooltipContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  padding: 0.5rem;
`;

export const AvailabilityTooltip: React.FC<{
  workflowConfig: WorkflowConfiguration;
}> = ({ workflowConfig }) => {
  const navigate = useNavigate();
  const triggerText = useGetTriggerText(workflowConfig);
  const tooltipContent = (
    <TooltipContainer>
      <Typography bold>Availability Health</Typography>
      <Typography>{triggerText}</Typography>
      <Link
        onClick={() =>
          navigate(
            `${RELIABILITY_SETTINGS_ROUTE}?${MONITORS_RULE_PARAM_KEY}=${workflowConfig?.id}`
          )
        }
        color={palette.darkBlue[500]}
      >
        Edit condition
      </Link>
    </TooltipContainer>
  );

  return (
    <Tooltip
      componentsProps={lightMuiTooltipStyle}
      title={tooltipContent}
      placement="bottom"
    >
      <InfoOutlined
        sx={{ width: 14, height: 16, color: muiColors.gray[500] }}
        aria-label={
          AriaLabels.ResourceView.Header.ServiceHealth.Availability
            .HealthInfoIcon
        }
      />
    </Tooltip>
  );
};
