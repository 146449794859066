import React from "react";

import { LogoIconProps } from "../types";

export const Cloudflare: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Cloudflare logo"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M17.7948 9.72001L15.7415 9.24335L15.3748 9.09668L5.95146 9.17001V13.7167L17.7948 13.7533V9.72001Z"
        fill="white"
      />
      <path
        d="M13.9448 13.3133C14.0037 13.1572 14.0245 12.9892 14.0054 12.8233C13.9863 12.6575 13.9278 12.4986 13.8348 12.36C13.7392 12.2455 13.622 12.1509 13.4899 12.0817C13.3578 12.0125 13.2133 11.97 13.0648 11.9567L6.68475 11.8833C6.64809 11.8833 6.61142 11.8467 6.57475 11.8467C6.56622 11.8403 6.55928 11.832 6.55451 11.8224C6.54974 11.8129 6.54725 11.8023 6.54725 11.7917C6.54725 11.781 6.54974 11.7705 6.55451 11.7609C6.55928 11.7514 6.56622 11.7431 6.57475 11.7367C6.61142 11.6633 6.64809 11.6267 6.72142 11.6267L13.1381 11.5533C13.5455 11.5081 13.9334 11.3552 14.2621 11.1103C14.5908 10.8654 14.8482 10.5374 15.0081 10.16L15.3748 9.20667C15.3748 9.17001 15.4114 9.13334 15.3748 9.09667C15.1754 8.20876 14.6917 7.41019 13.997 6.82231C13.3024 6.23444 12.4348 5.88939 11.5262 5.8396C10.6175 5.78981 9.71745 6.038 8.96274 6.54646C8.20802 7.05492 7.63992 7.79586 7.34475 8.65667C6.96157 8.38301 6.49418 8.25318 6.02475 8.29001C5.59395 8.33804 5.19229 8.53118 4.88578 8.8377C4.57926 9.14421 4.38612 9.54587 4.33809 9.97668C4.31367 10.1972 4.32606 10.4202 4.37475 10.6367C3.6777 10.6559 3.01566 10.9464 2.52948 11.4463C2.04329 11.9462 1.77132 12.616 1.77142 13.3133C1.7654 13.4488 1.77774 13.5845 1.80809 13.7167C1.80977 13.7453 1.8219 13.7723 1.84218 13.7926C1.86245 13.8129 1.88946 13.825 1.91809 13.8267H13.6881C13.7614 13.8267 13.8348 13.79 13.8348 13.7167L13.9448 13.3133Z"
        fill="#F48120"
      />
      <path
        d="M15.9614 9.20675H15.7781C15.7414 9.20675 15.7048 9.24342 15.6681 9.28008L15.4114 10.1601C15.3524 10.3162 15.3316 10.4843 15.3508 10.6501C15.3699 10.8159 15.4284 10.9748 15.5214 11.1134C15.617 11.228 15.7341 11.3225 15.8663 11.3917C15.9984 11.461 16.1429 11.5034 16.2914 11.5167L17.6481 11.5901C17.6848 11.5901 17.7214 11.6267 17.7581 11.6267C17.7666 11.6332 17.7736 11.6415 17.7783 11.651C17.7831 11.6605 17.7856 11.6711 17.7856 11.6817C17.7856 11.6924 17.7831 11.7029 17.7783 11.7125C17.7736 11.722 17.7666 11.7303 17.7581 11.7367C17.7214 11.8101 17.6848 11.8467 17.6114 11.8467L16.2181 11.9201C15.8107 11.9653 15.4228 12.1182 15.0941 12.3631C14.7654 12.608 14.5079 12.936 14.3481 13.3134L14.2748 13.6434C14.2381 13.6801 14.2748 13.7534 14.3481 13.7534H19.1881C19.203 13.7556 19.2183 13.7542 19.2326 13.7494C19.2469 13.7446 19.2599 13.7366 19.2706 13.7259C19.2813 13.7152 19.2893 13.7022 19.2941 13.6879C19.2989 13.6736 19.3002 13.6584 19.2981 13.6434C19.3854 13.3327 19.4346 13.0126 19.4448 12.6901C19.4389 11.768 19.0701 10.8854 18.4181 10.2334C17.7661 9.58142 16.8835 9.21256 15.9614 9.20675Z"
        fill="#FAAD3F"
      />
    </svg>
  );
};
