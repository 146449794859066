import { GridPaginationModel } from "@mui/x-data-grid";

import {
  CheckType,
  IgnoreRule,
  ViolationStatus,
  ViolationCountBy,
  ViolationsAggregationGroup,
  ImpactGroupIdentifier,
} from "@/generated/reliabilityApi";

export type AddEditIgnoreRuleDialogAction = {
  isOpen: boolean;
  checkType?: CheckType;
  existingIgnoreRule?: IgnoreRule;
};

export type PageRoutes =
  | "violations"
  | "checks"
  | "policies"
  | "best-practices";

export type ViolationsGroupBy =
  | "None"
  | "Impact"
  | "Cluster"
  | "CheckType"
  | "RealTimeIssues";

export type Severity = "high" | "medium" | "low";

export type TabValue = Severity | "all";

export type ColorScheme = {
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
};

export type ReliabilityErrorPayload = {
  action: string;
  message: string;
};

export type ReliabilityError =
  | "deleteIgnoreRule"
  | "addIgnoreRule"
  | "editIgnoreRule"
  | "updateViolationStatus"
  | "createViolationFeedback"
  | "deletePolicy"
  | "createPolicy"
  | "fetchPolicyData";

type AddEditIgnoreRuleDialogState = {
  isOpen: boolean;
  checkType?: CheckType;
  existingIgnoreRule?: IgnoreRule;
};

export type GroupData = {
  isExpanded: boolean;
  statuses: ViolationStatus[];
  checkTypes: CheckType[];
  searchTerm: string;
  paginationModel: GridPaginationModel;
};
export type Group = {
  groupData: Partial<Record<GroupIdentifier | string, GroupData>>;
};

export type GroupByOptions = Exclude<ViolationsGroupBy, "None">;

export type ViolationsState = {
  groups: Record<string, Group>;
  ungroupedTotalViolationsCount: number | undefined;
};

export type GroupsState = {
  groupBy: ViolationsGroupBy;
  uid: GroupIdentifier;
  newState: Partial<GroupData>;
};

export type UpdateGroupState = Omit<GroupsState, "uid"> & {
  uid: GroupIdentifier | string;
};

export type ReliabilityStoreState = {
  violationsState: ViolationsState;
  addEditIgnoreRuleDialogState: AddEditIgnoreRuleDialogState;
  initialTime: number;
  hasSubscribedToAppViewChanges: boolean;
};

export type GroupIdentifier = ImpactGroupIdentifier | "GroupedByNone";

type ReliabilityStoreActions = {
  setTopSectionIsShown: (isShown: boolean) => void;
  setUngroupedTotalViolationsCount: (count: number | undefined) => void;
  setGroupExpandedState: (
    groupBy: ViolationsGroupBy,
    groupId: GroupIdentifier
  ) => void;
  setGroupState: (groupState: UpdateGroupState) => void;
  setAddEditIgnoreRuleDialogState: (
    state: AddEditIgnoreRuleDialogAction
  ) => void;
  setHasSubscribedToAppViewChanges: (hasSubscribed: boolean) => void;
};

export type ReliabilityStore = ReliabilityStoreState & ReliabilityStoreActions;

export type ReliabilityFilterCategories = Partial<
  Record<ViolationCountBy, string[]>
>;

export type ViolationsTableConfiguration = Partial<
  Record<keyof ReliabilityFilterCategories, string[]>
>;

export const ViolationsTableType = {
  riskAssessment: "RiskAssessment",
  impactAnalysis: "ImpactAnalysis",
} as const;

type TypeOfViolationsTable = typeof ViolationsTableType;
export type ViolationsTableType =
  TypeOfViolationsTable[keyof typeof ViolationsTableType];

export type GroupProps = {
  group: ViolationsAggregationGroup;
  groupBy: GroupByOptions;
};

export type ViolationTableCustomPaginationModel = {
  customPaginationModel: GridPaginationModel;
  setCustomPaginationModel: (newPaginationModel: GridPaginationModel) => void;
};

export type IsRealtimeIssues = {
  isRealtimeIssues?: boolean;
};
