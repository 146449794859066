import React from "react";

import { LogoIconProps } from "../types";

export const Karpenter24: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Karpenter logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <g clipPath="url(#clip0_2952_49)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.459 3C14.8452 3 17.5902 5.67572 17.5902 8.97639L17.5902 17.4259L20.4754 17.4259C21.8697 17.4259 23 18.5277 23 19.8868V22H22.668C21.8661 22 21.2118 21.3741 21.1967 20.5926V20.2398L21.1966 20.2224C21.187 19.648 20.7063 19.1852 20.1148 19.1852H5.68852C3.09912 19.1852 1 17.139 1 14.615L1 8.97639C1 5.67572 3.74501 3 7.13115 3H11.459ZM11.0984 4.75926H7.4918L7.41427 4.75987C4.86063 4.80023 2.80328 6.83064 2.80328 9.32944V14.6135L2.80367 14.66C2.82914 16.1918 4.11098 17.4259 5.68852 17.4259H12.9016C14.4951 17.4259 15.7869 16.1668 15.7869 14.6135V9.32944L15.7863 9.25386C15.7449 6.76468 13.6619 4.75926 11.0984 4.75926ZM7.50623 7.57407C7.91738 7.57407 8.24197 7.9013 8.24197 8.31296V10.4557L10.7413 7.94352C11.0659 7.64796 11.5095 7.62685 11.8233 7.91185V7.92241C12.1262 8.19685 12.1046 8.65074 11.7692 8.9463L9.91902 10.8041L12.0721 13.8019C12.3318 14.1713 12.2561 14.6357 11.9098 14.8574C11.5311 15.0685 11.0984 14.9524 10.8387 14.583L8.90197 11.8174L8.24197 12.493V14.2241C8.24197 14.6463 7.91738 14.963 7.50623 14.963C7.09508 14.963 6.77049 14.6463 6.77049 14.2241V8.31296C6.77049 7.9013 7.09508 7.57407 7.50623 7.57407Z"
          fill="#5C62B0"
        />
      </g>
      <defs>
        <clipPath id="clip0_2952_49">
          <rect
            width="22"
            height="19"
            fill="white"
            transform="translate(1 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
