import React, { useState } from "react";
import {
  ActionItem,
  IconButton,
  MenuList,
  Popover,
} from "@komodorio/design-system/deprecated";
import { Cog16, Ellipsis16 } from "@komodorio/design-system/icons";
import { TooltipWrapper } from "react-tooltip";

import { DEFAULT_TOOLTIP } from "@/shared/constants/tooltipIds";
import { ZIndex } from "@/constants/zIndex";
import { AriaLabels } from "@/shared/config/ariaLabels";

export const MANAGE_MONITORS_DENIED_MESSAGE =
  "You do not have permissions to edit Komodor monitors";

type Props = {
  canManageMonitors?: boolean;
  onEditMonitorClick?: () => void;
};

export const ActionsPopover: React.FC<Props> = ({
  canManageMonitors,
  onEditMonitorClick,
}) => {
  const [openActions, setOpenActions] = useState(false);

  return (
    <div>
      <Popover
        isOpen={openActions}
        content={
          <MenuList>
            <TooltipWrapper
              content={
                !canManageMonitors ? MANAGE_MONITORS_DENIED_MESSAGE : undefined
              }
              tooltipId={DEFAULT_TOOLTIP}
            >
              <ActionItem
                disabled={!canManageMonitors}
                icon={Cog16}
                onClick={() => {
                  onEditMonitorClick?.();
                  setOpenActions(false);
                }}
                aria-label={AriaLabels.AvailabilityTriage.EditMonitorActionItem}
              >
                Edit monitor
              </ActionItem>
            </TooltipWrapper>
          </MenuList>
        }
        handleClose={() => setOpenActions(false)}
        containerStyle={{ zIndex: ZIndex.AboveMuiDrawer.toString() }}
      >
        <IconButton
          icon={Ellipsis16}
          size="medium"
          onClick={() => setOpenActions(true)}
        />
      </Popover>
    </div>
  );
};
