import { useCallback } from "react";

import { useHealthType } from "@/components/reliability/hooks/useHealthType";
import {
  DatadogReportLoadingTimeContextProviderProps,
  ViewOptions,
} from "@/shared/context/datadogReportLoadingTime/datadogReportLoadingTimeTypes";
import {
  getFullPageViewName,
  reliabilityService,
} from "@/components/reliability/constants/dataDogReporting";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { drawersStackSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useGetCurrentTab } from "@/components/reliability/hooks/useGetCurrentTab";

export const useDatadogReportingProps =
  (): DatadogReportLoadingTimeContextProviderProps => {
    const drawerIsOpen = useIsDrawerOpen();
    const healthType = useHealthType();
    const currentTab = useGetCurrentTab();

    const getCurrentViewOptions = useCallback((): ViewOptions => {
      return {
        name: getFullPageViewName(healthType, currentTab),
        service: reliabilityService,
      };
    }, [healthType, currentTab]);

    const shouldPreventReporting = useCallback((): boolean => {
      return drawerIsOpen;
    }, [drawerIsOpen]);

    return { getCurrentViewOptions, shouldPreventReporting };
  };

const useIsDrawerOpen = () => {
  const drawersStack = useDrawersStackStore(drawersStackSelector);
  return drawersStack.length > 0;
};
