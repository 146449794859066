import { useCallback, useMemo } from "react";

import {
  ExtendedViolation,
  ClusterProviderType,
} from "../../../../../../../generated/reliabilityApi";
import { HeaderProps } from "../components/shared/DrawerBase/Header";
import { parseKomodorUid } from "../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { getClusterProviderIcon } from "../violationDrawerUtils";
import { GetSummaryDataOutput } from "../violationDrawerTypes";
import { useGetViolationTitle } from "../../../../../hooks/useGetViolationTitle";

import { useAddPolicyLink } from "./useAddPolicyLink";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useGetServiceIdFromKomodorUid } from "@/shared/hooks/useGetServiceIdFromKomodorUid";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

export const useGetHeaderProps = (violation: ExtendedViolation | undefined) => {
  const {
    severity,
    createdAt,
    lastEvaluatedAt,
    status,
    komodorUid,
    checkType,
    supportingData,
  } = violation ?? {};

  const checkTitle = useGetViolationTitle({
    checkType,
    supportingData,
    komodorUid,
  });

  return useMemo<HeaderProps>(() => {
    return {
      title: checkTitle,
      severity,
      firstSeen: createdAt,
      lastEvaluatedAt,
      status,
      komodorUid,
      checkType,
      supportingData,
    };
  }, [
    checkType,
    checkTitle,
    createdAt,
    komodorUid,
    lastEvaluatedAt,
    severity,
    status,
    supportingData,
  ]);
};

export const useGetSummaryData = (
  violation: Partial<ExtendedViolation> | undefined
): GetSummaryDataOutput | undefined => {
  const { policyName = "", policyId = "" } = violation ?? {};
  const addPolicyLinkIfPossible = useAddPolicyLink({ policyName, policyId });
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);
  const { serviceId } =
    useGetServiceIdFromKomodorUid(violation?.komodorUid) ?? {};

  const onServiceLinkClick = useCallback(
    (serviceId: string) => {
      pushDrawer({
        drawerType: DrawerType.ResourceDrawerByServiceId,
        serviceId,
      });
    },
    [pushDrawer]
  );

  return useMemo(() => {
    const { komodorUid } = violation ?? {};
    const metadataItems = [];

    if (komodorUid) {
      const {
        name = "",
        namespace = "",
        cluster = "",
      } = parseKomodorUid(komodorUid) ?? {};

      metadataItems.push(
        {
          label: "cluster",
          value: cluster,
        },
        {
          label: "namespace",
          value: namespace,
        },
        {
          label: "service",
          value: name,
          onClick: serviceId ? () => onServiceLinkClick(serviceId) : undefined,
        }
      );
    }

    addPolicyLinkIfPossible(metadataItems);

    if (metadataItems.length === 0) return;

    return {
      komodorUid,
      metadataItems,
    };
  }, [violation, addPolicyLinkIfPossible, serviceId, onServiceLinkClick]);
};

export const useGetClusterSummaryData = ({
  komodorUid,
  clusterProvider,
  policyName,
  policyId,
}: {
  komodorUid?: string;
  clusterProvider?: ClusterProviderType;
  policyName?: string;
  policyId?: string;
}): GetSummaryDataOutput | undefined => {
  const addPolicyLinkIfPossible = useAddPolicyLink({ policyName, policyId });

  return useMemo(() => {
    const { cluster } = parseKomodorUid(komodorUid ?? "") ?? {};
    const metadataItems = [];

    if (clusterProvider) {
      metadataItems.push({
        label: "provider",
        value: getClusterProviderIcon(clusterProvider),
      });
    }

    if (cluster) {
      metadataItems.push({
        label: "cluster",
        value: cluster,
      });
    }

    addPolicyLinkIfPossible(metadataItems);

    if (metadataItems.length === 0) return;

    return {
      komodorUid,
      metadataItems,
    };
  }, [addPolicyLinkIfPossible, clusterProvider, komodorUid]);
};

export const useGetCommonDrawerProps = (
  violation: ExtendedViolation | undefined
) => {
  const headerProps = useGetHeaderProps(violation);

  const { supportingData, komodorUid, dependentByViolationsIds } =
    violation ?? {};

  const summaryProps = useGetSummaryData(violation);

  return {
    headerProps,
    summaryProps,
    supportingData,
    komodorUid,
    dependentByViolationsIds,
  };
};
