import React from "react";
import styled from "styled-components";

import { TimeWindowSelector } from "../TimeWindowSelector";

import { SectionTitle } from "@/components/reliability/components/shared/SectionTitle/SectionTitle";
import { reliabilityArialLabels } from "@/components/reliability/reliabilityArialLabels";
import { ImpactGroupType } from "@/generated/reliabilityApi";
import { GroupedViolations } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/GroupedViolations";

const TOOLTIP_TEXT =
  "These issues are monitored continuously, providing up-to-date information on the current state of your workloads. This allows for quick detection and resolution of emerging problems.";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const {
  sections: { realtime: realtimeSectionAriaLabel },
} = reliabilityArialLabels;

export const RealtimeSection: React.FC = () => {
  return (
    <Container aria-label={realtimeSectionAriaLabel}>
      <TimeWindowSelector withLabel={false} />
      <SectionTitle title="Realtime Issues" tooltipText={TOOLTIP_TEXT} />
      <GroupedViolations overrideImpactGroupType={ImpactGroupType.Realtime} />
    </Container>
  );
};
