import { GenericViolationFilterCategory } from "@/generated/reliabilityApi";
import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";

export const useShouldEnableApiCall = (
  filterBy: GenericViolationFilterCategory[] | undefined
) => {
  const [dataInUrl] = useStateInUrl();

  return dataInUrl.filters !== undefined && filterBy !== undefined;
};
