import React, { useState } from "react";
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import { useVirtualizer, VirtualizerOptions } from "@tanstack/react-virtual";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "components/komodor-ui/Select/shared/types";
import { MultiSelectItem } from "../MultiSelectPopover/MultiSelectItem";

const DEFAULT_OPTION_HEIGHT = 40;

interface VirtualizedMenuListProps<T extends MuiSelectionOptionValue> {
  options: MuiSelectionOption<T>[];
  selectedOptions: MuiSelectionOption<T>[] | undefined;
  onItemSelect: (value: T) => void;
  className?: string;
  classes?: {
    menuItem?: string;
  };
  ariaLabel?: string;
  customOptionElement?: (
    option: MuiSelectionOption<T>,
    isChecked: boolean
  ) => React.ReactNode;
  maxHeight?: string;
  virtualizerProps?: Partial<VirtualizerOptions<Element, Element>>;
  autoFocusItem?: boolean;
}
export const VirtualizedMultiSelectMenuList = <
  T extends MuiSelectionOptionValue
>({
  options,
  selectedOptions,
  onItemSelect,
  className,
  ariaLabel,
  customOptionElement,
  maxHeight,
  virtualizerProps,
  autoFocusItem,
  classes,
}: VirtualizedMenuListProps<T>) => {
  const [menuListRef, setMenuListRef] = useState<HTMLUListElement | null>(null);
  const virtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => menuListRef,
    estimateSize: () => DEFAULT_OPTION_HEIGHT,
    ...virtualizerProps,
  });

  const dropdownOptions = virtualizer.getVirtualItems().map((value) => {
    const option = options[value.index];
    return (
      <Box
        key={value.key}
        data-index={value.index}
        sx={{
          position: "absolute",
          width: "100%",
          height: `${value.size}px`,
          transform: `translateY(${value.start}px)`,
        }}
      >
        <MultiSelectItem
          className={classes?.menuItem}
          option={option}
          selectedOptions={selectedOptions}
          onSelect={onItemSelect}
          customOptionElement={customOptionElement}
        />
      </Box>
    );
  });

  return (
    <MenuList
      ref={(node) => setMenuListRef(node)}
      className={className}
      aria-label={ariaLabel}
      autoFocusItem={autoFocusItem}
      sx={{
        position: "relative",
        overflowY: "auto",
        maxHeight,
        paddingRight: "20px",
      }}
    >
      <Box
        sx={{
          height: `${virtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {dropdownOptions}
      </Box>
    </MenuList>
  );
};
