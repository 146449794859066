import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

import { getAppConfig } from "@/shared/config/appConfig";
import { cleanupKomodorLocalStorage } from "@/shared/utils/localStorageSettings";

export const useLogout: () => () => void = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  return useCallback(() => {
    cleanupKomodorLocalStorage();
    logout({
      returnTo: getAppConfig().authRedirectUri,
    });
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 1000); // the delay is for the 'Logging out...' component to not just disappear
  }, [logout, navigate]);
};
