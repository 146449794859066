import { HealthType } from "../hooks/useHealthType";

import { ReliabilityRoutes } from "@/components/reliability/constants/reliabilityConstants";

const workloadsHealthShortName = "|workloads";
const infrastructureHealthShortName = "|infrastructure";
const reliabilityPrefix = "reliability";
const overview = "overview";
const violationsDrawer = "violation_drawer";

export const historyTab = `${reliabilityPrefix}|${violationsDrawer}|history`;
export const reliabilityService = "reliability-api";

export const getViolationDrawerViewName = (checkType: string) =>
  `${reliabilityPrefix}|${violationsDrawer}|${checkType}`;

const healthTypeToViewShortName: Record<HealthType, string> = {
  [HealthType.WORKLOAD]: workloadsHealthShortName,
  [HealthType.INFRA]: infrastructureHealthShortName,
  [HealthType.NOT_SPLIT]: "",
};

export const getFullPageViewName = (
  healthType: HealthType,
  currentTab?: string
) => {
  const tabStr = TAB_NAMES[currentTab ?? ""]
    ? `|${TAB_NAMES[currentTab ?? ""]}`
    : "";

  return `${reliabilityPrefix}${healthTypeToViewShortName[healthType]}${tabStr}|${overview}`;
};

export const TAB_NAMES = {
  [ReliabilityRoutes.bestPractices]: "best_practices",
};
