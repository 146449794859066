import React, { useMemo } from "react";
import styled from "styled-components";

import { useActiveAgent } from "@/shared/hooks/useAgents";
import { useResource } from "@/components/ResourceView/useResource";
import { InvestigationSection } from "@/components/ResourceView/AiInvestigation/common/InvestigationSection";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useCertificateData } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/hooks/useCertificateData";
import { FlowType } from "@/components/ResourceView/AiInvestigation/common/types";

const Container = styled.div`
  padding: 8px 24px;
`;

type InvestigationSectionWrapperProps = {
  isHealthy: boolean;
  resourceType: string;
};

export const InvestigationSectionWrapper: React.FC<
  InvestigationSectionWrapperProps
> = ({ isHealthy, resourceType }) => {
  const { klaudiaAiAddons } = useOverridableFlags();
  // TODO: temp solution, this specifically casts data as CertificateFull
  const { data: certificateData } = useCertificateData();
  // this assumes structure of addonData's object.header is the same for all addons
  const { object } = certificateData?.data ?? {};
  const { name = "", clusterName = "", namespace } = object?.header ?? {};

  const agentId = useActiveAgent(clusterName) ?? "";
  const shouldFetchResource = !!klaudiaAiAddons && !isHealthy;

  const useResourceParams = useMemo(
    () => ({
      agentId,
      resourceType,
      cluster: clusterName,
      namespace,
      resourceName: name,
      executeMutation: shouldFetchResource,
    }),
    [agentId, clusterName, name, namespace, resourceType, shouldFetchResource]
  );

  const { resource } = useResource(useResourceParams);

  if (!resource || isHealthy) return null;

  return (
    <Container>
      <InvestigationSection
        isHealthy={isHealthy}
        resource={resource}
        requestId={resource.name} // TODO: temp, insufficient solution until we have a sessionId to go by
        flowType={FlowType.CertificateIssue}
      />
    </Container>
  );
};
