import { useMemo } from "react";
import lodash from "lodash";

import { useNodeTerminationsByOwnerKomodorUidsFromAPIQuery } from "../../../EventGroup/nodeEvent/useNodeChangeEvents";
import { groupNodeChangeEvents } from "../../../EventGroup/nodeEvent/NodeChangeGroup";
import { TimeWindow } from "../../../../../shared/types/TimeWindow";
import { buildServiceId } from "../../../../../shared/utils/serviceHelpers";
import { useUserMetadata } from "../../../../../shared/hooks/useUserMetadata/useUserMetadata";
import { useMapPodToService } from "../../../../../shared/hooks/resources-api/client/komodor-services/useMapPodToService";
import { KomodorServicePodMapParamsOwnerTypeEnum } from "../../../../../generated/resourcesApi";
import { buildKomodorUid } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";

import type PodPhaseGroup from "@/components/common/EventGroup/groupedPodEvent/PodPhaseGroup";

export const useGetCorrelatedNodeTerminations = (
  podPhaseGroup: PodPhaseGroup,
  timeWindow: Pick<TimeWindow, "start" | "end">
) => {
  const podUid = useMemo(
    () =>
      buildKomodorUid({
        resourceName: podPhaseGroup.podName,
        clusterName: podPhaseGroup.clusterName,
        namespace: podPhaseGroup.namespace,
        kind: "Pod",
      }),
    [podPhaseGroup]
  );

  const isValidPodUid = useMemo(() => podUid?.length > 5, [podUid]);

  const { data: podServicesMap } = useMapPodToService(
    {
      podsKomodorUids: [podUid],
      ownerType: KomodorServicePodMapParamsOwnerTypeEnum.WithRootOwner,
    },
    isValidPodUid
  );

  const ownerKomodorUid = useMemo(
    () => lodash.get(podServicesMap?.data, podUid),
    [podUid, podServicesMap?.data]
  );

  const { accountName } = useUserMetadata();

  const serviceIds = useMemo(
    () => [
      buildServiceId(
        accountName,
        podPhaseGroup.clusterName,
        podPhaseGroup.namespace,
        podPhaseGroup.podName
      ),
    ],
    [accountName, podPhaseGroup]
  );

  const [nodeTerminations, isLoadingNodeTerminations] =
    useNodeTerminationsByOwnerKomodorUidsFromAPIQuery(
      [ownerKomodorUid],
      timeWindow
    );

  const nodeTerminationsGroups = groupNodeChangeEvents(
    nodeTerminations?.flatMap((e) => e) ?? [],
    serviceIds[0]
  );

  return {
    nodeTerminationsGroups,
    isLoadingNodeTerminations,
  };
};
