import { useEffect, useMemo } from "react";

import { MetricType } from "../../../../../../../../../Metrics/types";
import { useHpaReachedMaxContext } from "../context/useHpaReachedMaxContext";
import { parseKomodorUid } from "../../../../../../../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { useIsMetricsDrawerSupported } from "../../../../../../../../../Metrics/metricsHooks";
import { useGetWorkloadMetrics } from "../../../../../../../../../../shared/hooks/metrics-api/client/useGetWorkloadMetrics";
import { Datapoint } from "../../../../../../../../../../generated/metricsApi";
import { useGetEpochsFromViolation } from "../../../../hooks/useGetEpochsFromViolation";

import { useMetricsGraphRequestParams } from "@/components/ResourceView/tabs/WorkloadMetricsTab/components/graphs/useWorkloadMetrics";
import { unixTimeToMilliseconds } from "@/shared/utils/timeUtils";
import { useEpochsFromWorkloadMetricsTimeWindow } from "@/components/Metrics/hooks/workload/workloadMetricsTabHooks";

export const useSetRequestDataFromMetrics = (type: MetricType) => {
  const { serviceKomodorUid, setRequestValues, requestValues } =
    useHpaReachedMaxContext();

  const {
    kind = "",
    cluster = "",
    namespace = "",
    name = " ",
  } = parseKomodorUid(serviceKomodorUid ?? "") ?? {};

  const isMetricsSupported = useIsMetricsDrawerSupported(cluster);

  const hasValidData = useMemo(
    () => !!isMetricsSupported && !!cluster && !!name && !!kind && !!namespace,
    [cluster, isMetricsSupported, kind, name, namespace]
  );

  const { toEpoch: toEpochViolation } = useGetEpochsFromViolation();

  const { fromEpoch, toEpoch } = useEpochsFromWorkloadMetricsTimeWindow(
    unixTimeToMilliseconds(toEpochViolation)
  );
  const requestParams = useMetricsGraphRequestParams(
    {
      cluster,
      namespace,
      kind,
      name,
    },
    fromEpoch,
    toEpoch
  );

  const { data: cpuData } = useGetWorkloadMetrics({
    type: "cpu",
    params: requestParams,
    enabled: hasValidData && type === "cpu",
  });

  const { data: memoryData } = useGetWorkloadMetrics({
    type: "memory",
    params: requestParams,
    enabled: hasValidData && type === "memory",
  });

  const metricsToUse = type === "cpu" ? cpuData : memoryData;

  useEffect(() => {
    if (requestValues[type]) return;
    if ((metricsToUse?.data?.request?.length || 0) > 0) {
      let i = 0,
        requestMetric: Datapoint | undefined = undefined;
      const len = metricsToUse?.data?.request.length ?? 0;

      while (!requestMetric && i <= len) {
        const metric = metricsToUse?.data?.request[len - i];
        if (metric && metric.value > 0) {
          requestMetric = metric;
        }
        i++;
      }

      requestMetric && setRequestValues({ [type]: requestMetric.value });
    }
  }, [metricsToUse?.data?.request, requestValues, setRequestValues, type]);
};
