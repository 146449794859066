import React from "react";
import { Karpenter24, Kubernetes24 } from "@komodorio/design-system/icons";
import { muiColors } from "@komodorio/design-system";

import { AutoScalerEnum } from "@/generated/addonsApi";

export const nodeAutoscalersTypeMapper: Record<
  AutoScalerEnum,
  { title: string; icon: React.FC }
> = {
  [AutoScalerEnum.ClusterAutoScaler]: {
    title: "Cluster AutoScaler",
    icon: () => <Kubernetes24 color={muiColors.indigo[500]} />,
  },
  [AutoScalerEnum.Karpenter]: {
    title: "Karpenter",
    icon: () => <Karpenter24 />,
  },
};
