import { useMemo, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import { Pod } from "kubernetes-types/core/v1";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { KomodorServiceResourceKind } from "../../../../shared/types/Resource";
import { Panel } from "../../../Inspection/styles";
import { LinesLoader } from "../../../common/loaders/Line";
import { LogsUpgradeAgentAlert } from "../../../common/LogsViewer/LogsUpgradeAgentAlert/LogsUpgradeAgentAlert";
import { usePodLogsStore } from "../../../../shared/hooks/podLogs/store/podLogsStore";
import { resetPodLogs } from "../../../../shared/hooks/podLogs/store/podLogsStoreSelectors";
import { NoLivePodsToDisplay } from "../NoLivePodsToDisplay/NoLivePodsToDisplay";

import { PodLogs } from "./PodLogs";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useResourcePodsState } from "./useResourcePodsState";

import { AriaLabels } from "@/shared/config/ariaLabels";

interface ServiceLogsTabProps {
  resource: KomodorServiceResourceKind;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Card = styled.div`
  background-color: ${muiColors.common.white};
  padding: 24px;
  border-radius: 4px;
`;

const SelectLabel = ({ label }: { label: string }) => {
  return (
    <Typography
      variant="body3"
      color={"text.secondary"}
      sx={{ textTransform: "uppercase", fontWeight: 500 }}
    >
      {label}:
    </Typography>
  );
};

const SelectsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ServiceLogsTab = ({ resource }: ServiceLogsTabProps): JSX.Element => {
  const [selectedPod, setSelectedPod] = useState<Pod | undefined>(undefined);
  const resetLogsInStore = usePodLogsStore(resetPodLogs);
  const [selectedContainerName, setSelectedContainerName] = useState<
    string | undefined
  >(undefined);

  const { data, isLoading } = useResourcePodsState(resource);

  const fetchedPods = data?.data?.json as Pod[] | undefined;
  const pod = useMemo(
    () => selectedPod ?? fetchedPods?.[0],
    [selectedPod, fetchedPods]
  );

  const allStatuses = useMemo(() => {
    return [
      ...(pod?.status?.containerStatuses ?? []),
      ...(pod?.status?.initContainerStatuses ?? []),
    ];
  }, [pod]);

  const selectedContainer = useMemo(() => {
    const containerName = selectedContainerName ?? allStatuses?.[0]?.name;
    if (!containerName) {
      return undefined;
    }
    return {
      label: `Container: ${containerName}`,
      value: containerName,
    };
  }, [selectedContainerName, allStatuses]);

  const selectContainerRef = useRef<HTMLDivElement>(null);

  if (!pod && !isLoading) {
    return <NoLivePodsToDisplay />;
  }

  if (!pod || isLoading) {
    return (
      <Panel>
        <LinesLoader />
      </Panel>
    );
  }

  return (
    <Container aria-label={AriaLabels.ResourceView.ServiceLogsTab.TabPanel}>
      <LogsUpgradeAgentAlert clusterName={resource.cluster} />
      <Card>
        <SelectsContainer ref={selectContainerRef}>
          <SelectContainer>
            <SelectLabel label="Pod" />
            <Select
              value={pod?.metadata?.name}
              size="small"
              sx={{ width: "15rem" }}
              onChange={(e) => {
                const pod = e.target.value;
                setSelectedPod(
                  fetchedPods?.find((p) => p.metadata?.name === pod)
                );
                resetLogsInStore();
              }}
            >
              {fetchedPods?.map((pod) => (
                <MenuItem key={pod.metadata?.name} value={pod.metadata?.name}>
                  {pod.metadata?.name}
                </MenuItem>
              ))}
            </Select>
          </SelectContainer>
          <SelectContainer>
            <SelectLabel label="Container" />
            <Select
              value={selectedContainer?.value}
              size="small"
              sx={{ width: "15rem" }}
              onChange={(e) => {
                setSelectedContainerName(e.target.value);
                resetLogsInStore();
              }}
            >
              {allStatuses.map((container) => (
                <MenuItem key={container.containerID} value={container.name}>
                  {container.name}
                </MenuItem>
              ))}
            </Select>
          </SelectContainer>
        </SelectsContainer>
        <PodLogs
          id={pod.metadata?.uid ?? ""}
          cluster={resource.cluster}
          namespace={pod.metadata?.namespace ?? ""}
          name={pod.metadata?.name ?? ""}
          containerStatuses={allStatuses}
          selectedContainer={selectedContainer}
          selectContainerRef={selectContainerRef}
        ></PodLogs>
      </Card>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default ServiceLogsTab;
