import { LogsViewerStore } from "./types";

// getters
export const selectIsLiveTailEnabledBySystem = (state: LogsViewerStore) =>
  state.isLiveTailEnabledBySystem;
export const selectIsLiveTailEnabledByUser = (state: LogsViewerStore) =>
  state.isLiveTailEnabledByUser;
export const selectIsLiveTailEnabled = (state: LogsViewerStore) =>
  state.isLiveTailEnabledByUser && state.isLiveTailEnabledBySystem;
export const selectIsAnalyzingLogs = (state: LogsViewerStore) =>
  state.isAnalyzingLogs;
export const selectViewSettings = (state: LogsViewerStore) =>
  state.viewSettings;

// setters
export const setLiveTailEnabledBySystem = (state: LogsViewerStore) =>
  state.setLiveTailEnabledBySystem;
export const setLiveTailEnabledByUser = (state: LogsViewerStore) =>
  state.setLiveTailEnabledByUser;
export const setLiveTailEnabled = (state: LogsViewerStore) =>
  state.setLiveTailEnabled;
export const setIsAnalyzingLogs = (state: LogsViewerStore) =>
  state.setAnalyzingLogs;
export const setViewSettings = (state: LogsViewerStore) =>
  state.setViewSettings;
