import Link from "@mui/material/Link";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import { EMPTY_CELL } from "@komodorio/design-system/komodor-ui";

import { parseKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { ConnectedWorkloadsModal } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/ConnectedWorkloadsCell/ConnectedWorkloadsModal";
import { ModalProvider } from "@/shared/context/modal/ModalProvider";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";

export const ConnectedWorkloadsCell: React.FC<{
  host: string;
  connectedWorkloadsUids: string[];
}> = ({ host, connectedWorkloadsUids }) => {
  const [openModal, setOpenModal] = useState(false);
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  if (!connectedWorkloadsUids.length) {
    return EMPTY_CELL;
  }
  const [firstWorkload, ...restWorkloads] = connectedWorkloadsUids;
  const {
    cluster = "",
    namespace = "",
    kind = "",
    name = "",
  } = parseKomodorUid(firstWorkload) ?? {};

  return (
    <>
      <Stack direction="row" gap="4px" width="100%">
        <Link
          variant="body2"
          underline="hover"
          noWrap
          onClick={() =>
            pushDrawer({
              drawerType: DrawerType.ResourceDrawerByData,
              cluster,
              namespace,
              resourceType: kind,
              resourceName: name,
            })
          }
        >
          {name}
        </Link>
        {restWorkloads.length > 0 && (
          <Link
            variant="body2"
            underline="hover"
            onClick={() => setOpenModal(true)}
          >
            +{restWorkloads.length}
          </Link>
        )}
      </Stack>
      <ModalProvider open={openModal} onClose={() => setOpenModal(false)}>
        <ConnectedWorkloadsModal
          host={host}
          workloadsUids={connectedWorkloadsUids}
        />
      </ModalProvider>
    </>
  );
};
