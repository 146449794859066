import React, { ReactElement, useState } from "react";
import { LineChart, YAxis, CartesianGrid, XAxis } from "recharts";
import { palette } from "@komodorio/design-system";
import { Button, Typography } from "@komodorio/design-system/deprecated";
import styled from "styled-components";
import AutoSizer from "react-virtualized-auto-sizer";

import { LinesLoader } from "../common/loaders/Line";
import UpgradeRequired from "../common/UpdateAgentModal/upgradeRequired";
import { UpgradeCommands } from "../common/upgradeAgentCta/UpgradeAgentCTA";
import { UpgradeRequiredModalStep } from "../common/UpdateAgentModal/types";
import { useGetHelmInstallationCommand } from "../../shared/hooks/useGetHelmInstallationCommand";

import {
  GraphChartMargin,
  GraphContainerHeight,
  GraphGridColor,
  MetricsChartContainer,
} from "./styles";

const Message = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
`;

export type EmptyState = {
  color: string;
  message: string;
  action?: ReactElement;
};
// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export enum EmptyStateStatus {
  Error = "Error",
  Empty = "Empty",
  Loading = "Loading",
  Upgrade = "Upgrade",
  Retention = "Retention",
}

const Upgrade: React.FC = () => {
  const [open, setOpen] = useState(false);
  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.UPGRADE_COMMAND_METRICS
  );
  return (
    <>
      <Button variant="primary" size="small" onClick={() => setOpen(true)}>
        Update Agent
      </Button>
      {open && (
        <UpgradeRequired
          handleClose={() => setOpen(false)}
          updateMessage={null}
          upgradeCommand={commandStr}
          desiredFeature="metrics"
          initialStep={UpgradeRequiredModalStep.ShowCommand}
        />
      )}
    </>
  );
};

export const ERROR_MESSAGE = "Error loading data";
export const EMPTY_STATE_MESSAGE = "No data available";
export const LOADING_MESSAGE = "Loading data";
export const UPGRADE_MESSAGE = "Update your agent to access metrics";
export const RETENTION_MESSAGE =
  "Metrics available only for 30 days since the issue started";

const EmptyStateStatusObject: Record<string, EmptyState> = {
  [EmptyStateStatus.Error]: {
    color: palette.pink[50],
    message: ERROR_MESSAGE,
  },
  [EmptyStateStatus.Empty]: {
    color: palette.blue[50],
    message: EMPTY_STATE_MESSAGE,
  },
  [EmptyStateStatus.Loading]: {
    color: palette.darkBlue[50],
    message: LOADING_MESSAGE,
    action: <LinesLoader marginTop="0" />,
  },
  [EmptyStateStatus.Upgrade]: {
    color: palette.gray[100],
    message: UPGRADE_MESSAGE,
    action: <Upgrade />,
  },
  [EmptyStateStatus.Retention]: {
    color: palette.blue[50],
    message: RETENTION_MESSAGE,
  },
};

type EmptyStateGraphProps = {
  status: EmptyStateStatus;
  excludeAxes?: boolean;
  graphHeight?: string;
  customEmptyState?: EmptyState;
};
const EmptyStateGraph: React.FC<EmptyStateGraphProps> = ({
  status,
  excludeAxes,
  graphHeight = GraphContainerHeight,
  customEmptyState,
}) => {
  const data = [{ timestamp: "0", value: 0 }];
  const state =
    customEmptyState ??
    EmptyStateStatusObject[status] ??
    EmptyStateStatusObject.Empty;

  return (
    <MetricsChartContainer
      aria-label={"metrics-empty-graph"}
      height={graphHeight}
    >
      <AutoSizer>
        {({ width, height }) => (
          <LineChart
            width={width}
            height={height}
            data={data}
            margin={GraphChartMargin}
          >
            <CartesianGrid
              fill={state.color}
              stroke={GraphGridColor}
              opacity={0.5}
              strokeWidth="2"
            />
            {!excludeAxes && (
              <>
                <XAxis
                  dataKey="timestamp"
                  axisLine={false}
                  tickLine={false}
                  hide={true}
                />
                <YAxis
                  dataKey="value"
                  fontSize={12}
                  axisLine={false}
                  tickLine={false}
                  hide={true}
                />
              </>
            )}
          </LineChart>
        )}
      </AutoSizer>
      <Message>
        <Typography size="large">{state.message}</Typography>
        {state.action}
      </Message>
    </MetricsChartContainer>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default EmptyStateGraph;
