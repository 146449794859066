import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  apiV1AccountsIdFeaturesPutUrl,
  AccountFeaturesResponse,
  ApiV1AccountsIdFeaturesPutRequest,
  AccountsApiApiV1AccountsIdFeaturesPutRequest,
} from "../../../../../generated/accounts";
import { useAccountsApiClient } from "../apiClient";
import { useUserMetadata } from "../../../useUserMetadata/useUserMetadata";

const ACCOUNT_FEATURES_PATH = "/:id/features";

const updateAccountFeatures = async (
  apiClient: AxiosInstance,
  params: AccountsApiApiV1AccountsIdFeaturesPutRequest
): Promise<AccountFeaturesResponse> => {
  const { data } = await apiClient.put(
    apiV1AccountsIdFeaturesPutUrl(params, apiClient.defaults.baseURL ?? ""),
    params
  );
  return data;
};

export const useUpdateAccountFeatures = (
  params: ApiV1AccountsIdFeaturesPutRequest
) => {
  const apiClient = useAccountsApiClient();
  const { accountId } = useUserMetadata();
  return useMutation([ACCOUNT_FEATURES_PATH, { id: accountId, params }], () =>
    updateAccountFeatures(apiClient, {
      id: accountId,
      ...params,
    })
  );
};
