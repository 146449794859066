import { ContainerStatus } from "kubernetes-types/core/v1";
import { useState } from "react";

import { useActiveAgent } from "../../../../shared/hooks/useAgents";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";
import { usePodLogsInfo } from "../../../../shared/hooks/podLogs/usePodLogsInfo";

import { useShouldFetchPodLogs } from "./useShouldFetchPodLogs";

import { isBailout } from "@/shared/hooks/podLogs/podLogsUtils";
import { PodLogLine } from "@/shared/hooks/podLogs/types";

type SelectedContainer = { value: string; label: string };

type PodContainer = {
  id: string;
  cluster: string;
  namespace: string;
  name: string;
  containerStatuses?: ContainerStatus[];
  fullObj?: Pod["fullObj"];
};

export type UsePodContainerLogsRes = {
  logs: PodLogLine[];
  retries?: number;
  failureMessage: string;
  loading: boolean;
  requestMoreLogs?: () => void;
  tail: number;
  fetching: boolean;
  isPreviousLogs: boolean;
  setIsPreviousLogs: (value: boolean) => void;
  isConnectionEstablished?: boolean;
};

export const usePodContainerLogs = (
  pod: PodContainer,
  selectedContainer: SelectedContainer | undefined
): UsePodContainerLogsRes => {
  const { id, cluster, namespace, name, containerStatuses = [], fullObj } = pod;
  const [isPreviousLogs, setIsPreviousLogs] = useState(false);

  const initContainerStatuses = fullObj?.status?.initContainerStatuses;

  const shouldExecuteFetchLogs = useShouldFetchPodLogs(
    containerStatuses,
    initContainerStatuses,
    selectedContainer?.value
  );

  const agentId = useActiveAgent(cluster);
  const {
    retries,
    failureMessage,
    requestMoreLogs,
    tail,
    logs,
    fetching,
    isConnectionEstablished,
  } = usePodLogsInfo({
    podName: name,
    agentId,
    namespace,
    clusterName: cluster,
    controlledBy: id,
    selectedContainer,
    isPreviousLogs,
    shouldExecuteFetchLogs,
  });

  return {
    logs,
    retries,
    failureMessage,
    loading: !isBailout(retries) && fetching,
    requestMoreLogs,
    tail,
    fetching,
    isPreviousLogs,
    setIsPreviousLogs,
    isConnectionEstablished,
  };
};
