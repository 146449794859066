import { useCallback } from "react";
import { GridPaginationModel } from "@mui/x-data-grid-pro";

import { useReliabilityStore } from "@/components/reliability/store/reliabilityStore";
import { selectSetGroupState } from "@/components/reliability/store/reliabilityStoreSelectors";
import { ViolationsGroupBy } from "@/components/reliability/ReliabilityTypes";

export const useSetPaginationModel = (
  uid: string | undefined,
  violationsGroupBy: ViolationsGroupBy
) => {
  const setGroupsState = useReliabilityStore(selectSetGroupState);

  return useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setGroupsState({
        uid: uid ?? "",
        groupBy: violationsGroupBy,
        newState: {
          paginationModel: newPaginationModel,
        },
      });
    },
    [setGroupsState, uid, violationsGroupBy]
  );
};
