import { useMemo } from "react";

import { PodLogLine } from "@/shared/hooks/podLogs/types";
import {
  highlightCodedWords,
  highlightTerm,
  HighlightTypes,
} from "@/components/common/HighlightedText";

type Props = {
  logs: PodLogLine[];
  searchTerm: string | undefined;
};

export const useHighlightedLines = ({ logs, searchTerm }: Props) => {
  return useMemo(() => {
    return logs.map(({ logTime, message, isEvent }) => {
      const highlightedTerm = highlightTerm(
        message ?? "",
        searchTerm ?? "",
        HighlightTypes.Search
      );
      const parts = highlightCodedWords(highlightedTerm);

      return {
        logTime,
        parts,
        matchesInLine: parts.filter((p) => {
          return p.type === HighlightTypes.Search;
        }).length,
        isEvent,
      };
    });
  }, [logs, searchTerm]);
};
