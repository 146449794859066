import { muiColors } from "@komodorio/design-system";
import { IconProps } from "@komodorio/design-system/icons";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import pluralize from "pluralize";
import React from "react";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import { LightTooltip } from "@komodorio/design-system/komodor-ui";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line mui-path-imports/mui-path-imports
import { SvgIconComponent } from "@mui/icons-material";

export type AddonCardProps = {
  label: string;
  count: number | undefined;
  icon: React.FC<IconProps> | SvgIconComponent;
  isLoading: boolean;
  path: string;
  ariaLabel: string;
};

export const AddonCard: React.FC<AddonCardProps> = ({
  label,
  count,
  icon,
  isLoading,
  path,
  ariaLabel,
}) => {
  const navigate = useNavigate();
  const Icon = icon;
  return (
    <LightTooltip title={`Go to ${label}`} placement="top">
      <Box
        aria-label={ariaLabel}
        border="1px solid"
        borderColor="divider"
        borderRadius="4px"
        padding="8px 10px"
        display="inline-flex"
        flex="0 1 222px"
        flexDirection="row"
        gap="8px"
        sx={{
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
        onClick={() => {
          navigate(path);
        }}
      >
        <Box gap="16px" display="flex" flexDirection="column" flexGrow={1}>
          <Typography variant="h6">{label}</Typography>
          {isLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "24px" }} />
          ) : (
            <Box display="flex" gap="8px" alignItems="center">
              {count === undefined ? null : count === 0 ? (
                <NoIssues />
              ) : (
                <IssuesCount count={count} />
              )}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            color: muiColors.gray[100],
            height: "62px",
            width: "62px",
            fontSize: "62px",
          }}
        >
          <Icon fontSize="inherit" width="inherit" height="inherit" />
        </Box>
      </Box>
    </LightTooltip>
  );
};

const NoIssues: React.FC = () => {
  return (
    <>
      <CheckCircleOutlined
        sx={{ fontSize: "20px", color: muiColors.green[700] }}
      />
      <Typography variant="h4" color="text.secondary">
        No issues
      </Typography>
    </>
  );
};

const IssuesCount: React.FC<{ count: number }> = ({ count }) => {
  return (
    <>
      <WarningAmberOutlined sx={{ color: "error.dark" }} />
      <Typography variant="h4" color="text.secondary">
        {count} {pluralize("Issue", count)}
      </Typography>
    </>
  );
};
