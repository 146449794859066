import {
  StyledOrderedList,
  TextContainer,
} from "../../../violationDrawerStyles";

export const getWhyDescription = () => (
  <TextContainer>
    ExternalDNS sync issues can disrupt access to services by leaving DNS
    records outdated, preventing proper routing. Timely sync ensures users and
    applications can reliably connect to services without interruptions.
  </TextContainer>
);

export const getHowDescription = () => (
  <TextContainer>
    <StyledOrderedList>
      <li>
        Verify DNS Record Propagation
        <StyledOrderedList type="a">
          <li>
            Check if the DNS records have been propagated correctly across DNS
            servers. Sometimes, there are delays in DNS record updates.
          </li>
          <li>
            Use tools like dig, nslookup, or online tools such as DNS Checker to
            see if the record is properly resolving across different regions.
          </li>
        </StyledOrderedList>
      </li>
      <li>
        Review ExternalDNS Logs
        <StyledOrderedList type="a">
          <li>
            Check the logs of ExternalDNS to identify if there were any errors
            during DNS record creation or updates.
          </li>
          <li>
            In the ExternalDNS logs, look for issues like:
            <StyledOrderedList type="i">
              <li>
                Failed API calls to your DNS provider (e.g., AWS Route53,
                Cloudflare).
              </li>
              <li>Rate limiting or authentication errors.</li>
            </StyledOrderedList>
          </li>
        </StyledOrderedList>
      </li>
    </StyledOrderedList>
  </TextContainer>
);
