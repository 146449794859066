import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useCallback, useEffect, useRef } from "react";
import { isAfter } from "date-fns";
import { AxiosInstance } from "axios";

import {
  apiV1KomodorServicesSearchGetUrl,
  KomodorServicesApiApiV1KomodorServicesSearchGetRequest,
  KomodorServicesResponse,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";
import {
  convertResourcesApiKomodorServiceResponseToServiceInfo,
  ServicesWithLastUpdatedAt,
} from "../../../../../components/servicesView/ProcessServiceFetchRequests";
import { useAppViewsStore } from "../../../../store/appViewsStore/appViewsStore";
import { FetchServicesOutput } from "../../../../../components/servicesView/serviceFetchHooks/types";
import { useWorkspaces } from "../../../../../components/workspaces/WorkspacesTopBar/hooks";
import { useWorkspaceQueryKey } from "../../../workspaces-api/useWorkspaceQueryKey";
import { KOMODOR_SERVICES_SEARCH } from "../../requestResponseMaps";

export const fetchKomodorServices = async (
  apiClient: AxiosInstance,
  params: KomodorServicesApiApiV1KomodorServicesSearchGetRequest
): Promise<KomodorServicesResponse> => {
  const { data } = await apiClient.get(
    apiV1KomodorServicesSearchGetUrl(params, apiClient.defaults.baseURL ?? "")
  );
  return data;
};

export type GetKomodorServicesParams = Omit<
  KomodorServicesApiApiV1KomodorServicesSearchGetRequest,
  "updatedAtFromEpoch"
>;
type QueryOptions = {
  isFullFetch?: boolean;
  refetchInterval?: number;
  enabled?: boolean;
};
export const useGetKomodorServices = (
  params: GetKomodorServicesParams,
  { isFullFetch = true, refetchInterval, enabled }: QueryOptions
):
  | UseQueryResult<{
      servicesWithLastUpdatedAt: ServicesWithLastUpdatedAt | undefined;
      isInitialLoad: boolean;
    }> & {
      fetchMode: FetchServicesOutput["fetchMode"];
    } => {
  const { isLoading, isWorkspaceKindBackendFiltered } = useWorkspaces();
  const skipAppViewInHeaders = !isLoading && !isWorkspaceKindBackendFiltered;
  const apiClient = useResourcesApiClient(skipAppViewInHeaders);

  const transformData = useCallback(
    (komodorServicesData: KomodorServicesResponse) => {
      return convertResourcesApiKomodorServiceResponseToServiceInfo(
        komodorServicesData
      );
    },
    []
  );

  const lastUpdatedAtRef = useRef<string | undefined>(undefined);
  const keys = useWorkspaceQueryKey(
    [KOMODOR_SERVICES_SEARCH, params.kind, params, isFullFetch],
    { skip: skipAppViewInHeaders }
  );

  const { remove: removeQuery, ...result } = useQuery(
    keys,
    async () => {
      const isInitialLoad =
        lastUpdatedAtRef.current === undefined || isFullFetch;
      const response = await fetchKomodorServices(apiClient, {
        ...params,
        includeDeletedAndInactive:
          !isInitialLoad || params.includeDeletedAndInactive,
        updatedAtFromEpoch:
          lastUpdatedAtRef.current === undefined || isFullFetch
            ? undefined
            : new Date(lastUpdatedAtRef.current).getTime().toString(),
      });
      const data = transformData(response);

      const newUpdatedAt = data?.lastUpdatedAt;
      if (
        newUpdatedAt &&
        (!lastUpdatedAtRef.current ||
          isAfter(new Date(newUpdatedAt), new Date(lastUpdatedAtRef.current)))
      ) {
        lastUpdatedAtRef.current = newUpdatedAt;
      }

      return {
        servicesWithLastUpdatedAt: data,
        isInitialLoad,
      };
    },
    {
      enabled: enabled && !isLoading,
      refetchInterval: enabled ? refetchInterval : undefined,
    }
  );

  useEffect(() => {
    const unsub = useAppViewsStore.subscribe(function onAppViewChanged(
      newState,
      oldState
    ) {
      if (newState.selectedAppViewId !== oldState.selectedAppViewId) {
        lastUpdatedAtRef.current = undefined;
        removeQuery();
      }
    });
    return () => unsub();
  }, [removeQuery]);
  return {
    ...result,
    remove: removeQuery,
    fetchMode: !result.data || result.data.isInitialLoad ? "full" : "diff",
  };
};
