import React, { useMemo } from "react";

import Resource from "../../resources";

import { WorkloadMetricsTabContextProvider } from "./context/WorkloadMetricsTabContext";
import { WorkloadMetricsTabContent } from "./components/WorkloadMetricsTabContent";

import { KomodorUidParams } from "@/shared/hooks/resources-api/resourcesAPIUtils";

interface WorkloadMetricsTabProps {
  resource: Resource;
}

export const WorkloadMetricsTab: React.FC<WorkloadMetricsTabProps> = ({
  resource,
}) => {
  const komodorUidParams: KomodorUidParams = useMemo(() => {
    return {
      name: resource.name,
      namespace: resource.namespace,
      cluster: resource.cluster,
      kind: resource.kind,
    };
  }, [resource.cluster, resource.kind, resource.name, resource.namespace]);
  return (
    <WorkloadMetricsTabContextProvider>
      <WorkloadMetricsTabContent komodorUidParams={komodorUidParams} />
    </WorkloadMetricsTabContextProvider>
  );
};
