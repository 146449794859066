/* eslint-disable max-lines */
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { LightningBolt16 } from "@komodorio/design-system/icons";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { muiTheme } from "@komodorio/design-system";

import {
  EVENTS_TAB_PREFIX,
  EVENT_PARAM_KEY,
  INVESTIGATED,
  RESOURCE_PREFIX,
} from "../../../../../../../shared/config/urlSearchParamsKeys";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  KlaudiaSessionCheckType,
  WorkflowRunWithResults,
} from "../../../../../../monitorsView/useWorkflowsRuns";
import WorkflowCheck from "../../../../../../monitorsView/workflowRunView/checks";
import WorkflowIssueEventGroup from "../../../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { AvailabilityCheckType } from "../../../../../../monitorsView/workflowRunView/checks/useWorkflowsChecks";
import { WorkloadHealthyPodsOutput } from "../../../../../../monitorsView/workflowRunView/checks/availability/types";
import EventDrawer from "../../../../EventDrawer";
import { AriaLabels } from "../../../../../../../shared/config/ariaLabels";
import { useHasPermissions } from "../../../../../../../shared/hooks/useUserMetadata/rbac";
import { ResourceDrawer } from "../../../../../../ResourceView/ResourceDrawer";
import MonitorRuleDrawer from "../../../../../../monitorsView/monitorsConfiguration/MonitorRuleDrawer";
import { useWorkflowConfigurationById } from "../../../../../../monitorsView/useWorkflowConfigurations";
import EventGroup from "../../../../../EventGroup";
import { useResourceView } from "../../../../../../ResourceView/ResourceProvider";
import { Container } from "../common/styles";
import { SnapshotInfoDetail } from "../../../../../Alert/SnapshotInfo";
import Divider from "../../../../../../monitorsView/common/Divider";
import { sleep } from "../../../../../../../shared/utils/sleep";
import { AvailabilityDrawerData } from "../../AvailabilityDrawerData";
import { INVESTIGATION } from "../../../../../../routes/routes";
import {
  AvailabilityResource,
  useInvestigationModeStore,
} from "../../../../../../../shared/store/investigationModeStore/investigationModeStore";
import { setLastUrlSelector } from "../../../../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { useDateFormatter } from "../../../../../../../shared/hooks/useDateFormatter";
import { AvailabilityInvestigationCheckType } from "../../../../../../InvestigationMode/availability/types";
import { IntroductionOutput } from "../../../../../../InvestigationMode/availability/Introduction/types";
import {
  AnalyticEvents,
  dispatchEvent,
} from "../../../../../../../shared/hooks/analytics";
import { HeaderSection } from "../common/sections/HeaderSection/HeaderSection";
import { WhereSection } from "../common/sections/WhereSection";
import { transformSecondsToText } from "../../../../../../InvestigationMode/availability/Introduction/utils";
import { useDDRumStartViewAndAddTiming } from "../../../../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { DiagnosticsFeedback } from "../../../../../DiagnosticsFeedback/DiagnosticsFeedback";
import { useOverridableFlags } from "../../../../../../../shared/context/featureFlags/OverridableFlags";
import { useActiveAgent } from "../../../../../../../shared/hooks/useAgents";
import Resource from "../../../../../../ResourceView/resources";
import { getPreferredSnapshot } from "../../../../../../InvestigationMode/availability/common/utils/getPreferredSnapshot";
import { dataDogViewNames } from "../../../../../../../shared/constants/datadog";

import { ImpactSection } from "./sections/ImpactSection";
import { InsightsSection } from "./sections/InsightsSection";
import { MessageSection } from "./sections/MessageSection";
import { KlaudiaSessionSection } from "./sections/KlaudiaSessionSection";
import whatHappenedSection from "./demo/assets/whatHappenedSection.png";
import relatedEvidenceSection from "./demo/assets/relatedEvidenceSection.png";
import suggestedRemediationSection from "./demo/assets/suggestedRemediationSection.png";

import { EVENTS_PULLER_SERVICE_DEMO } from "@/components/common/ProcessList/details/WorkflowIssueEventDetails/triage/AvailabilityTriage/demo/constants";
import { useShowAiInvestigationSection } from "@/components/common/ProcessList/details/WorkflowIssueEventDetails/triage/AvailabilityTriage/sections/useShowAiInvestigationSection";
import { AiInvestigationOutput } from "@/components/InvestigationMode/availability/AiInvestigation/types";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { useStringifiedStateInSearchParams } from "@/shared/hooks/state/useStringifiedStateInSearchParams";
import { closeAllDrawersSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 1.5rem 1rem;
  margin: 1rem 0;
`;

export const TRIAGE_RELEASE_DATE_UTC = "2023-03-07T13:30:18.498Z";
const INVESTIGATION_RELEASE_DATE_UTC = "2023-06-15T12:00:00.498Z";
const INVESTIGATION_DISABLED_TOOLTIP =
  "We didn't detect a reason for this availability issue.";

const Spacer = styled.div`
  flex-grow: 1;
`;

type Props = {
  allChecks: WorkflowCheck[];
  wfRun: WorkflowRunWithResults;
  eventGroup: WorkflowIssueEventGroup;
  resource: AvailabilityResource | undefined;
  onClose?: () => void;
  isFetchingResource: boolean;
  violationId?: string;
};

export const AvailabilityTriage: React.FC<Props> = ({
  allChecks,
  wfRun,
  eventGroup,
  resource,
  onClose,
  isFetchingResource,
  violationId,
}) => {
  const { demoOomKilled, demoKlaudiaAi } = useOverridableFlags();
  const agentId = useActiveAgent(eventGroup?.events[0]?.clusterName ?? "");
  const [drawerResource, setDrawerResource] = useState<Resource>();

  const [correlatedEvent, setCorrelatedEvent] = useState<EventGroup | null>(
    null
  );
  const [selectedRuleId, setSelectedRuleId] = useState<string>();

  const resourceFromResourceView = useResourceView();
  const [, setEventId] = useStateInSearchParams(
    (resourceFromResourceView.id ? RESOURCE_PREFIX + EVENTS_TAB_PREFIX : "") +
      EVENT_PARAM_KEY
  );
  const setLastUrlInStore = useInvestigationModeStore(setLastUrlSelector);
  const closeAllDrawers = useDrawersStackStore(closeAllDrawersSelector);
  const navigate = useNavigate();
  const [investigated, setInvestigated] =
    useStringifiedStateInSearchParams<boolean>(INVESTIGATED);
  const { clusterName, namespace } = eventGroup.events[0];
  const closed = !!wfRun?.closedAt;
  const issueEndTime = useMemo(
    () => (eventGroup?.isCompleted ? eventGroup.endTime : new Date()),
    [eventGroup.endTime, eventGroup?.isCompleted]
  );
  const podsHealthCheckOutput = useMemo(() => {
    const currentPodsHealthyCheck = allChecks.find(
      (c) => c.type === AvailabilityCheckType.WorkloadPodsHealthy
    );
    return currentPodsHealthyCheck?.output
      ? (currentPodsHealthyCheck?.output as WorkloadHealthyPodsOutput)
      : undefined;
  }, [allChecks]);

  const hasInvestigationInfo = useMemo(() => {
    const introInfo = wfRun.results.find(
      (c) => c.type === AvailabilityInvestigationCheckType.Introduction
    );
    const output = introInfo?.output
      ? (introInfo?.output as IntroductionOutput)
      : undefined;
    return output?.firstSnapshot != null || output?.lastSnapshot != null;
  }, [wfRun]);

  const { workflowConfiguration: workflowRuleUpToDate, refreshFromNetwork } =
    useWorkflowConfigurationById(wfRun?.workflowConfiguration?.id);
  const pastIssuesIncludingCurrent = useMemo(
    () => [
      ...wfRun.triageData.pastWeekIssues,
      {
        id: wfRun.id,
        startTime: wfRun.eventTime,
        endTime: issueEndTime,
        reasons: wfRun.reasons,
        isClosed: closed,
      },
    ],
    [
      closed,
      issueEndTime,
      wfRun.eventTime,
      wfRun.id,
      wfRun.reasons,
      wfRun.triageData.pastWeekIssues,
    ]
  );

  const { canManageMonitors } = useHasPermissions();
  const onEditMonitorClick = useCallback(() => {
    if (!canManageMonitors) {
      return;
    }
    setSelectedRuleId(wfRun?.workflowConfiguration?.id);
  }, [canManageMonitors, wfRun?.workflowConfiguration?.id]);

  const [startInvestigation, setStartInvestigation] = useState(false);

  const startInvestigating = () => {
    dispatchEvent(
      AnalyticEvents.WorkflowsView.Availability.Investigation
        .Investigate_Clicked,
      {
        reasons: wfRun.reasons.sort(),
      }
    );
    setInvestigated(true, true);
    setLastUrlInStore(`${window.location.pathname}${window.location.search}`);
    closeAllDrawers(true);
    navigate(`/${INVESTIGATION}/${wfRun.id}`);
  };

  const investigateRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    (async () => {
      if (startInvestigation) {
        await sleep(50);
        investigateRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    })();
  }, [startInvestigation]);
  const { format } = useDateFormatter();

  const isInvestigationURL = useMemo(() => {
    const currentURL = window.location.href;
    return currentURL.includes(`/${INVESTIGATION}/`);
  }, []);

  const shouldShowNewInvestigation = useMemo(
    () => wfRun.eventTime > new Date(INVESTIGATION_RELEASE_DATE_UTC),
    [wfRun.eventTime]
  );
  const { duration, minAvailable } = eventGroup.workflowConfig?.variables ?? {};

  const subtitle = useMemo(() => {
    if (!duration) {
      return undefined;
    }

    return `${
      closed
        ? `From ${format(wfRun.eventTime)} to ${format(
            wfRun.closedAt || undefined
          )}`
        : `Starting on ${format(wfRun.eventTime)}`
    } available pods were less than ${minAvailable} for more than ${transformSecondsToText(
      duration
    )}`;
  }, [closed, duration, format, minAvailable, wfRun.closedAt, wfRun.eventTime]);

  const onResourceClick = useMemo(
    () =>
      resourceFromResourceView.id !== resource?.id
        ? () => setDrawerResource(resource)
        : undefined,
    [resource, resourceFromResourceView.id]
  );

  const { errorMessages, reasons } = useMemo(() => {
    if (!hasInvestigationInfo) {
      return { errorMessages: [], reasons: wfRun.reasons };
    }
    return getMessagesAndReasonsFromInvestigationInfo(wfRun, !!demoOomKilled);
  }, [hasInvestigationInfo, demoOomKilled, wfRun]);

  useDDRumStartViewAndAddTiming({
    viewName: dataDogViewNames.getEventDrawerByType("availability_issue"),
    addTimingParams: {
      enable: !isFetchingResource,
    },
  });

  const shouldShowDemoSectionsMock =
    !!demoKlaudiaAi && wfRun.shortResourceName === EVENTS_PULLER_SERVICE_DEMO;

  const aiInvestigationRequestId = useMemo(() => {
    const aiInvestigationInfo = wfRun?.results.find(
      (c) => c.type === KlaudiaSessionCheckType
    );
    return aiInvestigationInfo?.output
      ? (aiInvestigationInfo?.output as AiInvestigationOutput)
      : undefined;
  }, [wfRun]);
  const aiInvestigationSection = useShowAiInvestigationSection(
    wfRun.id,
    resource,
    aiInvestigationRequestId,
    wfRun.closedAt,
    errorMessages
  );

  return (
    <>
      <Container>
        <HeaderSection
          wfRun={wfRun}
          reasons={reasons}
          canManageMonitors={canManageMonitors}
          subtitle={subtitle}
          onEditMonitorClick={onEditMonitorClick}
          onClose={
            onClose ??
            (() => {
              setEventId(null);
              setInvestigated(null);
            })
          }
          violationId={violationId}
        />
        {resource?.isDeleted && resource.deletedAt && (
          <SnapshotInfoDetail variant="deleted">
            This issue relates to a service which has been deleted on{" "}
            <b>{format(new Date(resource.deletedAt))}</b>
          </SnapshotInfoDetail>
        )}
        {closed && wfRun?.eventTime && (
          <SnapshotInfoDetail>
            All information presented here is a snapshot from{" "}
            <b>{format(new Date(wfRun?.eventTime))}</b> when this issue
            occurrence was triggered
          </SnapshotInfoDetail>
        )}
        <Divider />
        {errorMessages.length > 0 ? (
          <>
            <MessageSection
              errorMessages={errorMessages}
              reasons={reasons}
              agentId={agentId}
              cluster={eventGroup?.events[0]?.clusterName ?? ""}
              namespace={eventGroup?.events[0]?.namespace ?? ""}
              onClick={(rs) => setDrawerResource(rs)}
              checks={wfRun.results}
            />
            <Divider />
          </>
        ) : null}
        <WhereSection
          resourceName={wfRun.shortResourceName}
          resourceKind={resource?.kind ?? ""}
          clusterName={clusterName ?? ""}
          namespace={namespace ?? ""}
          onResourceClick={onResourceClick}
          resourceAriaLabel={AriaLabels.AvailabilityTriage.ResourceLink}
          deletedAt={resource?.isDeleted ? resource?.deletedAt : undefined}
        />
        <Divider />
        <ImpactSection
          wfRun={wfRun}
          resource={resource}
          podsHealthCheckOutput={podsHealthCheckOutput}
          pastIssuesIncludingCurrent={pastIssuesIncludingCurrent}
          isFetchingResource={isFetchingResource}
        />
        <Divider />
        {aiInvestigationSection.showSection && resource ? (
          <>
            <KlaudiaSessionSection
              wfRun={wfRun}
              eventGroup={eventGroup}
              resource={resource}
              sessionId={aiInvestigationSection.completedSessionId}
            />
            <Divider />
          </>
        ) : null}
        {shouldShowDemoSectionsMock && (
          <>
            <img
              src={whatHappenedSection}
              alt={"what happened section"}
              width={860}
            />
            <Divider />
            <img
              src={relatedEvidenceSection}
              alt={"related evidence section"}
              width={860}
            />
            <Divider />
            <img
              src={suggestedRemediationSection}
              alt={"suggested remediation section"}
              width={860}
            />
            <Divider />
          </>
        )}
        <InsightsSection
          wfRun={wfRun}
          eventGroup={eventGroup}
          setCorrelatedEvent={setCorrelatedEvent}
        />
        {investigated && (
          <FeedbackContainer>
            <DiagnosticsFeedback
              likeLabel={AriaLabels.AvailabilityTriage.LikeInvestigation}
              dislikeLabel={AriaLabels.AvailabilityTriage.DislikeInvestigation}
              message={"Did you find this investigation useful?"}
            />
          </FeedbackContainer>
        )}
        <Spacer />
        {!isInvestigationURL && (
          <Footer>
            {shouldShowNewInvestigation ? (
              <Tooltip
                title={
                  hasInvestigationInfo ? null : INVESTIGATION_DISABLED_TOOLTIP
                }
              >
                <span>
                  <Button
                    variant="contained"
                    disabled={!hasInvestigationInfo}
                    size="large"
                    style={{ width: "12rem" }}
                    onClick={startInvestigating}
                    aria-label={AriaLabels.AvailabilityTriage.InvestigateButton}
                  >
                    Investigate
                    <LightningBolt16 />
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                variant="contained"
                size="large"
                style={{ width: "12rem" }}
                onClick={() => {
                  setStartInvestigation(true);
                  investigateRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
                aria-label={AriaLabels.AvailabilityTriage.InvestigateButton}
              >
                Investigate <LightningBolt16 />
              </Button>
            )}
          </Footer>
        )}
      </Container>
      <div ref={investigateRef}>
        {startInvestigation && (
          <AvailabilityDrawerData
            allChecks={allChecks}
            wfRun={wfRun}
            serviceId={eventGroup.serviceId}
          />
        )}
      </div>
      <EventDrawer
        open={!!correlatedEvent}
        onClose={() => setCorrelatedEvent(null)}
        event={correlatedEvent}
      >
        {correlatedEvent?.renderEventDetails?.(() =>
          setCorrelatedEvent(null)
        ) ?? null}
      </EventDrawer>
      <ResourceDrawer
        open={!!drawerResource}
        onClose={() => setDrawerResource(undefined)}
        resource={drawerResource}
      />
      {workflowRuleUpToDate && (
        <MonitorRuleDrawer
          isOpen={!!selectedRuleId}
          handleClose={() => {
            setSelectedRuleId(undefined);
          }}
          isTypeStatic={true}
          refreshMonitors={refreshFromNetwork}
          initRule={workflowRuleUpToDate}
        />
      )}
    </>
  );
};

const Footer = styled.div`
  background: ${muiTheme.palette.common.white};
  border-top: 1px solid ${muiTheme.palette.divider};
  padding: 10px 24px;
  position: sticky;
  bottom: 0;
`;

function getMessagesAndReasonsFromInvestigationInfo(
  wfRun: WorkflowRunWithResults,
  demoOomKilled?: boolean
) {
  const { firstSnapshot, lastSnapshot, preferFirstSnapshot } =
    getPreferredSnapshot(wfRun.results, demoOomKilled);

  return preferFirstSnapshot
    ? {
        errorMessages: firstSnapshot?.errorMessages ?? [],
        reasons: firstSnapshot?.reasons ?? wfRun.reasons,
      }
    : {
        errorMessages:
          lastSnapshot?.errorMessages && lastSnapshot.errorMessages.length > 0
            ? lastSnapshot?.errorMessages
            : firstSnapshot?.errorMessages ?? [],
        reasons: lastSnapshot?.reasons ?? wfRun.reasons,
      };
}
