/* eslint-disable max-lines */
import React from "react";
import {
  AccessControl16,
  List16,
  Network16,
  Storage16,
  Workloads16,
} from "@komodorio/design-system/icons";

import { DriftAnalysis } from "./links/DriftAnalysis";
import { WorkloadHealthDemoLink } from "./links/WorkloadHealthDemo";
import { InfraHealthDemoLink } from "./links/InfraHealthDemo";

import { PageName } from "@/components/AppBar/links/pages";
import { ClustersLink } from "@/components/AppBar/links/Clusters";
import { OverviewLink } from "@/components/AppBar/links/Overview";
import { ExpertOverview } from "@/components/AppBar/links/ExpertOverview";
import {
  ClusterOverviewClustersDemoV3Link,
  ClustersDemoV3Link,
} from "@/components/AppBar/links/ClustersDemoV3";
import { ServicesLink } from "@/components/AppBar/links/Services";
import { CostOptimization } from "@/components/AppBar/links/CostOptimization";
import { DriftAnalysisDemo } from "@/components/AppBar/links/DriftAnalysisDemo";
import { JobsLink } from "@/components/AppBar/links/Jobs";
import { EventsLink } from "@/components/AppBar/links/Events";
import { PodsLink } from "@/components/AppBar/links/Pods";
import { ReplicaSetsLink } from "@/components/AppBar/links/ReplicaSets";
import { DeploymentsLink } from "@/components/AppBar/links/Deployments";
import { JobsInspectionLink } from "@/components/AppBar/links/JobsInspection";
import { CronJobsLink } from "@/components/AppBar/links/CronJobs";
import { StatefulSetsLink } from "@/components/AppBar/links/StatefulSets";
import { DaemonSetsLink } from "@/components/AppBar/links/DaemonSets";
import { PVCsLink } from "@/components/AppBar/links/PVCs";
import { PVsLink } from "@/components/AppBar/links/PVs";
import { StorageClassesLink } from "@/components/AppBar/links/StorageClasses";
import { ConfigMapsLink } from "@/components/AppBar/links/ConfigMaps";
import { SecretsLink } from "@/components/AppBar/links/Secrets";
import { ResourceQuotasLink } from "@/components/AppBar/links/ResourceQuotas";
import { LimitRangesLink } from "@/components/AppBar/links/LimitRanges";
import { HPAsLink } from "@/components/AppBar/links/HPAs";
import { PDBsLink } from "@/components/AppBar/links/PDBs";
import { K8sServicesLink } from "@/components/AppBar/links/K8sServices";
import { EndpointsLink } from "@/components/AppBar/links/Endpoints";
import { IngressesLink } from "@/components/AppBar/links/Ingresses";
import { NetworkPoliciesLink } from "@/components/AppBar/links/NetworkPolicies";
import { EndpointSlicesLink } from "@/components/AppBar/links/EndpointSlices";
import { ServiceAccountsLink } from "@/components/AppBar/links/ServiceAccounts";
import { ClusterRolesLink } from "@/components/AppBar/links/ClusterRoles";
import { InspectionRolesLink } from "@/components/AppBar/links/InspectionRoles";
import { ClusterRoleBindingsLink } from "@/components/AppBar/links/ClusterRoleBindingsLink";
import { RoleBindingsLink } from "@/components/AppBar/links/RoleBindingsLink";
import { WorkloadHealth } from "@/components/AppBar/links/WorkloadHealth";
import { InfrastructureHealth } from "@/components/AppBar/links/InfrastructureHealth";
import { UsersDemoLink } from "@/components/AppBar/links/UsersDemo";

export enum AppBarTitle {
  Resources = "Resources",
  Workloads = "Workloads",
  Storage = "Storage",
  Configuration = "Configuration",
  Network = "Network",
  AccessControl = "Access Control",
  K8sAddons = "Kubernetes Add-ons",
}

export const TOP_SECTION_MENU_ITEMS = [
  {
    name: PageName.Clusters,
    Component: ClustersLink,
    rejectedFeatures: ["showClustersPageDemoV3"],
  },
  {
    name: PageName.Overview,
    Component: OverviewLink,
    rejectedFeatures: ["showClustersPageDemoV3"],
  },
  {
    name: PageName.Topology,
    Component: ExpertOverview,
  },
  {
    name: "Clusters",
    Component: ClustersDemoV3Link,
    features: ["showClustersPageDemoV3"],
  },
  {
    name: "Overview",
    Component: ClusterOverviewClustersDemoV3Link,
    features: ["showClustersPageDemoV3"],
  },
  {
    name: PageName.WorkloadHealth,
    Component: WorkloadHealthDemoLink,
    features: ["showClustersPageDemoV3"],
  },
  {
    name: PageName.InfrastructureHealth,
    Component: InfraHealthDemoLink,
    features: ["showClustersPageDemoV3"],
  },
  {
    name: PageName.WorkloadHealth,
    Component: WorkloadHealth,
    rejectedFeatures: ["showClustersPageDemoV3"],
  },
  {
    name: PageName.InfrastructureHealth,
    Component: InfrastructureHealth,
    rejectedFeatures: ["showClustersPageDemoV3"],
  },
  {
    name: PageName.DriftAnalysisDemo,
    Component: DriftAnalysisDemo,
    features: ["showClustersPageDemoV3"],
  },
  {
    name: PageName.CostOptimization,
    Component: CostOptimization,
  },
  {
    name: PageName.DemoUsers,
    Component: UsersDemoLink,
    features: ["usersTabNavBarForDemo"],
  },
];

export const MIDDLE_SECTION_MENU_ITEMS = [
  {
    name: PageName.Services,
    Component: ServicesLink,
  },
  {
    name: PageName.DriftAnalysis,
    Component: DriftAnalysis,
    rejectedFeatures: ["showClustersPageDemoV3"],
  },
  {
    name: PageName.Jobs,
    Component: JobsLink,
  },
  {
    name: PageName.Events,
    Component: EventsLink,
  },
];

export const NATIVE_RESOURCES_MENU_ITEMS = [
  {
    header: AppBarTitle.Workloads,
    icon: <Workloads16 />,
    selector: "workloadsExpandableSectionTitle",
    items: [
      {
        name: "Pods",
        Component: PodsLink,
      },
      {
        name: "ReplicaSets",
        Component: ReplicaSetsLink,
      },
      {
        name: "Deployments",
        Component: DeploymentsLink,
      },
      {
        name: "Jobs",
        Component: JobsInspectionLink,
      },
      {
        name: "CronJobs",
        Component: CronJobsLink,
      },
      {
        name: "StatefulSets",
        Component: StatefulSetsLink,
      },
      {
        name: "DaemonSets",
        Component: DaemonSetsLink,
      },
    ],
  },
  {
    header: AppBarTitle.Storage,
    icon: <Storage16 />,
    selector: "storageExpandableSectionTitle",
    items: [
      {
        name: "PersistentVolumeClaims",
        Component: PVCsLink,
      },
      {
        name: "PersistentVolumes",
        Component: PVsLink,
      },
      {
        name: "StorageClasses",
        Component: StorageClassesLink,
      },
    ],
  },
  {
    header: AppBarTitle.Configuration,
    icon: <List16 />,
    selector: "configurationExpandableSectionTitle",
    items: [
      {
        name: "ConfigMaps",
        Component: ConfigMapsLink,
      },
      {
        name: "Secrets",
        Component: SecretsLink,
      },
      {
        name: "Resource Quotas",
        Component: ResourceQuotasLink,
      },
      {
        name: "Limit Ranges",
        Component: LimitRangesLink,
      },
      {
        name: "Horizontal Pod Autoscalers",
        Component: HPAsLink,
      },
      {
        name: "Pod Disruption Budgets",
        Component: PDBsLink,
      },
    ],
  },
  {
    header: AppBarTitle.Network,
    icon: <Network16 />,
    selector: "networkExpandableSectionTitle",
    items: [
      {
        name: "K8s Services",
        Component: K8sServicesLink,
      },
      {
        name: "Endpoints",
        Component: EndpointsLink,
      },
      {
        name: "Ingresses",
        Component: IngressesLink,
      },
      {
        name: "Network Policies",
        Component: NetworkPoliciesLink,
      },
      {
        name: "Endpoint Slices",
        Component: EndpointSlicesLink,
      },
    ],
  },
  {
    header: AppBarTitle.AccessControl,
    icon: <AccessControl16 />,
    selector: "accessControlExpandableSectionTitle",
    features: [
      "showServiceAccountInspectionPage",
      "showRolesInspectionPage",
      "showClusterRoleBindingInspectionPage",
      "showRoleBindingInspectionPage",
      "showClusterRoleInspectionPage",
    ],
    items: [
      {
        name: "Service Accounts",
        Component: ServiceAccountsLink,
      },
      {
        name: "Cluster Roles",
        Component: ClusterRolesLink,
      },
      {
        name: "Roles",
        Component: InspectionRolesLink,
      },
      {
        name: "Cluster Role Bindings",
        Component: ClusterRoleBindingsLink,
      },
      {
        name: "Role Bindings",
        Component: RoleBindingsLink,
      },
    ],
  },
];
