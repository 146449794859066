import { DurationOption } from "./logsTypes";

export const unitOptions: Record<
  DurationOption,
  { label: string; value: DurationOption }
> = {
  s: { label: "seconds", value: "s" },
  m: { label: "minutes", value: "m" },
  h: { label: "hours", value: "h" },
};
