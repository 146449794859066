import { useRef } from "react";
import Typography from "@mui/material/Typography";
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import { LightTooltip } from "components/komodor-ui/LightTooltip/LightTooltip";

export function DefaultCell<R, V, F>({
  value,
}: GridRenderCellParams<R, V, F, GridTreeNodeWithRender>): React.ReactNode {
  const cellRef = useRef(null);
  const isTruncated =
    cellRef.current?.scrollWidth > cellRef.current?.clientWidth;
  const parsedValue = value.toString();
  return (
    <LightTooltip title={isTruncated ? parsedValue : ""} placement="top">
      <Typography ref={cellRef} variant="body2" noWrap>
        {parsedValue}
      </Typography>
    </LightTooltip>
  );
}
