import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Typography,
} from "@komodorio/design-system/deprecated";
import { palette } from "@komodorio/design-system";
import {
  ActionMetadataRestartService,
  ActionTypes,
  TaskType,
} from "komodor-types";
import pluralize from "pluralize";

import { ModalGrid } from "../../common/styles";
import { ErrorMessage } from "../../common/ErrorMessage";
import useAgentTask from "../../../../shared/hooks/useAgentTask/useAgentTask";
import {
  ActionSentMessage,
  useShouldShowActionSentMessage,
} from "../../common/useActionSent";

import {
  useExecuteAgentTaskAndReport,
  useHandleAgentTaskResponse,
} from "@/components/Actions/modals/actionModalHooks";

interface RestartModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  resourceId: string;
  resourceName: string;
  resourceType: string;
  namespace: string;
  cluster: string;
  agentId: string;
  controlledBy?: string;
  afterEffect?: () => void;
}

const RestartModal: React.FC<RestartModalProps> = ({
  isModalOpen,
  handleClose,
  resourceId,
  resourceName,
  resourceType,
  namespace,
  cluster,
  agentId,
  controlledBy,
  afterEffect,
}) => {
  const [actionSent, setActionSent] = useState(false);
  const [showMessageActionSent, setShowMessageActionSent] = useState(false);

  const metadata: ActionMetadataRestartService = {
    cluster: cluster,
    namespace: namespace,
    serviceId: resourceId,
    type: ActionTypes.RestartService,
    resourceType: pluralize.singular(resourceType),
    resourceName: resourceName,
    controlledBy: controlledBy ?? "",
  };

  const serviceDetails = [
    {
      title: "Service name:",
      data: resourceName,
    },
    {
      title: "Service type:",
      data: resourceType,
    },
    {
      title: "Namespace:",
      data: namespace,
    },
    {
      title: "Cluster:",
      data: cluster,
    },
  ];

  const { execute, result, deniedObject, isFetching } = useAgentTask(
    agentId,
    TaskType.ACTION_COMMAND,
    metadata
  );

  const shouldShowActionSentMessage =
    useShouldShowActionSentMessage(isFetching);

  useEffect(() => {
    setShowMessageActionSent(shouldShowActionSentMessage);
  }, [shouldShowActionSentMessage]);

  const executeAndReport = useExecuteAgentTaskAndReport({
    execute,
    resourceType,
    reportType: "restartService",
  });

  const { showErrorMessage, setShowErrorMessage } = useHandleAgentTaskResponse({
    result,
    deniedObject,
    handleClose,
    setActionSent,
    afterEffect,
  });

  if (showMessageActionSent) {
    return (
      <ActionSentMessage
        isOpen={showMessageActionSent}
        onClose={() => {
          setShowMessageActionSent(false);
          handleClose();
        }}
      />
    );
  }

  return (
    <>
      <Modal isOpen={isModalOpen} width="28rem" onRequestClose={handleClose}>
        <ModalHeader>
          <Typography variant="headline">
            Restart Service: {resourceName}
          </Typography>
        </ModalHeader>
        <ModalContent>
          <Typography size="medium">
            This action will <b>restart</b> the following service:
            <br />
            <br />
            <ModalGrid>
              {serviceDetails.map((e, i) => (
                <React.Fragment key={i}>
                  <Typography size="medium" color={palette.gray[600]}>
                    {e.title}
                  </Typography>
                  <Typography size="medium" color={palette.gray[800]}>
                    {e.data}
                  </Typography>
                </React.Fragment>
              ))}
            </ModalGrid>
          </Typography>
          {showErrorMessage && deniedObject !== undefined && (
            <ErrorMessage reason={deniedObject} />
          )}
        </ModalContent>
        <ModalActions>
          <Button variant="secondary" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            size="small"
            disabled={actionSent}
            onClick={() => {
              executeAndReport();
              setShowErrorMessage(false);
              setActionSent(true);
            }}
          >
            {actionSent && !deniedObject ? "Restarting..." : "Restart Service"}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default RestartModal;
