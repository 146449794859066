import React from "react";

import { OldEmptyState } from "./OldEmptyState";
import { ReliabilityHealthManagementEmptyState } from "./ReliabilityHealthManagementEmptyState";

import { ImpactGroupType } from "@/generated/reliabilityApi";
import { useIsBestPracticesTab } from "@/components/reliability/hooks/useSelectedTab";

export const NoViolationsEmptyState: React.FC<{
  impactGroupType: ImpactGroupType | undefined;
}> = ({ impactGroupType }) => {
  const isBestPracticesTab = useIsBestPracticesTab();

  const isReliabilityHealthManagementView = !isBestPracticesTab;

  const isRealtimeGroup = impactGroupType === ImpactGroupType.Realtime;

  return isReliabilityHealthManagementView ? (
    <ReliabilityHealthManagementEmptyState isRealtimeGroup={isRealtimeGroup} />
  ) : (
    <OldEmptyState />
  );
};
