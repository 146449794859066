import type Deployment from "@/components/ResourceView/resources/deployment";
import type Rollout from "@/components/ResourceView/resources/rollout";
import { DescribeLoader } from "@/components/ResourceView/tabs/DescribeTab/common/DescribeLoader";
import { ManagedRecordsSection } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/ManagedRecordsSection/ManagedRecordsSection";
import { RootCauseAnalysisSection } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/RootCauseAnalysisSection";
import { SettingsSection } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/SettingsSection";
import { StatusSection } from "@/components/ResourceView/tabs/ExternalDNSSummaryTab/sections/StatusSection";
import {
  Addon,
  Entity,
  ExternalDnsRecordFullFromJSON,
  ExternalDnsRecordSyncStatusEnum,
} from "@/generated/addonsApi";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";

export const ExternalDNSSummaryTab: React.FC<{
  resource: Deployment | Rollout;
}> = ({ resource }) => {
  const { data, isFetching } = useGetAddonFullLiveState(
    {
      addon: Addon.ExternalDns,
      entity: Entity.ExternalDnsOperator,
      uid: resource.komodorUid,
      name: resource.name,
      getEntityRequest: {
        clusterName: resource.cluster,
      },
    },
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: ExternalDnsRecordFullFromJSON(json?.data?.data),
    })
  );

  if (!resource.id || isFetching || !data) {
    return <DescribeLoader />;
  }

  const externalDns = data.data;
  return (
    <>
      <RootCauseAnalysisSection
        resource={resource}
        isHealthy={
          externalDns.syncStatus === ExternalDnsRecordSyncStatusEnum.Synced
        }
      />
      <StatusSection externalDns={externalDns} />
      <SettingsSection externalDns={externalDns} />
      <ManagedRecordsSection externalDns={externalDns} />
    </>
  );
};
