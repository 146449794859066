import { ComponentProps, useCallback } from "react";
import { GridRowId } from "@mui/x-data-grid-pro";
import Skeleton from "@mui/material/Skeleton";

import { LoaderSkeleton } from "@/components/k8sAddons/components/table/LoaderSkeleton";

type GenerateSkeletonParams<T> = {
  id: GridRowId;
  columnName: keyof T;
  skeletonProps?: ComponentProps<typeof Skeleton>;
};

export const useGenerateSkeleton = <T,>(
  randRangeColumns: Partial<Record<keyof T, number[]>>
) => {
  return useCallback(
    ({ id, columnName, skeletonProps }: GenerateSkeletonParams<T>) => {
      return (
        <LoaderSkeleton
          id={id}
          columnName={columnName}
          randRangeColumns={randRangeColumns}
          skeletonProps={skeletonProps}
        />
      );
    },
    [randRangeColumns]
  );
};
