import React, { useCallback, useMemo, useRef, useState } from "react";
import { palette } from "@komodorio/design-system";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import Pod from "../../resources/pod";
import { LogsViewer } from "../../../common/LogsViewer/LogsViewer";
import ContainerPicker from "../../../common/LogsViewer/PodLogsBar/ContainerPicker";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { LogsUpgradeAgentAlert } from "../../../common/LogsViewer/LogsUpgradeAgentAlert/LogsUpgradeAgentAlert";
import { usePodLogsStore } from "../../../../shared/hooks/podLogs/store/podLogsStore";
import { resetPodLogs } from "../../../../shared/hooks/podLogs/store/podLogsStoreSelectors";

import { useReportLogsDDRum } from "./useReportLogsDDRum";
import { usePodContainerLogs } from "./usePodContainerLogs";

import { PreviousLogs } from "@/components/common/LogsViewer/PodLogsBar/PreviousLogs";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-block-end: 1rem;
  position: absolute;
  height: 95%;
  width: 95%;
`;

interface LogsTabProps {
  resource: Pod;
}

const LogsTab: React.FC<LogsTabProps> = ({ resource }) => {
  const [container, setContainer] = useState<string>();
  const containerRef = useRef<HTMLDivElement>(null);
  const resetLogsInStore = usePodLogsStore(resetPodLogs);

  const containerStatuses = useMemo(
    () => resource.containerStatuses || [],
    [resource]
  );
  const initContainerStatuses = useMemo(
    () => resource.fullObj.status?.initContainerStatuses || [],
    [resource]
  );

  const containersOptions = useMemo(() => {
    const containers = containerStatuses.map((c) => ({
      label: `Container: ${c.name}`,
      value: c.name,
    }));

    const initContainers = (initContainerStatuses || []).map((ic) => ({
      label: `Init Container: ${ic.name}`,
      value: ic.name,
    }));

    return [...containers, ...initContainers];
  }, [containerStatuses, initContainerStatuses]);

  const selectedContainer = useMemo(
    () =>
      containersOptions.find((o) => o.label === container) ??
      containersOptions[0],
    [container, containersOptions]
  );

  const {
    loading,
    logs,
    isPreviousLogs,
    setIsPreviousLogs,
    failureMessage,
    requestMoreLogs,
    tail,
    isConnectionEstablished,
  } = usePodContainerLogs(
    {
      id: resource.id,
      cluster: resource.cluster,
      namespace: resource.namespace,
      name: resource.name,
    },
    selectedContainer
  );

  useReportLogsDDRum({
    divRef: containerRef,
    fetching: Boolean(!isConnectionEstablished),
  });

  const setContainerCB = useCallback(
    (containerName: React.SetStateAction<string | undefined>) => {
      setContainer(containerName);
      resetLogsInStore();
    },
    [resetLogsInStore]
  );

  const containerPicker = (
    <ContainerPicker
      containersOptions={containersOptions}
      selectedContainer={selectedContainer}
      setContainer={setContainerCB}
      isPreviousLogs={isPreviousLogs}
    />
  );

  const previousLogsToggle = (
    <PreviousLogs
      pod={resource}
      setIsPreviousLogs={setIsPreviousLogs}
      isPreviousLogs={isPreviousLogs}
      containerName={selectedContainer?.value}
      containerStatuses={containerStatuses}
    />
  );

  return (
    <Container
      aria-label={AriaLabels.Inspection.Pod.LogsTab}
      ref={containerRef}
    >
      <LogsUpgradeAgentAlert clusterName={resource.cluster} />
      <LogsViewer
        podLogsRes={logs}
        failureMessage={failureMessage}
        loading={loading}
        podName={resource.name}
        containerName={selectedContainer?.value}
        requestedCount={tail}
        onLoadMoreClicked={requestMoreLogs}
        containerPickerElement={containerPicker}
        previousContainerControl={previousLogsToggle}
        toolbarBgColor={palette.gray[50]}
        namespace={resource.namespace}
        clusterName={resource.cluster}
      />
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default LogsTab;
