import { camelCase, capitalize } from "lodash";
import { useMemo } from "react";

import { useUserMetadata } from "./useUserMetadata";

const defaultPermissions = [
  "manage:users",
  "manage:monitors",
  "manage:integrations",
  "manage:helm",
  "manage:features",
  "manage:reliability",
  "manage:reliability-policies",
  "manage:workspaces",
  "manage:account-access",
  "manage:usage",
  "manage:agents",
  "manage:kubeconfig",
  "get:kubeconfig",
  "view:audit",
  "view:namespaces",
];

export const useHasPermissions = (): Record<string, boolean> => {
  const userMetadata = useUserMetadata();

  return useMemo(() => {
    let allowedActions: string[] | undefined;
    if (userMetadata.isKomodorAdmin) {
      allowedActions = defaultPermissions;
    } else {
      allowedActions = userMetadata.allowedActions;
    }

    // If '*' is present, return all permissions as true
    if (allowedActions?.includes("*")) {
      return new Proxy(
        {},
        {
          get: () => true,
        }
      );
    }
    return (
      allowedActions
        ?.map((a) => {
          const splitted = a.split(":");
          if (splitted.length === 2) {
            return camelCase(
              `can${capitalize(splitted[0])}${capitalize(splitted[1])}`
            );
          }
          return "";
        })
        .reduce((a, v) => ({ ...a, [v]: true }), {}) ?? {}
    );
  }, [userMetadata.allowedActions, userMetadata.isKomodorAdmin]);
};
