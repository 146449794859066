/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import { Tooltip } from "@komodorio/design-system/deprecated";
import { useAuth0 } from "@auth0/auth0-react";
import styled, { createGlobalStyle } from "styled-components";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { dispatchEvent } from "./shared/hooks/analytics";
import UrqlProviderAuthWrapper from "./shared/context/UrqlProviderAuthWrapper";
import {
  PrivateRoutes,
  PrivateRoutesAnonymous,
  PrivateRoutesMultipleAccounts,
} from "./components/routes/PrivateRoutes";
import {
  getStoredRedirectUrl,
  storeRedirectUrl,
} from "./shared/utils/redirectUrlService";
import {
  useUserMetadata,
  UserMetadataProvider,
} from "./shared/hooks/useUserMetadata/useUserMetadata";
import { IdentifiedLDProvider } from "./shared/context/featureFlags/ldProvider";
import { useOverridableFlags } from "./shared/context/featureFlags/OverridableFlags";
import { grayAppBackground } from "./Colors";
import { notifyDDError } from "./shared/hooks/exceptionManagement";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { AnalyticEvents } from "./shared/config/analyticsEvents";
import { LocalStorageItem } from "./shared/utils/localStorageSettings";
import { IdentifiedAnalyticsProvider } from "./shared/context/IdentifiedAnalyticsProvider";
import { useInterval } from "./components/common/useInterval";
import {
  K8S_INTEGRATION_MODAL_INITIAL_OPEN,
  newAppBarWidth,
} from "./constants";
import {
  sandboxRoute,
  sandboxSessionStorageParameter,
  useRouteToSandbox,
} from "./shared/utils/sandbox";
import { useSessionStorage } from "./components/common/useSessionStorage";
import { useDispatchFirstLogin } from "./shared/hooks/useDispatchFirstLogin";
import AppBanners from "./components/AppBanners";
import { AnalyticsApiProvider } from "./shared/context/analyticsProvider";
import { AppBar } from "./components/AppBar/AppBar";
import FreemiumModals from "./components/Freemium/Modals/FreemiumModals";
import { setIsFreemiumAccount } from "./components/Freemium/Utils/createFreeAccount";
import isElectronContext, {
  isDesktopAuth,
} from "./shared/context/electron/isElectronContext";
import { PodExecSessions } from "./components/PodExec/PodExecSessions";
import { DesktopAuth } from "./components/DesktopApp/auth";
import { AppProviders } from "./shared/context/AppProviders";
import useSetWindowsFlags from "./shared/hooks/useKomodorWindow/useSetWindowsFlag";
import useSetWindowsEmail from "./shared/hooks/useKomodorWindow/useSetWindowsEmail";
import "./shared/context/electron/listeners/handleElectronBugsnag";
import "./shared/context/electron/listeners/handleElectronLogs";
import useIsDesktopAppError from "./shared/context/electron/useIsDesktopAppError";
import { DesktopErrorPage } from "./components/DesktopApp/error/DesktopErrorPage";
import {
  useUserLoginInfo,
  UserLoginInfoProvider,
} from "./shared/hooks/useUserMetadata/useUserLoginInfo";
import { DEFAULT_TOOLTIP } from "./shared/constants/tooltipIds";
import { ZIndex } from "./constants/zIndex";
import { usePortForward } from "./shared/context/PortForwardProvider";
import { StartNewSessionModal } from "./components/PortForward/StartNewSessionModal";
import { AccountDisabledModal } from "./components/Freemium/Modals/AccountDisabledModal";
import { AppViews } from "./components/appView/sections/AppView/components/AppViews";
import { ApplicationsScopingErrorPage } from "./components/appView/sections/AppView/components/ApplicationsScopingErrorPage/ApplicationsScopingErrorPage";
import { MarketplaceActivatorProvider } from "./shared/hooks/marketplace/marketplaceActivatorProvider";
import { useSetMarketplaceActivationState } from "./shared/hooks/marketplace/localState";
import { useIntercomCustomEffects } from "./shared/hooks/useIntercomCustomEffects";
import { DrawersStack } from "./shared/context/drawersStack/DrawersStack";
import { JOBS_ROUTE, SERVICES_ROUTE } from "./components/routes/routes";
import { ZendeskProvider } from "./components/SupportTools/Zendesk/ZendeskProvider";
import { CustomFlagsAttributes } from "./shared/context/featureFlags/CustomFlagsAttributes";
import { AiInvestigationMinimizedSessions } from "./components/AiInvestigation/AiInvestigationMinimizedSessions";

import { useIsAnonymousUser } from "@/shared/hooks/useIsAnonymousUser";
import { TopBar } from "@/components/TopBar/TopBar";
import { SnackbarNotificationsHandler } from "@/shared/components/SnackbarNotificationsHandler";
import { useIsMultipleAccountsUserOnFirstLogin } from "@/shared/hooks/useIsMultipleAccountsUserOnFirstLogin";
import { useLogout } from "@/components/Logout/useLogout";
/// <reference types="@types/segment-analytics" />

const storedAuth0RedirectUrl = new LocalStorageItem("auth0RedirectUrl");
const INVALID_EMAIL_DOMAIN_ERROR = "invalid_email_domain";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 2 * 60 * 1000, refetchOnWindowFocus: false },
  },
});

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${grayAppBackground};
    overflow-x: hidden;
  }
`;

const Container = styled.div`
  font-family: "Roboto", sans-serif;
  color: black;
  line-height: initial;
  display: grid;
  grid-template: "nav page" auto / ${newAppBarWidth}rem 1fr;
`;

const extractPath = () => {
  const { pathname, search, hash } = window.location;
  return { pathname, search, hash };
};

// excluding these pages due to a known issue with infinite scroll usage on the page, which stops working when the page height is set to 100vh.
const EXCLUDE_PAGE_HEIGHT_PAGES = [SERVICES_ROUTE, JOBS_ROUTE];
const shouldExcludePageHeight = () => {
  const { pathname } = extractPath();
  return EXCLUDE_PAGE_HEIGHT_PAGES.includes(pathname);
};
const Page = styled.div<{ disableHeight?: boolean }>`
  grid-area: page;
  overflow-x: auto;
  ${({ disableHeight }) => (disableHeight ? "" : "height: 100vh;")}
`;

const Content: React.FC = () => {
  const user = useUserLoginInfo();
  const flags = useOverridableFlags();
  useSetWindowsFlags(flags);
  useSetWindowsEmail(user);

  const { accountDisabled } = useUserMetadata();
  const isAnonymousUser = useIsAnonymousUser();
  const isMultipleAccountsUserOnFirstLogin =
    useIsMultipleAccountsUserOnFirstLogin();
  const { isPortForwardModalOpen, setIsPortForwardModalOpen } =
    usePortForward();
  useDispatchFirstLogin();
  useIntercomCustomEffects();

  if (isMultipleAccountsUserOnFirstLogin || isAnonymousUser) {
    return (
      <>
        <GlobalStyle />
        {isAnonymousUser ? (
          <PrivateRoutesAnonymous />
        ) : (
          <PrivateRoutesMultipleAccounts />
        )}
      </>
    );
  }

  return (
    <>
      <AppViews />
      <AppBanners />
      <FreemiumModals />
      <DrawersStack />
      {accountDisabled && <AccountDisabledModal />}
      <Container>
        <GlobalStyle />
        <AppBar />
        <Page disableHeight={shouldExcludePageHeight()}>
          <TopBar />
          <ApplicationsScopingErrorPage>
            <PodExecSessions />
            {Boolean(flags.minimizedAiInvestigations) && (
              <AiInvestigationMinimizedSessions />
            )}
            <StartNewSessionModal
              isOpen={isPortForwardModalOpen}
              onClose={() => setIsPortForwardModalOpen(false)}
            />
            <PrivateRoutes />
          </ApplicationsScopingErrorPage>
        </Page>
      </Container>
      <Tooltip id={DEFAULT_TOOLTIP} style={{ zIndex: ZIndex.AboveAppBar }} />
      <SnackbarNotificationsHandler />
    </>
  );
};

export const PrivateApp: React.FC = () => {
  useSetMarketplaceActivationState({});

  const auth0 = useAuth0();
  const logout = useLogout();
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const {
    isLoading,
    isAuthenticated,
    error: auth0AuthenticateError,
    user,
  } = auth0;
  const [isSandbox, setIsSandbox] = useSessionStorage(
    sandboxSessionStorageParameter,
    false
  );
  const routeToSandbox = useRouteToSandbox(
    setAccessToken,
    ready,
    setReady,
    isSandbox,
    setIsSandbox
  );

  const isDesktopAuthAllowed = isElectronContext() && isDesktopAuth();
  const desktopError = useIsDesktopAppError();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isDesktopAuthAllowed) return;

    if (auth0AuthenticateError) {
      if (auth0AuthenticateError.message === INVALID_EMAIL_DOMAIN_ERROR) {
        const path = extractPath();
        storeRedirectUrl(path, storedAuth0RedirectUrl);
        auth0.loginWithRedirect({
          screen_hint: "signup",
          prompt: "login",
          "ext-authentication-error": INVALID_EMAIL_DOMAIN_ERROR,
        });
      }
      // eslint-disable-next-line no-console
      console.error("Authentication error: " + auth0AuthenticateError);
      notifyDDError(auth0AuthenticateError);

      logout();
      return;
    }
    const pathSplit = extractPath().pathname.split("/");
    if (pathSplit[1] === sandboxRoute) {
      pathSplit.splice(1, 1);
      const userRoute = pathSplit.join("/");
      routeToSandbox(
        userRoute !== "" ? userRoute + extractPath().search : undefined
      );
      return;
    }

    if (isSandbox) {
      routeToSandbox(extractPath().pathname + extractPath().search);
      return;
    }

    if (!isAuthenticated) {
      const path = extractPath();
      setIsFreemiumAccount(path.pathname);
      storeRedirectUrl(path, storedAuth0RedirectUrl);

      const queryParams = new URLSearchParams(window.location.search);
      const modeValue = queryParams.get("mode")?.includes("signUp");
      const emailValue = queryParams.get("email");
      const utmSource = queryParams.get("utm_source");
      const utmTerm = queryParams.get("utm_term");
      const utmMedium = queryParams.get("utm_medium");
      const utm_campaign = queryParams.get("utm_campaign");
      const utm_content = queryParams.get("utm_content");

      auth0.loginWithRedirect({
        screen_hint: modeValue ? "signup" : "login",
        login_hint: emailValue ?? "",
        // ext params can't be empty string, but can be null
        "ext-utm_source": utmSource === "" ? null : utmSource,
        "ext-utm_term": utmTerm === "" ? null : utmTerm,
        "ext-utm_medium": utmMedium === "" ? null : utmMedium,
        "ext-utm_campaign": utm_campaign === "" ? null : utm_campaign,
        "ext-utm_content": utm_content === "" ? null : utm_content,
      });
      return;
    }
    auth0.getAccessTokenSilently().then((token) => {
      setAccessToken(token);
      setIsSandbox(false);
    });
    const redirectUrl = getStoredRedirectUrl(storedAuth0RedirectUrl);
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
    }
    setReady(true);
  }, [
    isLoading,
    isAuthenticated,
    auth0,
    auth0AuthenticateError,
    logout,
    navigate,
    setIsSandbox,
    isSandbox,
    routeToSandbox,
    isDesktopAuthAllowed,
  ]);

  useInterval(() => {
    // This checks periodically if the current JWT is expired (once authenticated, is valid for 24hrs)
    // In some cases people leave their browser open for long period of times and without refreshing we didn't know the JWT expired
    // and it resulted in failed requests and errors.
    if (!accessToken) return;
    const jwtPayload: JwtPayload = jwtDecode(accessToken);
    if (!jwtPayload.exp) return;
    const expiryInMsSince1970WithBuffer = jwtPayload.exp * 1000 - 60_000; // JS dates require milliseconds, not seconds
    const now = Date.now();
    if (expiryInMsSince1970WithBuffer < now) {
      dispatchEvent(AnalyticEvents.App.Refresh_JWT_Expired, {
        email: user?.email,
      });
      window.location.reload();
      return;
    }
  }, 60_000);

  useEffect(() => {
    sessionStorage.setItem(K8S_INTEGRATION_MODAL_INITIAL_OPEN, "false");
  }, []);

  if (!isDesktopAuthAllowed && (!ready || (!accessToken && !isSandbox))) {
    return null;
  }

  if (desktopError) {
    return <DesktopErrorPage error={desktopError} />;
  }

  return (
    <ErrorBoundary>
      <UserLoginInfoProvider>
        <AnalyticsApiProvider accessToken={accessToken}>
          <UrqlProviderAuthWrapper accessToken={accessToken}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools
                initialIsOpen={false}
                buttonPosition="bottom-right"
              />
              <UserMetadataProvider
                accessToken={accessToken}
                isSandbox={isSandbox}
              >
                <CustomFlagsAttributes>
                  <IdentifiedLDProvider auth0User={user}>
                    <IdentifiedAnalyticsProvider>
                      <AppProviders>
                        <ZendeskProvider>
                          <MarketplaceActivatorProvider
                            accessToken={accessToken}
                          >
                            {isDesktopAuthAllowed && !accessToken ? (
                              <DesktopAuth setAccessToken={setAccessToken} />
                            ) : (
                              <Content />
                            )}
                          </MarketplaceActivatorProvider>
                        </ZendeskProvider>
                      </AppProviders>
                    </IdentifiedAnalyticsProvider>
                  </IdentifiedLDProvider>
                </CustomFlagsAttributes>
              </UserMetadataProvider>
            </QueryClientProvider>
          </UrqlProviderAuthWrapper>
        </AnalyticsApiProvider>
      </UserLoginInfoProvider>
    </ErrorBoundary>
  );
};
