import * as React from "react";
import styled from "styled-components";

import CopyComponent from "../../../common/Copy";
import { H3 } from "../../../common/typography";
import { useUserMetadata } from "../../../../shared/hooks/useUserMetadata/useUserMetadata";

const Container = styled.div`
  margin-bottom: 1.5rem;
`;

const Steps = styled.ol`
  width: 30rem;
  padding-inline-end: 1rem;
`;

const Step = styled.li`
  font-size: 0.85rem;
  margin-block-end: 0.5rem;
`;

const StyledCopy = styled(CopyComponent)`
  width: 25rem;
`;

const LaunchDarklyInstallationInstructions: React.FC<{
  webhookUrl: string;
}> = ({ webhookUrl }) => {
  return (
    <Container>
      <H3>Installation Steps</H3>
      <Steps>
        <Step>
          Navigate to{" "}
          <a
            href="https://app.launchdarkly.com/default/integrations/webhooks/new"
            target="_blank"
            rel="noreferrer"
          >
            LaunchDarkly Webhook Integration
          </a>
        </Step>
        <Step>
          In the <b>Name</b> field, give your integration a name.
        </Step>
        <Step>
          In the <b>URL</b> field, enter the value:
          <StyledCopy value={webhookUrl} />
        </Step>
        <Step>
          Check the "Sign this webhook" box. Enter the value:
          <StyledCopy value={useUserMetadata().accountId} />
        </Step>
        <Step>
          Add policy
          <ol>
            <li>
              For "Choose resources for this policy statement", enter{" "}
              <b>proj/*:env/*:flag/*</b>.
            </li>
            <li>
              For "Allow or deny actions on the resource", choose <b>Allow</b>.
            </li>
            <li>
              For "Choose actions to allow or deny", choose <b>All actions</b>.
            </li>
            <li>
              Click <b>UPDATE</b>.
            </li>
          </ol>
        </Step>
        <Step>Save Settings.</Step>
      </Steps>
    </Container>
  );
};

// [CU-86c022h1m] Enforce using Named Exports over Default Exports
// eslint-disable-next-line import/no-default-export
export default LaunchDarklyInstallationInstructions;
