import { useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { NoResultsText } from "../NoResultsText";
import { MoreOptionsText } from "../MoreOptionsText";
import {
  MAX_OPTIONS_PER_GROUP,
  MAX_RESULTS_PER_FILTER,
  SERVER_REQUEST_DEBOUNCE_DELAY,
  violationFilterCategoryToTitle,
} from "../filterSectionConstants";

import { CheckboxOptionsWithFilterCategory } from "./useCheckboxOptionsHandler";
import { useGetMissingOptions } from "./useGetMissingOptions";
import { useShouldEnableApiCall } from "./useShouldEnableApiCall";
import { useFiltersInUrlAsGenericViolationFilterCategory } from "./useFiltersInUrlAsGenericViolationFilterCategory";
import {
  convertFilterValueToLabel,
  sortFiltersByDefaultValues,
} from "./filterStateHadlerUtils";

import { useGetViolationsFilter } from "@/shared/hooks/reliability-api/filters/useGetViolationsFilter";
import {
  GenericViolationFilterCategory,
  ViolationCountBy,
} from "@/generated/reliabilityApi";
import { Option } from "@/shared/components/CheckboxOptionsHandler/checkboxOptionsHandlerTypes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import { useStateInUrl } from "@/shared/hooks/filtersDataInUrl/useStateInUrl";
import { useDefaultFilters } from "@/components/reliability/hooks/useDefaultFilters";
import { useDatadogReportLoadingTimeContext } from "@/shared/context/datadogReportLoadingTime/hooks/useDatadogReportLoadingTimeContext";
import { getReportingNameSuffixByType } from "@/components/reliability/utils/loadingStateUtils";

export const useGetCheckboxOptionsData = (
  countBy: ViolationCountBy
): CheckboxOptionsWithFilterCategory => {
  const [dataInUrl] = useStateInUrl();
  const [filterBy, setFilterBy] = useState<
    GenericViolationFilterCategory[] | undefined
  >();
  const defaultSelectedViolationFilters = useDefaultFilters();

  const { reportLoadingState } = useDatadogReportLoadingTimeContext();
  const { reliabilityFiltersRequestLimit } = useOverridableFlags();
  const requestLimit = reliabilityFiltersRequestLimit
    ? (reliabilityFiltersRequestLimit as number)
    : MAX_RESULTS_PER_FILTER;

  const { callback: setFilterByWithDebounce } = useDebouncedCallback(
    setFilterBy,
    SERVER_REQUEST_DEBOUNCE_DELAY
  );

  const filters = useFiltersInUrlAsGenericViolationFilterCategory(countBy);

  useEffect(() => {
    const filtersInUrl = dataInUrl.filters || {};
    if (!Object.keys(filtersInUrl).length) {
      setFilterByWithDebounce([]);
      return;
    }

    if (filterBy === undefined) {
      // initial state
      return setFilterBy(filters);
    }
    setFilterByWithDebounce(filters);
  }, [dataInUrl.filters, filterBy, filters, setFilterByWithDebounce]);

  const enableRequest = useShouldEnableApiCall(filterBy);
  const { data, isFetching, isLoading } = useGetViolationsFilter(
    {
      countBy,
      postViolationsCountRequestBody: {
        limit: requestLimit,
        filterBy: filterBy ?? [],
      },
    },
    { enabled: enableRequest }
  );

  useEffect(() => {
    reportLoadingState(
      getReportingNameSuffixByType("filter", countBy),
      isLoading
    );
  }, [countBy, isLoading, reportLoadingState]);

  const missingOptionsToAdd = useGetMissingOptions(
    countBy,
    data?.data.items.map((item) => item.key)
  );

  const optionsData = useMemo<Option[]>(() => {
    if (!data?.data.items) return [];
    const allOptions = sortFiltersByDefaultValues(countBy, [
      ...data.data.items,
      ...missingOptionsToAdd,
    ]);

    return (
      allOptions.map((item) => ({
        value: item.key,
        label: convertFilterValueToLabel(countBy, item.key),
        checked: false,
        count: item.value,
      })) || []
    );
  }, [data?.data.items, countBy, missingOptionsToAdd]);

  const defaultSelectedOptions =
    defaultSelectedViolationFilters[countBy]?.[0]?.values;

  return {
    filterCategory: countBy,
    options: optionsData,
    defaultSelectedOptions,
    onChange: () => undefined,
    maxHeightByOptionsNum: MAX_OPTIONS_PER_GROUP,
    collapsibleProps: {
      title: violationFilterCategoryToTitle[countBy] ?? "",
      initialOpen: true,
    },
    totalOptionNum: data?.data.totalItems,
    loading: isFetching || !enableRequest,
    hasMoreResultsMessage: <MoreOptionsText />,
    emptyResultsMessage: <NoResultsText />,
  };
};
