import React, { useRef } from "react";
import styled from "styled-components";

import { IssuesTabs } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/IssuesTableWithContext/IssuesTabs/IssuesTabs";
import { IssuesTable } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/RealtimeSection/IssuesTableWithContext/IssuesTable";
import { useRealtimeIssues } from "@/components/reliability/hooks/reliabilityHooks";
import { useOnContainerResizeWidth } from "@/components/common/LogsViewer/LogsBlackText/hooks/useContainerResizeWidth";

const Container = styled.div``;

export const IssuesTableHandler: React.FC = () => {
  const violationsRes = useRealtimeIssues();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = useOnContainerResizeWidth(containerRef);
  return (
    <Container ref={containerRef}>
      <IssuesTabs />
      <IssuesTable
        violationsRes={violationsRes}
        containerWidth={containerWidth}
      />
    </Container>
  );
};
