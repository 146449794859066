import React from "react";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";

const Container = styled.div`
  display: grid;
  grid-template-columns: 10% 2fr 10%;
  gap: 8px;
  width: 100%;
  align-items: center;
`;

export const ResourceUsageLoadingCell: React.FC = () => {
  return (
    <Container>
      <Skeleton variant="rounded" height={12} />
      <Skeleton variant="rounded" height={8} />
      <Skeleton variant="rounded" height={12} />

      <Skeleton variant="rounded" height={12} />
      <Skeleton variant="rounded" height={8} />
      <Skeleton variant="rounded" height={12} />
    </Container>
  );
};
