import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import { compact } from "lodash";

import { ExternalDnsRecord } from "@/generated/addonsApi";
import { DateCell } from "@/components/k8sAddons/components/table/DateCell";
import { disableLocalSortingInTableColumns } from "@/components/k8sAddons/hooks/table/useDisableLocalSortingInTableColumns";
import { SyncStatus } from "@/components/k8sAddons/addons/externalDns/SyncStatus";
import { DNSProviderIcon } from "@/components/k8sAddons/addons/externalDns/DNSProviderIcon";
import { DNSProvider } from "@/components/k8sAddons/addons/externalDns/types";
import { generateRandRangeList } from "@/components/k8sAddons/utils/tableUtils";
import { useGenerateSkeleton } from "@/components/k8sAddons/hooks/table/useGenerateSkeleton";
import { AgentUpgradeRequiredWrapper } from "@/components/k8sAddons/addons/externalDns/AgentUpgradeRequiredWrapper";

export const columnsConfig =
  disableLocalSortingInTableColumns<ExternalDnsRecord>({
    name: {
      field: "serviceName",
      headerName: "Name",
      flex: 1,
    },
    cluster: {
      field: "clusterName",
      headerName: "Cluster",
      flex: 1,
    },
    namespace: {
      field: "namespace",
      headerName: "Namespace",
      flex: 1,
    },
    provider: {
      field: "dnsProvider",
      headerName: "DNS Provider",
      flex: 0.75,
    },
    managedRecords: {
      field: "numberManagedRecords",
      headerName: "Managed Records",
      flex: 0.75,
    },
    status: {
      field: "syncStatus",
      headerName: "Status",
      flex: 0.75,
    },
    lastSynced: {
      field: "lastSuccessfulSyncTime",
      headerName: "Last synced",
      flex: 1,
    },
  });

export const useTableColumns = (): GridColDef<ExternalDnsRecord>[] => {
  return useMemo(() => {
    return compact([
      columnsConfig.name,
      columnsConfig.cluster,
      columnsConfig.namespace,
      {
        ...columnsConfig.provider,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                display: "flex",
                columnGap: "8px",
                alignItems: "center",
              }}
            >
              <DNSProviderIcon
                dnsProvider={params.row.dnsProvider as DNSProvider}
              />
              {params.row.dnsProvider}
            </Box>
          );
        },
      },
      {
        ...columnsConfig.managedRecords,
        renderCell: (params) => {
          return (
            <AgentUpgradeRequiredWrapper cluster={params.row.clusterName}>
              {params.row.numberManagedRecords}
            </AgentUpgradeRequiredWrapper>
          );
        },
      },
      {
        ...columnsConfig.status,
        renderCell: (params) => (
          <AgentUpgradeRequiredWrapper cluster={params.row.clusterName}>
            <SyncStatus status={params.row.syncStatus} />
          </AgentUpgradeRequiredWrapper>
        ),
      },
      {
        ...columnsConfig.lastSynced,
        renderCell: (params) => {
          return (
            <AgentUpgradeRequiredWrapper cluster={params.row.clusterName}>
              {params.row.lastSuccessfulSyncTime ? (
                <DateCell
                  dateString={params.row.lastSuccessfulSyncTime}
                  overrideOptions={{ timeZoneName: undefined }}
                />
              ) : null}
            </AgentUpgradeRequiredWrapper>
          );
        },
      },
    ]);
  }, []);
};

/** create static rand ranges to prevent skeleton width changes when table component re-renders */
const randRangeColumns: Partial<Record<keyof ExternalDnsRecord, number[]>> = {
  serviceName: generateRandRangeList(50, 150),
  clusterName: generateRandRangeList(50, 150),
  namespace: generateRandRangeList(50, 150),
  dnsProvider: generateRandRangeList(50, 150),
  numberManagedRecords: generateRandRangeList(50, 150),
  syncStatus: generateRandRangeList(50, 150),
  lastSuccessfulSyncTime: generateRandRangeList(50, 150),
};

export const useLoadingColumns = (): GridColDef<ExternalDnsRecord>[] => {
  const generateSkeleton = useGenerateSkeleton(randRangeColumns);
  return useMemo(
    () =>
      compact([
        {
          ...columnsConfig.name,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "serviceName" }),
        },
        {
          ...columnsConfig.cluster,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "clusterName" }),
        },
        {
          ...columnsConfig.namespace,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "namespace" }),
        },
        {
          ...columnsConfig.provider,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "dnsProvider" }),
        },
        {
          ...columnsConfig.managedRecords,
          renderCell: ({ id }) =>
            generateSkeleton({ id, columnName: "numberManagedRecords" }),
        },
        {
          ...columnsConfig.status,
          renderCell: ({ id }) =>
            generateSkeleton({
              id,
              columnName: "syncStatus",
              skeletonProps: { sx: { borderRadius: "16px" } },
            }),
        },
        {
          ...columnsConfig.lastSynced,
          renderCell: ({ id }) =>
            generateSkeleton({
              id,
              columnName: "lastSuccessfulSyncTime",
            }),
        },
      ]),
    [generateSkeleton]
  );
};
