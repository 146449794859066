import React, { useMemo } from "react";
import Divider from "@mui/material/Divider";
import {
  AdvancedMultiSelect,
  LinesLoader,
  SearchField,
} from "@komodorio/design-system/komodor-ui";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import { Buttons, Filters, Header } from "../styles";
import DataTable from "../../common/table/DataTable";
import { useGetRbacPolicyActions } from "../../../shared/hooks/auth-service/client/rbacActions/useGetRbacPolicyActions";
import { useGetRbacCustomK8sActions } from "../../../shared/hooks/auth-service/client/rbacActions/useGetRbacK8sCustomActions";
import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";
import { RbacCustomK8sAction } from "../../../generated/auth";
import { useDateFormatter } from "../../../shared/hooks/useDateFormatter";
import { useTimezone } from "../../../shared/context/TimezoneProvider";

import { RbacActionTypesOptions } from "./actionTypes";
import { buildRbacActionsColumns } from "./ActionsActionsColumns";
import { ActionFormModal } from "./Form/FormModal";
import { Action } from "./common";
import { DeleteCustomActionModal } from "./DeleteCustomActionModal";

import { RBAC_ACTION } from "@/shared/config/urlSearchParamsKeys";
import { useStateInSearchParams } from "@/shared/hooks/state/useStateInSearchParams";
import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";

const actionNameOrDescriptionMatchSearchTermPredicate = (term: string) => {
  return (p: Action) => {
    return (
      p.action.toLowerCase().includes(term.toLowerCase()) ||
      p.description.toLowerCase().includes(term.toLowerCase())
    );
  };
};
const actionTypeMatchesTypeFiltersPredicate = (typeFilters: string[]) => {
  return (p: Action) => {
    return typeFilters.length === 0 || typeFilters.includes(p.type);
  };
};

export const Actions: React.FC = () => {
  const { format: formatDate } = useDateFormatter({ timeZoneName: undefined });
  const { timeZoneName } = useTimezone();

  const { data: globalActions, isLoading: isLoadingGlobalActions } =
    useGetRbacPolicyActions();
  const {
    data: customActions,
    isLoading: isLoadingCustomActions,
    refetch: refetchCustomActions,
  } = useGetRbacCustomK8sActions();
  const isLoadingData = isLoadingCustomActions || isLoadingGlobalActions;

  const [searchPrompt, setSearchPrompt] = useStateInSearchParams(RBAC_ACTION);
  const [typeFilter, setTypeFilter] = React.useState<string[]>([]);
  const [openActionModal, setOpenActionModal] = React.useState(false);
  const [elementToEdit, setElementToEdit] = React.useState<Action | undefined>(
    undefined
  );
  const [elementToDelete, setElementToDelete] = React.useState<
    Action | undefined
  >(undefined);

  const allActions: Action[] = useMemo(() => {
    return [...(customActions ?? []), ...(globalActions ?? [])];
  }, [globalActions, customActions]);

  const filteredActions = useMemo(() => {
    if (!allActions) {
      return [];
    }
    const lowerCaseFilter = (searchPrompt || "").toLowerCase();
    return allActions
      .filter(actionNameOrDescriptionMatchSearchTermPredicate(lowerCaseFilter))
      .filter(actionTypeMatchesTypeFiltersPredicate(typeFilter));
  }, [allActions, searchPrompt, typeFilter]);

  const tableColumns = useMemo(() => {
    return buildRbacActionsColumns({
      setElementToDelete,
      setElementToEdit,
      timezoneName: timeZoneName,
      formatDate,
    });
  }, [formatDate, timeZoneName]);

  return (
    <>
      <SettingsViewVerticalLayout title={"Actions"}>
        <Header>
          <Filters>
            <SearchField
              size="medium"
              width="15rem"
              placeholder="Search"
              value={searchPrompt || ""}
              showCloseIcon={true}
              ariaLabel="Search actions"
              onSearch={setSearchPrompt}
            />
            <AdvancedMultiSelect
              width="15rem"
              options={RbacActionTypesOptions}
              placeholder="All action types"
              onChange={(value) =>
                setTypeFilter(Object.values(value).map((v) => v.value))
              }
            />
          </Filters>
          <Buttons>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<AddIcon />}
              onClick={() => setOpenActionModal(true)}
            >
              Add action
            </Button>
          </Buttons>
        </Header>
        <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
        {isLoadingData && <LinesLoader />}
        {!isLoadingData && (
          <DataTable
            data={filteredActions}
            columns={tableColumns}
            getRowId={(u) => u.action}
            highlightedRows
          />
        )}
      </SettingsViewVerticalLayout>
      {(!!elementToEdit || openActionModal) && (
        <ValidationsProvider>
          <ActionFormModal
            open={!!elementToEdit || openActionModal}
            onClose={() => {
              setElementToEdit(undefined);
              setOpenActionModal(false);
            }}
            refetchCustomActions={refetchCustomActions}
            element={elementToEdit}
          />
        </ValidationsProvider>
      )}
      {elementToDelete && (
        <DeleteCustomActionModal
          open={!!elementToDelete}
          handleClose={() => setElementToDelete(undefined)}
          element={elementToDelete as RbacCustomK8sAction}
          refetch={refetchCustomActions}
        />
      )}
    </>
  );
};
