import React, { ChangeEvent } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";

export const onlyDigitsOrEmptyRegex = /^(?:[1-9]\d*|)?$/;
export const onlyDigitsRegex = /^[1-9]\d*$/;

export type MaxNumOfLinesValue = number | "";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledInput = styled(OutlinedInput)<{ $inputWidth: number }>`
  height: 32px;
  width: ${({ $inputWidth }) => $inputWidth}px;
`;

const ErrorText = styled(Typography).attrs({
  variant: "body2",
  color: "error",
})`
  && {
    font-size: 12px;
    position: absolute;
    margin-top: 32px;
  }
`;

type Props = {
  value: MaxNumOfLinesValue;
  setValue: (value: MaxNumOfLinesValue) => void;
  error?: string;
  inputWidth: number;
  disabled?: boolean;
};

export const OnlyNumbersInput: React.FC<Props> = ({
  value,
  setValue,
  error,
  inputWidth,
  disabled = false,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (onlyDigitsOrEmptyRegex.test(value))
      setValue(value as MaxNumOfLinesValue);
  };
  return (
    <Container>
      <StyledInput
        value={value}
        onChange={handleInputChange}
        $inputWidth={inputWidth}
        error={!!error}
        disabled={disabled}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};
