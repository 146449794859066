import React from "react";

import { useGetViolations } from "@/shared/hooks/reliability-api/violations/useGetViolations";
import { CheckCategory, ImpactGroupType } from "@/generated/reliabilityApi";
import { ViolationStatusSelector } from "@/components/reliability/components/shared/ViolationStatusSelector/ViolationStatusSelector";

type Props = {
  violationId?: string;
};

export const ViolationsStatusAction: React.FC<Props> = ({ violationId }) => {
  const { data } = useGetViolations(
    {
      pageSize: 1,
      offset: 0,
      violationId: [violationId ?? ""],
      impactGroupType: [ImpactGroupType.Realtime],
      checkCategory: [CheckCategory.Workload],
    },
    !!violationId
  );

  if (!data?.data.violations.length) return null;

  return (
    <ViolationStatusSelector
      violation={data.data.violations[0]}
      isRealtimeIssues
    />
  );
};
