export const emailSettingsAriaLabels = {
  mainContainer: "email settings main container",
  isSubscribed: "subscribe to the Komodor digest",
  subscribeButton: "subscribe to the Komodor digest button",
  emailAddress: "email address",
  frequency: "frequency of the Komodor digest",
  scope: "digest scope",
  submitButton: "submit button",
  content: {
    failedDeploys: {
      isEnabled: "failed deploys",
    },
    issues: {
      isEnabled: "unhealthy services",
    },
    reliability: {
      isEnabled: "reliability violations",
      violations: {
        severities: {
          high: "High Severity Issues",
          medium: "Medium Severity Issues",
          low: "Low Severity Issues",
        },
      },
    },
  },
  notificationCenterContainer: "email digest notification center container",
};
