import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  ServiceComparisonRequest,
  ServiceComparisonResponse,
  apiV1DriftComparisonServicePostUrl,
} from "../../../../generated/workspacesApi";
import { useWorkspacesApiClient } from "../apiClient";

export const DRIFT_SERVICE_COMPARISON_PATH = "drift/comparison/service";

const fetchServiceDriftComparison = async (
  apiClient: AxiosInstance,
  params: ServiceComparisonRequest
): Promise<ServiceComparisonResponse> => {
  const { data } = await apiClient.post<ServiceComparisonResponse>(
    apiV1DriftComparisonServicePostUrl({}, apiClient.defaults.baseURL ?? ""),
    params
  );

  return data;
};

export const useServiceDriftComparison = (
  params: ServiceComparisonRequest,
  options?: {
    enabled?: boolean;
  }
) => {
  const apiClient = useWorkspacesApiClient();

  return useQuery(
    [DRIFT_SERVICE_COMPARISON_PATH, params],
    () => fetchServiceDriftComparison(apiClient, params),
    options
  );
};
