import { useWorkloadsGraphData } from "./graphDataHooks";
import {
  useParseMultipleMetricsResults,
  useWorkloadsMetricsParams,
} from "./metricsRequestsHooks";

import { MetricType } from "@/components/Metrics/types";
import { useGetWorkloadsMetrics } from "@/shared/hooks/metrics-api/client/useGetWorkloadMetrics";
import { WorkloadMetricsDataPoints } from "@/generated/metricsApi";

type WorkloadsMetricsParams = {
  type: MetricType;
  komodorUids: string[];
  endTime?: number;
};

export const useWorkloadsMetrics = ({
  type,
  komodorUids,
  endTime,
}: WorkloadsMetricsParams) => {
  const { toEpochMs, fromEpoch, toEpoch, requestsParams, enabledClusters } =
    useWorkloadsMetricsParams({
      komodorUids,
      endTime,
    });

  const results = useGetWorkloadsMetrics({
    type,
    params: requestsParams,
    enabledClusters,
  });

  const { error, loadingStates, isLoading, data } =
    useParseMultipleMetricsResults(results);

  const { graphProps, hasGraphData, linesContent } = useWorkloadsGraphData(
    data as (WorkloadMetricsDataPoints | undefined)[],
    type
  );

  return {
    loadingStates,
    isLoading,
    error,
    graphProps,
    hasGraphData,
    fromEpoch,
    toEpoch,
    toEpochMs,
    linesContent,
  };
};
