import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { K8S_ADD_ONS_FILTERS_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import { Operator } from "@/generated/addonsApi";
import { FiltersContextProvider } from "@/shared/context/filtersInUrlContext/FiltersInUrlContext";
import { nodeAutoscalersRoutes } from "@/components/k8sAddons/addons/NodeAutoscalers/nodeAutoscalersConstants";
import { NoAutoscalersFoundPage } from "@/components/k8sAddons/addons/NodeAutoscalers/NoAutoscalersFoundPage/NoAutoscalersFoundPage";
import { NodeAutoscalersPage } from "@/components/k8sAddons/addons/NodeAutoscalers/NodeAutoscalersPage/NodeAutoscalersPage";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";

export const NodeAutoscalers: React.FC = () => {
  return (
    <ResponsiveLayout>
      <FiltersContextProvider
        urlParamKey={K8S_ADD_ONS_FILTERS_PARAM_KEY}
        searchTypeFilterOperator={Operator.Like}
      >
        <Routes>
          <Route
            path={nodeAutoscalersRoutes.nodeAutoscalers}
            element={<NodeAutoscalersPage />}
          />
          <Route
            path={nodeAutoscalersRoutes.noAutoscalersFound}
            element={<NoAutoscalersFoundPage />}
          />
          <Route
            path="*"
            element={
              <Navigate to={nodeAutoscalersRoutes.nodeAutoscalers} replace />
            }
          />
        </Routes>
      </FiltersContextProvider>
    </ResponsiveLayout>
  );
};
