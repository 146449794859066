import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { isUndefined } from "lodash";

import {
  AddEditIgnoreRuleDialogAction,
  GroupIdentifier,
  ReliabilityStore,
  UpdateGroupState,
  ViolationsGroupBy,
} from "../ReliabilityTypes";
import { initialGroupState } from "../constants/reliabilityConstants";

import { initialGroupDataState, initialState } from "./initialState";

import { getInitialPaginationModel } from "@/components/reliability/components/pages/violations/ViolationsBreakdown/ViolationTableWithContext/violationTableConstants";

export const useReliabilityStore = create(
  immer<ReliabilityStore>((set) => ({
    ...initialState,
    setTopSectionIsShown: (isShown: boolean) => {
      set(() => ({
        topSectionIsShown: isShown,
      }));
    },
    setGroupExpandedState: (
      groupBy: ViolationsGroupBy,
      groupId: GroupIdentifier
    ) => {
      set((state) => {
        const groupsState = state.violationsState.groups;
        const groupData = groupsState[groupBy].groupData[groupId];
        const newGroupData = isUndefined(groupData)
          ? {
              isExpanded: true,
              ...initialGroupState,
              paginationModel: getInitialPaginationModel(groupBy),
            }
          : { ...groupData, isExpanded: !groupData.isExpanded };

        state.violationsState.groups = {
          ...groupsState,
          [groupBy]: {
            ...groupsState[groupBy],
            groupData: {
              ...groupsState[groupBy].groupData,
              [groupId]: newGroupData,
            },
          },
        };
      });
    },
    setGroupState: ({ uid, groupBy, newState }: UpdateGroupState) => {
      set((state) => {
        const groupsState = state.violationsState.groups;
        const group = groupsState[groupBy];
        const groupData = group.groupData[uid];

        state.violationsState.groups = {
          ...groupsState,
          [groupBy]: {
            ...groupsState[groupBy],
            groupData: {
              ...group.groupData,
              [uid]: {
                ...initialGroupDataState,
                ...groupData,
                ...newState,
              },
            },
          },
        };
      });
    },
    setUngroupedTotalViolationsCount: (count: number | undefined) => {
      set((state) => {
        state.violationsState.ungroupedTotalViolationsCount = count;
      });
    },
    setAddEditIgnoreRuleDialogState: (
      newState: AddEditIgnoreRuleDialogAction
    ) => {
      set((state) => {
        state.addEditIgnoreRuleDialogState = {
          ...initialState.addEditIgnoreRuleDialogState,
          isOpen: newState.isOpen,
          ...(newState.checkType && { checkType: newState.checkType }),
          ...(newState.existingIgnoreRule && {
            existingIgnoreRule: newState.existingIgnoreRule,
          }),
        };
      });
    },
    setHasSubscribedToAppViewChanges: (hasSubscribed: boolean) => {
      set((state) => {
        state.hasSubscribedToAppViewChanges = hasSubscribed;
      });
    },
  }))
);

export const resetReliabilityStore = () => {
  useReliabilityStore.setState({
    violationsState: initialState.violationsState,
    addEditIgnoreRuleDialogState: initialState.addEditIgnoreRuleDialogState,
  });
};
