import React, { useState } from "react";

import { useWorkloadsMetrics } from "../../hooks/useWorkloadsMetrics";
import { WorkloadsGraphProps } from "../../types";

import { EnlargedWorkloadsGraph } from "./EnlargedWorkloadsGraph";
import { WorkloadsMetricsGraphWithStatus } from "./WorkloadsMetricsGraphWithStatus";

import { WorkloadMetricsTabContextProvider } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/WorkloadMetricsTabContext";
import { useWorkloadMetricsTabContext } from "@/components/ResourceView/tabs/WorkloadMetricsTab/context/useWorkloadMetricsTabContext";

export const DriftWorkloadsMetricsGraph: React.FC<WorkloadsGraphProps> = ({
  type,
  loadingStateId,
  title,
  syncId,
  komodorUids,
}) => {
  const { initialLoadTime, selectedAggregationMetric, timeWindow } =
    useWorkloadMetricsTabContext();
  const [isGraphEnlarged, setIsGraphEnlarged] = useState(false);

  const {
    graphProps,
    error,
    loadingStates,
    isLoading,
    hasGraphData,
    toEpochMs,
    linesContent,
  } = useWorkloadsMetrics({
    type,
    komodorUids,
    endTime: initialLoadTime,
  });

  return (
    <>
      {isGraphEnlarged && (
        <WorkloadMetricsTabContextProvider
          givenTimeWindow={timeWindow}
          givenAggregationMetric={selectedAggregationMetric}
        >
          <EnlargedWorkloadsGraph
            endTime={toEpochMs}
            onClose={() => setIsGraphEnlarged(false)}
            type={type}
            komodorUids={komodorUids}
            title={title}
          />
        </WorkloadMetricsTabContextProvider>
      )}
      <WorkloadsMetricsGraphWithStatus
        loadingStateId={loadingStateId}
        graphProps={graphProps}
        error={error}
        loadingStates={loadingStates}
        isLoading={isLoading}
        hasGraphData={hasGraphData}
        linesContent={linesContent}
        title={title}
        komodorUids={komodorUids}
        type={type}
        syncId={syncId}
        onEnlargeGraph={() => setIsGraphEnlarged(true)}
      />
    </>
  );
};
