import React from "react";

import { MetricsNotSupportedSection } from "../MetricsNotSupportedSection";

import { GraphContainerWithStatus } from "@/components/Metrics/GraphContainerWithStatus";
import {
  MetricsContainer,
  MainContainer,
  GraphsContainer,
} from "@/components/ResourceView/tabs/MetricsTab/styles";

export const EmptyStateMetricsGraphs: React.FC<{ komodorUids: string[] }> = ({
  komodorUids,
}) => {
  return (
    <MetricsContainer>
      <MetricsNotSupportedSection komodorUids={komodorUids} />
      <MainContainer>
        <GraphsContainer>
          <GraphContainerWithStatus
            uid={undefined}
            hasGraphData={false}
            error={undefined}
            loading={false}
            children={undefined}
            title="Memory Utilizations"
            customEmptyState={{
              color: "white",
              message: "Select a baseline service to compare metrics",
            }}
          />
          <GraphContainerWithStatus
            uid={undefined}
            hasGraphData={false}
            error={undefined}
            loading={false}
            children={undefined}
            title="CPU Utilizations"
            customEmptyState={{
              color: "white",
              message: "Select a baseline service to compare metrics",
            }}
          />
        </GraphsContainer>
      </MainContainer>
    </MetricsContainer>
  );
};
