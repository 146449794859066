import React from "react";
import { ChipsContainer } from "../Select/AdvancedMultiSelect/components/ChipsContainer/ChipsContainer";

export type ChipListProps = {
  labels: string[];
  restrictedDimension: "width" | "height";
  onRemoveItem?: (value: string | number | undefined) => void;
  className?: string;
};

export const ChipList: React.FC<ChipListProps> = ({
  labels,
  restrictedDimension,
  onRemoveItem,
  className,
}) => {
  return (
    <ChipsContainer
      className={className}
      selectedItems={labels.map((l) => ({
        label: l,
        value: l,
      }))}
      onRemoveItem={onRemoveItem}
      size="small"
      restrictedDimension={restrictedDimension}
      enableLineBreaks={restrictedDimension === "height" ? true : false}
    />
  );
};
